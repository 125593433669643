import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import cx from 'classnames';
import React from 'react';
import { addEmailModalActions } from 'actions/action-add-email-modal';
import { assignRankingAndKeys } from 'services/utils/demographic-service';
import { AxiosPromise } from 'axios';
import { buildQaId } from 'utils/build-qa-id';
import { change, Field, InjectedFormProps, reduxForm, untouch } from 'redux-form';
import { compose } from 'recompose';
import { contactEnrollment, emailTypes } from 'constants/lists';
import { ContactUtil } from 'utils/contact-util';
import { editContact } from 'actions/action-contacts';
import { editPatient } from 'actions/action-patient';
import { getModalStyle } from 'services/utils/styles-service';
import { Grid, Modal, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IState } from 'interfaces/redux/IState';
import { nameOfFactory } from 'utils/types-util';
import { PatientUtil } from 'utils/patient-util';
import { valueIfPatientSelected, useContactOptions } from 'hooks/useContactOptions';
import { required, validateEmail } from 'components/form/validation/validation';
import { useDispatch, useSelector } from 'react-redux';
import {
  renderDropdown,
  renderRadioGroup,
  renderTextField,
} from 'components/form/field/redux-field';
import { styles } from '../add-address-modal/add-address-modal.styles';
import { IProps } from './interfaces/IProps';
import { IFormProps } from './interfaces/IFormProps';

// #region interfaces for the component
interface IAddEmailModalProps extends IProps, InjectedFormProps<IFormProps> {}
interface IStateProps {
  initialValues: Partial<IFormProps>;
}
type Props = IStateProps & IAddEmailModalProps;
// #endregion

// #region constant things
const qaIdBuilder = buildQaId('add-email-modal');
const nameOfFormFields = nameOfFactory<IFormProps>();
// #endregion

const AddEmailModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes } = props;

  // #region component state
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [saveDisabled, setSaveDisabled] = React.useState<boolean>(false);
  // #endregion

  // #region redux
  const patient = useSelector((state: IState) => state.patient);
  const contacts = useSelector((state: IState) => state.contactList);
  const addEmailModal = useSelector((state: IState) => state.addEmailModal);
  const contactOptions = useContactOptions();
  const dispatch = useDispatch();
  // #endregion

  // #region functions
  const handleSubmit = async (formValues: Partial<IFormProps>) => {
    setSaveDisabled(true);
    const { contact, ...resetFormValues } = formValues;

    if (contact === valueIfPatientSelected) {
      submitNewPatientEmail(resetFormValues);
    } else if (contact) {
      submitNewContactEmail(contact, resetFormValues);
    }
  };

  const handleCancel = (): void => {
    setErrorMessage('');
    setSaveDisabled(false);
    props.reset();
    if (addEmailModal.formId && addEmailModal.field) {
      dispatch(untouch(addEmailModal.formId, addEmailModal.field));
    }
    dispatch(addEmailModalActions.resetModal());
  };

  const formatFormValues = (formValues: Partial<IFormProps>) => {
    const { email, ...restFormValues } = formValues;
    const formatted = {
      ...restFormValues,
      value: email,
    };
    return formatted;
  };

  const formatFieldValue = (value: string) => {
    if (addEmailModal.mode === 'therapies') {
      return {
        label: value,
        value,
      };
    }
    return value;
  };

  const submitNewPatientEmail = (formValues: Partial<IFormProps>): void => {
    const formattedFormValues = formatFormValues(formValues);
    const rankedEmails = assignRankingAndKeys(
      (patient.emails || []).concat(formattedFormValues as any),
    );
    const payload = {
      ...PatientUtil.convertPatientFromStateForUpdate(patient),
      emails: JSON.stringify(rankedEmails),
    };
    (dispatch(editPatient(payload) as unknown) as AxiosPromise<any>)
      .then((result: any) => {
        if (addEmailModal.formId && addEmailModal.field) {
          const fieldValue = `${formValues.use} - ${formValues.email}`;
          dispatch(change(addEmailModal.formId, addEmailModal.field, formatFieldValue(fieldValue)));
        }
        handleCancel();
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update patient');
      });
  };

  const submitNewContactEmail = (contactId: number, formValues: Partial<IFormProps>): void => {
    const theContact = contacts[contactId];
    const formattedFormValues = formatFormValues(formValues);
    const rankedEmails = assignRankingAndKeys(theContact.emails.concat(formattedFormValues as any));
    const payload = {
      ...ContactUtil.convertContactFromStateForUpdate(theContact),
      emails: JSON.stringify(rankedEmails),
    };

    (dispatch(editContact(payload)) as unknown as AxiosPromise<any>)
      .then(result => {
        if (addEmailModal.formId && addEmailModal.field) {
          const formValue = `${theContact.first_name} ${theContact.last_name} (${theContact.relationship}) - ${formValues.email}`;
          dispatch(change(addEmailModal.formId, addEmailModal.field, formatFieldValue(formValue)));
        }
        handleCancel();
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update contact');
      });
  };
  // #endregion

  return (
    <Modal open={addEmailModal.visible} data-qa-id={qaIdBuilder('modal')}>
      <form data-qa-id={qaIdBuilder('form')}>
        <div style={getModalStyle()} className={classes.addModal}>
          <Grid container>
            {errorMessage && (
              <Grid item xs={12} className={classes.row}>
                <Typography className={classes.error}>{errorMessage}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Typography variant="h6" className={classes.heading}>
              Add New Email
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Field
                  name={nameOfFormFields('contact')}
                  label="Contact *"
                  component={renderDropdown}
                  fields={contactOptions}
                  validate={[required]}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={nameOfFormFields('use')}
                  label="Type *"
                  component={renderDropdown}
                  fields={emailTypes}
                  validate={[required]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={cx(classes.row, classes.lastRow)}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Field
                  label="Email *"
                  name={nameOfFormFields('email')}
                  validate={[required, validateEmail]}
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={7}>
                <Field
                  label="Email Enrollment *"
                  name={nameOfFormFields('enrollment')}
                  radioMap={contactEnrollment}
                  component={renderRadioGroup}
                  validate={[required]}
                  caption
                  width="100%"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ConfirmationPanel
              handleSubmit={props.handleSubmit(handleSubmit)}
              handleCancel={handleCancel}
              disableSubmit={saveDisabled}
            />
          </Grid>
        </div>
      </form>
    </Modal>
  );
};

export default compose<Props, IAddEmailModalProps>(
  withStyles(styles),
  reduxForm({ form: 'add-email-modal' }),
)(AddEmailModal);
