import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CustomerDropdown from '../components/customer-dropdown';
import { SectionTitleStyled } from '../components/section-title';
import { IHocProps } from './interface';
import HolidayTable from './table';
import { HolidayManagerStyles } from './index.styles';

const HolidayManager = ({
  loading,
  selectedCustomerId,
  setSelectedCustomerId,
  memorizedHolidays,
}: IHocProps): JSX.Element => (
  <>
    <SectionTitleStyled title="Holidays Manager" />
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <CustomerDropdown
              isLabelOn
              isSelectedOption
              selectedCustomerId={selectedCustomerId}
              setSelectedCustomerId={customerId => setSelectedCustomerId(customerId)}
            />
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <Grid item>
          <CircularProgress />
        </Grid>
      ) : memorizedHolidays.length === 0 ? (
        selectedCustomerId === 0 || 'No holidays for this customer'
      ) : (
        <HolidayTable data={memorizedHolidays} />
      )}
    </Grid>
  </>
);

export default withStyles(HolidayManagerStyles)(HolidayManager);
