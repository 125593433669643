import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { CounselingStatus } from 'interfaces/enums/TaskStatuses/CounselingStatus';
import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';
import { windowFeatureIsEnabled } from 'config/window-features';
import { StatusMap } from '../interfaces/StatusMap';
import { TaskStatus } from '../interfaces/enums/TaskStatus';
import { TaskCategory } from '../interfaces/enums/TaskCategory';

const dynamicFillDeliveryConfirmationStatusMap = () => {
  const FillDeliveryConfirmationStatusMap: StatusMap = {
    [FillDeliveryConfirmationStatus.Shipped]: {
      status: TaskStatus.Shipped,
      category: TaskCategory.Outstanding,
    },
    [FillDeliveryConfirmationStatus.WillPickUp]: {
      status: TaskStatus.WillPickUp,
      category: TaskCategory.Outstanding,
    },
    [FillDeliveryConfirmationStatus.Delivered]: {
      status: TaskStatus.Delivered,
      category: TaskCategory.Done,
    },
    [FillDeliveryConfirmationStatus.PickedUp]: {
      status: TaskStatus.PickedUp,
      category: TaskCategory.Done,
    },
    [FillDeliveryConfirmationStatus.DeliveryException]: {
      status: TaskStatus.DeliveryException,
      category: TaskCategory.InProgress,
    },
    [FillDeliveryConfirmationStatus.Canceled]: {
      status: TaskStatus.Canceled,
      category: TaskCategory.Archived,
    },
    [FillDeliveryConfirmationStatus.CanceledDeceased]: {
      status: TaskStatus.Canceled,
      category: TaskCategory.Archived,
      reason: 'Patient Deceased',
    },
    [FillDeliveryConfirmationStatus.Dispense]: {
      status: TaskStatus.Dispense,
      category: TaskCategory.Outstanding,
    },
    [FillDeliveryConfirmationStatus.Packed]: {
      status: TaskStatus.Packed,
      category: TaskCategory.Outstanding,
    },
    [FillDeliveryConfirmationStatus.NeedsReview]: {
      status: TaskStatus.NeedsReview,
      category: TaskCategory.Outstanding,
      hideFromStatusSelector: !windowFeatureIsEnabled('fdc_statuses'),
    },
  };
  return FillDeliveryConfirmationStatusMap;
};

const dynamicFillCoordinationStatusMap = () => {
  const FillCoordinationStatusMap: StatusMap = {
    [FillCoordinationStatus.Required]: {
      status: TaskStatus.Required,
      category: TaskCategory.Outstanding,
      nextStatus: FillCoordinationStatus.Completed,
    },
    [FillCoordinationStatus.In_Progress_Call_Patient]: {
      status: TaskStatus.InProgressCallPatient,
      category: TaskCategory.Outstanding,
      nextStatus: FillCoordinationStatus.Completed,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.In_Progress_Patient_Response_Ready]: {
      status: TaskStatus.InProgressPatientResponseReady,
      category: TaskCategory.Outstanding,
      nextStatus: FillCoordinationStatus.Completed,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.In_Progress_Waiting_for_Patient_Response]: {
      status: TaskStatus.InProgressWaitingForPatientResponse,
      category: TaskCategory.Outstanding,
      nextStatus: FillCoordinationStatus.Completed,
      hideFromStatusSelector: !windowFeatureIsEnabled('fc_self_service'),
    },
    [FillCoordinationStatus.In_Progress_Secure_Link_Expired]: {
      status: TaskStatus.InProgressSecureLinkExpired,
      category: TaskCategory.Outstanding,
      nextStatus: FillCoordinationStatus.Completed,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.In_Progress]: {
      status: TaskStatus.InProgress,
      category: TaskCategory.Outstanding,
      nextStatus: FillCoordinationStatus.Completed,
    },
    [FillCoordinationStatus.NeedsReview]: {
      status: TaskStatus.NeedsReview,
      category: TaskCategory.InProgress,
      nextStatus: FillCoordinationStatus.Completed,
    },
    [FillCoordinationStatus.Completed]: {
      status: TaskStatus.Completed,
      readonly: true,
      category: TaskCategory.Done,
    },
    [FillCoordinationStatus.Canceled_MedicationDiscontinued]: {
      status: TaskStatus.Canceled,
      reason: 'Medication Discontinued',
      category: TaskCategory.Archived,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.Canceled_MedicationPlacedOnHold]: {
      status: TaskStatus.Canceled,
      reason: 'Medication Placed on Hold',
      category: TaskCategory.Archived,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.Canceled_PharmacyNoLongerFilling]: {
      status: TaskStatus.Canceled,
      reason: 'Pharmacy no Longer Filling',
      category: TaskCategory.Archived,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.Canceled_PatientUnreachable]: {
      status: TaskStatus.Canceled,
      reason: 'Patient Unreachable',
      category: TaskCategory.Archived,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.Canceled_PatientDeceased]: {
      status: TaskStatus.Canceled,
      reason: 'Patient Deceased',
      category: TaskCategory.Archived,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.Canceled_TherapyComplete]: {
      status: TaskStatus.Canceled,
      reason: 'Therapy Complete',
      category: TaskCategory.Archived,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.Canceled_Other]: {
      status: TaskStatus.Canceled,
      reason: 'Other',
      category: TaskCategory.Archived,
      hideFromStatusSelector: true,
    },
    [FillCoordinationStatus.Canceled]: {
      status: TaskStatus.Canceled,
      category: TaskCategory.Archived,
    },
  };
  return FillCoordinationStatusMap;
};

const CounselingStatusMap: StatusMap = {
  [CounselingStatus.Required]: {
    status: TaskStatus.Required,
    category: TaskCategory.Outstanding,
    nextStatus: CounselingStatus.Completed,
  },
  [CounselingStatus.Scheduled]: {
    status: TaskStatus.Scheduled,
    category: TaskCategory.InProgress,
    nextStatus: CounselingStatus.Completed,
  },
  [CounselingStatus.PharmacistReviewNeeded]: {
    status: TaskStatus.PharmacistReviewNeeded,
    category: TaskCategory.InProgress,
    nextStatus: CounselingStatus.Completed,
  },
  [CounselingStatus.Completed]: {
    status: TaskStatus.Completed,
    category: TaskCategory.Done,
    readonly: true,
  },
  [CounselingStatus.Canceled]: {
    status: TaskStatus.Canceled,
    category: TaskCategory.Archived,
    nextStatus: CounselingStatus.Canceled,
  },
  [CounselingStatus.OnHold]: { status: TaskStatus.OnHold, category: TaskCategory.InProgress },
  [CounselingStatus.AttemptedToReachPatient]: {
    status: TaskStatus.AttemptedToReachPatient,
    category: TaskCategory.InProgress,
    nextStatus: CounselingStatus.Completed,
  },
  [CounselingStatus.CanceledDeceased]: {
    status: TaskStatus.Canceled,
    category: TaskCategory.Archived,
    reason: 'Patient Deceased',
    hideFromStatusSelector: true,
  },
  [CounselingStatus.CanceledByTherapyOptIn]: {
    status: TaskStatus.Canceled,
    category: TaskCategory.Archived,
    hideFromStatusSelector: true,
  },
};

const FillCoordinationStatusMap = dynamicFillCoordinationStatusMap();
const FillDeliveryConfirmationStatusMap = dynamicFillDeliveryConfirmationStatusMap();

export {
  FillCoordinationStatusMap,
  CounselingStatusMap,
  FillDeliveryConfirmationStatusMap,
  dynamicFillCoordinationStatusMap,
  dynamicFillDeliveryConfirmationStatusMap,
};
