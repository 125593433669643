import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { convertMapToList } from 'reducers/helper-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { goToAnchor } from 'utils/react-scrollable-anchor';
import { styles } from '../task-detail-styles';
import AddFAData from './add-fa-data';

import CopayCardDetail from './copay-card/copay-card-detail';
// eslint-disable-next-line max-len
import PrescriptionAssistanceProgramDetail from './prescription-assistance-program/prescription-assistance-program-detail';
import FoundationDetail from './foundation/foundation-detail';
import { FinancialAssistanceTypes } from '../../../constants/enums';
import { LinkIcon } from '../../../components/icons/icons';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { withCustomer } from '../../../helpers/router';
import { CATEGORY_ARCHIVED, FA } from '../../../constants';
import { fetchFinancialAssistance } from '../../../actions/action-financials';
import { TaskStatus } from '../../../interfaces/enums/TaskStatus';
import DetailField from '../../../components/form/field/field';
import { numberToDollars } from '../../../services/utils/formatting';

const FaData = props => {
  const [open, setOpen] = useState(false);
  const loadingFAs = useTypedSelector(state => !state.financials.financial_assistances.loaded);
  const therapies = useSelector(state => state.therapies);
  const financials = useSelector(state => state.financials);
  const tasks = useTypedSelector(state => state.tasks.data);
  const statuses = useTypedSelector(state => state.lookups.lookupStatusReasons);
  const selectedPatientId = useTypedSelector(state => state.selectedPatientId);
  const incomes = useTypedSelector(state => state.financials.incomes?.data);
  const latestIncome = useMemo(
    () =>
      Object.values(incomes).reduce((previousValue, currentValue) => {
        if (previousValue?.year >= currentValue.year) {
          return previousValue;
        }
        return currentValue;
      }, undefined),
    [incomes],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFinancialAssistance(selectedPatientId));
  }, [selectedPatientId]);
  const handleToggle = () => {
    setOpen(!open);
  };

  const { task, classes, targetStatusId, archived } = props;
  const hideAddFABtn = useMemo(
    () =>
      statuses.some(
        status =>
          targetStatusId === status.id &&
          status.resource === FA.toLowerCase() &&
          status.category_id === CATEGORY_ARCHIVED,
      ),
    [statuses, targetStatusId],
  );

  const existingTask = tasks?.[`FA${task.existing_task_id}`];
  const existingTherapy = therapies?.data?.[existingTask?.therapy_id];

  let { therapy } = props;
  if (!therapy) {
    therapy = therapies.data[task.therapy_id];
  }
  const financialAssistances = convertMapToList(financials.financial_assistances.data);
  const copayCardStatuses = financials.financial_assistance_category_statuses.filter(
    f => f.category === FinancialAssistanceTypes.CopayCard,
  );
  const papStatuses = financials.financial_assistance_category_statuses.filter(f =>
    [FinancialAssistanceTypes.Pap, FinancialAssistanceTypes.Mpap].includes(f.category),
  );
  const foundationStatuses = financials.financial_assistance_category_statuses.filter(f =>
    [
      FinancialAssistanceTypes.InternalFoundation,
      FinancialAssistanceTypes.ExternalFoundation,
      FinancialAssistanceTypes.Foundation,
    ].includes(f.category),
  );

  const copayCardFinancialAssistances =
    financialAssistances && financialAssistances.length > 0 // eslint-disable-line
      ? financialAssistances.filter(
          f =>
            f.task_fa_id === task.id &&
            copayCardStatuses.some(e => e.id === f.financial_assistance_type),
        )
      : [];

  const papFinancialAssistances =
    financialAssistances && financialAssistances.length > 0 // eslint-disable-line
      ? financialAssistances.filter(
          f =>
            f.task_fa_id === task.id && papStatuses.some(e => e.id === f.financial_assistance_type), // eslint-disable-line
        )
      : [];

  const foundationFinancialAssistances =
    financialAssistances && financialAssistances.length > 0 // eslint-disable-line
      ? financialAssistances.filter(
          f =>
            f.task_fa_id === task.id &&
            foundationStatuses.some(e => e.id === f.financial_assistance_type),
        )
      : [];

  const isTaskApprovedOrSubmitted = () => {
    if (financialAssistances.some(x => x.task_fa_id === task.id && x.zhi_program_id !== null)) {
      if (
        [TaskStatus.Submitted, TaskStatus.Approved, TaskStatus.NotRequested].includes(
          task?.task_status_text,
        )
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const shouldOpenEditFAForm = () => {
    if (
      financialAssistances.some(x => x.task_fa_id === task.id && x.zhi_program_id !== null) &&
      [TaskStatus.Submitted].includes(task?.task_status_text)
    ) {
      return true;
    }
    return false;
  };

  const hasFinancialAssistances = !!financialAssistances?.find(f => f.task_fa_id === task.id);

  const handleRedirectToTherapy = existingTherapy => {
    const { history } = props;
    const redirectLinkToTherapy = `/patients/${existingTherapy.patient_id}/therapies?sidebar=tasks`;
    history.push(withCustomer(redirectLinkToTherapy));
    goToAnchor(`THERAPY_${existingTherapy.id}`);
  };

  const renderCopayCardList = () =>
    copayCardFinancialAssistances.length > 0 && (
      <Grid item xs={12}>
        <Typography variant="body2">Manufacturer Copay Cards:</Typography>
        {financialAssistances
          .filter(
            f =>
              f.task_fa_id === task.id &&
              copayCardStatuses.some(e => e.id === f.financial_assistance_type),
          )
          .map(financialAssistanceObj => (
            <div key={financialAssistanceObj.id}>
              <Divider className={classes.divider} />

              <CopayCardDetail
                type={
                  copayCardStatuses.find(
                    f => f.id === financialAssistanceObj.financial_assistance_type,
                  ).sub_category
                }
                statuses={copayCardStatuses}
                financialAssistance={financialAssistanceObj}
              />
            </div>
          ))}
      </Grid>
    );
  const renderPAPList = () =>
    papFinancialAssistances.length > 0 && (
      <Grid item xs={12}>
        <Typography variant="body2">Financial Assistance Programs:</Typography>
        {financialAssistances
          .filter(
            f =>
              f.task_fa_id === task.id &&
              papStatuses.some(e => e.id === f.financial_assistance_type),
          )
          .map(financialAssistanceObj => (
            <div key={financialAssistanceObj.id}>
              <Divider className={classes.divider} />
              <PrescriptionAssistanceProgramDetail
                statuses={papStatuses}
                financialAssistance={financialAssistanceObj}
              />
            </div>
          ))}
      </Grid>
    );

  const renderIncome = () => {
    if (!latestIncome) {
      return <></>;
    }

    return (
      <Grid item container xs={12} spacing={3} paddingTop={3}>
        <Grid item xs={12}>
          <Typography variant="body2">Income:</Typography>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={3} className={classes.textContainer}>
          <DetailField fieldName="Income Year" field={latestIncome.income_year} variant="raw" />
        </Grid>
        <Grid item xs={3} className={classes.textContainer}>
          <DetailField
            fieldName="Total Income From Wages"
            field={numberToDollars(latestIncome.taxable_income)}
            variant="raw"
          />
        </Grid>
        <Grid item xs={3} className={classes.textContainer}>
          <DetailField fieldName="Family Size" field={latestIncome.family_size} variant="raw" />
        </Grid>
      </Grid>
    );
  };

  const renderFoundationList = () =>
    foundationFinancialAssistances.length > 0 && (
      <Grid item xs={12}>
        <Typography variant="body2">Foundations:</Typography>
        {financialAssistances
          .filter(
            f =>
              f.task_fa_id === task.id &&
              foundationStatuses.some(e => e.id === f.financial_assistance_type),
          )
          .map(financialAssistanceObj => (
            <div key={financialAssistanceObj.id}>
              <Divider className={classes.divider} />
              <FoundationDetail
                type={
                  foundationStatuses.find(
                    f => f.id === financialAssistanceObj.financial_assistance_type,
                  ).sub_category
                }
                displayEdit={shouldOpenEditFAForm()}
                statuses={foundationStatuses}
                financialAssistance={financialAssistanceObj}
              />
            </div>
          ))}
      </Grid>
    );

  return (
    <>
      {open && <AddFAData handleCancel={handleToggle} therapy={therapy} task={task} />}
      {!hideAddFABtn && !hasFinancialAssistances && (
        <Grid item xs={3} align="left">
          {!loadingFAs ? (
            <Button
              className={classes.newFA}
              name="add_fa_button"
              id="AddFAButton"
              variant="outlined"
              onClick={handleToggle}
            >
              + Financial Assistance
            </Button>
          ) : (
            <div>Loading financial assistances...</div>
          )}
        </Grid>
      )}
      {renderCopayCardList()}
      {renderPAPList()}
      {isTaskApprovedOrSubmitted() && renderFoundationList()}
      {isTaskApprovedOrSubmitted() && renderIncome()}
      {existingTherapy && (
        <Grid item xs align="left">
          <Button
            onClick={() => handleRedirectToTherapy(existingTherapy)}
            variant="contained"
            className={classes.linkedDrugButton}
          >
            <LinkIcon />
            <span className={classes.linkOptionsSpan}>
              {`${existingTherapy.drug_name} ${existingTherapy.strength || ''} ${
                existingTherapy.strength_unit_of_measure
              }`}
            </span>
          </Button>
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(FaData);
