import { isEmpty } from 'lodash';
import moment from 'moment';
import { TaskStatus } from '../../../interfaces/enums/TaskStatus';
import { FA } from '../../../constants';

export const FinancialAssistanceStatus = {
  Active: 1,
  Inactive: 0,
};

export const canFAWithFundingAvailableBeApproved = (
  tasks,
  financialAssistances,
  taskIds,
  taskType,
  activeForms,
  valid,
) => {
  const isRequestedTasks = Object.values(tasks).filter(
    faTask =>
      taskIds.some(v => v === faTask.id) &&
      faTask.task_status_text === TaskStatus.Submitted &&
      taskType === FA,
  );

  const [fa] = Object.values(financialAssistances).filter(
    f => isRequestedTasks.some(v => v.id === f.task_fa_id) && f.zhi_program_id !== null,
  );
  if (fa !== undefined) {
    const requiredFieldsEmpty =
      fa.award_amount === undefined ||
      isEmpty(fa.program_name) ||
      fa.status === undefined ||
      isEmpty(fa.expiration_date);
    return !requiredFieldsEmpty && isRequestedTasks.length;
  }
  return true;
};

export const isFAExpired = fa => {
  const expirationDate = moment(fa.expiration_date);
  return moment().utc().isAfter(expirationDate);
};
