import { FETCH_SHIPPING_HOLIDAYS } from 'constants/index';

const initialState = {
  shippingHolidays: {
    shippingCustomerHolidays: [],
    shippingCourierHolidays: [],
    shippingUpsHolidays: [],
  },
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload: { data: unknown } },
) => {
  switch (type) {
    case FETCH_SHIPPING_HOLIDAYS:
      if (payload.data) {
        return {
          ...state,
          shippingHolidays: payload.data,
        };
      }
      return state;
    default:
      return state;
  }
};
