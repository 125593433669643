/* eslint-disable import/no-unresolved */
import {
  Button,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Popover,
  SwipeableDrawer,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Error as ErrorIcon, Sms as SmsIcon } from '@mui/icons-material';
import Audit from 'components/display/audit';
import { notifyError } from 'actions/action-notifications';
import { existsAuditsOfStatus } from 'services/utils/audit-service';
import { updateNoteBar } from 'actions/action-notes';
import { selectPatientTab, reportDuplicatePatientProfiles } from 'actions/action-patient';
import { clearDuplicateProfileInformation } from 'actions/action-patient-merge';
import { fetchPreferredContact } from 'actions/action-contacts';
import {
  addUserCustomListWorkflow,
  deleteUserCustomListItem,
} from 'actions/action-user-custom-lists';
import { PhoneUtils } from 'utils/phone-utils';
import classNames from 'classnames';
import { auditContext } from 'constants/lists';
import {
  PatientOverviewCopy,
  ScheduleIcon,
  AdherenceLessButton,
  AdherenceMoreButton,
  MoreMrnsButton,
  LessMrnsButton,
  DuplicateProfilesDetails,
} from 'components/icons/icons';
import Communication from 'components/note/communication';
import Intervention from 'components/note/intervention';
import Incident from 'components/note/incident';
import NoteBar from 'components/note/note';
import NoteDisplay from 'components/note/note-display';
import { usePatient } from 'hooks/usePatient';
import { useFCSocket, useFaSocket, useSocket, useRulesEngineSocket } from 'hooks/useSocket';
import { ReactComponent as InterventionIcon } from 'lib/logos/intervention.svg';
import { ReactComponent as IncidentIcon } from 'lib/logos/incident.svg';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { globalZIndex } from 'constants/z-index';
import DocumentBar from 'components/document/document-bar';
// eslint-disable-next-line max-len
import BenefitsInvestigationModal from 'containers/patient/therapy/therapy-benefits-investigation/benefits-investigaiton-modal';
// eslint-disable-next-line max-len
import BenefitsInvestigationDetailedHistoryModal from 'containers/patient/therapy/therapy-benefits-investigation/benefits-investigation-detailed-history-modal';
import { getDisplayNotes, getNoteTagId } from 'services/utils/note-service';
import { getAddressForDisplay, getFilteredList } from 'services/utils/task-service';
import AbbreviatedList from 'components/display/abbreviated-list';
import Label from 'components/display/label';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import { HorizontalTabs } from 'components/horizontal-tabs/horizontal-tabs';
import CommunicationForm from 'components/note/communication-form';
import {
  FinancialAssistanceTypes,
  NoteBarTrigger,
  FinancialAssistanteStatus,
} from 'constants/enums';
import { HighlightStar } from 'components/highlight-star/highlight-star';
import AdherenceSection from 'components/adherence/adherence-section';
import { PatientMergeClient } from 'clients/patient-merge';
import { windowFeatureIsEnabled } from 'config/window-features';
import TaskPaApprovedModal from 'components/task-pa-approved-modal';
import TaskFCDispensingModal from 'components/task-fc-disensing-modal/task-fc-disensing-modal';
import AddContactModal from 'components/add-contact-modal/add-contact-modal';
import { useContactOptions } from 'hooks/useContactOptions';
import { DISPENSING_STATUSES } from 'constants';
import { LookupPaymentMethodTypes } from 'containers/add-payment-method/types';
import MRNSection from './components/multiple-mrns-section';
import { withCustomer } from '../../../helpers/router';
import { ReactComponent as ContactIcon } from '../../../lib/logos/talking.svg';
import { styles } from './patient-overview-styles';
import EligibilityCheckModal from '../../../components/eligibility-check/eligibility-check-modal';
import AddAddressModal from '../../../components/add-address-modal/add-address-modal';
import AddEmailModal from '../../../components/add-email-modal/add-email-modal';
import AddPhoneModal from '../../../components/add-phone-modal/add-phone-modal';
import { AddPbmInsuranceModal } from '../modals/add-pbm-insurance/add-pbm-insurance-modal';
import { PreferredContact } from './components/preferred-contact';
import { AdherenceComplianceStatusEnum } from '../../../interfaces/redux/IAdherenceData';
import { PatientMergeModal } from '../../../components/patient-merge-modal/index';
import MedSyncBanner from '../med-sync/components/medSyncBanner';
import PatientSmsEmail from './patient-sms-email';
import PatientReconciliationBanner from '../reconciliation/PatientReconciliationBanner';
import AddPaymentMethodModal from '../../add-payment-method/add-payment-method-modal';
import { MultiuserIndicator } from './multiuser-indicator';
import { useDeepDiffSelector } from '../../../utils/deep-diff-props';

function PatientOverview(props) {
  const { location, classes } = props;

  const [selectedUserCustomList] = useState(window.USER.id);
  const patient = useSelector(state => state.patient);
  const patientHasLoaded = !!patient.id;
  const therapies = useSelector(state => state.therapies.data);
  const sourceNumber = useSelector(state => state.lookups.sourceNumber);
  const auditState = useSelector(state => state.audit);
  const patientId = useSelector(state => state.selectedPatientId);
  const tabControl = useSelector(state => state.tabControl);
  const noteBar = useDeepDiffSelector(state => state.noteBar);
  const isLeftDrawerOpen = useSelector(state => state.view.leftDrawer);
  const users = useSelector(state => state.lookups.users);
  const serviceGroups = useSelector(state => state.lookups.serviceGroups);
  const allPinnedNotes = useSelector(state => state.notes.notes.allPinnedNotes);
  const allNonPinnedNotes = useSelector(state => state.notes.notes.allNonPinnedNotes);
  const displayEligibilityCheck = useSelector(state => state.eligibilityCheck.open);
  const specialPopulations = useSelector(state => state.specialPopulations);
  const financials = useSelector(state => state.financials);

  const ref = useRef();
  const adherenceComplianceStatusFromState = useSelector(
    state => state.patient.adherence_compliance_status,
  );
  const userCustomLists = useSelector(state => state.userCustomLists);
  const [patientMerge, setPatientMerge] = useState();
  const [patientMergeInputs, setPatientMergeInputs] = useState();
  const [loadingPatientMerge, setLoadingPatientMerge] = useState(false);

  const [adherenceComplianceStatus, setAdherenceComplianceStatus] = useState(
    adherenceComplianceStatusFromState,
  );
  const [showDuplicateProfilesDetails, setShowDuplicateProfilesDetails] = useState(false);
  const [anchorElAppointmentIcon, setAnchorElAppointmentIcon] = useState(null);
  const [anchorElClinics, setAnchorElClinics] = useState(null);
  const [adherenceDataVisible, setAdherenceDataVisible] = useState(false);
  const [showAdditionalMrns, setShowAdditionalMrns] = useState(false);
  const [currentInternalFoundation, setCurrentInternalFoundation] = useState(null);

  const metrics = useMemo(() => patient.metrics, [patient]);
  const contacts = useContactOptions();

  const { patientTab } = tabControl;
  const {
    source_patient_id: sourcePatientId,
    source_name: sourceName,
    addresses,
    phones,
    dob,
    gender,
    pronouns,
    clinics,
    status,
    appointment,
    patient_preferences_updated: patientPreferencesUpdated,
    id,
    active,
  } = patient;

  const mrnCount = patient?.additional_source_patient_ids?.length // eslint-disable-line
    ? patient.additional_source_patient_ids.length + 1
    : 1;
  const multiMrn = mrnCount > 1;

  const mrnData = [{ source_patient_id: sourcePatientId, source_name: sourceName || '' }].concat(
    patient.additional_source_patient_ids || [],
  );

  let taskListClass = null;
  if (isLeftDrawerOpen) {
    taskListClass = classes.patientOverviewTaskListExpandedContainer;
  }

  const { patientFullName } = usePatient(patient);

  const dispatch = useDispatch();

  const closeNoteBar = useCallback(() => {
    dispatch(updateNoteBar({ display: false }));
  }, [dispatch]);

  const handleChange = (event, selectedTab) => {
    dispatch(selectPatientTab(selectedTab));
  };

  const handlePopoverOpenAppointmentIcon = event => {
    setAnchorElAppointmentIcon(event.currentTarget);
  };

  const handlePopoverCloseAppointmentIcon = () => {
    setAnchorElAppointmentIcon(null);
  };

  const handlePopoverOpenClinics = event => {
    setAnchorElClinics(event.currentTarget);
  };

  const handlePopoverCloseClinics = () => {
    setAnchorElClinics(null);
  };

  const toggleAdherenceDataVisible = () => {
    setAdherenceDataVisible(!adherenceDataVisible);
    setShowAdditionalMrns(false);
  };
  const toggleAdditionalMRNsDataVisible = () => {
    setShowAdditionalMrns(!showAdditionalMrns);
    setAdherenceDataVisible(false);
  };

  const toggleCustomListStar = () => {
    const userCustomListsForUser = userCustomLists.users.find(
      user => user.user_id === window.USER.id,
    );
    const userPatientList = userCustomListsForUser?.lists.find(
      list => list.user_id === window.USER.id,
    );
    const patientOnList = userPatientList?.list.find(p => p.patient_id === patient.id);
    if (patientOnList) {
      dispatch(deleteUserCustomListItem(Number(patientOnList?.id)));
    } else {
      dispatch(addUserCustomListWorkflow(Number(userPatientList?.id), patient));
    }
  };

  const openSupportTicket = () => {
    dispatch(reportDuplicatePatientProfiles(patient));
  };

  const openAppointmentIcon = Boolean(anchorElAppointmentIcon);
  const openClinics = Boolean(anchorElClinics);

  useEffect(() => {
    setCurrentInternalFoundation(null);
    const { financial_assistances } = financials || {};
    if (financial_assistances?.data) {
      const internalFoundations = Object.values(financial_assistances.data)
        .filter(
          f =>
            f.financial_assistance_type_category === FinancialAssistanceTypes.InternalFoundation &&
            f.status === FinancialAssistanteStatus.Active,
        )
        .sort((a, b) => (Date.parse(a.expiration_date) > Date.parse(b.expiration_date) ? -1 : 1));

      const [internalFoundation] = internalFoundations;
      if (internalFoundation) {
        setCurrentInternalFoundation(internalFoundation);
      }
    }
  }, [financials]);

  useEffect(() => {
    if (id) {
      dispatch(fetchPreferredContact(id));
    }
  }, [id, patientPreferencesUpdated]);

  useEffect(() => {
    setAdherenceDataVisible(false);
    setShowDuplicateProfilesDetails(false);
  }, [patientId]);

  useEffect(() => {
    setAdherenceComplianceStatus(adherenceComplianceStatusFromState);
  }, [adherenceComplianceStatusFromState]);

  const validPaymentMethod = useMemo(() => {
    if (!patient || !patient.payment_methods) return null;
    return patient.payment_methods.reduce((payMethod, cPayMethod) => {
      if (
        cPayMethod.payment_type === LookupPaymentMethodTypes.VirtualCreditCard ||
        !cPayMethod.expiration_date
      ) {
        return payMethod;
      }
      if (!payMethod || !payMethod.expiration_date) {
        return cPayMethod;
      }

      const [payMethodExpiredDate, currentDate] = [
        payMethod.expiration_date,
        cPayMethod.expiration_date,
      ].map(date => {
        const [month, year] = date.split('/');
        return new Date(Number(year), Number(month) - 1, 1);
      });

      return currentDate > payMethodExpiredDate ? cPayMethod : payMethod;
    }, null);
  }, [patient]);

  useSocket(selectedUserCustomList);
  useFaSocket();
  useFCSocket();
  useRulesEngineSocket();

  const generateTabLink = tab => {
    const query = location.search;
    return withCustomer(`/patients/${patientId}/${tab}${query}`);
  };

  const renderSpecialPopulations = (val, additionalValsArr = null) => {
    if (!val) {
      return <></>;
    }
    let hoverTextToShow = val.hoverText || null;
    if (additionalValsArr && additionalValsArr.length > 0) {
      const additionalValsComponent = additionalValsArr.map(v => <Grid xs={12}>{v}</Grid>);
      hoverTextToShow = additionalValsComponent;
    }
    const labelPopulation = (
      <Label
        labelName={`${val.label}` || ''}
        labelType="special-populations"
        key={`specialty_population_${val}_key`}
        className={classes.labelPadding}
      />
    );
    if (hoverTextToShow) {
      return (
        <Tooltip title={hoverTextToShow}>
          <div>{labelPopulation}</div>
        </Tooltip>
      );
    }
    return labelPopulation;
  };

  let adherenceStatusStyle = classes.adherenceStatusTextGrey;
  if (adherenceComplianceStatus === AdherenceComplianceStatusEnum.Checked) {
    adherenceStatusStyle = classes.adherenceStatusTextGrey;
  } else if (adherenceComplianceStatus === AdherenceComplianceStatusEnum.NeedCheck) {
    adherenceStatusStyle = classes.adherenceStatusTextRed;
  } else {
    adherenceStatusStyle = classes.adherenceStatusTextGreen;
  }

  // left-most, and widest col
  const renderDemographicsColumn = () => {
    const patientArborDob = convertToArborDate(dob, true);
    const displayDob = dob ? patientArborDob.getUtcDate(true) : '';
    const patientYears = patientArborDob.getYearsFromNow();
    const displayAge = patientArborDob.getCustomerAgeFormatted();
    const displayName = patient.nickname ? (
      `${patientFullName} (${patient.nickname})`
    ) : patientFullName !== '' ? (
      patientFullName
    ) : (
      <br />
    );
    const adherenceComplianceValue = adherenceComplianceStatus ? (
      adherenceComplianceStatus === AdherenceComplianceStatusEnum.Ok ? (
        'OK'
      ) : (
        'CHECK'
      )
    ) : (
      <br />
    );
    const adherenceComplianceDisplayValue = patientHasLoaded ? adherenceComplianceValue : <br />;

    return (
      <>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.demographicsRowSpacing}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <MultiuserIndicator />
            <Typography display="inline" id="patient-full-name" className={classes.patientName}>
              {displayName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.demographicsRowSpacing}>
          <Typography
            display="inline"
            className={classNames({
              [classes.typographyTextSmall]: true,
              [classes.importantText]: active,
              [classes.inlineTypeographyDemographicsLeft]: true,
            })}
          >
            {status ?? <br />}
          </Typography>
          {gender && (
            <Typography
              display="inline"
              className={classNames(
                classes.typographyTextSmall,
                classes.inlineTypeographyDemographics,
              )}
            >
              {gender}
            </Typography>
          )}
          {pronouns && (
            <Typography
              display="inline"
              className={classNames(
                classes.typographyTextSmall,
                classes.boldText,
                classes.inlineTypeographyDemographicsLeft,
              )}
            >
              {`(${pronouns})`}
            </Typography>
          )}
          <Typography
            display="inline"
            className={classNames(
              classes.typographyTextSmall,
              classes.inlineTypeographyDemographics,
            )}
          >
            {displayDob}
          </Typography>
          {!!displayAge && (
            <Typography
              display="inline"
              className={classNames({
                [classes.typographyTextSmall]: true,
                [classes.boldText]: patientYears < 18 || patientYears >= 65,
                [classes.age]: patientYears < 18 || patientYears >= 65,
                [classes.inlineTypeographyDemographicsRight]: true,
              })}
            >
              {`(${displayAge})`}
            </Typography>
          )}
        </Grid>
        {currentInternalFoundation && (
          <Grid item xs={12}>
            <Typography display="inline" className={classes.typographyTextSmall}>
              Internal Foundation exp.{' '}
              {convertToArborDate(currentInternalFoundation.expiration_date, true).getCustomerDate(
                true,
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {/* Figure out change callback primary to work like the tabs do */}
          {patientHasLoaded ? (
            <AbbreviatedList
              arr={
                specialPopulations && specialPopulations.specialPopulationsList
                  ? specialPopulations.specialPopulationsList
                      .filter(pop => !!pop.active)
                      .map(pop => ({
                        label: pop.type,
                        value: pop.id,
                      }))
                  : []
              }
              changeCallbackPrimary={e => handleChange(e, 'demographics')}
              criteriaField="value"
              maxRowsToShow={2}
              primaryRenderComponent={renderSpecialPopulations}
              orientVertical={false}
            />
          ) : null}
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6}>
            <Typography className={classes.summarySectionHeaderNoPadding}>
              {`MRN ${multiMrn ? `(${mrnCount})` : ''}`}
            </Typography>
            <Grid container className={classes.copyBox}>
              <Grid item container xs={multiMrn ? 8 : 10} className={classes.copyBoxTextContainer}>
                <Typography className={classes.typographyTextSmall} id="patient-mrn">
                  {sourcePatientId || '-'}
                </Typography>
              </Grid>
              <Grid item container xs={2}>
                <Grid container item>
                  <Tooltip title="Copy to clipboard">
                    {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                    <>
                      {sourcePatientId && (
                        <CopyToClipboard
                          text={sourcePatientId}
                          className={
                            multiMrn
                              ? classes.multiMrnCopyToClipboardButton
                              : classes.singleMrnCopyToClipboardButton
                          }
                        >
                          <Button>
                            <PatientOverviewCopy />
                          </Button>
                        </CopyToClipboard>
                      )}
                    </>
                  </Tooltip>
                </Grid>
              </Grid>
              {multiMrn && (
                <Grid item container xs={2} className={classes.additionalMrnsButtonContainer}>
                  <Tooltip
                    title={showAdditionalMrns ? 'Hide Additional MRNs' : 'Show Additional MRNs'}
                  >
                    <Button
                      className={classes.additionalMrnsButton}
                      onClick={toggleAdditionalMRNsDataVisible}
                    >
                      {showAdditionalMrns ? <LessMrnsButton /> : <MoreMrnsButton />}
                    </Button>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <Typography className={classes.summarySectionHeaderNoPadding}>ADHERENCE</Typography>
            <Grid item container className={classes.copyBox}>
              <Grid item xs={9} align="left" className={classes.copyBoxTextContainer}>
                <Typography className={adherenceStatusStyle}>
                  {adherenceComplianceDisplayValue}
                </Typography>
              </Grid>
              <Grid item container xs={3}>
                <Tooltip
                  title={adherenceDataVisible ? 'Hide Adherence Data' : 'Show Adherence Data'}
                >
                  <Button className={classes.adherenceButton} onClick={toggleAdherenceDataVisible}>
                    {adherenceDataVisible ? <AdherenceLessButton /> : <AdherenceMoreButton />}
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item container xs={6}>
            {windowFeatureIsEnabled('med_sync') && patient && <MedSyncBanner />}
          </Grid>
          <Grid item container xs={6}>
            <Box
              borderRadius="3px"
              bgcolor="rgba(142, 166, 188, 0.1)"
              marginTop="8px"
              marginLeft="0px"
              marginRight="0px"
              paddingX="4px"
              width="100%"
              sx={{ display: 'flex' }}
            >
              <Grid item container xs={2} display="inline-flex" paddingTop={1} marginRight={1}>
                <SmsIcon />
              </Grid>
              {metrics?.map(metric => (
                <Grid item container xs={4} display="inline-flex" paddingTop={1}>
                  <Grid item>
                    <Tooltip title={`${metric.name}: ${metric.description}`}>
                      <Typography className={classes.summarySectionHeaderNoPadding}>
                        {metric.short_code}
                      </Typography>
                      {metric.value || '-'}
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderContactColumn = () => {
    const filteredPhones = getFilteredList(phones);
    const filteredAddresses = getFilteredList(addresses);
    const address =
      filteredAddresses && filteredAddresses[0]
        ? getAddressForDisplay(filteredAddresses[0], true)
        : null;
    const phoneNumber =
      filteredPhones && filteredPhones.length > 0 && filteredPhones[0].value
        ? filteredPhones[0].value
        : null;
    const formattedPatientPhoneAvailability = PhoneUtils.formatAvailableTimes(filteredPhones[0]);
    const wkIsVisible = Object.values(therapies || {}).some(
      therapy => therapy.dispensing_status === DISPENSING_STATUSES.OPT_IN,
    );
    const anchorTherapy = therapies?.[patient.asl_anchor_therapy_id] || {};
    const anchorTherapyString = `${anchorTherapy.drug_name} ${anchorTherapy.strength} ${anchorTherapy.strength_unit_of_measure} ${anchorTherapy.dosage_form_description}`;

    return (
      <>
        <Grid item xs={12} className={classes.columnHorizontalDivider}>
          <Typography className={classes.summarySectionHeader}>Contact Information</Typography>
          <Typography noWrap className={classes.typographyTextSmall}>
            {address ?? <br />}
          </Typography>
          <div className={classes.typographyTextSmall}>
            <TalkdeskPhoneNumber
              number={phoneNumber}
              availability={formattedPatientPhoneAvailability}
              owner={patient}
            />
          </div>
          <PatientSmsEmail
            filteredEmails={patient.filteredEmails}
            filteredPhones={patient.filteredPhones}
            filteredWelcomeKit={patient.welcome_kit}
            wkIsVisible={wkIsVisible}
            sourceNumber={sourceNumber}
            patientLoaded={patientHasLoaded}
            expirationDate={validPaymentMethod?.expiration_date}
            patientAutoFCStatus={patient.auto_secure_link}
            autoFCAnchorTherapy={anchorTherapyString}
          />
        </Grid>
        <Grid item xs={12}>
          <PreferredContact />
        </Grid>
      </>
    );
  };

  const renderDisplayClinics = maxClinicsToShow => {
    let clinicText;

    const renderClinicName = (name, lastOne = false) =>
      !!name && (
        <Typography className={classes.typographyTextSmall} noWrap key={name}>
          {lastOne ? `${name}` : `${name}, `}
        </Typography>
      );

    if (!clinics || clinics.length === 0) {
      clinicText = (
        <Typography className={classes.typographyTextSmall} display="inline" noWrap>
          No Active Clinics
        </Typography>
      );
    } else if (clinics.length === 1) {
      clinicText =
        !!clinics[0] && !!clinics[0].name ? (
          <Typography noWrap>{clinics[0].name}</Typography>
        ) : null;
    } else {
      const clinicsInList = clinics
        .slice(0, maxClinicsToShow - 1)
        .map(({ name = null }) => renderClinicName(name));
      const lastClinic = (
        <div
          aria-owns={openAppointmentIcon ? 'mouse-over-popover-clinics' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpenClinics}
          onMouseLeave={handlePopoverCloseClinics}
        >
          <Typography noWrap className={classes.typographyTextSmall}>
            {`${clinics[maxClinicsToShow - 1].name}${
              maxClinicsToShow < clinics.length ? ', ...' : ''
            }`}
          </Typography>
          {clinics.length > maxClinicsToShow && (
            <Popover
              id="mouse-over-popover-clinics"
              open={openClinics}
              anchorEl={anchorElClinics}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverCloseClinics}
              disableRestoreFocus
            >
              <div className={classes.listPopover}>
                <Typography noWrap className={classes.boldText}>
                  Clinics:
                </Typography>

                {clinics
                  .slice(maxClinicsToShow)
                  .map(({ name = null }, index, subClinics) =>
                    renderClinicName(name, index === subClinics.length - 1),
                  )}
              </div>
            </Popover>
          )}
        </div>
      );
      clinicText = (
        <Grid container>
          {clinicsInList}
          {lastClinic}
        </Grid>
      );
    }
    return clinicText;
  };

  const renderClinicalColumn = () => (
    <>
      <Grid item xs={12} className={classes.columnHorizontalDivider}>
        <Grid container>
          <Typography className={classes.summarySectionHeader}>Clinics</Typography>
          {renderDisplayClinics(2)}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.summarySectionHeader}>Next Appointment</Typography>
        {appointment ? (
          <div>
            <Typography
              noWrap
              className={classNames(classes.typographyTextSmall, classes.boldText)}
            >
              {appointment.reason || ''}
            </Typography>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography className={classes.typographyTextSmall}>
                  {convertToArborDate(appointment.start_dt, true).getCustomerDatetime(true)}
                </Typography>
              </Grid>
              <Grid
                xs={2}
                aria-owns={openAppointmentIcon ? 'mouse-over-popover-appointment-icon' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpenAppointmentIcon}
                onMouseLeave={handlePopoverCloseAppointmentIcon}
              >
                <ScheduleIcon className={classes.appointmentIcon} />
                <Popover
                  id="mouse-over-popover-appointment-icon"
                  open={openAppointmentIcon}
                  anchorEl={anchorElAppointmentIcon}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverCloseAppointmentIcon}
                  disableRestoreFocus
                >
                  <div className={classes.listPopover}>
                    <Typography noWrap>{appointment.clinician_name}</Typography>
                    <Typography noWrap>{appointment.source_facility_id}</Typography>
                  </div>
                </Popover>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Typography className={classes.typographyTextSmall}>Not available</Typography>
        )}
      </Grid>
    </>
  );

  const isStarHighlighted = () => {
    const userCustomListsForUser = userCustomLists.users.find(
      user => user.user_id === window.USER.id,
    );
    return userCustomListsForUser?.lists[0]?.list?.some(l => l.patient_id === patient.id);
  };

  // right-most, and narrowest col
  const renderActionBar = () => {
    const tagTypeId = getNoteTagId('Patient');
    return (
      <div className={classes.actionBar}>
        <div className={classes.starIcon}>
          <HighlightStar
            variant="patient-overview"
            onHighlight={toggleCustomListStar}
            isHighlight={isStarHighlighted()}
            additionalUsers={patient.otherUsersWithPatientOnList || []}
          />
        </div>
        <div>
          <Communication
            tagTypeId={tagTypeId}
            tagResourceId={patientId}
            buttonOverride={<ContactIcon className={classes.actionIcon} />}
            selectedPatientId={patientId}
            tagName={patientFullName}
          />
        </div>
        <div>
          <Intervention
            therapy={null}
            tagTypeId={tagTypeId}
            tagResourceId={patientId}
            serviceGroups={serviceGroups}
            taskType="patient"
            inheritedClass={classes.actionIcon}
            buttonOverride={<InterventionIcon className={classes.actionIcon} />}
            activeForPatient
          />
        </div>
        <div>
          <Incident
            tagTypeId={tagTypeId}
            tagResourceId={patientId}
            serviceGroups={serviceGroups}
            taskType="patient"
            inheritedClass={classes.actionIcon}
            buttonOverride={<IncidentIcon className={classes.actionIcon} />}
            activeForPatient
          />
        </div>
      </div>
    );
  };

  // middle-right col
  const renderNotesColumn = () => {
    const tagTypeId = getNoteTagId('Patient');
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      tagTypeId,
      patientId,
      users,
      serviceGroups,
      2,
    );
    return (
      <Grid container>
        <Grid item xs={9}>
          <NoteDisplay
            patientId={patientId}
            tagName={patientFullName}
            tagTypeId={tagTypeId}
            tagResourceId={patientId}
            notes={displayNotes}
            containerClasses={classes}
            maxLine="2"
            isInContext
            patient
          />
        </Grid>
        <Grid item xs={3} className={classes.verticalDividerLeft2}>
          <Grid container className={classes.verticalDividerLeft}>
            {renderActionBar()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderAdherenceSection = () => {
    if (!adherenceDataVisible) {
      return null;
    }
    return <AdherenceSection />;
  };

  const renderMultipleMrnsSection = () => {
    if (!showAdditionalMrns || !multiMrn) {
      return null;
    }
    return <MRNSection mrnData={mrnData} />;
  };

  const getAuditDisplayLabel = (auditCategory, display) => {
    if (
      auditState &&
      auditState[auditCategory] &&
      auditState[auditCategory].length > 0 &&
      existsAuditsOfStatus(auditState[auditCategory])
    ) {
      return (
        <Grid container align-items="center">
          {display || auditCategory}
          <Audit iconType="warning" context={auditContext.Tab} auditCategory={auditCategory} />
        </Grid>
      );
    }
    return display || auditCategory;
  };

  const loadPatientMerge = async () => {
    try {
      const result = await PatientMergeClient.loadPatientMerge(patientId);
      if (result && result.data) {
        setPatientMerge(result.data);
        setPatientMergeInputs(
          result.data.inputs?.map(input => ({
            selectedId: input.selectedId,
            type: input.tagId,
            extraInfo: input.extraInfo,
          })),
        );
        if (ref.current) {
          ref.current.setOpen(true);
        }
      }
    } catch (e) {
      dispatch(notifyError('Could not start patient merge'));
    } finally {
      setLoadingPatientMerge(false);
    }
  };

  const setAsNotDuplicated = async () => {
    try {
      setLoadingPatientMerge(true);
      await PatientMergeClient.setAsNotDuplicated(patient.possibleDuplicateProfiles.duplicateIds);
      dispatch(clearDuplicateProfileInformation());
    } catch (e) {
      dispatch(notifyError('Could not mark as Not Duplicated'));
    } finally {
      setLoadingPatientMerge(false);
    }
  };

  const duplicateProfilesBanner = () =>
    patient?.possibleDuplicateProfiles?.duplicates?.length ? (
      <Grid container className={classes.possibleDuplicateProfilesContainer}>
        <Grid item xs={9}>
          <div className={classes.duplicateProfilesHeader}>
            <ErrorIcon />
            <span className={classes.duplicateProfilesHeaderText}>
              Duplicate profiles may exist for this patient
            </span>
            <Tooltip title={showDuplicateProfilesDetails ? 'Hide Details' : 'Show Details'}>
              <Button
                name="expand_duplicate_profiles_details"
                id="showHideDuplicateProfilesDetails"
                className={classes.duplicateProfilesDetailsExpandButton}
                onClick={() => setShowDuplicateProfilesDetails(!showDuplicateProfilesDetails)}
              >
                <DuplicateProfilesDetails
                  direction={showDuplicateProfilesDetails ? 'open' : 'closed'}
                />
              </Button>
            </Tooltip>
            <PatientMergeModal
              ref={ref}
              profiles={patientMerge?.profiles}
              mergeId={patientMerge?.id}
              defaultInputs={patientMergeInputs}
            />
          </div>
          <div>
            {showDuplicateProfilesDetails &&
              patient.possibleDuplicateProfiles.duplicates.map(dup => {
                const patientArborDob = convertToArborDate(dup.dob, true);
                const displayDob = dup.dob ? patientArborDob.getUtcDate(true) : '';
                const queryString = '?sidebar=search&task_type=SMS';
                return (
                  <div key={dup.id}>
                    <a
                      className={classes.duplicateProfilesLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      // eslint-disable-next-line max-len
                      href={`/customers/${dup.customer_id}/patients/${dup.id}/therapies${queryString}`}
                    >
                      {
                        // eslint-disable-next-line max-len
                        `${dup.first_name} ${dup.last_name} - ${displayDob} - MRN: ${dup.source_patient_id}`
                      }
                    </a>
                  </div>
                );
              })}
          </div>
        </Grid>
        <Grid item xs={3} className={classes.reportIssueButton}>
          {showDuplicateProfilesDetails && (
            <>
              <Tooltip
                title={
                  patient.possibleDuplicateProfiles.enableReportIssueButton
                    ? 'Create a support ticket'
                    : `Reported by ${patient.possibleDuplicateProfiles.lastReportedBy}`
                }
              >
                {/* span created to allow Tooltip to function if Button is disabled */}
                <span className={classes.reportIssueButton}>
                  <Button
                    name="report_duplicate_patient_button"
                    id="ReportDuplicatePatient"
                    variant="contained"
                    onClick={openSupportTicket}
                    disabled={!patient.possibleDuplicateProfiles.enableReportIssueButton}
                  >
                    Report Issue
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Report as Not Duplicated">
                <span className={classes.reportIssueButton}>
                  <Button
                    className={classes.mergeProfileButton}
                    name="report_not_duplicated"
                    id="ReportNotDuplicated"
                    variant="contained"
                    disabled={patient.possibleDuplicateProfiles.length === 0 || loadingPatientMerge}
                    onClick={async () => {
                      await setAsNotDuplicated();
                    }}
                  >
                    Not Duplicated
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Merge patient profiles">
                <span className={classes.reportIssueButton}>
                  <Button
                    className={classes.mergeProfileButton}
                    name="merge_patient_profile"
                    id="MergePatientProfile"
                    variant="contained"
                    disabled={patient.possibleDuplicateProfiles.length || loadingPatientMerge}
                    onClick={async () => {
                      await loadPatientMerge();
                    }}
                  >
                    Merge Profiles
                  </Button>
                </span>
              </Tooltip>
            </>
          )}
        </Grid>
      </Grid>
    ) : null;

  return (
    <div className={classNames(classes.patientOverviewContainer, taskListClass)}>
      <PatientReconciliationBanner />
      <div className={classes.summaryContainer}>
        <Grid container>
          <Grid item xs={3} className={classes.demographicsColumn}>
            {renderDemographicsColumn()}
          </Grid>
          <Grid item xs={4} className={classes.demographicsColumn}>
            {renderContactColumn()}
          </Grid>
          <Grid item xs={2} className={classes.demographicsColumn}>
            {renderClinicalColumn()}
          </Grid>
          <Grid item xs={3} className={classes.sidebarContainer}>
            {renderNotesColumn()}
          </Grid>
          <Grid item container xs={12} className={classes.adherenceData}>
            {renderAdherenceSection()}
          </Grid>
          <Grid item container xs={12} className={classes.MrnData}>
            {renderMultipleMrnsSection()}
          </Grid>
        </Grid>
      </div>
      {!!id && (
        <SwipeableDrawer
          anchor="right"
          open={noteBar.display}
          onClose={closeNoteBar}
          onOpen={() => {}}
          sx={{ zIndex: globalZIndex.notePanel }}
        >
          <HorizontalTabs
            defaultSelected={noteBar.trigger === NoteBarTrigger.Notes ? 1 : 0}
            visible={noteBar.display}
            tabs={[
              {
                label: 'Contact Patient',
                content: (
                  <div className={classes.communicationWrapper}>
                    <CommunicationForm
                      tagTypeId={noteBar.tagTypeId}
                      tagResourceId={noteBar.tagResourceId}
                      cancelCallback={closeNoteBar}
                      scheduleOutreach={false}
                      task={false}
                      startOnSms={noteBar.trigger === NoteBarTrigger.Sms}
                    />
                  </div>
                ),
              },
              {
                label: 'Notes',
                content: <NoteBar closeHandler={closeNoteBar} patientId={id} noteBar={noteBar} />,
              },
            ]}
          />
        </SwipeableDrawer>
      )}
      {duplicateProfilesBanner()}
      <DocumentBar />

      {/* Modals for Arbor */}
      {window.FEATURES.benefits_investigations && <BenefitsInvestigationModal />}
      <AddAddressModal />
      <AddEmailModal />
      <AddPaymentMethodModal />
      <AddPhoneModal />
      <AddPbmInsuranceModal />
      <TaskFCDispensingModal />
      <TaskPaApprovedModal />
      {displayEligibilityCheck && <EligibilityCheckModal patient={patient} />}
      <BenefitsInvestigationDetailedHistoryModal />
      <AddContactModal contacts={contacts} />

      <Tabs
        value={patientTab === 'medications' ? 'therapies' : patientTab}
        onChange={handleChange}
        classes={{
          root: classes.secondaryTabRoot,
          indicator: classes.secondaryTabsIndicator,
        }}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sx={{ '& .MuiTabs-flexContainer': { justifyContent: 'space-around' } }}
      >
        <Tab
          label={getAuditDisplayLabel('therapies')}
          value="therapies"
          component={Link}
          to={generateTabLink('therapies')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          id="tab-therapies"
        />
        <Tab
          label="Tasks"
          value="tasks"
          component={Link}
          to={generateTabLink('tasks')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          id="tab-tasks"
        />
        <Tab
          label="Checklist"
          value="checklist"
          component={Link}
          to={generateTabLink('checklist')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          id="tab-checklist"
        />
        <Tab
          label={getAuditDisplayLabel('clinical')}
          value="clinical"
          component={Link}
          to={generateTabLink('clinical')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          id="tab-clinical"
        />
        <Tab
          label={getAuditDisplayLabel('demographics')}
          value="demographics"
          component={Link}
          to={generateTabLink('demographics')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          id="tab-demographics"
        />
        <Tab
          label={getAuditDisplayLabel('financials', 'Income & Benefits')}
          value="financials"
          component={Link}
          to={generateTabLink('financials')}
          id="tab-financials"
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label="Documents"
          value="documents"
          component={Link}
          to={generateTabLink('documents')}
          id="tab-documents"
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      </Tabs>
    </div>
  );
}

const mapStateToProps = state => {
  const { patient } = state;
  return {
    patient,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps),
)(PatientOverview);
