export const styles = theme => ({
  title: {
    fontWeight: 'bold',
    fontSize: theme.font.largeFontSize,
  },
  subTitle: {
    fontWeight: 400,
    fontSize: theme.font.mediumFontSize,
  },
  subTitleAttachments: {
    fontWeight: 350,
    fontSize: theme.font.largeFontSize,
    paddingTop: 10,
  },
  drawer: {
    padding: 10,
  },
  uploadContainer: {
    'border': `1px dashed ${theme.palette.primary.steelGrey}`,
    'borderRadius': 10,
    'marginTop': 10,
    'marginBottom': 10,
    'padding': 10,
    'cursor': 'pointer',
    '&:active': {
      outline: 0,
      border: `1px dashed ${theme.palette.primary.steelGrey}`,
    },
    '&:focus': {
      outline: 0,
      border: `1px dashed ${theme.palette.primary.steelGrey}`,
    },
  },
  uploadContainerDragging: {
    backgroundColor: theme.palette.primary.bluesky,
  },
  uploadForm: {
    paddingTop: 10,
  },
  selectFileText: {
    fontSize: theme.typography.fontSize,
  },
  selectFileButton: {
    'color': theme.palette.primary.white,
    'backgroundColor': theme.palette.primary.cuttySark,
    'textTransform': 'none',
    'fontWeight': 'normal',
    '&:hover': {
      backgroundColor: theme.palette.primary.cuttySark,
      opacity: 0.9,
    },
    '&:disabled': {
      color: theme.palette.primary.white,
      opacity: 0.5,
    },
  },
  fileButton: {
    'color': theme.palette.primary.white,
    'backgroundColor': theme.palette.primary.cuttySark,
    'textTransform': 'uppercase',
    'borderRadius': 5,
    'padding': '6px 16px',
    '&:hover': {
      backgroundColor: theme.palette.primary.cuttySark,
      opacity: 0.9,
    },
    '&:disabled': {
      color: theme.palette.primary.white,
      opacity: 0.5,
    },
  },
  fileSelectedFormContainer: {
    border: `1px solid ${theme.palette.primary.trellisBlue}`,
    borderRadius: 5,
    padding: 10,
  },
  removeFile: {
    textAlign: 'right',
  },
  removeFileIcon: {
    color: theme.palette.primary.black,
    size: 36,
    cursor: 'pointer',
  },
  fileName: {
    fontWeight: 'bold',
    fontSize: theme.font.mediumFontSize,
  },
  fileType: {
    color: theme.palette.primary.lightGrey,
    fontSize: theme.font.mediumFontSize,
    fontWeight: 400,
  },
  fileTypeIcon: {
    height: 25,
    paddingRight: 10,
    paddingBottom: 10,
    paddingTop: 6,
  },
  buttonContainer: {
    textAlign: 'right',
  },
  cancelButton: {
    'backgroundColor': theme.palette.primary.white,
    'color': theme.palette.primary.black,
    'marginRight': 10,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  linkIcon: {
    color: theme.palette.primary.steelGrey,
    paddingRight: 5,
  },
  linkText: {
    fontSize: theme.font.smallFontSize,
    fontWeight: 'bold',
    color: theme.palette.primary.steelGrey,
  },
  uploadError: {
    color: theme.palette.primary.important,
    margin: '10px 0',
    fontSize: theme.font.mediumFontSize,
  },
  uploadSuccess: {
    color: theme.palette.primary.cuttySark,
    margin: '10px 0',
  },
  fileUploadingContainer: {
    border: `1px solid ${theme.palette.primary.trellisBlue}`,
    padding: 50,
  },
  documentBarButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 0 0 0',
  },
  documentBarButton: {
    borderRadius: 30,
  },
  documentBarToggleButtonText: {
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 2,
  },
  fileIconPadding: {
    padding: 10,
  },
  tag: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    bottom: 0,
    fontSize: theme.font.mediumFontSize,
  },
  uploadDocumentForm: {
    paddingBottom: 5,
  },
});
