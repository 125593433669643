/* eslint-disable react/require-default-props, @typescript-eslint/naming-convention */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { FcQuestionsEnum } from 'constants/enums';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { selectMissingRequiredKeys } from 'containers/tasks/fill-coordination/fc-util';
import { styles } from './missing-required-form-values.styles';

const fieldLabels = {
  ...{
    ancillary_supplies: 'Ancillary Supplies',
    dispensing_pharmacy: 'Dispensing Pharmacy *',
    method: 'Method *',
    dispense_qty: 'Dispense Quantity *',
    delivery_dt: ['Expected Delivery Date *', 'Expected Pick Up Date *'],
    signature_required: 'Signature Required *',
    order_type_address: 'Pick-up Location *',
    shipping_method: 'Shipping Method *',
    spoke_to_patient_dt: 'Spoke to Patient Date *',
    ship_date: 'Ship Date *',
    courier_type: 'Courier Type *',
    payment_methods: 'Payment Method on File',
    phones: 'Phones',
    emails: 'Emails',
    address: 'Address',
    days_supply: 'Days Supply *',
  },
  ...Object.entries(FcQuestionsEnum).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
};

interface Props {
  formName: string;
  isPickUp?: boolean;
  classes: any;
}

const MissingResponses: React.FC<Props> = (props: Props): JSX.Element => {
  const { isPickUp, classes } = props;
  const missingRequiredValueKeys = useTypedSelector(state =>
    selectMissingRequiredKeys(state, props.formName),
  );
  return (
    <Grid container sx={{ display: 'flex' }} justifyContent="flex-end" xs={12}>
      <Grid item sx={{ paddingInline: 3, paddingY: 2 }} className={classes.content}>
        <Typography className={classes.title}>Missing information :</Typography>
        {missingRequiredValueKeys.map(item => {
          const key = item as keyof typeof fieldLabels;
          return (
            <Typography
              key={item}
              className={classes.error}
              onClick={() => {
                const field = document.querySelector(
                  `*[name="${item}"]:not([type="hidden"]), #${item}`,
                );
                let element;
                if (!field) {
                  const label = fieldLabels[key] as string;
                  element = Array.from(document.querySelectorAll('span, label, p, h6')).find(
                    e => (e.textContent?.search(label) as number) >= 0,
                  );
                }

                (field || element)?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                });
              }}
            >
              {Array.isArray(fieldLabels[key])
                ? fieldLabels[key][Number(isPickUp)]
                : fieldLabels[key] || key}
            </Typography>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default compose<Props, any>(withStyles(styles))(MissingResponses);
