/* eslint-disable arrow-body-style */
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import withStyles from '@mui/styles/withStyles';
import Divider from 'components/divider';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import SubHeader from 'components/form/header/subheader';
import {
  RenderAddresses,
  renderAddress,
  renderEmails,
  renderPhones,
} from 'components/form/subform/subform';
import { required } from 'components/form/validation/validation';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field,
  FieldArray,
  getFormValues,
  reduxForm,
  formValueSelector,
  formValues,
} from 'redux-form';
import { addContact, editContactAndUpdatePatient } from 'actions/action-contacts';
import { renderDatePicker, renderTimePicker } from 'components/form/datepicker/datetime-picker';
import ReactSelectForRedux from 'components/form/field/react-select';
import { renderCheckbox, renderDropdown, renderTextField } from 'components/form/field/redux-field';
import {
  ADD_CONTACT_INFO_FORM,
  EDIT_CONTACT_INFO_FORM,
  CONTACT_FORM,
  ENSURE_EMERGENCY_CONTACT_ADDRESSED,
  AUDIT_NOT_RESOLVED,
  CONTACT_RELATIONSHIPS,
  EIGHT_AM,
  EIGHT_PM,
  CONTACT_TYPE_OPTIONS,
  IS_ADDITIONAL_FIELD,
} from 'constants/index';
import {
  relationshipList,
  auditCategories,
  auditContext,
  auditResolutionStates,
} from 'constants/lists';
import { findAuditByCategoryRuleAndStatus } from 'services/utils/audit-service';
import {
  archiveInfo,
  assignRankingAndKeys,
  getLanguageList,
  processPhoneData,
} from 'services/utils/demographic-service';

import { resolveAudit as resolveAuditAction } from 'actions/action-audit';
import { MODAL_CLOSE_TYPE } from 'constants/modal';
import { styles } from './patient-demographics-styles';

const phoneOrDeclineRequired = (value, allValues) => {
  if (
    allValues.is_declined_to_provide_phones === 1 ||
    allValues.patient_doesnt_provide_contact === 1
  ) {
    return undefined;
  }
  return required(value);
};

export class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      doesntProvideContact: props.formValues.patient_doesnt_provide_contact,
      phoneRequired: props.phoneRequired,
    };

    this.setDoesntProvideContact = this.setDoesntProvideContact.bind(this);
    this.clearDisabledFields = this.clearDisabledFields.bind(this);
    this.shouldClearDisabledFields = this.shouldClearDisabledFields.bind(this);
    this.handleRelationshipToPatient = this.handleRelationshipToPatient.bind(this);
  }

  setDoesntProvideContact() {
    this.setState(
      prevState => {
        return {
          ...prevState,
          doesntProvideContact: !prevState.doesntProvideContact,
        };
      },
      () => {
        if (this.shouldClearDisabledFields()) {
          this.clearDisabledFields();
        }
      },
    );
  }

  shouldClearDisabledFields() {
    const { doesntProvideContact: clearFields } = this.state;
    return clearFields;
  }

  handleRelationshipToPatient(e) {
    const { change } = this.props;
    if (e === CONTACT_RELATIONSHIPS.PROVIDER) {
      const currentValue = formValues.contact_type || [];
      change('contact_type', [...currentValue, IS_ADDITIONAL_FIELD]);
    }
  }

  /**
   * Clear all the fields that the user can fill out besides the checkboxes.
   */
  clearDisabledFields() {
    const { change } = this.props;

    // TODO: Put these field names in constants.
    // TODO: Update this file to TS so that we don't have to put field names in constants.
    [
      'last_name',
      'first_name',
      'middle_name',
      'suffix',
      'nickname',
      'dob',
      'relationship',
      'languages',
      'need_interpreter',
      'addresses',
      'emails',
      'phones',
      'is_declined_to_provide_addresses',
      'is_declined_to_provide_phones',
      'email_options',
      'contact_type',
    ].forEach(fieldName => {
      change(fieldName, null);
    });
  }

  handleSubmit(values) {
    const {
      patient,
      addContact, // eslint-disable-line
      editContactAndUpdatePatient, // eslint-disable-line
      edit,
      contact,
      closeHandler,
      audit,
      resolveAudit,
      setLastContact,
    } = this.props;

    const auditToResolve = findAuditByCategoryRuleAndStatus(
      audit,
      patient,
      auditCategories.Demographics,
      auditContext.Warning,
      ENSURE_EMERGENCY_CONTACT_ADDRESSED,
      AUDIT_NOT_RESOLVED,
    );

    const contactTypeSelectedItems = values.contact_type?.map(item => item.value) || [];

    if (
      auditToResolve &&
      (contactTypeSelectedItems.includes(CONTACT_TYPE_OPTIONS.FIELD_NAMES.EMERGENCY_CONTACT) ||
        values.patient_doesnt_provide_contact === true)
    ) {
      resolveAudit({
        ...auditToResolve,
        resolution_state_id: auditResolutionStates.Resolved,
      });
    }

    const payload = {
      patient_id: patient.id,
      is_emergency: contactTypeSelectedItems.includes(
        CONTACT_TYPE_OPTIONS.FIELD_NAMES.EMERGENCY_CONTACT,
      ),
      is_responsible: contactTypeSelectedItems.includes(
        CONTACT_TYPE_OPTIONS.FIELD_NAMES.RESPONSIBLE_CONTACT,
      ),
      is_additional: contactTypeSelectedItems.includes(
        CONTACT_TYPE_OPTIONS.FIELD_NAMES.ADDITIONAL_CONTACT,
      ),
      first_name: values.first_name,
      last_name: values.last_name,
      middle_name: values.middle_name,
      suffix: values.suffix,
      nickname: values.nickname,
      dob: convertToArborDate(values.dob).getUtcDate(),
      relationship: values.relationship,
      languages:
        values.languages && values.languages.length > 0 ? JSON.stringify(values.languages) : null,
      need_interpreter: values.need_interpreter,
      provider_office_name: values.provider_office_name,
      provider_available_hours: `${values.provider_availability_start || ''}-${
        values.provider_availability_end || ''
      }`,

      addresses: JSON.stringify(
        assignRankingAndKeys(
          archiveInfo(values.addresses, contact && contact.addresses),
          values.preferred_address_index,
        ),
      ),
      emails: JSON.stringify(
        assignRankingAndKeys(
          archiveInfo(values.emails, contact && contact.emails),
          values.preferred_email_index,
        ),
      ),
      phones: JSON.stringify(
        processPhoneData(
          assignRankingAndKeys(
            archiveInfo(values.phones, contact && contact.phones),
            values.preferred_phone_index,
          ),
          true,
        ),
      ),

      is_declined_to_provide_addresses: values.is_declined_to_provide_addresses,
      email_options: values.email_options,
      is_declined_to_provide_phones: values.is_declined_to_provide_phones,
      [CONTACT_FORM.FIELD_NAMES.DECLINED_TO_PROVIDE_EMERGENCY_CONTACT]:
        values.patient_doesnt_provide_contact,
      [CONTACT_FORM.FIELD_NAMES.DOESNT_HAVE_EMERGENCY_CONTACT]:
        values.patient_doesnt_provide_contact,
      is_verified: 1, // If in UI, consider verified
      verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
    };
    if (edit) {
      payload.id = contact.id;
      editContactAndUpdatePatient(payload);
    } else {
      setLastContact?.();
      addContact(payload);
    }
    closeHandler(MODAL_CLOSE_TYPE.SUBMIT_SUCCESS);
  }

  handleCancel() {
    const { reset, closeHandler } = this.props;
    reset();
    closeHandler(MODAL_CLOSE_TYPE.DIALOG_CLOSED);
  }

  render() {
    const {
      classes,
      handleSubmit,
      submitting,
      pristine,
      formValues,
      patient,
      contact,
      edit,
      change,
      form,
      hideConfirmationPanel,
      relationshipToPatientValue,
      hasDoesntProvideContactField,
      numberOfPotentialRxDeliveryAddresses,
    } = this.props;
    const { phoneRequired, doesntProvideContact: disableAllRequiredFields } = this.state;

    if (!formValues) {
      return null;
    }

    const contactTypeOptions = Object.entries(CONTACT_TYPE_OPTIONS.FIELD_NAMES).map(
      ([key, value]) => ({
        label: CONTACT_TYPE_OPTIONS.LABELS[key],
        value,
      }),
    );

    const isProvider = relationshipToPatientValue === CONTACT_RELATIONSHIPS.PROVIDER;

    const providerOfficeName = (
      <Grid item xs={2}>
        <Field
          name="provider_office_name"
          label="Office Name *"
          validate={!disableAllRequiredFields ? [required] : []}
          component={renderTextField}
        />
      </Grid>
    );

    return (
      <div className={classes.contactForm}>
        <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
          <Grid>
            {hasDoesntProvideContactField && (
              <Grid item xs={12}>
                <Field
                  name="patient_doesnt_provide_contact"
                  label="Patient doesn't have additional contact / declined to provide additional contact"
                  component={renderCheckbox}
                  onChange={this.setDoesntProvideContact}
                  disabled={Boolean(formValues.contact_type?.length)}
                  validate={!formValues.contact_type?.length ? [required] : []}
                  wrapperProps={{ className: classes.inlineCheckbox }}
                />
              </Grid>
            )}
            <Grid container className={classes.backdropWrapper}>
              <Backdrop open={!!disableAllRequiredFields} className={classes.backdrop} />
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                  <Grid container spacing={3} className={classes.contactInfo}>
                    <Grid item xs={3}>
                      <Field
                        name="relationship"
                        label="Relationship to Patient *"
                        validate={!disableAllRequiredFields ? [required] : undefined}
                        component={renderDropdown}
                        onChange={this.handleRelationshipToPatient}
                        fields={relationshipList}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Field
                        name="contact_type"
                        label="Contact type *"
                        validate={
                          !disableAllRequiredFields && !formValues.patient_doesnt_provide_contact
                            ? [required]
                            : []
                        }
                        fields={contactTypeOptions}
                        disabled={formValues.patient_doesnt_provide_contact}
                        component={ReactSelectForRedux}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={0} />
                  <Grid container spacing={7}>
                    <Grid item xs={12}>
                      <SubHeader name="Personal Information" />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="last_name"
                        label={`Last Name${!disableAllRequiredFields ? ' *' : ''}`}
                        validate={!disableAllRequiredFields && !isProvider ? [required] : undefined}
                        component={renderTextField}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="first_name"
                        label={`First Name${isProvider ? '' : ' *'}`}
                        validate={!disableAllRequiredFields && !isProvider ? [required] : undefined}
                        component={renderTextField}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="middle_name"
                        label="Middle Initial"
                        component={renderTextField}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="suffix" label="Suffix" component={renderTextField} />
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="nickname" label="Preferred Name" component={renderTextField} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={7}>
                    {!isProvider && (
                      <Grid item xs={3}>
                        <Field name="dob" component={renderDatePicker} label="Date of Birth" />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <Field
                        id="languages"
                        name="languages"
                        label={`Languages${formValues.need_interpreter ? ' *' : ''}`}
                        fields={getLanguageList()}
                        component={ReactSelectForRedux}
                        validate={
                          !disableAllRequiredFields && formValues.need_interpreter ? [required] : []
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="need_interpreter"
                        label="Needs Interpreter"
                        component={renderCheckbox}
                      />
                    </Grid>
                  </Grid>
                  <Divider />

                  <Grid container>
                    <FieldArray
                      name="addresses"
                      formValues={formValues}
                      preferredField="preferred_address_index"
                      declineField="is_declined_to_provide_addresses"
                      component={isProvider ? renderAddress : RenderAddresses}
                      change={change}
                      classes={classes}
                      idPrefix={form}
                      nameOverride={isProvider ? 'Provider Address *' : null}
                      additionalFields={isProvider ? providerOfficeName : null}
                      contactId={contact?.id}
                      patient={patient}
                      numberOfPotentialRxDeliveryAddresses={numberOfPotentialRxDeliveryAddresses}
                    />
                  </Grid>
                  {isProvider && (
                    <Grid item xs={5}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Field
                            name="provider_availability_start"
                            label="Availability Start"
                            component={renderTimePicker}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="provider_availability_end"
                            label="Availability End"
                            component={renderTimePicker}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Divider />
                  <Grid container>
                    <FieldArray
                      name="emails"
                      formValues={formValues}
                      preferredField="preferred_email_index"
                      declineField="email_options"
                      component={renderEmails}
                      change={change}
                      classes={classes}
                      idPrefix={form}
                    />
                  </Grid>
                  <Divider />
                  <Grid container>
                    <FieldArray
                      name="phones"
                      formValues={formValues}
                      preferredField="preferred_phone_index"
                      declineField="is_declined_to_provide_phones"
                      component={renderPhones}
                      change={change}
                      classes={classes}
                      idPrefix={form}
                      validate={
                        !disableAllRequiredFields && phoneRequired
                          ? [phoneOrDeclineRequired]
                          : undefined
                      }
                      required={phoneRequired}
                      isTypeContact
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!hideConfirmationPanel && (
              <ConfirmationPanel
                handleCancel={this.handleCancel}
                disableSubmit={submitting || pristine}
                buttonIdPrefix={form}
              />
            )}
          </Grid>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { audit, patient } = state;
  const { contact, edit, enableReinitialize, hasDoesntProvideContactField, setLastContact } = props;

  let formName;

  if (edit) {
    formName = `${EDIT_CONTACT_INFO_FORM}_${contact.id}`;
  } else {
    formName = ADD_CONTACT_INFO_FORM;
  }

  const initialValues = {
    preferred_address_index: 0,
    preferred_email_index: 0,
    preferred_phone_index: 0,
  };

  const selector = formValueSelector(formName);

  const relationshipToPatientValue = selector(state, 'relationship');

  const splitProviderAvalibility = () => {
    if (!contact || !contact.provider_available_hours) {
      return {
        provider_availability_start: EIGHT_AM,
        provider_availability_end: EIGHT_PM,
      };
    }
    const hoursSubstringArr = contact.provider_available_hours.split('-');
    return {
      provider_availability_start: hoursSubstringArr[0],
      provider_availability_end: hoursSubstringArr[1],
    };
  };

  const initialContactTypeOptions = Object.entries(CONTACT_TYPE_OPTIONS.FIELD_NAMES)
    .filter(([key, value]) => Boolean(contact?.[value]))
    .map(([key, value]) => ({
      label: CONTACT_TYPE_OPTIONS.LABELS[key],
      value,
    }));

  const patientDoesntProvideContact =
    Boolean(contact?.[CONTACT_FORM.FIELD_NAMES.DOESNT_HAVE_EMERGENCY_CONTACT]) ||
    Boolean(contact?.[CONTACT_FORM.FIELD_NAMES.DECLINED_TO_PROVIDE_EMERGENCY_CONTACT]);

  if (contact) {
    initialValues.first_name = contact.first_name;
    initialValues.last_name = contact.last_name;
    initialValues.middle_name = contact.middle_name;
    initialValues.suffix = contact.suffix;
    initialValues.nickname = contact.nickname;
    initialValues.dob = contact.dob ? convertToArborDate(contact.dob, true).getUtcDate(true) : null;
    initialValues.languages = contact.languages;
    initialValues.need_interpreter = contact.need_interpreter;
    initialValues.relationship = contact.relationship;
    initialValues.addresses = contact.filteredAddresses;
    initialValues.emails = contact.filteredEmails;
    initialValues.phones = contact.filteredPhones;
    initialValues.provider_office_name = contact.provider_office_name;
    initialValues.is_declined_to_provide_addresses = contact.is_declined_to_provide_addresses;
    initialValues.email_options = contact.email_options;
    // eslint-disable-next-line max-len
    initialValues.provider_availability_start =
      splitProviderAvalibility().provider_availability_start;
    initialValues.provider_availability_end = splitProviderAvalibility().provider_availability_end;
    initialValues.is_declined_to_provide_phones = contact.is_declined_to_provide_phones;
    initialValues.patient_doesnt_provide_contact = patientDoesntProvideContact;
    initialValues.contact_type = initialContactTypeOptions;
  }

  const formValues = getFormValues(formName)(state) || initialValues;

  return {
    audit,
    patient,
    form: formName,
    enableReinitialize,
    initialValues,
    formValues,
    relationshipToPatientValue,
    hasDoesntProvideContactField,
    setLastContact,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    addContact,
    editContactAndUpdatePatient,
    resolveAudit: resolveAuditAction,
  }),
)(reduxForm({})(ContactForm));
