// @ts-nocheck
import HTTP from 'services/http';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import React from 'react';
import axios, { AxiosPromise, CancelTokenSource } from 'axios';
import { buildQaId } from 'utils/build-qa-id';
import { IndexType } from 'interfaces/IndexType';
import { SortDirections } from 'interfaces/enums/SortDirections';
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  Button,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';
import cx from 'classnames';
import { ArborCheckbox } from 'components/arbor-checkbox/arbor-checkbox';
import { cloneDeep } from 'lodash';
import { UseStyles } from './base-table.styles';
import { CustomSortLabel } from '../components/CustomSortLabel';
import { ButtonActiveWhen, IHeaderCell, IQueryParameterSettings } from './types';
import { BaseRow } from './base-row';

interface IBaseTableProps<TParent, TChild, TApiData> {
  actionsPermitted: boolean;
  enableParentCheckboxes?: boolean;
  enableChildCheckboxes?: boolean;
  endpointBase?: string;
  dataSet?: TApiData | undefined;
  paginationQueryParamSettings: IQueryParameterSettings;
  columnSettings: (IHeaderCell<TParent, TChild> | undefined)[];
  childPkSelector: (child: TChild) => IndexType;
  parentPkSelector: (parent: TParent) => IndexType;
  orderByDefaultChild?: keyof TChild | undefined;
  orderByDefaultParent?: keyof TParent | undefined;
  searchTerm: string | undefined;
  rightActionButtons?: {
    key: string;
    label: string;
    activeWhen: ButtonActiveWhen;
    onClick?: (checkedItems: TParent[]) => void;
  }[];
  setCount?: (count: number) => void;
  transformResponseData?: (data: TApiData) => TApiData;
  customChildrenComponent?: (parent: TParent) => JSX.Element | null;
  /**
   * If the rows per page need to be controlled externally, these options are here.
   */
  rowsPerPageSettings?: {
    override: number;
    onChange: (rowsPerPage: number) => void;
  };
  // A trigger so that the component knows it needs to reload data.
  // Just set a new Date when we want this event to happen
  triggerReload?: Date | undefined;
  triggerExternalCheckUpdate?:
    | { parentPk: IndexType; childPk: IndexType; checked: boolean }
    | undefined;
  classesOverride?: {
    tableContainer?: string;
    table?: string;
    lastCell?: string;
    customChildRow?: string;
  };
  forcedQueryParams?: Record<string, string | string[]>;
  checkedItemTracking?: {
    items: Record<IndexType, IndexType[]>;
    setCheckedItems: (checkedItems: Record<IndexType, IndexType[]>) => void;
  };
  afterDataLoad?: (data: TApiData) => void;
}

const getQaId = buildQaId('application-manager.base-table', '.');

const buildGetUrl = (
  baseUrl: string,
  settings: IQueryParameterSettings,
  pageNumber: number,
  pageSize: number,
  sortProp: string | undefined = undefined,
  sortOrder: SortDirections | undefined = undefined,
  searchTerm: string | undefined = undefined,
  forcedQueryParams?: Record<string, string | string[]>,
) => {
  const requestParams: Record<string, string> = {
    [settings.pageNumberQueryStringKey]: (pageNumber + 1).toString(),
    [settings.pageSizeQueryStringKey]: pageSize.toString(),
  };
  if (sortProp && sortOrder) {
    requestParams[settings.sortPropQueryStringKey] = sortProp;
    requestParams[settings.sortOrderQueryStringKey] = sortOrder;
  }

  if (searchTerm) {
    requestParams[settings.searchTermQueryStringKey] = searchTerm;
  }

  const urlSearchParams = new URLSearchParams(requestParams);

  if (forcedQueryParams) {
    Object.entries(forcedQueryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => {
          urlSearchParams.append(key, v);
        });
      } else {
        urlSearchParams.append(key, value);
      }
    });
  }
  return `${baseUrl}?${urlSearchParams.toString()}`;
};

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

const defaultPage = 0;
const defaultRowsPerPage = 10;
const rowsPerPageOptions = [defaultRowsPerPage, 25, 50];

/**
 * The interface for this object should be "<TResult,TParent, TChild,>"
 * If you auto format this file, it may remove the dangling comma which is needed.
 * @param props
 */
export const BaseTable = <
  TApiResult extends { totalCount: number; results: TParent[] },
  TParent extends { children: TChild[] | null },
  TChild,
>(
  props: IBaseTableProps<TParent, TChild, TApiResult>,
): JSX.Element => {
  const classes: any = UseStyles();

  // #region useState
  const [checkedItems, setCheckedItems] = React.useState<Record<IndexType, IndexType[]>>({});
  const [data, setData] = React.useState<TApiResult | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<SortDirections>(SortDirections.ASC);
  const [orderByChild, setOrderByChild] = React.useState<keyof TChild | undefined>(
    props.orderByDefaultChild,
  );
  const [orderByParent, setOrderByParent] = React.useState<keyof TParent | undefined>(
    props.orderByDefaultParent,
  );
  const [childSortFn, setChildSortFn] = React.useState<(a: TChild, b: TChild) => number>(
    () => () => {
      return 1;
    },
  );
  const [parentSortFn, setParentSortFn] = React.useState<(a: TParent, b: TParent) => number>(
    () => () => 1,
  );
  const [triggerExpandAll, setTriggerExpandAll] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = React.useState<{
    field: keyof TChild | keyof TParent | undefined;
    makeRequest: boolean;
  }>({ field: props.orderByDefaultParent || props.orderByDefaultChild, makeRequest: false });
  const [page, setPage] = React.useState<number>(defaultPage);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    props.rowsPerPageSettings?.override || defaultRowsPerPage,
  );
  const [cancelToken, setCancelToken] = React.useState<CancelTokenSource | undefined>();
  // #endregion

  // #region useMemo
  const checkedItemsDataStore = React.useMemo<Record<IndexType, IndexType[]>>(() => {
    return props.checkedItemTracking?.items ?? checkedItems;
  }, [checkedItems, props.checkedItemTracking]);

  const checkedItemsSetter = React.useMemo(() => {
    return props.checkedItemTracking?.setCheckedItems ?? setCheckedItems;
  }, [props.checkedItemTracking]);

  const allChecked = React.useMemo<boolean>(() => {
    if (props.actionsPermitted && data && props.enableParentCheckboxes) {
      const everyChecked = data.results.every(r => {
        return r.children.every(c => {
          return r.children.every(c => {
            return (
              checkedItemsDataStore[props.parentPkSelector(r)] &&
              checkedItemsDataStore[props.parentPkSelector(r)].includes(props.childPkSelector(c))
            );
          });
        });
      });
      return everyChecked;
    }
    return false;
  }, [checkedItemsDataStore]);

  const someChecked = React.useMemo<boolean>(() => {
    return (
      props.actionsPermitted &&
      Object.keys(checkedItemsDataStore).length > 0 &&
      Object.values(checkedItemsDataStore).some(x => x.length > 0)
    );
  }, [checkedItemsDataStore]);

  const noneChecked = React.useMemo<boolean>(() => {
    return (
      (props.actionsPermitted && Object.keys(checkedItemsDataStore).length === 0) ||
      Object.values(checkedItemsDataStore).every(x => x.length === 0)
    );
  }, [checkedItemsDataStore]);
  // #endregion

  // #region helper functions
  const handleRowsPerPageChange = (rowsPerPage: number): void => {
    setRowsPerPage(rowsPerPage);
    if (props.rowsPerPageSettings?.onChange) {
      props.rowsPerPageSettings.onChange(rowsPerPage);
    }
  };

  const addToChecked = (parentPk: IndexType, childPk: IndexType): void => {
    const newChecked = { ...checkedItemsDataStore };
    if (!newChecked[parentPk]) {
      newChecked[parentPk] = [];
    }

    const childCheckedItems = new Set(newChecked[parentPk]);
    childCheckedItems.add(childPk);

    newChecked[parentPk] = Array.from(childCheckedItems);

    checkedItemsSetter(newChecked);
  };

  const removeFromChecked = (parentPk: IndexType, childPk: IndexType): void => {
    const newChecked = { ...checkedItemsDataStore };
    if (newChecked[parentPk]) {
      newChecked[parentPk] = newChecked[parentPk].filter(x => x !== childPk);
    }

    checkedItemsSetter(newChecked);
  };

  const checkAll = () => {
    const allCheckedItems: Record<IndexType, IndexType[]> = {};

    data?.results.forEach(parent => {
      const parentPk = props.parentPkSelector(parent);
      if (!allCheckedItems[parentPk]) {
        allCheckedItems[parentPk] = [];
      }
      parent.children.forEach(child => {
        const childPk = props.childPkSelector(child);
        allCheckedItems[parentPk].push(childPk);
      });
    });

    checkedItemsSetter(allCheckedItems);
    setTriggerExpandAll(true);
  };

  const uncheckAll = () => {
    checkedItemsSetter({});
  };

  const loadAndSetState = async () => {
    if (props.endpointBase) {
      (async () => {
        cancelToken?.cancel();
        const newToken = axios.CancelToken.source();
        setCancelToken(newToken);
        checkedItemsSetter({});
        setCheckedItems({});
        setLoading(true);
        const url = buildGetUrl(
          props.endpointBase!,
          props.paginationQueryParamSettings,
          page,
          rowsPerPage,
          searchParams.field as string,
          order,
          props.searchTerm,
          props.forcedQueryParams,
        );
        try {
          const result = await (HTTP.get(url, {
            cancelToken: newToken.token,
          }) as AxiosPromise<TApiResult>);
          if (result) {
            const data = props.transformResponseData
              ? props.transformResponseData(result.data)
              : result.data;
            setData(data);

            if (props.afterDataLoad) {
              props.afterDataLoad(data);
            }
            if (props.setCount) {
              props.setCount(data.totalCount);
            }
            setLoading(false);
          }
        } catch (err) {
          if (!axios.isCancel(err)) {
            setLoading(false);
            throw err;
          }
        }
      })();
    } else if (props.dataSet) {
      (async () => {
        checkedItemsSetter({});
        setCheckedItems({});
        setLoading(true);
        const dataSetDeepCopy = cloneDeep(props.dataSet);
        if (props.searchTerm && props.searchTerm.trim() !== '') {
          const searchObj = JSON.parse(props.searchTerm);
          if (dataSetDeepCopy && searchObj.type === 'SIMPLE') {
            /**
             * {
             *  type: 'SIMPLE',
             *  search: [
             *    {
             *      values: 'value1, value2, valuen, ...',
             *      fullMatch: boolean,
             *      active: boolean,
             *    }
             *  ],
             * }
             */
            const searchArr: { values: string; fullMatch: boolean; active: boolean }[] =
              searchObj.search;
            searchArr
              .filter(x => x.active)
              .forEach(searchParams => {
                dataSetDeepCopy.results = dataSetDeepCopy.results.filter(currentRow =>
                  searchOnAnyColumn(currentRow, searchParams.values, searchParams.fullMatch),
                );
                dataSetDeepCopy.totalCount = dataSetDeepCopy.results.length;
              });
          } else if (dataSetDeepCopy && searchObj.type === 'MULTIPLE') {
            /**
             * {
             *  type: 'MULTIPLE',
             *  search: [
             *    {
             *      values: 'value1, value2, valuen, ...',
             *      columns: ['column1', 'column2', 'column-n', ...],
             *      fullMatch: boolean,
             *      active: boolean,
             *    }
             *  ],
             * }
             */
            const searchArr: {
              values: string;
              columns: string[];
              fullMatch: boolean;
              active: boolean;
            }[] = searchObj.search;
            searchArr
              .filter(x => x.active)
              .forEach(searchParams => {
                dataSetDeepCopy.results = dataSetDeepCopy.results.filter(currentRow =>
                  searchOnMultipleColumns(
                    currentRow,
                    searchParams.values,
                    searchParams.columns,
                    searchParams.fullMatch,
                  ),
                );
                dataSetDeepCopy.totalCount = dataSetDeepCopy.results.length;
              });
          }
        }
        // pagination logic
        if (dataSetDeepCopy) {
          const pageTofetch = page + 1;
          const maxRange = rowsPerPage * pageTofetch;
          const minRange = maxRange - rowsPerPage;
          dataSetDeepCopy.results = dataSetDeepCopy.results.filter(
            (x, index) => index >= minRange && index < maxRange,
          );
        }
        setData(dataSetDeepCopy);
        if (props.afterDataLoad && props.dataSet) {
          props.afterDataLoad(props.dataSet);
        }
        if (props.setCount && props.dataSet?.totalCount) {
          props.setCount(props.dataSet.totalCount);
        }
        setLoading(false);
      })();
    }
  };

  const searchOnMultipleColumns = (
    row: any,
    values: string,
    columns: string[],
    fullMatch = false,
  ): boolean => {
    const lowerCaseValues: string[] = values
      .toLowerCase()
      .split(',')
      .reduce((acc, currentVal) => {
        if (currentVal.trim() !== '') {
          acc.push(currentVal.trim());
        }
        return acc;
      }, [] as string[]);
    const lowerCaseColumns = columns.join().toLowerCase().split(',');
    return Object.entries(row).some(
      keyValuePair =>
        typeof keyValuePair[1] !== 'object' &&
        lowerCaseColumns.includes(keyValuePair[0].toLowerCase()) &&
        (fullMatch
          ? lowerCaseValues.includes(String(keyValuePair[1]).toLowerCase())
          : lowerCaseValues.some(possibleValue =>
              String(keyValuePair[1]).toLowerCase().includes(possibleValue),
            )),
    );
  };

  const searchOnAnyColumn = (row: any, values: string, fullMatch = false): boolean => {
    const lowerCaseValues: string[] = values
      .toLowerCase()
      .split(',')
      .reduce((acc, currentVal) => {
        if (currentVal.trim() !== '') {
          acc.push(currentVal.trim());
        }
        return acc;
      }, [] as string[]);
    return Object.entries(row).some(
      keyValuePair =>
        typeof keyValuePair[1] !== 'object' &&
        (fullMatch
          ? lowerCaseValues.includes(String(keyValuePair[1]).toLowerCase())
          : lowerCaseValues.some(possibleValue =>
              String(keyValuePair[1]).toLowerCase().includes(possibleValue),
            )),
    );
  };

  const buttonEnabled = (enabledWhen: ButtonActiveWhen) => {
    switch (enabledWhen) {
      case ButtonActiveWhen.Always:
        return true;
      case ButtonActiveWhen.NoneSelected:
        return noneChecked;
      case ButtonActiveWhen.SomeSelected:
        return !noneChecked;
    }
  };

  const buildCheckedItemsList = (): TParent[] => {
    const results: TParent[] = [];

    Object.entries(checkedItems).forEach(([parentPk, childPks]) => {
      const matchingParent = data?.results.find(
        x => props.parentPkSelector(x).toString() === parentPk,
      );
      if (matchingParent) {
        const matchingChildren = matchingParent.children.filter(x =>
          childPks.map(x => x.toString()).includes(props.childPkSelector(x).toString()),
        );

        results.push({ ...matchingParent, children: matchingChildren });
      }
    });

    return results;
  };

  const handleRootCheckboxClick = (): void => {
    if (allChecked || someChecked) {
      uncheckAll();
    } else if (noneChecked) {
      checkAll();
    }
  };
  // #endregion

  // #region useCallback
  const compareByOrder = React.useCallback(
    (a: any, b: any, orderBy: any) =>
      ((a[orderBy] === null) as any) - ((b[orderBy] === null) as any) ||
      collator.compare(b[orderBy] as any, a[orderBy] as any),
    [],
  );
  // //#endregion

  // #region useEffect
  React.useEffect(() => {
    if (props.rowsPerPageSettings?.override) {
      setRowsPerPage(props.rowsPerPageSettings?.override);
    }
  }, [props.rowsPerPageSettings]);

  React.useEffect(() => {
    if (searchParams.makeRequest) {
      (async () => {
        loadAndSetState();
      })();
    }
  }, [searchParams, order]);

  React.useEffect(() => {
    (async () => {
      // only execute if initial request was already made
      if (cancelToken !== undefined) {
        setPage(0);

        await loadAndSetState();
      }
    })();
  }, [props.searchTerm, props.endpointBase, props.forcedQueryParams]);

  React.useEffect(() => {
    if (props.triggerReload) {
      (async () => {
        await loadAndSetState();
      })();
    }
  }, [props.triggerReload]);

  React.useEffect(() => {
    if (order) {
      const childCell = props.columnSettings.find(cell => cell?.childKey === orderByChild);
      if (orderByChild && !childCell?.serverSideSearchField) {
        const childSortFn =
          order === SortDirections.DESC
            ? () => (a: TChild, b: TChild) => compareByOrder(b, a, orderByChild)
            : () => (a: TChild, b: TChild) => compareByOrder(a, b, orderByChild);
        setChildSortFn(childSortFn);
      }

      const parentCell = props.columnSettings.find(cell => cell?.parentKey === orderByParent);
      if (orderByParent && !parentCell?.serverSideSearchField) {
        const parentSortFn =
          order === SortDirections.ASC
            ? () => (a: TParent, b: TParent) => compareByOrder(b, a, orderByParent)
            : () => (a: TParent, b: TParent) => compareByOrder(a, b, orderByParent);
        setParentSortFn(parentSortFn);
      }
    }
  }, [order, orderByChild, orderByParent]);

  React.useEffect(() => {
    (async () => {
      await loadAndSetState();
    })();
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    if (props.triggerExternalCheckUpdate) {
      const { parentPk, childPk, checked } = props.triggerExternalCheckUpdate;
      if (checked) {
        addToChecked(parentPk, childPk);
      } else {
        removeFromChecked(parentPk, childPk);
      }
    }
  }, [props.triggerExternalCheckUpdate]);
  // #endregion

  const renderTopRightButtons = (): JSX.Element | null => {
    return (
      <>
        {props.rightActionButtons?.map(button => {
          return (
            <React.Fragment key={button.key}>
              {props.actionsPermitted ? (
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    disabled={!buttonEnabled(button.activeWhen)}
                    onClick={event => {
                      if (button.onClick) {
                        button.onClick(buildCheckedItemsList());
                      }
                    }}
                  >
                    {button.label}
                  </Button>
                </Grid>
              ) : null}
            </React.Fragment>
          );
        })}
      </>
    );
  };
  const totalCountCheck = data && data.totalCount > 0;
  const renderTableBody = (): JSX.Element => {
    return (
      <TableBody>
        {data?.results.length && totalCountCheck ? (
          data?.results
            .sort((a, b) => parentSortFn(a, b))
            .map((r, index) => {
              const parentPk = props.parentPkSelector(r);
              return (
                <BaseRow
                  actionsPermitted={props.actionsPermitted}
                  enableParentCheckboxes={props.enableParentCheckboxes}
                  enableChildCheckboxes={props.enableChildCheckboxes}
                  customChildrenComponent={props.customChildrenComponent}
                  key={parentPk}
                  index={index}
                  triggerExpand={triggerExpandAll}
                  headerCells={props.columnSettings}
                  parent={r}
                  children={r.children}
                  checkedItems={checkedItemsDataStore}
                  sortFn={childSortFn}
                  parentPkSelector={props.parentPkSelector}
                  childPkSelector={props.childPkSelector}
                  addToChecked={addToChecked}
                  removeFromChecked={removeFromChecked}
                  classesOverrides={props.classesOverride}
                />
              );
            })
        ) : (
          <TableRow>
            <TableCell colSpan={props.columnSettings.length}>
              <Typography align="center" className={classes.noResultsType}>
                No Results
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  const renderTableHead = (): JSX.Element => {
    return (
      <TableHead data-qa-id={getQaId('table.head')}>
        <TableRow>
          {/* Checkbox col */}
          {props.actionsPermitted && props.enableParentCheckboxes ? (
            <TableCell
              className={cx(classes.tableHeadCell, classes.tableHeadCheckbox)}
              onClick={() => handleRootCheckboxClick()}
            >
              <ArborCheckbox
                checked={allChecked}
                indeterminate={!noneChecked && !allChecked && someChecked}
                onClick={() => {
                  handleRootCheckboxClick();
                }}
              />
            </TableCell>
          ) : null}
          {/* Rest of the cols */}
          {props.columnSettings.map(cell => {
            if (cell == null) {
              return null;
            }

            return (
              <TableCell
                data-qa-id={getQaId(`table.head.cell.${String(cell.childKey)}`)}
                key={cell.childKey as string}
                className={classes.tableHeadCell}
                onClick={() => {
                  if (cell.label && cell.sortable) {
                    const isAsc = orderByChild === cell.childKey && order === SortDirections.ASC;
                    setOrder(isAsc ? SortDirections.DESC : SortDirections.ASC);
                    setOrderByParent(cell.parentKey);
                    setOrderByChild(cell.childKey);
                    if (cell.serverSideSearchField) {
                      setSearchParams({ field: cell.serverSideSearchField, makeRequest: true });
                    } else {
                      setSearchParams(prev => {
                        return { ...prev, makeRequest: false };
                      });
                    }
                  }
                }}
              >
                {cell.label ? (
                  <CustomSortLabel
                    active={
                      orderByParent === cell.parentKey ||
                      (cell.childKey != null && orderByChild === cell.childKey)
                    }
                    direction={orderByChild === cell.childKey ? order : SortDirections.ASC}
                  >
                    {cell.label}
                  </CustomSortLabel>
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableFoot = (): JSX.Element => {
    return (
      <TableFooter data-qa-id={getQaId('table.footer')}>
        <TableRow>
          <TablePagination
            data-qa-id={getQaId('table.pagination')}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={event => {
              const rowsPerPage = parseInt(event.target.value, 10);
              handleRowsPerPageChange(rowsPerPage);
            }}
            page={page}
            count={data?.totalCount || 0}
            onPageChange={(_event, pageNumber) => {
              setPage(pageNumber);
            }}
          />
        </TableRow>
      </TableFooter>
    );
  };

  return (
    <Grid container>
      {/* Button Row */}
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          {/* Left */}
          <Grid item xs="auto" />
          {/* Right */}
          <Grid item xs="auto">
            <Grid container spacing={1}>
              {renderTopRightButtons()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* The Table */}
      <Grid item xs={12}>
        <TableContainer
          className={cx(classes.tableContainer, props.classesOverride?.tableContainer)}
        >
          <Table size="small" className={cx(props.classesOverride?.table)}>
            {renderTableHead()}
            {renderTableBody()}
            {renderTableFoot()}
          </Table>
          <LoadingOverlay open={loading} />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

BaseTable.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  enableCheckbox: true,
};
