import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { windowFeatureIsEnabled } from 'config/window-features';
import { Grid, Typography } from '@mui/material';
import compose from 'recompose/compose';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import Divider from 'components/divider';
import {
  ADD_TASK_INTERVENTION_FORM,
  INTERVENTION_SEVERITIES,
  INT,
  INTERVENTION_TYPES,
  CATEGORY_ARCHIVED,
} from 'constants/index';
import {
  DISEASE_PROGRESSION_OPTIONS,
  VISIT_TYPE_OPTIONS,
} from 'constants/intervention-hospitalization-options';
import { MedicationStatus } from 'constants/enums';
import { workListChanged } from 'actions/action-patient';
import {
  addInterventionTaskPatient,
  addInterventionTask,
  addInterventionCount,
  fetchInterventionTasks,
  fetchTaskCounts,
} from 'actions/action-tasks';
import { fetchResourceLink } from 'actions/action-resource-links';
import { convertToArborDate } from 'models/time/arbor-date';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { addMedsIntoMedGroups } from 'services/utils/medication-service';
import {
  renderDropdown,
  renderTextField,
  renderMultipleDropdown,
  renderCheckbox,
} from 'components/form/field/redux-field';
import StaticDropdown from 'components/form/field/static-dropdown';

// eslint-disable-next-line max-len
import InterventionCopyContentButton from 'components/intervention-button/intervention-copy-content-button';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import {
  getInitialServiceGroupForReactSelect,
  reduceStatusesWithSubstatusesToArray,
  sortWithOtherComparer,
} from 'services/utils/task-service';
import { TherapyUtil } from 'utils/therapy-util';
import {
  interventionReasonIds,
  interventionInfectionTypesIds,
  severityAlertList,
} from 'constants/lists';
import { validateDate, required } from 'components/form/validation/validation';
import { Error } from '@mui/icons-material';
import moment from 'moment';
import cx from 'classnames';
import { styles } from '../task-detail-styles';
import LengthOfStay from '../../../components/dynamic-form/renders/render-length-of-stay';

class AddInterventionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      severityDropdown: -1,
      serviceGroupDropdown: -1,
      reasonTypeDropdown: -1,
      showAdditionalReason: false,
      showDates: false,
      isSaving: false,
      infectionType: -1,
      showOtherInfectionField: false,
    };
    this.handleSeverityDropdown = this.handleSeverityDropdown.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReasonTypeDropdown = this.handleReasonTypeDropdown.bind(this);
    this.handleAdditionalReason = this.handleAdditionalReason.bind(this);
    this.handleAdmissionDateChange = this.handleAdmissionDateChange.bind(this);
    this.handleDischargedDateChange = this.handleDischargedDateChange.bind(this);
    this.handleInfectionTypeChange = this.handleInfectionTypeChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      interventionTypeFormVal,
      reasonTypeVal,
      change,
      defaultSeverityId,
      defaultServiceGroup,
      serviceGroups,
      defaultDetails,
    } = this.props;
    if (
      interventionTypeFormVal !== prevProps.interventionTypeFormVal ||
      reasonTypeVal !== prevProps.reasonTypeVal
    ) {
      // eslint-disable-next-line
      this.setState({ showAdditionalReason: false }); // This is allowed since it is wrapped in a conditional see https://reactjs.org/docs/react-component.html
      change('reason', '');
    }

    if (defaultSeverityId !== prevProps.defaultSeverityId) {
      change('severity_id', defaultSeverityId);
    }

    if (defaultServiceGroup !== prevProps.defaultServiceGroup) {
      const serviceGroup = getInitialServiceGroupForReactSelect(defaultServiceGroup, serviceGroups);
      change('service_group_id', serviceGroup);
    }

    if (defaultDetails !== prevProps.defaultDetails) {
      change('details', defaultDetails);
    }
  }

  handleSeverityDropdown(e) {
    this.setState({
      severityDropdown: e.target.value,
    });
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  handleReasonTypeDropdown(e) {
    this.setState({
      reasonTypeDropdown: e.target.value,
    });
  }

  handleAdditionalReason(e, val) {
    if (val) {
      const ids = String(val).split(',');
      if (
        ids.includes(String(interventionReasonIds.INTENTIONAL_OTHER)) ||
        ids.includes(String(interventionReasonIds.UNINTENTIONAL_OTHER))
      ) {
        this.setState({
          showAdditionalReason: true,
        });
      } else {
        this.setState({
          showAdditionalReason: false,
        });
      }
      if (
        ids.includes(String(interventionReasonIds.INTENTIONAL_PRESCRIBER_HELD)) ||
        ids.includes(String(interventionReasonIds.INTENTIONAL_ILLNESS)) ||
        ids.includes(String(interventionReasonIds.INTENTIONAL_PLANNED_MEDICAL_PROCEDURE)) ||
        ids.includes(String(interventionReasonIds.INTENTIONAL_DOSE_REDUCTION))
      ) {
        this.setState({
          showDates: true,
        });
      } else {
        this.setState({
          showDates: false,
        });
      }
    } else {
      this.setState({
        showAdditionalReason: false,
        showDates: false,
      });
    }
  }

  handleAdmissionDateChange(value) {
    this.setState({
      admissionDate: value,
    });
  }

  handleDischargedDateChange(value) {
    this.setState({
      dischargedDate: value,
    });
  }

  handleInfectionTypeChange(value) {
    this.setState({
      showOtherInfectionField: value === interventionInfectionTypesIds.OTHER,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    this.setState({
      isSaving: true,
    });

    const {
      addInterventionTask, // eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
      addInterventionTaskPatient, // eslint-disable-line
      workListChanged, // eslint-disable-line
      fetchResourceLink, // eslint-disable-line
      reset,
      therapy,
      cancel,
      selectedPatientId,
      taskAddedFromData,
      medicationIds,
      handleSubmitComplete,
      additionalLinkMetadata = {},
      cancelOnSubmit = true,
      taskTherapyId,
    } = this.props;

    const calcTherapyId = () => {
      if (taskTherapyId) {
        return taskTherapyId;
      }
      if (therapy) {
        return therapy.id;
      }
      return null;
    };

    const payload = {
      ...values,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      last_checked_dt: convertToArborDate(values.last_checked_dt).getUtcDatetime(),
      ...(values.discharged_date && {
        discharged_date: convertToArborDate(values.discharged_date).getUtcDatetime(),
      }),
      ...(values.admission_date && {
        admission_date: convertToArborDate(values.admission_date).getUtcDatetime(),
      }),
      service_group_id: values.service_group_id.value,
      status_id: 8001,
      therapy_id: calcTherapyId(),
      medication_ids: medicationIds && medicationIds.length ? medicationIds : [],
      category_id: values.category_id,
      type_id: values.type_id,
      outcome: null,
      severity_id: values.severity_id,
      task_added_from_data: taskAddedFromData || null,
      patient_id: selectedPatientId,
      is_patient: therapy && therapy.id ? 0 : 1,
      reason: values.reason,
      additional_reason: values.additional_reason ? values.additional_reason : null,
      reason_start_date: values.reason_start_date
        ? convertToArborDate(values.reason_start_date, true).getUtcDate()
        : null,
      reason_end_date: values.reason_end_date
        ? convertToArborDate(values.reason_end_date, true).getUtcDate()
        : null,
      interacting_drugs: values.interacting_drugs || null,
      therapy_ids: values.therapy_ids || null,
      drug_interaction_description: values.drug_interaction_description || null,
      details: values.details || null,
      additional_link_metadata: additionalLinkMetadata,
    };

    let linkedTaskTypeIds = [];
    if (taskAddedFromData) {
      if (Array.isArray(taskAddedFromData)) {
        linkedTaskTypeIds = taskAddedFromData.map(x => ({
          task_type: x.task_type,
          task_id: x.task_id,
        }));
      } else {
        linkedTaskTypeIds.push({
          task_type: taskAddedFromData.task_type,
          task_id: taskAddedFromData.task_id,
        });
      }
    }

    // List of actions to pass the api call results to
    const resultActions = [handleSubmitComplete];
    const addAction = payload.is_patient ? addInterventionTaskPatient : addInterventionTask;

    addAction(payload)
      .then(result => {
        workListChanged();
        if (linkedTaskTypeIds && linkedTaskTypeIds.length) {
          Promise.all(
            linkedTaskTypeIds.map(linkedTaskTypeId => {
              const results = fetchResourceLink(
                linkedTaskTypeId.task_type,
                linkedTaskTypeId.task_id,
              );
              return results;
            }),
          );
        }
        if (!payload.is_patient) {
          fetchTaskCounts();
        }
        resultActions.forEach(action => {
          if (action) {
            action(result.payload.data.added_task);
          }
        });
        if (cancel && cancelOnSubmit) {
          cancel();
        }
        if (reset) {
          reset();
        }
        this.setState({
          isSaving: false,
        });
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        });
      });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      valid,
      submitting,
      classes,
      cancel,
      taskStatuses,
      lookups,
      interventionTypeFormVal,
      detailsVal,
      reasonTypeVal,
      buttonIdPrefix,
      medications,
      auth,
      therapies,
      noTherapyIdsCheckboxVal,
      formValues,
    } = this.props;

    const {
      severityDropdown,
      serviceGroupDropdown,
      reasonTypeDropdown,
      showAdditionalReason,
      showDates,
      isSaving,
      admissionDate,
      dischargedDate,
      infectionType,
      showOtherInfectionField,
    } = this.state;

    const {
      ADHERENCE: adherence,
      CARE_PLAN: carePlan,
      LAB: lab,
      DDI,
      DUR,
      ADR,
      REGIMEN: regimen,
      REFERRAL_OF_SERVICE: referralOfService,
      LINKAGE_TO_CARE: linkageToCare,
      OTHER_CLINICAL: otherClinical,
      HOSPITALIZATION: hospitalization,
      PRODUCT_ISSUE: productIssue,
      DRUG_INFORMATION: drugInformation,
      VACCINE: vaccine,
    } = INTERVENTION_TYPES;

    const interventionType = taskStatuses.intervention_types.find(
      type => type.id === interventionTypeFormVal,
    );
    const { medicationList, medicationGroups: overwriteGroups } = medications;
    const fullMedicationGroups = addMedsIntoMedGroups(overwriteGroups, medicationList);
    const medicationGroups = [];
    fullMedicationGroups.forEach(group => {
      if (group.header.active && group.header.status_code === MedicationStatus.Active) {
        medicationGroups.push({
          label: group.header.medication,
          value: group.header.medication,
        });
      }
    });

    const activeTherapies = therapies?.data
      ? Object.values(therapies.data).filter(
          t => t.administration_status_category_id !== CATEGORY_ARCHIVED,
        )
      : [];

    const therapiesListWithDosage = TherapyUtil.therapiesListWithDosage(activeTherapies);
    const reasonArr = taskStatuses.intervention_reasons
      .filter(rec => rec.type_id === interventionTypeFormVal)
      .sort(sortWithOtherComparer('reason_name'));
    const reasonTypeArr = reduceStatusesWithSubstatusesToArray(reasonArr, 'reason_subtype');

    const taskFieldTransitions =
      // eslint-disable-next-line camelcase
      auth?.currentUser?.permissions?.tasks_field[INT.toLowerCase()] || [];
    // eslint-disable-next-line camelcase
    const fieldPermissions = taskFieldTransitions?.field_transitions || null;

    const infectionTypes = lookups.lookupInterventionInfectionTypes.map(int => ({
      label: int.type_name,
      value: int.id,
    }));

    const now = moment();
    const isOutsideBusinessHours = now.hour() < 8 || now.hour() >= 18;

    return (
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <form className={classes.addFormContainer} autoComplete="off">
            <Grid item container className={classes.formRowContainer}>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  component={renderMultipleDropdown}
                  name="category_id"
                  label="Category *"
                  fields={taskStatuses.intervention_categories
                    .sort((typeA, typeB) => typeA.category_name.localeCompare(typeB.category_name))
                    .map(int => ({
                      label: int.category_name,
                      value: int.id,
                    }))}
                  validate={[required]}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="type_id"
                  label="Type *"
                  component={renderDropdown}
                  validate={[required]}
                  fields={taskStatuses.intervention_types
                    .sort((typeA, typeB) => typeA.type_name.localeCompare(typeB.type_name))
                    .map(int => ({
                      label: int.type_name,
                      value: int.id,
                    }))}
                />
              </Grid>
              <Grid item xs={2} className={classes.fieldContainer}>
                <Field
                  name="after_hours_call"
                  label="After Hours Call?"
                  component={renderCheckbox}
                />
              </Grid>
            </Grid>
            <Grid item container className={classes.formRowContainer}>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="severity_id"
                  label="Severity *"
                  validate={[required]}
                  component={renderDropdown}
                  fields={taskStatuses.intervention_severities.map(int => ({
                    label: int.severity_name,
                    value: int.id,
                  }))}
                  disabled={
                    // eslint-disable-next-line camelcase
                    fieldPermissions?.severity_id &&
                    !fieldPermissions.severity_id.some(p => p.canEdit)
                  }
                  state={severityDropdown}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={lookups.serviceGroups.map(int => ({
                    label: int.display_name || int.name,
                    value: int.id,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  label="Follow up Date *"
                  component={renderDatePicker}
                  validate={[validateDate, required]}
                />
              </Grid>
              {formValues?.severity_id > 0 && (
                <Grid
                  item
                  xs={12}
                  className={cx(classes.severityAlert, {
                    [classes.severityLow]: formValues?.severity_id === 1,
                    [classes.severityMedium]: formValues?.severity_id === 2,
                    [classes.severityHigh]: formValues?.severity_id === 3,
                  })}
                >
                  {severityAlertList[formValues?.severity_id]}
                </Grid>
              )}
              <Grid item xs={6} className={classes.fieldContainer}>
                {!noTherapyIdsCheckboxVal ? (
                  <Field
                    name="therapy_ids"
                    label="Therapies"
                    component={renderMultipleDropdown}
                    fields={therapiesListWithDosage}
                  />
                ) : (
                  <StaticDropdown
                    fieldName="Therapies"
                    field="No Associated Therapies"
                    variant="raw"
                  />
                )}
              </Grid>
              <Grid item xs={2} className={classes.fieldContainer}>
                <Field
                  name="no_therapy_ids_checkbox"
                  label="No Associated Therapies"
                  component={renderCheckbox}
                />
              </Grid>
              {interventionTypeFormVal === INTERVENTION_TYPES.INFECTION_CONTROL && (
                <Grid container>
                  <Grid item xs={4} className={classes.fieldContainer}>
                    <Field
                      name="infection_type_id"
                      label="Infection Type/Organism *"
                      component={renderDropdown}
                      fields={infectionTypes}
                      validate={[required]}
                      state={infectionType}
                      onChange={this.handleInfectionTypeChange}
                    />
                  </Grid>
                  {!!showOtherInfectionField && (
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="other_infection_type"
                        label="Other Infection *"
                        component={renderTextField}
                        validate={[required]}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              {interventionTypeFormVal && (
                <Grid container>
                  {reasonTypeArr.length > 0 && (
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="reason_type"
                        label="Reason Type *"
                        component={renderDropdown}
                        fields={reasonTypeArr}
                        validate={[required]}
                        state={reasonTypeDropdown}
                      />
                    </Grid>
                  )}
                  {reasonTypeArr.length === 0 &&
                    (interventionTypeFormVal === ADR ||
                      interventionTypeFormVal === regimen ||
                      interventionTypeFormVal === lab ||
                      interventionTypeFormVal === linkageToCare ||
                      interventionTypeFormVal === referralOfService ||
                      interventionTypeFormVal === otherClinical ||
                      interventionTypeFormVal === productIssue ||
                      interventionTypeFormVal === drugInformation ||
                      interventionTypeFormVal === vaccine) && (
                      <>
                        <Grid
                          item
                          xs={interventionTypeFormVal === ADR ? 8 : 12}
                          className={classes.fieldContainer}
                        >
                          <Field
                            name="reason"
                            label="Reason *"
                            multiple
                            component={
                              interventionTypeFormVal === ADR ||
                              interventionTypeFormVal === linkageToCare ||
                              interventionTypeFormVal === regimen ||
                              interventionTypeFormVal === otherClinical ||
                              interventionTypeFormVal === productIssue ||
                              interventionTypeFormVal === drugInformation ||
                              interventionTypeFormVal === vaccine
                                ? renderDropdown
                                : renderMultipleDropdown
                            }
                            fields={reasonArr.map(int => ({
                              label: int.reason_name,
                              value: int.id,
                            }))}
                            validate={[required]}
                            onChange={this.handleAdditionalReason}
                          />
                        </Grid>
                        {interventionTypeFormVal === ADR && (
                          <Grid item xs={4} className={classes.fieldContainer}>
                            <Field
                              name="serious_adverse_event"
                              label="Serious/Unexpected Adverse Event"
                              component={renderCheckbox}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  {reasonTypeArr.length > 0 && reasonTypeVal && (
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="reason"
                        label="Reason *"
                        multiple
                        component={
                          interventionTypeFormVal === linkageToCare
                            ? renderDropdown
                            : renderMultipleDropdown
                        }
                        validate={[required]}
                        fields={reasonArr
                          .filter(r => reasonTypeVal === r.reason_subtype && r.reason_name)
                          .map(int => ({
                            label: int.reason_name,
                            value: int.id,
                            key: int.id,
                          }))}
                        onChange={this.handleAdditionalReason}
                      />
                    </Grid>
                  )}

                  {showAdditionalReason && (
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="additional_reason"
                        label="Reason *"
                        validate={[required]}
                        component={renderTextField}
                      />
                    </Grid>
                  )}
                  {interventionTypeFormVal === adherence && showDates && (
                    <>
                      <Grid item xs={4} className={classes.fieldContainer}>
                        <Field
                          name="reason_start_date"
                          label="Start Date *"
                          component={renderDatePicker}
                          validate={[required, validateDate]}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.fieldContainer}>
                        <Field
                          name="reason_end_date"
                          label="End Date *"
                          component={renderDatePicker}
                          validate={[required, validateDate]}
                        />
                      </Grid>
                    </>
                  )}
                  {interventionTypeFormVal === carePlan && (
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="additional_reason"
                        label="Problems/Determination of Needs of the Patient *"
                        component={renderTextField}
                        validate={[required]}
                      />
                    </Grid>
                  )}
                  {(interventionTypeFormVal === DDI || interventionTypeFormVal === DUR) && (
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="interacting_drugs"
                        label="Interacting Drugs *"
                        validate={[required]}
                        component={renderMultipleDropdown}
                        plainTextValue
                        maxOptions={2}
                        fields={medicationGroups}
                      />
                    </Grid>
                  )}
                  {(interventionTypeFormVal === DDI || interventionTypeFormVal === DUR) && (
                    <Grid item xs={8} className={classes.fieldContainer}>
                      <Field
                        name="drug_interaction_description"
                        label="Drug Interaction Description *"
                        multiline
                        validate={[required]}
                        component={renderTextField}
                      />
                    </Grid>
                  )}
                  {interventionTypeFormVal === otherClinical && (
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="additional_reason"
                        label="Description of Reason *"
                        validate={[required]}
                        multiline
                        component={renderTextField}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              {interventionTypeFormVal === hospitalization && (
                <>
                  <Grid container>
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="disease_progression"
                        label="Disease Progression/Exacerbation *"
                        fields={DISEASE_PROGRESSION_OPTIONS}
                        validate={[required]}
                        component={renderDropdown}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="visit_type"
                        label="Type of visit *"
                        fields={VISIT_TYPE_OPTIONS}
                        validate={[required]}
                        component={renderDropdown}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="admission_date"
                        label="Admin date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                        onChange={this.handleAdmissionDateChange}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="discharged_date"
                        label="Discharge date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                        onChange={this.handleDischargedDateChange}
                      />
                    </Grid>
                    <LengthOfStay
                      xs={3}
                      admissionDate={admissionDate}
                      dischargedDate={dischargedDate}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item container className={classes.formRowContainer}>
              <Grid item xs={9} className={classes.fieldContainer}>
                <Field
                  name="details"
                  label="Details"
                  component={renderTextField}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
            <Divider />
            {isOutsideBusinessHours && (
              <Grid container className={classes.afterHoursContent} xs={12}>
                <Error className={classes.afterHoursIcon} />
                <Typography className={classes.afterHoursText}>
                  It is {now.format('hh:mm A').toLowerCase()}. Should this intervention be marked as
                  an after hours call?
                </Typography>
              </Grid>
            )}
            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleSubmit={handleSubmit(this.handleSubmit)}
              handleCancel={cancel}
              disableSubmit={!valid && (pristine || submitting)}
              buttonIdPrefix={buttonIdPrefix}
              isLoading={isSaving}
              loadingText="Saving"
              leftAlignedButtons={[
                {
                  button: (
                    <Grid sx={{ marginTop: 1 }}>
                      <InterventionCopyContentButton
                        details={detailsVal}
                        interventionType={interventionType ? interventionType.type_name : null}
                      />
                    </Grid>
                  ),
                },
              ]}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  const { taskStatuses, selectedPatientId, lookups, medications, auth, therapies } = state;
  const selector = formValueSelector(props.form || ADD_TASK_INTERVENTION_FORM);
  const interventionTypeFormVal = selector(state, 'type_id');
  const detailsVal = selector(state, 'details');
  const reasonVal = selector(state, 'reason');
  const reasonTypeVal = selector(state, 'reason_type');
  const noTherapyIdsCheckboxVal = selector(state, 'no_therapy_ids_checkbox');
  const infectionTypeId = selector(state, 'infection_type_id');
  const formValues = getFormValues(ADD_TASK_INTERVENTION_FORM)(state);

  return {
    taskStatuses,
    selectedPatientId,
    lookups,
    interventionTypeFormVal,
    detailsVal,
    reasonVal,
    reasonTypeVal,
    medications,
    auth,
    therapies,
    noTherapyIdsCheckboxVal,
    infectionTypeId,
    formValues,
    initialValues: {
      additional_reason: null,
      reason_start_date: null,
      reason_end_date: null,
      followup_dt: convertToArborDate(new Date()).getCustomerDate(true),
      last_checked_dt: null,
      status_id: null,
      therapy_id: props?.therapy?.id || null,
      therapy_ids: props?.defaultTherapyIds || null,
      category_id: props.defaultCategoryId || null,
      type_id: props.defaultTypeId || null,
      outcome: null,
      details: props.defaultDetails || null,
      severity_id: props.defaultSeverityId || INTERVENTION_SEVERITIES.LOW,
      service_group_id: getInitialServiceGroupForReactSelect(
        props.defaultServiceGroup,
        props.serviceGroups,
      ),
    },
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    addInterventionTask,
    addInterventionTaskPatient,
    addInterventionCount,
    fetchInterventionTasks,
    fetchResourceLink,
  }),
)(reduxForm({ form: ADD_TASK_INTERVENTION_FORM })(AddInterventionForm));
