import React from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './confirmation-panel-styles';

// eslint-disable-next-line @typescript-eslint/naming-convention, react/function-component-definition
const ConfirmationDialogPanel = props => {
  const {
    classes,
    open,
    title,
    content,
    maxWidth = 'md',
    backdropClick,
    cancelText,
    continueText,
    onCancel,
    onContinue,
    showCancelButton = true,
  } = props;

  return (
    <Dialog open={open} maxWidth={maxWidth} onBackdropClick={backdropClick}>
      {title && <DialogTitle className={classes.confirmationDialogTitle}>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          {showCancelButton ? (
            <Grid item>
              <Button onClick={onCancel}>{cancelText}</Button>
            </Grid>
          ) : null}
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={onContinue}
              className={classes.confirmationContinueButton}
            >
              {continueText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(ConfirmationDialogPanel);
