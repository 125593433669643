import React, { useEffect, useRef } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import moment from 'moment';
import { TextField, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DATABASE_DATE_FORMAT } from 'constants/index';
import Validation from '../validation/validation';
import { styles } from '../field/field-styles';
import 'react-datepicker/dist/react-datepicker.css';
import './datetime-picker.css';

function DateTimePicker({
  input,
  placeholder,
  disabled,
  id,
  name,
  useCustomContainer,
  textFieldInputProps,
  InputComponentInputProps,
  minDate,
  maxDate,
  showDisabledMonthNavigation,
  ...props
}) {
  const { showTimeSelect, fullWidth, onChange, 'data-qa-id': dataQaId } = props;
  const inputRef = useRef(null);
  const formatDate = !showTimeSelect ? DATABASE_DATE_FORMAT : 'YYYY-MM-DD HH:mm';

  let value = null;
  if (moment.isMoment(input.value)) {
    value = moment(input.value.format(formatDate), formatDate).toDate();
  } else if (input.value) {
    value = moment(input.value).toDate();
  }

  // eslint-disable-next-line react/no-find-dom-node
  const getElement = type => inputRef.current.input.querySelector(type);

  // eslint-disable-next-line arrow-body-style
  function CustomCalendarContainer({ children }) {
    return <CalendarContainer>{children}</CalendarContainer>;
  }

  return (
    <DatePicker
      calendarContainer={useCustomContainer ? CustomCalendarContainer : undefined}
      selected={value}
      onChange={date => {
        let newValue = null;
        if (moment(date).isValid()) {
          if (showTimeSelect) {
            newValue = moment.utc(moment(date).format('YYYY-MM-DD hh:mm A'), 'YYYY-MM-DD hh:mm A');
          } else {
            newValue = moment.utc(moment(date).format(DATABASE_DATE_FORMAT), DATABASE_DATE_FORMAT);
          }
        }
        input.onChange(newValue);
        if (onChange) {
          onChange(newValue);
        }
        if (input.onBlur) {
          input.onBlur();
        }
      }}
      placeholderText={placeholder}
      customInput={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <TextField
          variant="standard"
          autoComplete="off"
          disabled={disabled}
          id={id}
          name={name}
          fullWidth={fullWidth}
          data-qa-id={dataQaId}
          inputProps={textFieldInputProps}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={InputComponentInputProps}
        />
      }
      ref={inputRef}
      onBlur={() => {
        if (input.onBlur) {
          input.onBlur();
        }
      }}
      disabledKeyboardNavigation
      onKeyDown={event => {
        // fix issue with focus and delete keys by forcing blur upon ESC and ENTER keys press
        if (event.key === 'Escape' || event.key === 'Enter') {
          getElement('input').blur();
          getElement('div').focus();
        }
      }}
      {...props}
      minDate={minDate}
      maxDate={maxDate}
      showDisabledMonthNavigation={showDisabledMonthNavigation}
      id={id}
      disabled={disabled}
    />
  );
}

export const renderDatePicker = withStyles(styles, { withTheme: true })(
  ({ input, classes, label, id, placeholder, meta: { touched, error, warning }, renderFooter, ...custom }) => (
    <Grid container direction="column">
      {label && (
        <Typography variant="caption" className={classes.fieldLabel}>
          {label}
        </Typography>
      )}
      <DateTimePicker
        input={input}
        dateFormat="MM/dd/yyyy"
        placeholder={placeholder !== undefined ? placeholder : 'mm/dd/yyyy'}
        {...custom}
        id={id}
        className={custom.centerInput ? classes.centerInputValue : null}
      />
      <Validation touched={touched} error={error} warning={warning} />
      {renderFooter && renderFooter()}
    </Grid>
  ),
);

export const renderDateTimePicker = withStyles(styles, { withTheme: true })(
  ({ input, classes, label, meta: { touched, error, warning }, ...custom }) => (
    <Grid container direction="column">
      {label && (
        <Typography variant="caption" className={classes.fieldLabel}>
          {label}
        </Typography>
      )}
      <DateTimePicker
        input={input}
        showTimeSelect
        timeFormat="hh:mm a"
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="MM/dd/yyyy hh:mm a"
        placeholder="mm/dd/yyyy hh:mm"
        {...custom}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </Grid>
  ),
);

export const renderCalendarPicker = withStyles(styles, { withTheme: true })(
  ({ input, label, meta: { touched, error, warning }, ...custom }) => (
    <Grid container direction="column" alignItems="center">
      <Typography variant="caption">{label}</Typography>
      <DateTimePicker
        input={input}
        inline
        dateFormat="MM/dd/yyyy"
        placeholder="mm/dd/yyyy"
        calendarClassName="calendar-inline"
        {...custom}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </Grid>
  ),
);

export const renderTimePicker = withStyles(styles, { withTheme: true })(
  ({ input, label, classes, meta: { touched, error, warning }, ...custom }) => (
    <div style={{ display: 'inline-flex', flexDirection: 'column', width: '80%' }}>
      <Typography className={classes.fieldLabel}>{label}</Typography>
      <TextField
        variant="standard"
        {...input}
        id="time"
        type="time"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        {...custom}
      />
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  ),
);

export default function ({ value, onChange, ...props }) {
  return <DateTimePicker
    {...props}
    input={{
      value,
      onChange,
    }}
  />;
}
