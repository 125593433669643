import { assignKeys, processPhoneData } from 'services/utils/demographic-service';
import { getFilteredList, convertFilteredAddresses } from 'services/utils/task-service';
import {
  ADD_CONTACT,
  EDIT_CONTACT,
  PATIENT_CONTACT_LIST_REQUEST,
  PATIENT_CONTACT_LIST_SUCCESS,
  PATIENT_CONTACT_LIST_FAILURE,
  ADD_PATIENT,
  EDIT_PATIENT,
  ADD_DELIVERY_PROVIDER,
} from '../constants';

const convertContactData = patient => ({
  ...patient,
  addresses: assignKeys(patient.addresses),
  emails: assignKeys(patient.emails),
  phones: assignKeys(patient.phones),
  filteredAddresses: convertFilteredAddresses(patient.addresses),
  filteredEmails: getFilteredList(assignKeys(patient.emails)),
  filteredPhones: processPhoneData(getFilteredList(assignKeys(patient.phones)))
    .sort((a, b) => a?.rank - b?.rank),
});

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PATIENT_CONTACT_LIST_REQUEST: {
      return { loading: true };
    }
    case PATIENT_CONTACT_LIST_SUCCESS: {
      return payload.reduce((map, obj) => {
        map[obj.id] = convertContactData(obj);
        return map;
      }, {});
    }
    case PATIENT_CONTACT_LIST_FAILURE: {
      return initialState;
    }
    case ADD_CONTACT:
    case ADD_PATIENT:
    case ADD_DELIVERY_PROVIDER:
      if (payload.data && payload.data.added_contact) {
        return {
          ...state,
          [payload.data.added_contact.id]: convertContactData(payload.data.added_contact),
        };
      }
      return state;
    case EDIT_CONTACT:
    case EDIT_PATIENT:
      if (payload.data && payload.data.updated_contact) {
        return {
          ...state,
          [payload.data.updated_contact.id]: convertContactData(payload.data.updated_contact),
        };
      }
      return state;
    default:
      return state;
  }
};
