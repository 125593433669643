const INLINE_FLEX = 'inline-flex';

export const styles = theme => ({
  addFormContainer: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    padding: 15,
    margin: '15px 30px 30px 30px',
  },
  addFormContainerStretched: {
    marginLeft: 0,
    marginRight: 0,
  },
  editFormContainer: {
    padding: 15,
    margin: '15px 30px 30px 30px',
  },
  formRowContainer: {
    marginBottom: 15,
  },
  radioGroup: {
    flexDirection: 'row',
  },
  addInsuranceBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    marginRight: 5,
  },
  addFormHeader: {
    color: theme.palette.primary.main,
    borderRadius: '5px 5px 0px 0px',
    fontSize: theme.font.largeFontSize,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  spacer: {
    height: 30,
  },
  editPanel: {
    margin: 30,
    paddingTop: 12,
    paddingRight: 55,
    paddingLeft: 24,
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
    color: theme.palette.addBtnColor,
  },
  buttonJump: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.addBtnColor,
    marginLeft: 0,
  },
  summary: {
    backgroundColor: theme.palette.primary.summaryPanel,
    paddingRight: 0,
  },
  summaryNoteContainer: {
    backgroundColor: theme.palette.primary.white,
    marginTop: 1,
    marginBottom: 1,
    borderRadius: 5,
  },
  detailContainer: {
    display: 'block',
    width: '100%',
  },
  insuranceTitle: {
    fontSize: theme.font.largeFontSize,
    float: 'left',
    marginLeft: '20px',
  },
  note: {
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.grey13,
  },
  Modal: {
    position: 'absolute',
    width: theme.spacing(150),
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  StatusContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    marginTop: 1,
    marginBottom: 1,
    padding: theme.spacing(1),
  },
  effectiveDate: {
    height: '18px',
    fontSize: '14px',
    color: theme.palette.primary.grey12,
    lineHeight: '16px',
    float: 'right',
    marginRight: '7px',
  },
  header: {
    display: 'inline',
  },
  clear: {
    clear: 'both',
  },
  expandIcon: {
    left: 8,
    position: 'absolute',
  },
  iconPadding: {
    bottom: '10px',
    color: theme.palette.addBtnColor,
  },
  fieldLabel: {
    fontSize: theme.font.formLabelSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
  },
  fieldValue: {
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.black,
  },
  fieldSelectValue: {
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.black,
    minWidth: '160px',
  },
  headerLabel: {
    fontSize: theme.font.largeFontSize,
    fontWeight: 500,
  },
  headerLabelUpper: {
    textTransform: 'uppercase',
  },
  subHeaderLabelUpper: {
    textTransform: 'uppercase',
    fontSize: theme.font.smallFontSize,
    fontWeight: 500,
  },
  paddedTop: {
    margin: 0,
    marginTop: 25,
  },
  borderBottom: {
    borderBottom: 'solid 1px lightgrey',
    margin: 0,
    marginTop: 25,
  },
  pullRight: {
    float: 'right',
    fontSize: theme.font.largeFontSize,
    fontWeight: 500,
  },
  editFieldLabel: {
    fontSize: theme.font.formLabelSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
  },
  editFieldValue: {
    fontSize: theme.font.formItemSize,
    color: theme.palette.primary.formItem,
  },
  containerDiv: {
    width: 'calc(100% - 48px)',
    paddingTop: 20,
    paddingLeft: 24,
  },
  containerDivStretched: {
    paddingTop: 0,
    paddingLeft: 0,
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.font.weight.regular,
  },
  panel: {
    marginTop: 0,
    marginLeft: 30,
    marginRight: 30,
  },
  loadContainer: {
    position: 'relative',
    minHeight: 200,
  },
  summaryContent: {
    margin: 0,
  },
  noIncomeBackdrop: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  noIncomeBackdropWrapper: {
    position: 'relative',
  },
  formDropdown: {
    paddingRight: 20,
  },
  noInsuranceBackdrop: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  noInsuranceBackdropWrapper: {
    position: 'relative',
  },
  povertyInfoLabel: {
    display: INLINE_FLEX,
    fontSize: theme.font.formLabelSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
    paddingLeft: 15,
    margin: 0,
  },
  povertyInfoWrapper: {
    display: INLINE_FLEX,
    width: '75%',
    cursor: 'pointer',
    marginBottom: '50px',
  },
  povertyInfoDetailWrapper: {
    display: INLINE_FLEX,
    width: '75%',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  eligibilityCheckWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    padding: 10,
  },
  eligibilityCheckButton: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.cuttySark,
    textTransform: 'none',
    borderRadius: 5,
    padding: 10,
    marginLeft: 2,
  },
  eligibilityButtonsWrapper: {
    float: 'right',
  },
  eligibilityTitle: {
    marginTop: 0,
  },
  eligibilityContainer: {
    padding: '0 15px',
    marginLeft: 15,
    marginRight: 15,
  },
  stretchedPbmPanel: {
    padding: 0,
  },
  inactivePanel: {
    backgroundColor: theme.palette.primary.summaryPanel,
    color: theme.palette.primary.formLabel,
  },
  inactiveSectionHeader: {
    marginLeft: 30,
  },
  compactPanel: {
    padding: 0,
    marginBottom: 0,
  },
  financialGroup: {
    marginBottom: 20,
  },
  financialGroupNoSideMargin: {
    border: 'none',
  },
  inactiveHeader: {
    borderBottom: '1px solid rgba(68, 72, 76, 0.2)',
    paddingLeft: 12,
  },
  inputLabel: {
    fontSize: 12,
    marginBottom: 10,
  },
  insurancePlan: {
    marginBottom: 19,
    marginRight: 20,
  },
  insurancePopoverBox: {
    padding: theme.spacing(2),
  },
  insurancePopoverBoxText: {
    fontSize: 12,
    color: theme.palette.primary.steelGrey,
    paddingBottom: 3,
  },
  insurancePopoverButton: {
    'margin': 0,
    'padding': 0,
    'minWidth': 0,
    'backgroundColor': 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
