import React from 'react';

export interface IHeaderCell<TParent, TChild> {
  childKey?: keyof TChild;
  label?: string;
  parentKey?: keyof TParent;
  hideLeftPadding?: boolean;
  sortable?: boolean;
  serverSideSearchField?: keyof TChild | keyof TParent;
  childCellValueOverride?: (child: TChild) => string;
  parentCellValueOverride?: (parent: TParent) => string;
  childValueFormatter?: (value: unknown) => string;
  parentValueFormatter?: (value: unknown) => string;
  showParentTooltip?: (cellValue: string | null) => boolean;
  parentCellClick?: (event: React.MouseEvent<HTMLElement>, parent: TParent) => void;
  childCellClick?: (event: React.MouseEvent<HTMLElement>, parent: TParent, child: TChild) => void;
  childColSpan?: number;
  hideChildCol?: boolean;
  parentComponent?: (parent: TParent) => JSX.Element;
  childComponent?: (parent: TParent, child: TChild) => JSX.Element | undefined;
  parentMaxWidth?: string;
}

export enum ButtonActiveWhen {
  Always,
  SomeSelected,
  NoneSelected,
}

export interface IQueryParameterSettings {
  pageSizeQueryStringKey: string;
  pageNumberQueryStringKey: string;
  searchTermQueryStringKey: string;
  sortPropQueryStringKey: string;
  sortOrderQueryStringKey: string;
}
