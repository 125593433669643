import React from 'react';
import { FieldTypes } from 'containers/application-manager/types';
import { CustomerBranchTrellisTherapeuticClassPriority } from 'constants/enums';
import YesNoForm from './yes-no-form';
import { DateFormStyled } from './date-form';
import { TherapeuticClassFormStyled } from './therapeutic-class-form';
import { SpecialityTypeFormStyled } from './speciality-type-form';
import ActiveInactiveForm from './active-inactive-form';
import { LicensedStatesFormStyled } from './licensed-states-form';
import TherapeuticAreaForm from './therapeutic-area-form';
import LockedUnlockedForm from './locked-unlocked-form';

import {
  CUSTOMER_BRANCH_TTC_HIGH_DISPLAY_TEXT,
  CUSTOMER_BRANCH_TTC_LOW_DISPLAY_TEXT,
} from '../../../constants';

interface IRenderFieldFormProps {
  initialValue: unknown;
  options?: { id: number; name: string }[];
  fieldType: FieldTypes | undefined;
  handleCancel: () => void;
  handleSubmit: (value: unknown) => void;
}

export const FormField: React.FC<IRenderFieldFormProps> = (
  props: IRenderFieldFormProps,
): JSX.Element | null => {
  switch (props.fieldType) {
    case FieldTypes.SpecialityType:
      return (
        <SpecialityTypeFormStyled
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
        />
      );
    case FieldTypes.YesNo:
      return (
        <YesNoForm
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
          options={{ optionLeft: 'Yes', optionRight: 'No', align: 'center' }}
        />
      );
    case FieldTypes.DatePicker:
      return (
        <DateFormStyled
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
        />
      );
    case FieldTypes.TherapeuticClass:
      return (
        <TherapeuticClassFormStyled
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
        />
      );
    case FieldTypes.ActiveInactive:
      return (
        <ActiveInactiveForm
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
        />
      );
    case FieldTypes.LockedUnlockedForm:
      return (
        <LockedUnlockedForm
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
        />
      );
    case FieldTypes.Licensed:
      return (
        <LicensedStatesFormStyled
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
        />
      );
    case FieldTypes.ActiveDeprecated:
      return (
        <YesNoForm
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
          options={{ optionLeft: 'Active', optionRight: 'Deprecated', align: 'left' }}
        />
      );
    case FieldTypes.TherapeuticArea:
      return (
        <TherapeuticAreaForm
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={props.handleSubmit}
          options={props.options}
        />
      );
    case FieldTypes.HighLow:
      return (
        <YesNoForm
          initialValue={props.initialValue as any}
          handleCancel={props.handleCancel}
          handleSubmit={(valueFromForm: boolean) =>
            props.handleSubmit(
              valueFromForm === true
                ? CustomerBranchTrellisTherapeuticClassPriority.High
                : CustomerBranchTrellisTherapeuticClassPriority.Low,
            )
          }
          options={{
            optionLeft: CUSTOMER_BRANCH_TTC_HIGH_DISPLAY_TEXT,
            optionRight: CUSTOMER_BRANCH_TTC_LOW_DISPLAY_TEXT,
            align: 'left',
          }}
        />
      );
    default:
      return null;
  }
};
