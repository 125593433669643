import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { fetchShippingHolidays } from 'actions/action-shipping';
import { convertToArborDate } from 'models/time/arbor-date';
import { DateMapping, IApiHolidayDates } from './interface';
import HolidayManager from './holidays';

export default (): JSX.Element => {
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const memorizedHolidays = useMemo(() => holidays, [holidays]);

  const dateMapping = (obj: DateMapping, currentDate: string) => {
    if (!obj[currentDate]) {
      const arborDt = convertToArborDate(currentDate);
      const weekday = arborDt.getUtcDate(true, 'dddd');
      const dt = arborDt.getUtcDate(true);
      obj[currentDate] = {
        holidayDate: `${weekday} - ${dt}`,
        customer: 'No',
        courier: 'No',
        ups: 'No',
      };
    }
  };

  const mergeDates = (holidaysObject: IApiHolidayDates) => {
    // TODO - create a generic type for it
    const dateMap: DateMapping = {};
    const { shippingCustomerHolidays, shippingCourierHolidays, shippingUpsHolidays } =
      holidaysObject;

    const maxLength = Math.max(
      shippingCustomerHolidays.length,
      shippingCourierHolidays.length,
      shippingUpsHolidays.length,
    );

    for (let i = 0; i < maxLength; i += 1) {
      if (shippingCustomerHolidays.length > i) {
        const date = shippingCustomerHolidays[i];
        dateMapping(dateMap, date);
        dateMap[date].customer = 'Yes';
      }

      if (shippingCourierHolidays.length > i) {
        const date = shippingCourierHolidays[i];
        dateMapping(dateMap, date);
        dateMap[date].courier = 'Yes';
      }

      if (shippingUpsHolidays.length > i) {
        const date = shippingUpsHolidays[i];
        dateMapping(dateMap, date);
        dateMap[date].ups = 'Yes';
      }
    }

    return Object.values(dateMap);
  };

  const fetchHolidays = useCallback(async () => {
    setLoading(true);
    const { payload } = await fetchShippingHolidays(selectedCustomerId);
    const holidayTreated = mergeDates(payload) as any;
    setHolidays(holidayTreated);
  }, [selectedCustomerId]);

  useEffect(() => {
    if (isMounted && selectedCustomerId !== 0) {
      fetchHolidays();
    } else {
      setIsMounted(true);
    }
  }, [fetchHolidays, isMounted, selectedCustomerId]);

  useEffect(() => {
    if (memorizedHolidays) {
      setLoading(false);
    }
  }, [memorizedHolidays]);

  return (
    <HolidayManager
      loading={loading}
      isMounted={isMounted}
      memorizedHolidays={memorizedHolidays}
      selectedCustomerId={selectedCustomerId}
      setSelectedCustomerId={setSelectedCustomerId}
    />
  );
};
