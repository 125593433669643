import React from 'react';
import cx from 'classnames';
import { compose } from 'recompose';
import { combineStyles } from 'services/utils/styles-service';
import { accordianStyles } from 'components/accordian/accordian-styles';
import AccordionHeader from 'components/form/header/accordion-header';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { groupBy } from 'helpers/misc';
import { Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './financials-styles';

export const FinancialsGrouping = props => {
  const { classes, renderMethod, financialItems, type, groupByKey, sortFn, auditRules, noSideMargin } = props;

  const groupedFinancialItems = groupBy(financialItems, groupByKey);

  return (
    <Grid item xs={12} className={cx(classes.financialGroup, { [classes.financialGroupNoSideMargin]: noSideMargin })}>
      <Accordion className={cx(classes.accordianPanel, { [classes.accordianPanelNoSideMargin]: noSideMargin })} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
        >
          <AccordionHeader name={type} hideHistory hideEdit smallHeader auditRules={auditRules} />
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.compactPanel,
          }}
        >
          <Grid item xs={12}>
            {groupedFinancialItems &&
              Object.keys(groupedFinancialItems)
                .sort(sortFn)
                .map(item => renderMethod(groupedFinancialItems[item], item))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const combinedStyles = combineStyles(styles, accordianStyles);

export default compose(withStyles(combinedStyles))(FinancialsGrouping);
