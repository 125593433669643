import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Add as AddIcon } from '@mui/icons-material';
import { IState } from 'interfaces/redux/IState';
import { sortBy } from 'lodash';
import { ISectionDetailsProps } from '../../section/interfaces';
import { IPatientEnrollmentProps } from '../types';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { convertMapToList } from '../../../reducers/helper-reducer';
import PbmDetail from '../../../containers/patient/financials/pbm-insurances/pbm-detail';
import { fetchPbmInsurances } from '../../../actions/action-financials';
import { groupBy } from '../../../helpers/misc';
import AddPbmInsurance from '../../../containers/patient/financials/pbm-insurances/add-pbm-insurance';
import { ToggleDisplayAddPBMInsurance } from '../../../actions/action-form-displays';
import { selectInsuranceVerification, useVerifiedText, SelectVerifiedInfo } from '../utils';

const selectPbmInsuranceVerification: SelectVerifiedInfo = ({ financials, selectedPatientId }) =>
  selectInsuranceVerification(convertMapToList(financials.pbm_insurances.data), selectedPatientId);

export function PatientEnrollmentPbmSection({
  alert: alertMessage,
}: ISectionDetailsProps<IPatientEnrollmentProps>) {
  const pbmInsurances = useTypedSelector(state => {
    return convertMapToList(state.financials.pbm_insurances.data).sort(
      (a, b) => a.insurance_type_id - b.insurance_type_id,
    );
  });
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const displayAddPbm = useTypedSelector(state => state.formDisplays.displayAddPBMInsurance);
  const dispatch = useDispatch();

  const pbmInsurancesGroupByType = groupBy(pbmInsurances, 'insurance_type');
  const insuranceTypes = Object.keys(pbmInsurancesGroupByType);

  const verifiedMessage = useVerifiedText(selectPbmInsuranceVerification);

  useEffect(() => {
    if (verifiedMessage) alertMessage(verifiedMessage, true);
    else alertMessage('Not Verified');
  }, [verifiedMessage]);

  return (
    <Grid item xs={12}>
      {insuranceTypes.map((insuranceType: string) => {
        const insurances = pbmInsurancesGroupByType[insuranceType];
        return (
          <PbmDetail
            stretch
            expand
            key={insuranceType}
            insuranceType={insuranceType}
            pbmInsurances={insurances}
            fetchPbmInsurances={fetchPbmInsurances}
          />
        );
      })}
      {displayAddPbm && (
        <AddPbmInsurance
          patientId={patientId}
          stretch
          cancel={() => dispatch(new ToggleDisplayAddPBMInsurance(false))}
        />
      )}
      {!displayAddPbm && (
        <Grid marginTop={1}>
          <Button
            variant="outlined"
            onClick={() => dispatch(new ToggleDisplayAddPBMInsurance(true))}
            startIcon={<AddIcon />}
            sx={{ float: 'right' }}
          >
            Add PBM Insurance
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
