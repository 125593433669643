import React, { FC, useState, useEffect } from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { Box } from '@mui/material';

import { fetchTask } from 'actions/action-tasks';
import { useDispatch } from 'react-redux';
import { ACTIVE, ARCHIVED, DC, RS } from '../../../../constants';
import TasksTableActiveContainer from './tasks-table-active-container';
import TasksTableArchivedContainer from './tasks-table-archived-container';
import { ITaskFilterItem } from './interfaces';

const taskActiveArchivedOptions = [
  { label: 'Active', value: ACTIVE },
  { label: 'Archived', value: ARCHIVED },
];
const TasksTable: FC = (): JSX.Element => {
  const [showActiveOrArchived, setShowActiveOrArchived] = useState<ITaskFilterItem>({
    label: 'Active',
    value: ACTIVE,
  });
  const patientId = useTypedSelector(state => state.patient).id;
  const tasks = useTypedSelector(state => state.tasks.data);
  const dispatch = useDispatch();

  const toggleTabs = (newValue: ITaskFilterItem) => setShowActiveOrArchived(newValue);

  useEffect(() => {
    if (tasks && Object.values(tasks).length) {
      const tasksToBeUpdated = Object.values(tasks).filter(
        t =>
          t.taskType &&
          [DC, RS].includes(t.taskType) &&
          t.patientClinicalDataItems?.length &&
          t.patientClinicalDataItems.some((a: any) => a.questionnaire),
      );
      tasksToBeUpdated.forEach(t => {
        dispatch(fetchTask(t.taskType, t.id));
      });
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 3 }}>
      <TasksTableActiveContainer
        toggleActiveArchived={toggleTabs}
        taskActiveArchivedSelected={showActiveOrArchived}
        taskActiveArchivedOptions={taskActiveArchivedOptions}
        display={showActiveOrArchived.value === ACTIVE}
      />
      <TasksTableArchivedContainer
        toggleActiveArchived={toggleTabs}
        taskActiveArchivedSelected={showActiveOrArchived}
        taskActiveArchivedOptions={taskActiveArchivedOptions}
        display={showActiveOrArchived.value === ARCHIVED}
      />

      <Box mb={20} />
    </Box>
  );
};

export default TasksTable;
