import React, { Component } from 'react';
import { Button, Grid, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { EditPencil } from 'components/icons/icons';
import DetailField from 'components/form/field/field';
import { convertToArborDate } from 'models/time/arbor-date';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import DocumentDisplay from 'components/document/document-display';
import { getDocumentsForDisplay } from 'services/utils/document-service';
import { numberToDollars } from 'services/utils/formatting';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import EditCopayCard from './edit-copay-card';
import { styles } from '../../task-detail-styles';
import { FINANCIAL } from '../../../../constants';
import { noteTagTypes } from '../../../../constants/lists';
import { linkFinancialAssistanceTask } from '../../../../actions/action-financials';
import { editFa } from '../../../../actions/action-tasks';
import { CANCELED_BY_USER_STATUS_ID } from '../constants';

const tagType = noteTagTypes.find(tag => tag.label === FINANCIAL);

class CopayCardDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEdit: false,
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleKeepExisting = this.handleKeepExisting.bind(this);
    this.handleCancelExisting = this.handleCancelExisting.bind(this);
  }

  handleCancelExisting() {
    const { showForm, financialAssistance, editFa } = this.props;
    if (showForm) {
      showForm();
    }

    return editFa({
      id: financialAssistance.task_fa_id,
      status_id: CANCELED_BY_USER_STATUS_ID,
    });
  }

  handleKeepExisting() {
    const { showForm } = this.props;
    if (showForm) {
      showForm();
    }
  }

  handleEdit() {
    this.setState(prevState => ({
      displayEdit: !prevState.displayEdit,
    }));
  }

  render() {
    const { classes, financialAssistance, type, uploadedDocuments, therapies, showLinkButton, noEdit } = this.props;
    const {
      id,
      status,
      bin,
      pcn,
      group_id: group,
      phone_number: phoneNumber,
      expiration_date: expDate,
      instructions,
      annual_limit: annualLimit,
      remaining_balance: remainingBalance,
      member_id: memberId,
      copay,
    } = financialAssistance;

    const { displayEdit } = this.state;
    const drugName = therapies[financialAssistance.therapy_id]
      ? therapies[financialAssistance.therapy_id].drug_name
      : undefined;
    return (
      <div>
        <Grid container>
          {!displayEdit && !noEdit && (
            <Grid container justifyContent="flex-end">
              <Grid item xs={3}>
                <DocumentDisplay
                  taskIdResourceIds={[{ tagTypeId: tagType.value, resourceId: id }]}
                  tagTypeId={tagType.value}
                  resourceId={id}
                  drugName={drugName}
                  tagTypeLabel={financialAssistance.financial_assistance_type_category}
                  documents={getDocumentsForDisplay(uploadedDocuments.documents, id, tagType.value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container alignItems="center">
            {!displayEdit && (
              <Grid item xs={11}>
                <Grid container>
                  {type && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Type" field={`${type}`} />
                    </Grid>
                  )}
                  {(status || status === 0) && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Status"
                        field={status === 1 ? 'Active' : 'Inactive'}
                      />
                    </Grid>
                  )}
                  {!!bin && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="BIN" field={`${bin}`} />
                    </Grid>
                  )}
                  {!!pcn && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="PCN" field={`${pcn}`} />
                    </Grid>
                  )}
                  {!!memberId && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Member ID" field={memberId} />
                    </Grid>
                  )}
                  {!!group && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Group" field={group} />
                    </Grid>
                  )}
                  {!!phoneNumber && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <TalkdeskPhoneNumber number={phoneNumber} headerText="Phone Number" />
                    </Grid>
                  )}
                  {!!annualLimit && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Annual Limit" field={numberToDollars(annualLimit)} />
                    </Grid>
                  )}
                  {!!expDate && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Expiration Date"
                        field={convertToArborDate(expDate, true).getCustomerDate(true)}
                      />
                    </Grid>
                  )}
                  {!!remainingBalance && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Remaining Balance"
                        field={numberToDollars(remainingBalance)}
                      />
                    </Grid>
                  )}
                  {!!instructions && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField
                        fieldName="Processing Instructions"
                        field={instructions}
                        variant="raw"
                      />
                    </Grid>
                  )}
                  {!!copay && (
                    <Grid item xs={3} className={classes.textContainer}>
                      <DetailField fieldName="Copay" field={numberToDollars(copay)} variant="raw" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {displayEdit && (
              <Grid item xs={11}>
                <EditCopayCard
                  cancel={this.handleEdit}
                  type={type}
                  financialAssistance={financialAssistance}
                />
              </Grid>
            )}

            {!noEdit && (
              <Grid item xs={1}>
                <Grid container justifyContent="flex-end">
                  <Button className={classes.button} onClick={this.handleEdit}>
                    <EditPencil />
                  </Button>
                </Grid>
              </Grid>
            )}

            {showLinkButton && (
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button className={classes.button} onClick={this.handleCancelExisting} style={{ marginRight: 6 }}>
                    Cancel existing FA chain
                  </Button>
                  <Button variant="contained" className={classes.button} onClick={this.handleKeepExisting}>
                    Keep existing FA chain
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!window.FEATURES.new_financials_view && <Divider />}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { uploadedDocuments, therapies } = state;
  return { uploadedDocuments, therapies: therapies.data };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    linkFinancialAssistanceTask,
    editFa,
  }),
)(CopayCardDetail);
