import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Email, CheckCircle, CreditCard } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
// eslint-disable-next-line import/no-unresolved
import { PhoneUsage } from 'interfaces/redux/IPhone';
import { SMSIcon, WKIcon } from '../../../components/icons/icons';
import { IPreferredContact } from '../../../interfaces/redux/IPreferredContact';
import { PatientStyles } from './patient.styles';
import { MobilePhoneSmsEnum } from '../../../constants/enums';
import IconWithTooltip from './icon-with-tooltip';
import PaymentMethodStatus from './payment-method-status';

interface IPatientSmsEmail extends IPreferredContact, WithStyles<typeof PatientStyles> {
  wkIsVisible: boolean;
  sourceNumber: string;
  patientLoaded?: boolean;
  expirationDate?: string;
  patientAutoFCStatus?: number;
  autoFCAnchorTherapy?: string | number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function PatientSmsEmail(props: IPatientSmsEmail): JSX.Element {
  const {
    classes,
    filteredPhones,
    filteredEmails,
    filteredWelcomeKit,
    sourceNumber,
    wkIsVisible = false,
    patientLoaded = true,
    patientAutoFCStatus,
    autoFCAnchorTherapy,
  } = props;

  const displayPhoneProvided = (): JSX.Element => {
    const classNameIcon =
      filteredPhones &&
      filteredPhones?.length > 0 &&
      filteredPhones.some(
        ({ sms, use }) => sms === MobilePhoneSmsEnum.OptOut && use === PhoneUsage.Mobile,
      )
        ? classes.iconActive
        : classes.iconInactive;
    return <SMSIcon className={classNameIcon} />;
  };

  const displayEmailProvided = (): JSX.Element => {
    const classNameIcon =
      filteredEmails && filteredEmails?.length > 0 ? classes.iconActive : classes.iconInactive;
    return <Email className={classNameIcon} />;
  };

  const displayPhonesOptInCheck = () => {
    const mobilePhones =
      filteredPhones?.filter(phone => phone.use === PhoneUsage.Mobile && !phone.deleted) || [];
    if (mobilePhones.length === 0) {
      return <IconWithTooltip state="no_mobile_numbers" tooltip="No mobile number on file" />;
    }
    // From this point on we can assume there is at least one active, Mobile number configured.

    let smsStopDate;
    const customerSentOptOutMessage = mobilePhones?.some(item => {
      smsStopDate = item.sms_stop_date;
      return item.is_opt_in_sent && item.patient_choice && smsStopDate;
    });

    if (customerSentOptOutMessage) {
      return (
        <IconWithTooltip
          state="patient_sent_stop"
          tooltip={`SMS Opted Out. - Patient sent STOP-(${
            smsStopDate || ''
          }).\nTo Opt In Patient needs to send START to ${sourceNumber}`}
        />
      );
    }

    if (
      mobilePhones?.some(phone => phone.sms === MobilePhoneSmsEnum.OptIn && !phone.is_opt_in_sent)
    ) {
      return (
        <IconWithTooltip
          state="opt_in_message_not_sent"
          tooltip="SMS Opt-in, opt-in message has not been sent"
        />
      );
    }

    if (mobilePhones?.every(phone => phone.sms === MobilePhoneSmsEnum.NotSpecified)) {
      return (
        <IconWithTooltip
          state="sms_not_specified"
          tooltip="SMS Opt-in status has not been selected"
        />
      );
    }

    if (
      mobilePhones?.every(phone => phone.sms != MobilePhoneSmsEnum.OptIn) &&
      mobilePhones?.filter(phone => phone.sms === MobilePhoneSmsEnum.OptOut && !phone.sms_stop_date)
        .length > 0
    ) {
      return <IconWithTooltip state="sms_opt_out" tooltip="Patient selected SMS Opt-out" />;
    }

    if (
      mobilePhones?.some(
        phone => phone.sms === MobilePhoneSmsEnum.OptIn && phone.patient_choice === undefined,
      )
    ) {
      return (
        <IconWithTooltip
          state="opt_in_sent_waiting_patient_response"
          tooltip="SMS Opt-in - Waiting for patient confirmation"
        />
      );
    }
    const optInComplete = mobilePhones?.some(
      phone => phone.sms === MobilePhoneSmsEnum.OptIn && phone.patient_choice,
    );
    if (optInComplete && (patientAutoFCStatus === 0 || patientAutoFCStatus === null)) {
      return <IconWithTooltip state="sms_only" tooltip="SMS Opt-in / Secure Link Opt-out" />;
    }
    if (optInComplete && patientAutoFCStatus === 1) {
      return (
        <IconWithTooltip
          state="manual_secure_link"
          tooltip="SMS Opt-in / Secure Link Opt-in (Manual)"
        />
      );
    }
    if (optInComplete && patientAutoFCStatus === 2) {
      return (
        <IconWithTooltip
          state="auto_secure_link"
          tooltip={
            <div>
              SMS Opt-in
              <br />
              Automated Secure Link Opt-in
              <br />
              Anchor Therapy: {autoFCAnchorTherapy}
            </div>
          }
        />
      );
    }
    if (
      mobilePhones?.some(phone => phone.sms === MobilePhoneSmsEnum.OptOut && !phone.sms_stop_date)
    ) {
      return <IconWithTooltip state="sms_opt_out" tooltip="SMS Opt-out" />;
    }

    if (mobilePhones?.some(item => !item.patient_choice && item.is_opt_in_sent)) {
      return (
        <IconWithTooltip
          state="opt_in_sent_waiting_patient_response"
          tooltip="SMS Opt-in - Waiting for patient confirmation"
        />
      );
    }

    return <IconWithTooltip state="provided" tooltip="SMS Opt-in" />;
  };

  const displayEmailOptInCheck = (): JSX.Element => {
    if (!patientLoaded) {
      return <></>;
    }
    const emailSms =
      filteredEmails?.map(email => email.enrollment).filter(enrollment => enrollment != null) ?? [];
    const optIn = emailSms.includes(MobilePhoneSmsEnum.OptIn);
    const optOut = emailSms.includes(MobilePhoneSmsEnum.OptOut);
    const notSpecified = emailSms.includes(MobilePhoneSmsEnum.NotSpecified);
    return (
      <>
        {!emailSms?.length && <InfoIcon className={classes.notProvided} />}
        {optIn && <CheckCircle className={classes.provided} />}
        {optOut && <InfoIcon className={classes.notProvided} />}
        {notSpecified && <InfoIcon className={classes.notProvided} />}
      </>
    );
  };

  const displayWk = (): JSX.Element => {
    const classNameIcon = filteredWelcomeKit ? classes.iconActive : classes.iconInactive;
    return (
      <>
        {filteredWelcomeKit ? (
          <Tooltip title="Welcome Kit/Patient Educational materials received" placement="bottom">
            <span>
              <WKIcon className={classNameIcon} />
            </span>
          </Tooltip>
        ) : (
          <WKIcon className={classNameIcon} />
        )}
      </>
    );
  };

  const displayWkCheck = (): JSX.Element => {
    return filteredWelcomeKit ? (
      <CheckCircle className={classes.provided} />
    ) : (
      <CancelIcon className={classes.notProvided} />
    );
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          {displayPhoneProvided()}
          {displayPhonesOptInCheck()}
        </Grid>
        <Grid item>|</Grid>
        <Grid item>
          {displayEmailProvided()}
          {displayEmailOptInCheck()}
        </Grid>
        {wkIsVisible && (
          <>
            <Grid item>|</Grid>
            <Grid item>
              {displayWk()}
              {displayWkCheck()}
            </Grid>
          </>
        )}
        <Grid item>|</Grid>
        <Grid>
          <CreditCard
            className={props.expirationDate ? classes.iconActive : classes.iconInactive}
          />
          <PaymentMethodStatus expiration={props.expirationDate} />
        </Grid>
      </Grid>
      <br />
    </>
  );
}

export default withStyles(PatientStyles)(PatientSmsEmail);
