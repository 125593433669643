// eslint-disable camelcase
import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import {
  getChecklistData,
  editPatient,
  fetchProblems,
  fetchProblemsSuccess,
  fetchProblemsFailed,
  fetchPatientMetricsSuccess,
} from 'actions/action-patient';
import { notifyError } from 'actions/action-notifications';

import { fetchTaskCounts } from 'actions/action-tasks';
import { assignRanking } from 'services/utils/demographic-service';
import { getPatientProblems } from 'services/utils/patient-problems-service';
import { editContact } from 'actions/action-contacts';
import { clearClinicalData } from 'actions/action-clinical-data';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { HttpStatusCodes } from 'constants/http-status-codes';
import {
  EDIT_CONTACT,
  EDIT_PATIENT,
  EDIT_CONTACT_AND_UPDATE_PATIENT,
  CLEAR_CONTACT,
  CLEAR_PATIENT_DATA,
  PATIENT_CHANGE,
  WORK_LIST_CHANGED,
  SELECT_PATIENT_TASK,
  SELECT_PATIENT,
  ADD_THERAPY,
  ADD_THERAPY_AR,
  EDIT_THERAPY,
  ADD_THERAPY_LINK_DRUG,
  LINK_SCHEDULE_OUTREACH_THERAPIES,
  SELECT_PATIENT_TASK_FORCED,
  NEED_PATIENT_REFRESH,
  PATIENT_CHECKLIST_FETCH,
  PATIENT_CHECKLIST_REQUEST,
  PATIENT_CHECKLIST_SUCCESS,
  PATIENT_CHECKLIST_FAILURE,
  UPDATE_PATIENT_PREFERENCE_REQUEST,
  UPDATE_PATIENT_PREFERENCE_SUCCESS,
  DISCHARGE_PATIENT,
  FETCH_NOTES,
  ADD_MEDICATION,
  ADD_MEDICATION_SUCCESS,
  UPDATE_MEDICATION,
  UPDATE_MEDICATION_SUCCESS,
  NOTIFY_ERROR,
  NOTIFY_SUCCESS,
  TASK_LIFECYCLE_ACTIONS,
  ADD_TO_TREE_VIEW,
  ADD_TASK_TO_PATIENT,
  FETCH_RESOURCE_LINK,
  MARK_PATIENT_DECEASED,
  SELECTED_PATIENT_ID,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_REQUEST,
  FETCH_RECONCILIATIONS_REQUEST,
  FETCH_ALLERGIES,
  FETCH_SPECIAL_POPULATIONS,
  DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
  DUR_TRIGGER_CHECK_INTERACTION_SUCCESS,
  DUR,
  INTERACTION_FINDER_TRIGGER,
  UPDATE_THERAPY_PROPERTIES,
  FETCH_AUDITS_REQUEST,
  FETCH_PATIENT_PROBLEMS,
  FETCH_SPECIAL_POPULATIONS_SUCCESS,
  FETCH_PATIENT_ADHERENCE_DATA,
  FETCH_PATIENT_ADHERENCE_DATA_SUCCESS,
  FETCH_PATIENT_ADHERENCE_HISTORY,
  FETCH_PATIENT_ADHERENCE_HISTORY_SUCCESS,
  FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS,
  FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS_SUCCESS,
  BULK_TASKS_UPDATED,
  TASK_TYPES,
  LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT,
  REPORT_DUPLICATE_PATIENT_PROFILES,
  SET_REPORT_DUPLICATE_PROFILE_BUTTON_STATUS,
  DEFAULT_SUPPORT_TICKET_URL,
  DUR_FETCH_BY_ID,
  REFRESH_PATIENT_PHONES,
  REFRESH_PATIENT_PHONES_SUCCESS,
  REFRESH_PATIENT_QUESTIONS_SUCCESS,
  FETCH_HIDDEN_QUESTIONS,
  SELECT_PATIENT_SUCCESS,
} from '../constants';
import { InterventionTypeId } from '../interfaces/enums/InterventionType';
import HTTP from '../services/http';
import { updateSpecialPopulation } from '../actions/action-special-populations';

const getReadyForReviewDurFromTasks = tasks =>
  Object.values(tasks || {}).filter(
    task => task?.taskType === DUR && task?.status_id === DurStatus.ReadyForReview,
  );

const medicationChanged = ({ previousMedications, updatedMedication }) => {
  const previousMedication = previousMedications.find(({ id }) => id === updatedMedication.id);
  if (!previousMedication || !updatedMedication) {
    return false;
  }

  return previousMedication.ndc !== updatedMedication.ndc;
};

function* simpleFetchWorker(actionBase, request, requestPayload = {}, patientId) {
  yield put({ type: `${actionBase}_REQUEST`, payload: requestPayload });
  try {
    const response = yield call(() => request);
    const { data } = response;
    yield put({
      type: `${actionBase}_SUCCESS`,
      payload: data,
      meta: { patientId },
    });
  } catch (error) {
    yield put({ type: `${actionBase}_FAILURE` });
  }
}

const adherenceInterventionChanged = (actionPayload, patientId) => {
  let upsertedInterventions = actionPayload.payload?.data?.added_task ?? [];

  if (actionPayload.type === BULK_TASKS_UPDATED) {
    upsertedInterventions = actionPayload.payload?.data?.updated_tasks ?? [];
  }

  return !!upsertedInterventions?.find(
    ({ type: taskType, type_id: intTypeId, patient_id: taskPatientId }) =>
      taskType === TASK_TYPES.INTERVENTION &&
      intTypeId === InterventionTypeId.Adherence &&
      taskPatientId === patientId,
  );
};

function* simpleFetchWorkerPatientDocuments(
  actionBase,
  request,
  requestPayload = {},
  patientId,
  patientChanged,
) {
  yield put({ type: `${actionBase}_REQUEST`, payload: requestPayload });

  try {
    const response = yield call(() => request);
    const { data } = response;
    yield put({ type: `${actionBase}_SUCCESS`, payload: data });
    if (patientChanged) {
      yield put({
        type: FETCH_DOCUMENTS_REQUEST,
      });
      const documentsRequest = HTTP.post('/documents/_search', {
        patient_id: patientId,
      });
      const documentsResponse = yield call(() => documentsRequest);
      yield put({
        type: FETCH_DOCUMENTS,
        payload: {
          data: { ...documentsResponse.data },
        },
        meta: { patientId },
      });
    }
  } catch (error) {
    yield put({ type: `${actionBase}_FAILURE` });
  }
}

function* simpleFetchWorkerPatientNotes(
  actionBase,
  request,
  requestPayload = {},
  patientId,
  patientChanged,
) {
  yield put({ type: `${actionBase}_REQUEST`, payload: requestPayload });
  try {
    const state = yield select();
    const existingTasks = Object.values(state.tasks?.data || {});
    const response = yield call(() => request);
    const { data } = response;
    yield put({ type: `${actionBase}_SUCCESS`, payload: data });
    if (patientChanged) {
      const notesRequest = HTTP.post('/notes/_search', {
        patient_id: patientId,
        group_by_tag: true,
      });

      const notesResponse = yield call(() => notesRequest);

      yield put({
        type: FETCH_NOTES,
        payload: {
          data: {
            ...notesResponse.data,
            tasks: existingTasks,
          },
        },
        meta: { patientId },
      });
    }
  } catch (error) {
    yield put({ type: `${actionBase}_FAILURE` });
  }
}

function* workerAddTasks(actionBase) {
  try {
    const state = yield select();
    const existingTasks = state.tasks;
    const addedTask =
      actionBase && actionBase.payload && actionBase.payload.data
        ? actionBase.payload.data.added_task[0]
        : null;
    if (
      !existingTasks.some(
        t =>
          t.id === addedTask.id &&
          t.type_id === addedTask.type_id &&
          t.therapy_id === addedTask.therapy_id,
      ) &&
      addedTask.therapy_id !== null
    ) {
      yield put({
        type: ADD_TASK_TO_PATIENT,
        payload: existingTasks.concat(addedTask),
      });
    }
    yield put({
      type: ADD_TO_TREE_VIEW,
      payload: existingTasks.concat(addedTask),
    });
  } catch (error) {
    yield put({ type: `${actionBase}_FAILURE` });
  }
}

function* workerAddTasksFromTherapy(actionBase) {
  try {
    const addedTherapy =
      actionBase && actionBase.payload && actionBase.payload.data
        ? actionBase.payload.data.therapy
        : null;
    const addedTherapySpecialPopulations =
      actionBase && actionBase.payload && actionBase.payload.data
        ? actionBase.payload.data.specialPopulations
        : null;
    if (addedTherapySpecialPopulations) {
      yield put({
        type: FETCH_SPECIAL_POPULATIONS_SUCCESS,
        payload: addedTherapySpecialPopulations,
      });
    }
    if (addedTherapy && addedTherapy.drugUtilizationReview) {
      yield put({
        type: DUR_TRIGGER_CHECK_INTERACTION_SUCCESS,
        payload: {
          data: {
            updated_tasks: [
              {
                ...addedTherapy.drugUtilizationReview,
                taskType: DUR,
                type: 'DRUG UTILIZATION REVIEW',
              },
            ],
          },
        },
      });
    }
    if (addedTherapy && addedTherapy.dataCollectList && addedTherapy.dataCollectList.length) {
      yield put({
        type: BULK_TASKS_UPDATED,
        payload: {
          data: {
            updated_tasks: addedTherapy.dataCollectList,
            post_ar_therapy_add: actionBase.type === ADD_THERAPY_AR,
          },
        },
      });
    }
    let addedTasks = [];
    if (addedTherapy.prior_authorization) {
      addedTasks = [...addedTasks, ...addedTherapy.prior_authorization];
    }
    if (addedTherapy.medicationReviewList) {
      addedTasks = [...addedTasks, ...addedTherapy.medicationReviewList];
    }
    if (addedTherapy.financial_assistance) {
      addedTasks = [...addedTasks, ...addedTherapy.financial_assistance];
    }
    if (addedTherapy.dataCollectList) {
      addedTasks = [...addedTasks, ...addedTherapy.dataCollectList];
    }

    yield put({
      type: ADD_TO_TREE_VIEW,
      payload: addedTasks,
    });
  } catch (error) {
    yield put({ type: `${actionBase}_FAILURE` });
  }
}

function* workerAddTasksFromTherapyWorkflow(actionBase) {
  try {
    const state = yield select();
    const existingTasks = state.tasks;
    const updatedTherapyTasks =
      actionBase && actionBase.payload && actionBase.payload.data
        ? actionBase.payload.data.therapy
        : null;
    const addedTasks = updatedTherapyTasks.fill_coordination
      .concat(updatedTherapyTasks.counseling_list)
      .concat(updatedTherapyTasks.outreach_list);
    yield put({
      type: ADD_TASK_TO_PATIENT,
      payload: existingTasks.concat(addedTasks),
    });
    const newState = yield select();
    yield put({
      type: ADD_TO_TREE_VIEW,
      payload: newState.tasks,
    });
  } catch (error) {
    yield put({ type: `${actionBase}_FAILURE` });
  }
}

function* workerAddTasksFromTaskWorkflow(actionBase) {
  try {
    const state = yield select();
    const existingTasks = state.tasks.data;
    const updatedTasks =
      actionBase &&
      actionBase.payload &&
      actionBase.payload.data &&
      actionBase.payload.data.addedResources &&
      actionBase.payload.data.addedResources.length > 0
        ? actionBase.payload.data.addedResources.map(resource => resource.data)
        : null;
    if (updatedTasks) {
      const addedTasks = [];
      updatedTasks.forEach(task => addedTasks.concat(task));
      yield put({
        type: ADD_TASK_TO_PATIENT,
        payload: existingTasks.concat(addedTasks),
      });
      const newState = yield select();
      yield put({
        type: ADD_TO_TREE_VIEW,
        payload: newState.tasks,
      });
    }
  } catch (error) {
    yield put({ type: `${actionBase}_FAILURE` });
  }
}

function* workerSelectPatientSaga(action) {
  const { payload: patient } = action;
  const deceasedPatientId =
    action.type === 'MARK_PATIENT_DECEASED' &&
    action.payload &&
    action.payload.data &&
    action.payload.data.successfullyMarkedPatientAsDeceased
      ? action.payload.data.patientId
      : null;
  const patientId =
    (action.type === SELECTED_PATIENT_ID && patient) ||
    action.patientId ||
    patient.patientId ||
    (patient.data && patient.data.therapy && patient.data.therapy.patient_id) ||
    (patient.data &&
      patient.data.therapy &&
      patient.data.therapy.length &&
      patient.data.therapy[0].patient_id) ||
    deceasedPatientId;
  if (patientId) {
    yield put({ type: CLEAR_CONTACT, payload: null });
    yield put({ type: CLEAR_PATIENT_DATA, payload: null });
    const request = HTTP.get(`/patients/${patientId}`, {});

    yield put(clearClinicalData());

    yield simpleFetchWorkerPatientDocuments(
      SELECT_PATIENT,
      request,
      {
        reload: patient.reload,
      },
      patientId,
      (action.type = SELECTED_PATIENT_ID),
    );

    yield put({
      type: FETCH_RECONCILIATIONS_REQUEST,
    });

    yield put({
      type: FETCH_AUDITS_REQUEST,
    });

    yield put(fetchProblems(patientId));
    yield put({
      type: FETCH_HIDDEN_QUESTIONS,
    });

    yield simpleFetchWorkerPatientNotes(
      SELECT_PATIENT,
      request,
      {
        reload: patient.reload,
      },
      patientId,
      action.type === SELECTED_PATIENT_ID,
    );

    yield simpleFetchWorker(
      FETCH_ALLERGIES,
      HTTP.get(`/patients/${patientId}/allergies`, {}),
      {},
      patientId,
    );

    yield simpleFetchWorker(
      FETCH_SPECIAL_POPULATIONS,
      HTTP.get(`/patients/${patientId}/special_populations`, {}),
      {},
      patientId,
    );
    const patientIdMap = [patientId];
    yield put({
      type: LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT,
      patientIdMap,
    });
  }
}

function* workerFetchPatientAdherenceData({ payload, type }) {
  const state = yield select();
  const patientId = state.selectedPatientId;

  let fetchAdherenceInfo = false;
  if (
    [TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION_PATIENT, BULK_TASKS_UPDATED].includes(payload.type)
  ) {
    fetchAdherenceInfo = adherenceInterventionChanged({ payload, type }, patientId);
  } else {
    fetchAdherenceInfo = true;
  }

  if (fetchAdherenceInfo) {
    try {
      const url = `/patients/${patientId}/therapies/adherences`;
      const request = HTTP.get(url);
      const response = yield call(() => request);
      yield put({ type: FETCH_PATIENT_ADHERENCE_DATA_SUCCESS, payload: response.data });
    } catch (e) {
      yield put({
        type: NOTIFY_ERROR,
        payload: 'Unable to load adherence data',
      });
    }
  }
}

function* workerFetchPatientAdherenceHistory({ payload }) {
  try {
    const url = `/patients/${payload.patient_id}/interventions/history`;
    const request = HTTP.get(url);
    const response = yield call(() => request);
    yield put({ type: FETCH_PATIENT_ADHERENCE_HISTORY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Unable to load adherence history',
    });
  }
}

function* workerAddMedicationSaga({ payload }) {
  try {
    if (payload && Array.isArray(payload) && payload.length) {
      for (const payloadItem of payload) {
        const url = `/patients/${payloadItem.patient_id}/medications/`;
        const request = HTTP.post(url, payloadItem, {});
        const response = yield call(() => request);
        yield put({ type: ADD_MEDICATION_SUCCESS, payload: response });
      }
      // We trigger the interactions as a refresh, because this way we guarantee that we check
      // interactions for all the meds that were just added
      const [firstMedication] = payload;
      if (firstMedication) {
        const addManyMedications = payload.length > 1;
        yield put({
          type: DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
          patientId: firstMedication.patient_id,
          // If user is adding multiple medications at same time (check error in https://bitbucket.org/trellisrx/tcms-services/pull-requests/4752)
          // then we make the interactions/ API call as a User refresh.
          // If medication is being added as part of add medication process or as part of the FC workflow,
          // then we make the API call with Medication as trigger.
          source: addManyMedications
            ? INTERACTION_FINDER_TRIGGER.User
            : INTERACTION_FINDER_TRIGGER.Medication,
          sourceValue: addManyMedications ? {} : firstMedication,
        });
      }
    } else {
      const url = `/patients/${payload.patient_id}/medications/`;
      const request = HTTP.post(url, payload, {});
      const response = yield call(() => request);
      yield put({ type: ADD_MEDICATION_SUCCESS, payload: response });

      yield put({
        type: DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
        patientId: payload.patient_id,
        source: INTERACTION_FINDER_TRIGGER.Medication,
        sourceValue: payload,
      });
    }

    yield put({
      type: NOTIFY_SUCCESS,
      payload: 'Data Updated Successfully',
    });

    const { tasks } = yield select();
    const durTasks = getReadyForReviewDurFromTasks(tasks?.data);
    if (durTasks.length > 0) {
      yield put({
        type: DUR_FETCH_BY_ID,
        payload: { id: durTasks[0].id },
      });
    }
  } catch (err) {
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Data Updated Failed',
    });
  }
}

function* workerUpdateMedicationSaga(action) {
  const { payload } = action;
  try {
    const response = yield call(() =>
      HTTP.patch(`/patients/${payload.patient_id}/medications/${payload.id}`, payload, {}),
    );

    const {
      medications: { medicationGroups: previousMedications },
    } = yield select();

    const { data: { updated_medications: updatedMedications } = {} } = response;
    const updatedMedication = updatedMedications.find(({ id }) => id === payload.id);

    const { therapy_id: therapyId, ndc } = updatedMedication || {};

    const isMedicationChanged = medicationChanged({
      previousMedications,
      updatedMedication,
    });

    yield put({
      type: UPDATE_MEDICATION_SUCCESS,
      payload: response,
      patientId: payload.patient_id,
      onlyFetchPatient: true,
    });
    if (isMedicationChanged) {
      // Only update therapy's NDC when its medication changed its NDC
      if (therapyId) {
        yield put({
          type: UPDATE_THERAPY_PROPERTIES,
          payload: {
            id: therapyId,
            values: {
              ndc,
            },
          },
        });
      }

      yield put({
        type: DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
        patientId: payload.patient_id,
        source: INTERACTION_FINDER_TRIGGER.Medication,
        sourceValue: payload,
      });
    }

    const { tasks } = yield select();
    const durTasks = getReadyForReviewDurFromTasks(tasks?.data);
    if (durTasks.length > 0) {
      yield put({
        type: DUR_FETCH_BY_ID,
        payload: { id: durTasks[0].id },
      });
    }

    yield put({
      type: NOTIFY_SUCCESS,
      payload: 'Medication Updated Successfully',
    });
  } catch (error) {
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Error Updating Medication List',
    });
  }
}

function* workerUpdatePatientPreferenceSaga(action) {
  const { payload, meta } = action;
  const { patient, preferredContact, preferredContactItem } = meta;

  try {
    const response = yield call(() =>
      HTTP.post(`/patients/${payload.patient_id}/preferences`, payload, {}),
    );
    const SpecialPopulationResponse = yield call(() =>
      HTTP.get(`/patients/${payload.patient_id}/special_populations`, payload, {}),
    );
    if (SpecialPopulationResponse.data.length) {
      yield put(updateSpecialPopulation(SpecialPopulationResponse));
    }
    if (patient && payload.preferred_contact_patient) {
      // If patient is the preferred contact
      const patientPayload = {
        id: patient.id,
      };
      if (payload.preferred_contact_method === 2) {
        patientPayload.emails = JSON.stringify(assignRanking(patient.emails, preferredContactItem));
      } else {
        patientPayload.phones = JSON.stringify(assignRanking(patient.phones, preferredContactItem));
      }
      yield put(editPatient(patientPayload));
    } else if (preferredContact) {
      const contactPayload = {
        id: preferredContact.id,
        patient_id: patient.id,
      };
      if (payload.preferred_contact_method === 2) {
        contactPayload.emails = JSON.stringify(
          assignRanking(preferredContact.emails, preferredContactItem),
        );
      } else {
        contactPayload.phones = JSON.stringify(
          assignRanking(preferredContact.phones, preferredContactItem),
        );
      }
      yield put(editContact(contactPayload));
    }
    yield put({
      type: UPDATE_PATIENT_PREFERENCE_SUCCESS,
      payload: response,
    });
    yield put({
      type: NOTIFY_SUCCESS,
      payload: 'Patient Preferences Updated Successfully',
    });
  } catch (error) {
    yield put({
      type: NOTIFY_ERROR,
      payload: `Error Updating Patient Preferences ${error}`,
    });
  }
}

function* workerAddTherapyLinkSaga({ payload: { newTherapy, drug, onSuccess, onFail } }) {
  try {
    if (newTherapy && drug) {
      const postURL = `/patients/${newTherapy.patient_id}/therapies`;
      const request = HTTP.post(postURL, newTherapy, {});
      yield put({ type: ADD_THERAPY_AR, payload: request });
      const response = yield call(() => request);
      if (drug.schedule_outreach_id) {
        const soPayload = { therapy_id: response.data.therapy.id, ndc: drug.ndc };
        const soRequest = HTTP.patch(
          `/tasks/so/${drug.schedule_outreach_id}/links/therapies`,
          soPayload,
          {},
        );
        yield put({ type: LINK_SCHEDULE_OUTREACH_THERAPIES, payload: soRequest });
      }
      yield put({ type: WORK_LIST_CHANGED });
    }
    if (onSuccess) onSuccess();
  } catch (error) {
    if (onFail) onFail(error);
  }
}

function* workerLoadPatientChecklistDataSaga(action) {
  const { patientId } = action.payload;
  yield put({ type: PATIENT_CHECKLIST_REQUEST });
  try {
    const response = yield call(() => HTTP.get(`/patients/${patientId}/clinics`, {}));
    const data = {
      patientId,
      clinics: response.data.clinics,
    };
    yield put({
      type: PATIENT_CHECKLIST_SUCCESS,
      payload: data,
      meta: {
        patientId,
      },
    });
  } catch (error) {
    yield put({ type: PATIENT_CHECKLIST_FAILURE });
  }
}

function* workerDischargePatientSaga(action) {
  const state = yield select();
  if (action.payload.data.updated_patient) {
    const patientId = action.payload.data.updated_patient.id;
    const { selectedPatientId } = state;
    if (selectedPatientId === patientId) {
      yield put(getChecklistData(patientId));
      yield put(fetchTaskCounts());
    }
  }
}

function* workerFetchChecklistData(action) {
  const patientId = action.payload;
  if (patientId) {
    yield put(getChecklistData(patientId));
  }
}

function* workerFetchAdherenceComplianceStatus({ payload, type }) {
  const state = yield select();
  const patientId = state.selectedPatientId;

  let fetchAdherence = false;

  if ([TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION_PATIENT, BULK_TASKS_UPDATED].includes(type)) {
    fetchAdherence = adherenceInterventionChanged({ payload, type }, patientId);
  } else {
    fetchAdherence = true;
  }

  if (fetchAdherence) {
    const url = `/patients/${patientId}/adherences/compliance-status`;

    try {
      const request = HTTP.get(url);
      const response = yield call(() => request);
      yield put({
        type: FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS_SUCCESS,
        payload: response.data,
        meta: { patientId },
      });
    } catch (error) {
      yield put(notifyError('Failed fetching patient compliance status'));
    }
  }
}

function* workerFetchPatientMetrics() {
  const state = yield select();
  const patientId = state.selectedPatientId;

  const url = `/patients/${patientId}/metrics`;

  try {
    const request = HTTP.get(url);
    const response = yield call(() => request);
    yield put(fetchPatientMetricsSuccess(response.data));
  } catch (error) {
    yield put(notifyError('Failed fetching patient compliance status'));
  }
}

function* workerDetectPatientChange(action) {
  const { patientId } = action.payload;
  if (patientId) {
    yield put({
      type: PATIENT_CHANGE,
      payload: { patientId },
    });
  }
}

function* workerPatientProblems(action) {
  try {
    const problems = yield call(getPatientProblems, action.payload.patientId);
    yield put(fetchProblemsSuccess(problems));
  } catch (error) {
    yield put(fetchProblemsFailed());
    yield put(notifyError("Failed fetching patient's problems list"));
  }
}

function* workerReportDuplicateProfiles(action) {
  const {
    payload: { patient },
  } = action;
  const arrDuplicates = patient.possibleDuplicateProfiles.duplicateIds;

  const request = HTTP.post('/patients/report_duplicate_profiles', {
    duplicates: arrDuplicates,
  });
  const response = yield call(() => request);
  const state = yield select();
  const userDisplayName = state?.auth?.currentUser?.display_name || '';
  if (response.status === 200) {
    yield put({
      type: SET_REPORT_DUPLICATE_PROFILE_BUTTON_STATUS,
      payload: { displayName: userDisplayName },
    });
  }

  const summary = encodeURIComponent('Duplicate Patient Profiles');
  const supportTicketUrl = DEFAULT_SUPPORT_TICKET_URL;
  let description = `Duplicate Patient Details:\n${patient.first_name} ${
    patient.last_name
  } - MRN: ${patient.source_patient_id}, id: ${
    patient.id
  }\n${patient.possibleDuplicateProfiles.duplicates
    .map(dup => `${dup.first_name} ${dup.last_name} - MRN:${dup.source_patient_id}, id:${dup.id}\n`)
    .join('')}`;
  description = encodeURIComponent(description);
  const url = encodeURIComponent(window.location);
  window.open(
    `${supportTicketUrl}?summary=${summary}&description=${description}&customfield_10734=${url}`,
  );
}

function* workerRefreshPattientPhones({ payload }) {
  const { patientId } = payload;
  const request = HTTP.get(`/patients/${patientId}/phones`);

  const response = yield call(() => request);

  if (response.status === 200) {
    yield put({
      type: REFRESH_PATIENT_PHONES_SUCCESS,
      payload: { phones: response?.data?.phones || [] },
    });
  }
}

export function* workerFetchHiddenQuestion() {
  const state = yield select();
  const { selectedPatientId } = state;

  const request = HTTP.get(`/patients/${selectedPatientId}/questions`, {});
  const response = yield call(() => request);

  if (response.status === 200) {
    yield put({
      type: REFRESH_PATIENT_QUESTIONS_SUCCESS,
      payload: { hidden_questions: response?.data || [] },
    });
  }
}

function* workerEditContactAndUpdatePatient({ payload }) {
  const { updatedContact } = payload;
  const postURL = `/patients/${updatedContact.patient_id}/contacts/${updatedContact.id}`;
  const request = HTTP.patch(postURL, updatedContact, {});
  const response = yield call(() => request);
  if (response.status === HttpStatusCodes.OK) {
    yield put({
      type: EDIT_CONTACT,
      payload: response,
    });
    if (response.data.updated_patient) {
      yield put({
        type: EDIT_PATIENT,
        payload: response,
      });
    }

    yield put({ type: NOTIFY_SUCCESS, payload: 'Contact Updated Successfully' });
  } else {
    yield put({ type: NOTIFY_ERROR, payload: 'Contact Update Failed' });
  }
}

export function* watcherPatientsSaga() {
  yield takeLatest(ADD_MEDICATION, workerAddMedicationSaga);
  yield takeEvery([EDIT_CONTACT_AND_UPDATE_PATIENT], workerEditContactAndUpdatePatient);
  yield takeLatest(UPDATE_MEDICATION, workerUpdateMedicationSaga);
  yield takeLatest(
    [
      SELECT_PATIENT_TASK,
      SELECT_PATIENT_TASK_FORCED,
      NEED_PATIENT_REFRESH,
      MARK_PATIENT_DECEASED,
      SELECTED_PATIENT_ID,
    ],
    workerSelectPatientSaga,
  );
  yield takeLatest(REFRESH_PATIENT_PHONES, workerRefreshPattientPhones);
  yield takeLatest(ADD_THERAPY_LINK_DRUG, workerAddTherapyLinkSaga);
  yield takeLatest(PATIENT_CHECKLIST_FETCH, workerLoadPatientChecklistDataSaga);
  yield takeEvery(DISCHARGE_PATIENT, workerDischargePatientSaga);
  yield takeEvery(UPDATE_PATIENT_PREFERENCE_REQUEST, workerUpdatePatientPreferenceSaga);
  yield takeEvery(FETCH_PATIENT_PROBLEMS, workerPatientProblems);
  yield takeEvery(SELECTED_PATIENT_ID, workerFetchChecklistData);
  yield takeEvery([SELECT_PATIENT_SUCCESS, SELECTED_PATIENT_ID], workerFetchPatientMetrics);
  yield takeEvery(FETCH_HIDDEN_QUESTIONS, workerFetchHiddenQuestion);
  yield takeLatest(
    [
      SELECTED_PATIENT_ID,
      FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS,
      TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION_PATIENT,
      BULK_TASKS_UPDATED,
    ],
    workerFetchAdherenceComplianceStatus,
  );
  yield takeLatest(
    [
      FETCH_PATIENT_ADHERENCE_DATA,
      TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION_PATIENT,
      BULK_TASKS_UPDATED,
    ],
    workerFetchPatientAdherenceData,
  );
  yield takeLatest(FETCH_PATIENT_ADHERENCE_HISTORY, workerFetchPatientAdherenceHistory);
  yield takeEvery(REPORT_DUPLICATE_PATIENT_PROFILES, workerReportDuplicateProfiles);
}
export function* watcherPatientChangeSaga() {
  const actions = [SELECT_PATIENT, SELECT_PATIENT_TASK_FORCED, SELECT_PATIENT_TASK];
  yield takeEvery(actions, workerDetectPatientChange);
}

export function* watcherTaskAddSaga() {
  const addActions = Object.values(TASK_LIFECYCLE_ACTIONS.ADD);
  yield takeEvery([...addActions], workerAddTasks);
  yield takeEvery([EDIT_THERAPY], workerAddTasksFromTherapyWorkflow);
  yield takeEvery([FETCH_RESOURCE_LINK], workerAddTasksFromTaskWorkflow);
  yield takeEvery([ADD_THERAPY, ADD_THERAPY_AR], workerAddTasksFromTherapy);
}
