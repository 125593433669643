import debounce from 'debounce-promise';
import { DEBOUNCE_SEARCH_IN_MS } from 'constants/config';
import { takeLatest, call, put, select, take } from 'redux-saga/effects';
import { formValueSelector } from 'redux-form';
import { push } from 'connected-react-router';
import {
  addUserCustomList,
  addUserCustomListItem,
  updateAddedUserCustomListItem,
} from 'actions/action-user-custom-lists';

import {
  ADD_TASK,
  ADD_TASK_FILTER_SERVICE_GROUP,
  ADD_THERAPY,
  ADD_THERAPY_AR,
  CLEAR_TASK_FILTERS_BY_KEYS,
  DELETE_TASK_FILTER_SERVICE_GROUP,
  DISCHARGE_PATIENT,
  DUR_CREATE_NEW_SUCCESS,
  EDIT_THERAPY,
  FETCH_USER_PREFERENCES,
  FETCH_WORK_LIST_FAILURE,
  FETCH_WORK_LIST_REQUEST,
  FETCH_WORK_LIST_SEARCH_FAILURE,
  FETCH_WORK_LIST_SEARCH_SUCCESS,
  FETCH_WORK_LIST_SUCCESS,
  MARK_PATIENT_DECEASED,
  RECENT_PATIENTS_REQUEST,
  RECENT_PATIENTS_REQUEST_FAILURE,
  RECENT_PATIENTS_REQUEST_SUCCESS,
  REFRESH_THERAPIES_AND_TASKS,
  REFRESH_TASKS,
  RESOLVE_AUDIT,
  SEARCH_WORK_LIST,
  SELECT_FIRST_TASK,
  SIDEBAR_FILTERS_CLEAR,
  SIDEBAR_FILTERS_CLEAR_IDS,
  SIDEBAR_FILTERS_SET,
  SIDEBAR_TASK_TYPE,
  UPDATE_TASK_CLINICS,
  UPDATE_TASK_FILTERS,
  UPDATE_TASK_FILTER_COMPLETED,
  UPDATE_TASK_FILTER_FROM_DATE,
  UPDATE_TASK_FILTER_TO_DATE,
  UPDATE_TASK_SERVICE_GROUP,
  WORK_LIST_CHANGED,
  REFRESH_QUEUE_WORKLIST,
  REFRESH_SMS_MESSAGES,
  STAR,
  SMS,
  LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST,
  LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT,
  LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST_SUCCESS,
  LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT_SUCCESS,
  LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_FAILURE,
  ADD_USER_CUSTOM_LIST_WORKFLOW,
  ADD_USER_CUSTOM_LIST_WORKFLOW_FAILURE,
  UPDATE_ADDED_USER_CUSTOM_LIST_ITEM_FAILURE,
  ADD_USER_CUSTOM_LIST_WORKFLOW_SUCCESS,
  ADD_USER_CUSTOM_LIST_ITEM,
  ADD_USER_CUSTOM_LIST,
  THERAPIES,
  PATIENTS,
  SEARCH_FORM,
  FA_GRANT_NOTIFICATION,
} from '../constants';
import { TaskTypesEnum } from '../constants/enums';

import HTTP from '../services/http';
import { getTaskUrl } from '../helpers/router';
import { getQueryFilters } from '../services/utils/filters-service';

const fetchWorkList = (taskType, sidebarFilters) => {
  const queryParameterString = getQueryFilters(sidebarFilters, taskType, { addStatusIds: true });
  const requestConfig = {};
  return HTTP.get(`/patients?${queryParameterString}`, requestConfig).then(payload => ({
    ...payload,
    data: payload.data.patients.map(task => ({
      ...task,
      taskType: task.taskType || taskType,
    })),
    unique_patient_count: payload.data.unique_patient_count,
  }));
};

const searchWorkList = debounce(
  criteria =>
    HTTP.post('/patients/_search', criteria, {}).then(payload => ({
      ...payload,
      data: payload.data.search_patients.map(patient => patient),
    })),
  DEBOUNCE_SEARCH_IN_MS,
);

function getRecentPatients(patientIds) {
  const body = {
    patient_ids: patientIds,
  };
  return HTTP.post('/patients/byIds/simple', body, {}).then(response => {
    const result = response;
    return result;
  });
}

const getPatientsCustomListData = () => {
  return HTTP.post('custom-lists-patients').then(payload => ({
    ...payload,
    data: payload.data,
  }));
};

function* workerUpdateSMSMessages() {
  const state = yield select();
  const {
    sidebarFilters,
    patientList,
    view: { initialUrl },
  } = state;
  const { firstTime, loadedTask } = patientList;
  const selector = formValueSelector(SEARCH_FORM);
  const searchStr = selector(state, 'search')?.trim();
  const searchIsActive = searchStr?.split(',').join('').length > 1;
  if (!state.userPreferences.loaded) return;
  try {
    const sidebarFilterData =
      sidebarFilters && sidebarFilters.data ? sidebarFilters.data.task : null;

    if (searchIsActive) {
      const response = yield call(searchWorkList, { searchStr });
      const { data } = response;
      yield put({ type: FETCH_WORK_LIST_SEARCH_SUCCESS, data, task: 'SEARCH' });
    } else {
      const response = yield call(fetchWorkList, SMS, sidebarFilterData);
      const { data, unique_patient_count: uniquePatientCount } = response;
      yield put({
        type: FETCH_WORK_LIST_SUCCESS,
        data: { patients: data, uniquePatientCount },
        task: SMS,
      });

      const taskTypeChanged = loadedTask && loadedTask !== SMS;
      const firstTimeLoaded = firstTime && initialUrl.endsWith('/patients');
      if (firstTimeLoaded || taskTypeChanged) {
        yield put({
          type: SELECT_FIRST_TASK,
          data,
          task: SMS,
        });
      }

      const patientIdMap = [
        ...data
          .reduce((acc, curr) => {
            acc.set(curr.id, curr);
            return acc;
          }, new Map())
          .values(),
      ].map(p => p.id);

      yield put({
        type: LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST,
        patientIdMap,
      });
    }
  } catch (error) {
    yield put({ type: FETCH_WORK_LIST_FAILURE });
  }
}

/* eslint-disable no-unused-vars */
function* workerWorkListSaga(context, action) {
  const state = yield select();
  const {
    sidebarType,
    sidebarTaskType,
    sidebarFilters,
    patientList,
    view: { initialUrl },
    userCustomLists,
  } = state;
  const { firstTime, loadedTask } = patientList;
  if (!sidebarTaskType || !state.userPreferences.loaded) return;
  try {
    yield put({
      type: FETCH_WORK_LIST_REQUEST,
    });
    const sidebarFilterData =
      sidebarFilters && sidebarFilters.data ? sidebarFilters.data.task : null;

    let response;

    const taskTypesRegex = new RegExp(
      `[^(${[...Object.values(TaskTypesEnum), STAR, THERAPIES, PATIENTS].join('|')})]`,
      'g',
    );
    // We remove all characters which are not part of a legal task type name
    const fixedTaskType = sidebarTaskType.replace(taskTypesRegex, '');

    if (fixedTaskType === STAR) {
      const responseDataCustomLists = [];
      userCustomLists.lists.forEach(l =>
        l.list.forEach(patient => responseDataCustomLists.push(patient)),
      );
      response = { data: responseDataCustomLists };
    } else {
      response = yield call(fetchWorkList, fixedTaskType, sidebarFilterData);
    }
    const { data, unique_patient_count: uniquePatientCount } = response;
    if (sidebarType !== 'tasks') {
      return;
    }

    yield put({
      type: FETCH_WORK_LIST_SUCCESS,
      data: { patients: data, uniquePatientCount },
      task: fixedTaskType,
    });

    const taskTypeChanged = loadedTask && loadedTask !== fixedTaskType;
    const firstTimeLoaded = firstTime && initialUrl.endsWith('/patients');
    if (firstTimeLoaded || taskTypeChanged) {
      yield put({
        type: SELECT_FIRST_TASK,
        data,
        task: fixedTaskType,
      });
    }

    const patientIdMap = [
      ...data
        .reduce((acc, curr) => {
          acc.set(curr.id, curr);
          return acc;
        }, new Map())
        .values(),
    ].map(p => p.id);

    yield put({
      type: LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST,
      patientIdMap,
    });
  } catch (error) {
    yield put({ type: FETCH_WORK_LIST_FAILURE });
  }
}

function* workerRecentPatients() {
  try {
    const state = yield select();
    const { userPreferences } = state;
    // eslint-disable-next-line camelcase
    const { recent_items } = userPreferences;
    // eslint-disable-next-line camelcase
    const { patients: patientIds } = recent_items;
    const response = yield call(getRecentPatients, patientIds);
    const { data } = response;
    yield put({ type: RECENT_PATIENTS_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: RECENT_PATIENTS_REQUEST_FAILURE });
  }
}

function* workerWorkListSearchSaga(action) {
  try {
    const response = yield call(searchWorkList, action.payload);
    const { data } = response;
    yield put({ type: FETCH_WORK_LIST_SEARCH_SUCCESS, data, task: 'SEARCH' });
  } catch (error) {
    yield put({ type: FETCH_WORK_LIST_SEARCH_FAILURE });
  }
}

function* selectFirstTask(_context, action) {
  const { data } = action;

  const { sidebarType } = yield select();
  if (sidebarType === 'tasks' && data && data.length > 0) {
    const patientWithTask = data[0];
    const url = getTaskUrl(
      patientWithTask.id,
      undefined,
      patientWithTask.taskType,
      patientWithTask.task_id,
    );
    yield put(push(url));
  }
}

function* workerUpdateWorklistWithCustomListDataSaga(action) {
  try {
    let response;
    if (action && action.patientIdMap && action.patientIdMap.length > 0) {
      response = yield call(getPatientsCustomListData);
    }
    const { data } = response || [];
    if (action.type === LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST) {
      yield put({ type: LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST_SUCCESS, data });
    }
    if (action.type === LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT) {
      yield put({ type: LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT_SUCCESS, payload: { data } });
    }
  } catch {
    yield put({ type: LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_FAILURE });
  }
}

function* workerAddUserCustomListSaga(action) {
  try {
    const { payload } = action;
    let { listId } = payload;
    const { patient } = payload;
    const state = yield select();
    const userCustomListsForUser = state.userCustomLists.users
      ? state.userCustomLists.users.find(user => user.user_id === window.USER.id)
      : null;
    if (
      state &&
      state.userCustomLists &&
      state.userCustomLists.loaded &&
      (!userCustomListsForUser ||
        !userCustomListsForUser.lists ||
        userCustomListsForUser.lists.length === 0 ||
        !userCustomListsForUser.lists.some(list => list.user_id === window.USER.id))
    ) {
      yield put(addUserCustomList());
      yield take(ADD_USER_CUSTOM_LIST);
    }

    if (!listId) {
      const stateUpdated = yield select();
      const userCustomListsForUserNew = stateUpdated.userCustomLists.users
        ? stateUpdated.userCustomLists.users.find(user => user.user_id === window.USER.id)
        : null;
      listId = userCustomListsForUserNew && userCustomListsForUserNew.lists[0].id;
    }
    if (listId) {
      yield put(addUserCustomListItem(listId, patient));
      yield put({ type: ADD_USER_CUSTOM_LIST_WORKFLOW_SUCCESS, payload });
    }
  } catch {
    yield put({ type: ADD_USER_CUSTOM_LIST_WORKFLOW_FAILURE });
  }
}
function* workerUpdatedAddedUserCustomListSaga(action) {
  try {
    const { payload } = action;
    const { listId, patient } = payload;
    yield take(ADD_USER_CUSTOM_LIST_ITEM);

    yield put(updateAddedUserCustomListItem(listId, patient));
  } catch {
    yield put({ type: UPDATE_ADDED_USER_CUSTOM_LIST_ITEM_FAILURE });
  }
}

export function* watcherWorkListSaga(context) {
  const actions = [
    ADD_TASK_FILTER_SERVICE_GROUP,
    ADD_THERAPY,
    ADD_THERAPY_AR,
    CLEAR_TASK_FILTERS_BY_KEYS,
    DELETE_TASK_FILTER_SERVICE_GROUP,
    EDIT_THERAPY,
    FETCH_USER_PREFERENCES,
    REFRESH_THERAPIES_AND_TASKS,
    REFRESH_TASKS,
    RESOLVE_AUDIT,
    SIDEBAR_FILTERS_CLEAR,
    SIDEBAR_FILTERS_CLEAR_IDS,
    SIDEBAR_FILTERS_SET,
    SIDEBAR_TASK_TYPE,
    UPDATE_TASK_CLINICS,
    UPDATE_TASK_FILTERS,
    UPDATE_TASK_FILTER_COMPLETED,
    UPDATE_TASK_FILTER_FROM_DATE,
    UPDATE_TASK_FILTER_TO_DATE,
    UPDATE_TASK_SERVICE_GROUP,
    WORK_LIST_CHANGED,
  ];
  yield takeLatest(
    [
      ...actions,
      MARK_PATIENT_DECEASED,
      DISCHARGE_PATIENT,
      ADD_TASK,
      DUR_CREATE_NEW_SUCCESS,
      REFRESH_QUEUE_WORKLIST,
      FA_GRANT_NOTIFICATION,
    ],
    workerWorkListSaga,
    context,
  );
  yield takeLatest(REFRESH_SMS_MESSAGES, workerUpdateSMSMessages);
  yield takeLatest(RECENT_PATIENTS_REQUEST, workerRecentPatients);
  yield takeLatest(SEARCH_WORK_LIST, workerWorkListSearchSaga);
  yield takeLatest([SELECT_FIRST_TASK], selectFirstTask, context);
  yield takeLatest(
    [LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST, LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT],
    workerUpdateWorklistWithCustomListDataSaga,
  );
  yield takeLatest([ADD_USER_CUSTOM_LIST_WORKFLOW], workerAddUserCustomListSaga);
  yield takeLatest([ADD_USER_CUSTOM_LIST_WORKFLOW_SUCCESS], workerUpdatedAddedUserCustomListSaga);
}
