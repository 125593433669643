/* eslint-disable max-len */
import uuid from 'uuid/v4';
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,
  Button,
  Typography,
  Box,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import compose from 'recompose/compose';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { SmallSearch, EditAdd, EditDelete } from 'components/icons/icons';
import { Info as InfoIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { addNote } from 'actions/action-notes';
import {
  renderDropdown,
  renderCheckbox,
  renderNumberField,
  renderCheckboxHorizontal,
  renderReactRadioGroup,
  renderTextField,
} from 'components/form/field/redux-field';
import { parseNoteToDb } from 'services/utils/note-service';
import FieldLabel from 'components/form/field/field-label';
import FormHeader from 'components/form/header/header';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import {
  ReactSelectForReduxSingle,
  CustomReactSelectForMiniGrid,
} from 'components/form/field/react-select';
import Divider from 'components/divider';
import OverrideFieldSection from 'components/form/field/override-field-section';
import {
  ADD_PATIENT_THERAPY_FORM,
  THERAPY_ADMIN_STATUS_PRE_THERAPY,
  THERAPY_ENROLLMENT_STATUS_REFERRAL_ID,
  PRESCRIBING_CLINIC_METHOD,
  DISPENSING_STATUSES,
  THERAPY_ENROLLMENT_OPTOUT,
  DOSIS_REGIMEN_CHARACTER_LIMIT,
  MED_SYNC_STATUS,
  CDM,
  RS,
} from 'constants/index';
import { TherapyWorkflowLabel, PatientStatusEnum } from 'constants/enums';
import { emrStatusDropdown, noteTagTypes } from 'constants/lists';
import { addTherapy, addTherapyLinkDrug } from 'actions/action-therapies';
import { fetchTaskCounts } from 'actions/action-tasks';
import {
  validateDate,
  required,
  therapyNBDWithinDaysSupply,
} from 'components/form/validation/validation';
import { getTodayDateTime } from 'services/utils/date-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { parseFullName } from 'parse-full-name';
import {
  fetchDiagnosisCodes,
  fetchPhysicians,
  fetchGpis,
  fetchGpisNoDebounce,
  fetchClinics,
  fetchPharmacies,
  getTherapyStatusId,
  handlePrescribingClinic,
  getTherapyStatuses,
  statusArrayForEdit,
  specialtyTypeFormatter,
} from 'services/utils/therapy-service';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { getInitialServiceGroupForReactSelect } from 'services/utils/task-service';
import { addMedication, updateMedication } from 'actions/action-medications';
import {
  updateLocalUserPreferencesRecentClinics,
  updateLocalUserPreferencesRecentPrescribers,
  updateLocalUserPreferencesRecentPharmacies,
} from 'actions/action-user';
import boolean from 'boolean';
import EligibilityCheck from 'containers/patient/financials/eligibility/eligibility-check';
import { setMedicationExcludedValue } from 'services/utils/reconciliation-service';
import RenderTherapyLeadSource from 'components/radio-button-group/render-therapy-lead-source';
import RenderTherapyRxType from 'components/radio-button-group/render-therapy-rx-type';
import RenderTherapyPrescribingClinic from 'components/radio-button-group/render-therapy-prescribing-clinic';
import { getChecklistData } from 'actions/action-patient';

import { buildQaId } from 'utils/build-qa-id';
import renderDaysSelector from 'components/dynamic-form/renders/render-days-selector';
import { MedSynchronized } from 'containers/patient/med-sync/components/medSyncIcon';
import { windowFeatureIsEnabled } from 'config/window-features';
import lodash from 'lodash';
import { styles } from './add-patient-therapy-styles';
import NpiLookup from '../npi-lookup';
import EditTherapyEnrollment from '../edit-therapy-enrollment-simple';
import { isDosingRegimenRequired } from '../util';
import { PatientTherapiesClient } from '../../../../clients/patient-therapies';

const qaIdBuilder = buildQaId('dosing-regimen', '.');
const INPUT_DEBOUNCE_MS = 800;

class AddPatientTherapy extends Component {
  constructor(props) {
    super(props);
    const { selectedDrug } = props;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDosisRegimenChange = this.handleDosisRegimenChange.bind(this);
    this.debouncedOnChange = this.debouncedOnChange.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveColumnClinic = this.handleSaveColumnClinic.bind(this);
    this.handleSaveColumnPhysician = this.handleSaveColumnPhysician.bind(this);
    this.handleSaveColumnPharmacy = this.handleSaveColumnPharmacy.bind(this);
    this.handleSaveColumnTransferPharmacy = this.handleSaveColumnTransferPharmacy.bind(this);
    this.renderDosisRegimenTextField = this.renderDosisRegimenTextField.bind(this);
    this.handlePharmacyNpiChange = this.handlePharmacyNpiChange.bind(this);
    this.handleTransferPharmacyNpiChange = this.handleTransferPharmacyNpiChange.bind(this);
    this.handleFetchGpisOptions = this.handleFetchGpisOptions.bind(this);
    this.icdVerification = this.icdVerification.bind(this);
    this.icdVerificationDialogBox = this.icdVerificationDialogBox.bind(this);

    this.state = {
      isSpecialty: selectedDrug ? boolean(selectedDrug.is_specialty_drug) : true,
      requireDosingRegimen: selectedDrug
        ? isDosingRegimenRequired(selectedDrug.specialty_type)
        : true,
      npiLookupClinic: false,
      npiLookup: false,
      transferNpiLookup: false,
      serviceGroupDropdown: -1,
      managingClinicDropdown: -1,
      tertiaryDiagnosisAdded: false,
      dosisRegimenValue: '',
      dynamicDosisRegimenValues: [],
      showIcdIndicator: false,
      submittingForm: false,
      dialogOpen: false,
      stateSubmitHandler: null,
      event: null,
      docOnlyTherapySearch: null,
      docOnlySelectedTherapies: null,
      therapyEdit: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { selectedDrug, initialTherapyValues, change } = this.props;
    if (selectedDrug?.ndc) {
      const initialTherapy = fetchGpisNoDebounce(selectedDrug.ndc, true, true);
      initialTherapy.then(result => {
        if (result && result.data && result.data.drugs && result.data.drugs.length) {
          const therapy = result.data.drugs[0];
          change('therapy', {
            ...therapy,
            id: therapy.ndc,
            label: therapy.drug_info,
          });
        }
        this.setState({
          requireDosingRegimen: isDosingRegimenRequired(selectedDrug.specialty_type),
          isSpecialty: boolean(selectedDrug.is_specialty_drug),
          dosisRegimenValue: selectedDrug.dose_quantity ?? '',
        });
      });
    } else {
      this.setState({
        isSpecialty: true,
        requireDosingRegimen: true,
      });
    }
    if (initialTherapyValues) {
      this.handlePhysicianMatch(initialTherapyValues.clinicianName);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedDiagnosis: prevSelectedDiagnosis, selectedTherapy: prevSelectedTherapy } =
      prevProps;
    const { selectedDiagnosis, selectedTherapy } = this.props;
    this.icdVerification(
      selectedDiagnosis,
      selectedTherapy,
      prevSelectedDiagnosis,
      prevSelectedTherapy,
    );
  }

  icdVerification(selectedDiagnosis, selectedTherapy, prevSelectedDiagnosis, prevSelectedTherapy) {
    if (
      Object.keys(selectedDiagnosis).length &&
      Object.keys(selectedTherapy).length &&
      selectedTherapy.specialty_type.includes(CDM)
    ) {
      if (
        selectedDiagnosis.code &&
        selectedTherapy.specialty_type &&
        (Object.keys(prevSelectedDiagnosis).length || Object.keys(prevSelectedTherapy).length)
      ) {
        PatientTherapiesClient.getTherapyWorkflow(
          selectedDiagnosis.code,
          selectedTherapy.specialty_type,
        ).then(res => {
          const workflowLabel = TherapyWorkflowLabel[res.data?.therapyWorkflow] ?? '';
          if (workflowLabel !== RS) {
            if (!this.state.submittingForm) this.resetIcdIndicator(true);
          } else {
            this.resetIcdIndicator(false);
          }
        });
      }
    } else if (this.state.showIcdIndicator) {
      this.resetIcdIndicator(false);
    }
  }

  resetIcdIndicator(flag) {
    if (this.state.showIcdIndicator !== flag) {
      this.setState({ showIcdIndicator: flag });
    }
  }

  handleCancelClick() {
    const { reset, handleCloseAddForm, selectedDrug } = this.props;
    handleCloseAddForm();
    if (!selectedDrug) {
      reset();
    }
  }

  handleDosisRegimenChange(event) {
    this.setState({ dosisRegimenValue: event.target.value });
  }

  debouncedOnChange(e, key) {
    lodash.debounce((e, key) => {
      if (e && key && e.target) {
        const currentDynamicDosisregimenValues = this.state.dynamicDosisRegimenValues;
        if (currentDynamicDosisregimenValues && currentDynamicDosisregimenValues.length === 0) {
          this.setState({ dynamicDosisRegimenValues: [{ key: key, value: e.target.value }] });
        } else {
          const existingItem = currentDynamicDosisregimenValues.find(t => t.key == key);
          if (existingItem && Object.keys(existingItem).length) {
            this.setState({
              dynamicDosisRegimenValues: currentDynamicDosisregimenValues.map(it => {
                if (it.key == key) {
                  it.value = e.target.value;
                  return it;
                }
                return it;
              }),
            });
          } else {
            this.setState({
              dynamicDosisRegimenValues: currentDynamicDosisregimenValues.concat([
                { key: key, value: e.target.value },
              ]),
            });
          }
        }
      }
    }, INPUT_DEBOUNCE_MS)(e, key);
  }

  handleSubmit(values) {
    const {
      addTherapy, // eslint-disable-line
      addTherapyLinkDrug, // eslint-disable-line
      addMedication, // eslint-disable-line
      fetchTaskCounts, // eslint-disable-line
      getChecklistData, // eslint-disable-line
      updateMedication, // eslint-disable-line
      failedTherapyForm, // When this prop exist
      addNote, // eslint-disable-line
      reset,
      patientId,
      handleCloseAddForm,
      therapyStatuses,
      selectedTherapy,
      selectedDiagnosis,
      selectedSecondaryDiagnosis,
      selectedTertiaryDiagnosis,
      selectedDrug,
      stagingMedication,
      excludedValues,
      arDrug,
      enrollmentStatuses,
      submitCallback,
    } = this.props;
    let { isSpecialty } = this.state;
    const { dosisRegimenValue } = this.state;
    const { therapyAdministrationStatuses } = therapyStatuses;
    const dispensingStatusId = getTherapyStatusId(enrollmentStatuses, values.dispensing_status);

    if (arDrug) {
      isSpecialty = true;
    }

    if (stagingMedication) {
      stagingMedication.start_dt = convertToArborDate(stagingMedication.start_dt).getUtcDate();
      stagingMedication.end_dt = convertToArborDate(stagingMedication.end_dt).getUtcDate();
    }

    let medicationPayload = {};
    if (
      values.is_documenting_only &&
      this.state.docOnlySelectedTherapies &&
      this.state.docOnlySelectedTherapies.length
    ) {
      medicationPayload = this.state.docOnlySelectedTherapies.map(it => {
        return {
          patient_id: patientId,
          ndc: it.ndc,
          is_documenting_only: values.is_documenting_only,
          start_dt: convertToArborDate(values[`start_date_${it.id}`]).getUtcDate(),
          end_dt: convertToArborDate(values[`end_date_${it.id}`]).getUtcDate(),
          source_medication_id:
            selectedDrug && selectedDrug.source_medication_id
              ? selectedDrug.source_medication_id
              : uuid(),
          category_code: values[`source_${it.id}`],
          active: 1,
          source_id: selectedDrug && selectedDrug.source_id ? selectedDrug.source_id : 1,
          status_code: values[`emr_status_${it.id}`],
          dose_quantity: values[`dosis_regimen_${it.id}`],
        };
      });
    } else {
      medicationPayload = {
        patient_id: patientId,
        ndc: selectedTherapy.ndc,
        is_documenting_only: values.is_documenting_only,
        start_dt: convertToArborDate(values.start_date).getUtcDate(),
        end_dt: convertToArborDate(values.end_date).getUtcDate(),
        source_medication_id:
          selectedDrug && selectedDrug.source_medication_id
            ? selectedDrug.source_medication_id
            : uuid(),
        category_code: values.source,
        active: 1,
        source_id: selectedDrug && selectedDrug.source_id ? selectedDrug.source_id : 1,
        status_code: values.emr_status,
      };
    }
    // return;
    const adminStatusId = getTherapyStatusId(
      therapyAdministrationStatuses,
      isSpecialty ? THERAPY_ADMIN_STATUS_PRE_THERAPY : values.administration_status,
    );

    if (values.is_documenting_only) {
      // upsert medication if therapy is documenting only or during reconciliation
      if (selectedDrug && selectedDrug.id) {
        for (const medicationPayloadItem of medicationPayload) {
          updateMedication({
            id: selectedDrug.id,
            ...medicationPayloadItem,
          });
        }
      } else {
        const addMedicationList = [];
        const comparedMedicationList = [];
        for (const medicationPayloadItem of medicationPayload) {
          addMedicationList.push({
            ...stagingMedication,
            ...medicationPayloadItem,
          });
          comparedMedicationList.push({
            ...stagingMedication,
            ...medicationPayloadItem,
          });
        }
        if (
          addMedicationList &&
          comparedMedicationList &&
          addMedicationList.length &&
          comparedMedicationList.length
        ) {
          addMedication(addMedicationList);
          for (const comparedMedication of comparedMedicationList) {
            if (excludedValues) {
              setMedicationExcludedValue(comparedMedication, stagingMedication, excludedValues);
            }
          }
        }
      }
    } else {
      const prescribingClinic = handlePrescribingClinic(
        values.prescribing_method_id,
        values.managing_clinic_name_and_id,
        values.referring_clinic,
        isSpecialty,
      );

      const managingClinicIdValue = values.managing_clinic_name_and_id
        ? values.managing_clinic_name_and_id.value
        : null;

      const managingClinicNameValue = values.managing_clinic_name_and_id
        ? values.managing_clinic_name_and_id.label
        : null;

      const payload = {
        patient_id: patientId,
        ndc: selectedTherapy.ndc,
        lead_source_id: values.lead_source_id,
        rx_type_id: values.rx_type_id,
        referring_clinic_npi: prescribingClinic ? prescribingClinic.npi : null,
        referring_clinic_name: prescribingClinic ? prescribingClinic.name : null,
        referring_clinic_internal_id: prescribingClinic ? prescribingClinic.id : null,
        managing_clinic_id: managingClinicIdValue,
        managing_clinic_name: managingClinicNameValue,
        dispensing_status_id: dispensingStatusId,
        enrollment_status_id: THERAPY_ENROLLMENT_STATUS_REFERRAL_ID,
        // eslint-disable-next-line
        administration_status_id: adminStatusId,
        referral_dt: isSpecialty ? convertToArborDate(values.referral_dt).getUtcDatetime() : null,
        needsby_date: convertToArborDate(values.needsby_date).getUtcDate(),
        days_supply: values.days_supply,
        is_needsby_per_protocol: values.is_needsby_per_protocol,
        service_group_id: values.service_group_name_and_id.value,
        diagnosis_code: selectedDiagnosis.code,
        secondary_diagnosis_code: selectedSecondaryDiagnosis.code,
        tertiary_diagnosis_code: selectedTertiaryDiagnosis.code,
        one_time_fill: Boolean(values.one_time_fill ?? selectedTherapy.one_time_fill),
        prescribing_physician_npi: values.physician ? values.physician.npi : null,
        prescribing_physician_name: values.physician ? values.physician.name : null,
        prescribing_physician_credential: values.physician ? values.physician.credential : null,
        is_specialty: Boolean(isSpecialty),
        dispensing_pharmacy_npi: values.dispensing_pharmacy ? values.dispensing_pharmacy.npi : null,
        dispensing_pharmacy_name: values.dispensing_pharmacy
          ? values.dispensing_pharmacy.name
          : null,
        transfer_pharmacy_npi: values.transfer_pharmacy ? values.transfer_pharmacy.npi : null,
        transfer_pharmacy_name: values.transfer_pharmacy ? values.transfer_pharmacy.name : null,
        therapy_duration: values.therapy_duration,
        active: 1,
        source_id: 1,
        category_code: isSpecialty ? 'outpatient' : values.source,
        external_dispensing_additional_reason: values.external_dispensing_additional_reason,
        dosis_regimen: dosisRegimenValue || null,
        as_needed: values.as_needed ?? false,
        clinic_administered: values.clinic_administered ?? false,
      };
      if (arDrug) {
        // If therapy is added from AR, we need to insert the medication_id
        if (selectedDrug.staging_medication_id) {
          payload.staging_medication = selectedDrug;
        } else {
          payload.medication_id = selectedDrug.medication_id;
        }
        payload.external_dispensing_reason = values.external_dispensing_reason;
        payload.dispensing_pharmacy_doing_business_as = values.dispensing_pharmacy
          ? values.dispensing_pharmacy.doing_business_as
          : null;
        payload.transfer_pharmacy_doing_business_as = values.transfer_pharmacy
          ? values.transfer_pharmacy.doing_business_as
          : null;
        const clinicalSupportStatusId = getTherapyStatusId(
          enrollmentStatuses,
          values.clinical_support_status,
          values.clinical_support_undecided_reason,
        );
        payload.clinical_support_status_id = clinicalSupportStatusId;
        payload.clinical_support_status_reason =
          clinicalSupportStatusId === THERAPY_ENROLLMENT_OPTOUT
            ? (Array.isArray(values.clinical_support_status_reason) &&
                values.clinical_support_status_reason.join(',')) ||
              values.clinical_support_status_reason
            : null;
        payload.clinical_support_additional_reason = values.clinical_support_additional_reason;
        addTherapyLinkDrug({
          newTherapy: payload,
          drug: { ...selectedDrug, ndc: selectedDrug.ndc || selectedTherapy.ndc },
        });
      } else {
        payload.medication_id = selectedDrug ? selectedDrug.id : null;
        // If we add a therapy upon staging medication, we need to use it as a template
        payload.staging_medication = stagingMedication;
        addTherapy(payload).then(addedTherapy => {
          const newTherapy =
            addedTherapy &&
            addedTherapy.payload &&
            addedTherapy.payload.data &&
            addedTherapy.payload.data.therapy
              ? addedTherapy.payload.data.therapy
              : null;
          fetchTaskCounts();
          getChecklistData(patientId);
          if (values.needsby_dt_override_reason) {
            const { value: tagTypeId } = noteTagTypes.find(tag => tag.label === 'Therapy');
            const newValues = parseNoteToDb({ note_text: values.needsby_dt_override_reason });
            const tags = [{ tag_type_id: tagTypeId, resource_id: newTherapy.id }];

            const payloadNotes = {
              patient_id: patientId,
              note_type_id: 1,
              note_text: newValues.note_text,
              is_pinned: 0,
              mentions: newValues.mentions,
              tags,
            };
            addNote(payloadNotes);
          }
        });
      }
    }

    if (failedTherapyForm) {
      handleCloseAddForm(selectedTherapy.ndc);
    } else {
      handleCloseAddForm();
    }

    if (!selectedDrug) {
      reset();
    }
    if (submitCallback) {
      submitCallback(excludedValues);
    }
    this.setState({
      showIcdIndicator: false,
      submittingForm: false,
      dialogOpen: false,
      stateSubmitHandler: null,
      event: null,
    });
  }

  handleSaveColumnClinic(clinic) {
    const { updateLocalUserPreferencesRecentClinics, onValueChange, selectedDrug } = this.props; // eslint-disable-line no-shadow
    if (onValueChange) {
      onValueChange(selectedDrug, 'referring_clinic', clinic);
    }
    updateLocalUserPreferencesRecentClinics(clinic);
  }

  handleSaveColumnPhysician(physician) {
    const { updateLocalUserPreferencesRecentPrescribers, onValueChange, selectedDrug } = this.props; // eslint-disable-line no-shadow
    if (onValueChange) {
      onValueChange(selectedDrug, 'physician', physician);
    }
    updateLocalUserPreferencesRecentPrescribers(physician);
  }

  handleSaveColumnPharmacy(pharmacy) {
    const { updateLocalUserPreferencesRecentPharmacies, onValueChange, selectedDrug } = this.props; // eslint-disable-line no-shadow
    if (onValueChange) {
      onValueChange(selectedDrug, 'dispensing_pharmacy', pharmacy);
    }
    updateLocalUserPreferencesRecentPharmacies(pharmacy);
  }

  handleSaveColumnTransferPharmacy(pharmacy) {
    const { updateLocalUserPreferencesRecentPharmacies, onValueChange, selectedDrug } = this.props; // eslint-disable-line no-shadow
    if (onValueChange) {
      onValueChange(selectedDrug, 'transfer_pharmacy', pharmacy);
    }
    updateLocalUserPreferencesRecentPharmacies(pharmacy);
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchPhysiciansOptions(searchText) {
    // eslint-disable-next-line
    return fetchPhysicians(searchText).then(result =>
      result.data
        ? result.data.physicians.map(icd10 => ({
            ...icd10,
            id: icd10.npi,
            label: icd10.name,
            description: icd10.address,
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchDiagnosisOptions(searchText) {
    // eslint-disable-next-line
    return fetchDiagnosisCodes(searchText).then(result =>
      result.data
        ? result.data.icd10s.map(icd10 => ({
            ...icd10,
            id: icd10.code,
            label: icd10.description,
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchGpisOptions(searchText) {
    const { arDrug, ancillaryDrug } = this.props;

    // eslint-disable-next-line no-confusing-arrow
    return fetchGpis(searchText).then(result =>
      result.data
        ? result.data.drugs.map(therapy => ({
            ...therapy,
            id: therapy.ndc,
            label: therapy.drug_info,
            ...(arDrug ? { disabled: !therapy.specialty_type } : {}),
            ...(ancillaryDrug ? { disabled: therapy.specialty_type } : {}),
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchClinicOptions(searchText) {
    // eslint-disable-next-line no-confusing-arrow
    return fetchClinics(searchText, false, null).then(result =>
      result.data
        ? result.data.clinics.map(clinic => ({
            ...clinic,
            id: clinic.npi,
            label: clinic.name,
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchPharmacyOptions(searchText) {
    // eslint-disable-next-line no-confusing-arrow
    return fetchPharmacies(searchText).then(result =>
      result.data
        ? result.data.pharmacies.map(pharmacy => ({
            ...pharmacy,
            label: pharmacy.name,
            description: pharmacy.address,
          }))
        : [],
    );
  }

  handleClinicNpiChange(clinic) {
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    change('referring_clinic', {
      ...clinic,
      id: clinic.npi,
      label: clinic.name,
    });
    this.handleSaveColumnClinic(clinic);
  }

  handlePharmacyNpiChange(pharmacy) {
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    change('dispensing_pharmacy', {
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    });
    this.handleSaveColumnPharmacy(pharmacy);
  }

  handleTransferPharmacyNpiChange(pharmacy) {
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    change('transfer_pharmacy', {
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    });
    this.handleSaveColumnTransferPharmacy(pharmacy);
  }

  handlePhysicianMatch(clinician) {
    if (!clinician) {
      return;
    }
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    const { first, last } = parseFullName(clinician.trim());
    if (first && last) {
      fetchPhysicians(`${first} ${last}`, true).then(result => {
        if (result.data.physicians && result.data.physicians.length === 1) {
          const matchedPhysician = result.data.physicians[0];
          change('physician', {
            ...matchedPhysician,
            id: matchedPhysician.npi,
            label: matchedPhysician.name,
            description: matchedPhysician.address,
          });
        }
      });
    }
  }

  handleManagingClinicChange = data => {
    const { change, onValueChange, selectedDrug, managingClinics, serviceGroups } = this.props;
    if (onValueChange) onValueChange(selectedDrug, 'managing_clinic_name_and_id', data);

    const clinic = managingClinics?.find(item => item.id === data.value);
    if (!clinic) return;

    const group = serviceGroups?.find(item => item.id === clinic.service_group_id);
    if (!group) return;

    const selectedGroup = {
      ...group,
      value: group.id,
      label: group.display_name,
    };

    if (onValueChange) onValueChange(selectedDrug, 'service_group_name_and_id', selectedGroup);
    change('service_group_name_and_id', selectedGroup);
  };

  renderDosisRegimenTextField() {
    const { classes } = this.props;
    const { dosisRegimenValue, requireDosingRegimen } = this.state;
    return (
      <>
        <Typography className={classes.subtitle}>
          Dosing Regimen {requireDosingRegimen ? '*' : ''}
        </Typography>
        <Field
          component={renderTextField}
          validate={requireDosingRegimen ? [required] : []}
          variant="standard"
          name="dosis_regimen"
          fullWidth
          placeholder="Text about the dosing regimen for this therapy..."
          inputProps={{
            maxLength: DOSIS_REGIMEN_CHARACTER_LIMIT,
            value: dosisRegimenValue,
          }}
          value={dosisRegimenValue}
          onChange={this.handleDosisRegimenChange}
          helperText={`${dosisRegimenValue.length}/${DOSIS_REGIMEN_CHARACTER_LIMIT}`}
          margin="normal"
          data-qa-id={qaIdBuilder('text-field')}
        />
      </>
    );
  }

  renderDosisRegimenTextFieldDocOnly(key) {
    const { classes } = this.props;
    const { dynamicDosisRegimenValues, requireDosingRegimen } = this.state;
    const dosisRegimenValue = dynamicDosisRegimenValues.find(t => t.key == key)?.value ?? '';
    return (
      <>
        <Typography className={classes.subtitle}>
          Dosing Regimen {requireDosingRegimen ? '*' : ''}
        </Typography>
        <Field
          component={renderTextField}
          validate={requireDosingRegimen ? [required] : []}
          variant="standard"
          name={`dosis_regimen_${key}`}
          fullWidth
          placeholder="Text about the dosing regimen for this therapy..."
          inputProps={{
            maxLength: DOSIS_REGIMEN_CHARACTER_LIMIT,
          }}
          value={dosisRegimenValue}
          onChange={e => {
            this.debouncedOnChange(e, key);
          }}
          helperText={`${dosisRegimenValue.length}/${DOSIS_REGIMEN_CHARACTER_LIMIT}`}
          margin="normal"
          data-qa-id={qaIdBuilder('text-field')}
        />
      </>
    );
  }

  icdVerificationDialogBox(diagnosisCode, diagnosisDescription) {
    const { classes } = this.props;
    const message = `You are pairing this Therapy with a NON RS ICD Code (${diagnosisCode} ${diagnosisDescription})`;
    const question = 'Do you want to continue?';
    return (
      <Dialog open={this.state.dialogOpen} maxWidth="md" data-qa-id="icd_verification_dialogbox">
        <DialogContent>
          <DialogContentText>
            <Typography className={classes.icdValidatorText}>{message}</Typography>
            <Typography className={classes.icdValidatorText}>{question}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => {
              this.setState({
                dialogOpen: false,
                submittingForm: false,
              });
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({
                showIcdIndicator: false,
                submittingForm: false,
              });
              this.state.stateSubmitHandler(this.state.event);
            }}
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  promptUserToAddtherapyFromAr() {
    const { classes } = this.props;
    const message = 'Please add this therapy via an AR task.';
    return (
      <Dialog open maxWidth="md">
        <DialogContent>
          <DialogContentText>
            <Typography className={classes.icdValidatorText}>{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.handleCancelClick();
            }}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      serviceGroups,
      managingClinics,
      managingClinicNameAndId,
      selectedReferringClinic,
      prescribingMethodId,
      enrollmentStatuses,
      selectedTherapy,
      selectedDiagnosis,
      selectedSecondaryDiagnosis,
      selectedTertiaryDiagnosis,
      selectedPhysician,
      selectedDispensingPharmacy,
      selectedTransferPharmacy,
      selectedPharmacyName,
      selectedIsDocumentingOnly,
      selectedDispensingStatus,
      selectedClinicalStatus,
      selectedDispensingReason,
      selectedClinicalReason,
      recentPharmacies,
      therapyStatuses,
      displayDocumentOnlyOption,
      forceDocumentOnlyOption,
      arDrug,
      medsEnrollment,
      failedTherapyForm,
      formTitle = null,
      bordered = true,
      showEligibilityCheck = true,
      selectedNeedsByDate,
      selectedDaysSupply,
      medicationCategoryCodes,
      onValueChange,
      selectedDrug,
      recentProviders,
      disableTherapyInput,
      medSync,
      patient,
      fromMedicationList,
    } = this.props;

    if (
      selectedIsDocumentingOnly &&
      this.state.docOnlySelectedTherapies === null &&
      selectedTherapy &&
      Object.keys(selectedTherapy).length
    ) {
      this.setState({
        docOnlySelectedTherapies: [
          {
            ...selectedTherapy,
            value: selectedTherapy.ndc,
          },
        ],
        therapyEdit: true,
      });
    }

    let { isSpecialty } = this.state;
    const { tertiaryDiagnosisAdded } = this.state;

    const {
      serviceGroupDropdown,
      managingClinicDropdown,
      npiLookupClinic,
      transferNpiLookup,
      npiLookup,
      clinicalSupportStatusId,
    } = this.state;
    const { gpiLookupConfig } = this.props;

    if (arDrug) {
      isSpecialty = true;
    }

    const therapyClinicalDispensingStatuses = statusArrayForEdit(
      getTherapyStatuses(enrollmentStatuses),
    );

    const renderFormHeader = () => {
      if (failedTherapyForm) {
        return <FormHeader header="Add failed Therapy" />;
      }
      if (selectedTherapy.ndc) {
        return (
          <FormHeader
            header={isSpecialty ? 'New Therapy - Specialty' : 'New Therapy - Non Specialty'}
          />
        );
      }
      if (formTitle) {
        return <FormHeader header={formTitle} />;
      }
      return <FormHeader header="New Therapy" />;
    };

    const showDialogBox = () => {
      return this.icdVerificationDialogBox(selectedDiagnosis.code, selectedDiagnosis.description);
    };

    const optIn = selectedDispensingStatus === DISPENSING_STATUSES.OPT_IN;
    const optOut = selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT;
    const { therapyAdministrationStatuses } = therapyStatuses;
    const statusArray = getTherapyStatuses(therapyAdministrationStatuses);
    const statusDropdown = statusArrayForEdit(statusArray);
    const { patientId, back } = this.props;
    const showManagingClinicField = (isSpecialty || medsEnrollment) && !selectedIsDocumentingOnly;

    const handleNbdInDaysSupply = () =>
      therapyNBDWithinDaysSupply(selectedNeedsByDate, selectedDaysSupply) === true;

    const managingClinicField = (
      <Grid container rowSpacing={0} columnSpacing={3} alignItems="flex-start">
        <Grid item xs={3}>
          <Field
            name="managing_clinic_name_and_id"
            label="Managing Clinic *"
            validate={[required]}
            component={ReactSelectForReduxSingle}
            onChange={this.handleManagingClinicChange}
            fields={managingClinics.map(int => ({
              label: int.name,
              value: int.id,
              npi: int.npi,
            }))}
            state={managingClinicDropdown}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteLabel
            idValue={managingClinicNameAndId ? managingClinicNameAndId.npi : null}
            defaultIdValue=""
            label="Managing Clinic NPI"
            useLargeLabel
          />
        </Grid>
      </Grid>
    );

    const resetSelectedTherapies = e => {
      if (e.target.checked === false) {
        this.setState({
          docOnlySelectedTherapies: null,
        });
      }
    };

    return (
      <>
        {(patient.status_id === PatientStatusEnum.OnRecord && fromMedicationList === undefined) ||
        patient.status_id === PatientStatusEnum.Inactive ? (
          this.promptUserToAddtherapyFromAr()
        ) : (
          <Grid
            container
            justifyContent="center"
            spacing={1}
            className={(classes.formRowContainer, classes.formContainer)}
          >
            <Grid item xs={12}>
              {!!npiLookupClinic && (
                <NpiLookup
                  open
                  title="Prescribing Clinic"
                  values={{
                    taxonomy: '',
                    organization: selectedReferringClinic ? selectedReferringClinic.name : '',
                  }}
                  handleChange={item => {
                    this.handleClinicNpiChange(item);
                  }}
                  handleClose={() => {
                    this.setState({ npiLookupClinic: false });
                  }}
                />
              )}
              {!!npiLookup && (
                <NpiLookup
                  open
                  title="Pharmacy"
                  values={{
                    taxonomy: 'pharmacy',
                    organization: selectedPharmacyName || '',
                  }}
                  handleChange={item => {
                    this.handlePharmacyNpiChange(item);
                  }}
                  handleClose={() => {
                    this.setState({ npiLookup: false });
                  }}
                />
              )}
              {!!transferNpiLookup && (
                <NpiLookup
                  open
                  title="Transfer From Pharmacy"
                  values={{
                    taxonomy: 'pharmacy',
                    organization: selectedPharmacyName || '',
                  }}
                  handleChange={item => {
                    this.handleTransferPharmacyNpiChange(item);
                  }}
                  handleClose={() => {
                    this.setState({ transferNpiLookup: false });
                  }}
                />
              )}
              {this.state.showIcdIndicator && this.state.submittingForm ? showDialogBox() : null}
              <form
                onSubmit={e => {
                  const submitHandler = handleSubmit(this.handleSubmit);
                  if (!this.state.showIcdIndicator) {
                    submitHandler(e);
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                  }
                  this.setState({
                    submittingForm: true,
                    dialogOpen: true,
                    stateSubmitHandler: submitHandler,
                    event: e,
                  });
                  e.preventDefault();
                }}
                className={bordered ? classes.addFormContainer : classes.noBorderFormContainer}
                autoComplete="off"
              >
                {/* First Row */}
                <Grid container>
                  <Grid item xs={9}>
                    {renderFormHeader()}
                  </Grid>
                  {(displayDocumentOnlyOption || forceDocumentOnlyOption) && (
                    <Grid item xs={3}>
                      <Field
                        name="is_documenting_only"
                        label="Documenting only"
                        disabled={
                          patient.status_id === PatientStatusEnum.OnRecord ||
                          forceDocumentOnlyOption ||
                          !!medsEnrollment
                        }
                        component={renderCheckboxHorizontal}
                        onChange={e => resetSelectedTherapies(e)}
                        styleOverride={{
                          justifyContent: 'flex-end',
                        }}
                        style={{
                          padding: '7.5px',
                          transform: 'translateY(-5px)',
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                {selectedIsDocumentingOnly && (
                  <Grid container>
                    <Grid container spacing={3} alignItems="center">
                      {!failedTherapyForm && (
                        <Grid item xs={12}>
                          {showEligibilityCheck ? <EligibilityCheck /> : <Divider />}
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="therapy"
                        disabled={disableTherapyInput}
                        label="Therapy *"
                        component={CustomReactSelectForMiniGrid}
                        validate={
                          this.state.docOnlySelectedTherapies &&
                          this.state.docOnlySelectedTherapies.length
                            ? []
                            : [required]
                        }
                        fetchOptions={this.handleFetchGpisOptions}
                        hint="Search by Therapy or GPI or NDC"
                        columnsToShow={{
                          gpi: 'GPI',
                          drug_name: 'Drug Name',
                          dosage_form_description: 'Form',
                          dose: 'Dose',
                          ldd: 'LDD',
                          specialty_type: 'Specialty Type',
                          ndc: 'NDC',
                        }}
                        minSearchLength={2}
                        therapyToSearch={this.state.docOnlyTherapySearch ?? null}
                        qaId="therapy_name"
                        valueFormatter={specialtyTypeFormatter}
                        gpiLookupConfig={gpiLookupConfig}
                        handleOnChange={drug => {
                          this.setState({ docOnlySelectedTherapies: drug, therapyEdit: false });
                          if (drug) {
                            this.setState({
                              isSpecialty: boolean(drug.is_specialty_drug),
                              requireDosingRegimen: isDosingRegimenRequired(drug.specialty_type),
                            });
                          }
                        }}
                        isMulti
                        handleOnInputChange={value => {
                          if (value) {
                            this.setState({ docOnlyTherapySearch: value });
                          }
                        }}
                        value={this.state.docOnlySelectedTherapies}
                        defaultOptions={
                          this.state.therapyEdit ? this.state.docOnlySelectedTherapies : null
                        }
                        placeholder="Search by Therapy or GPI or NDC"
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.dynamicContainer}>
                      {this.state.docOnlySelectedTherapies &&
                        this.state.docOnlySelectedTherapies.length > 0 && (
                          <Grid item xs={12}>
                            {this.state.docOnlySelectedTherapies.map(x => {
                              return (
                                <Grid container item xs={12} rowSpacing={3}>
                                  <Grid item xs={12}>
                                    <Typography className={classes.title}>
                                      {`${x.drug_name} ${x.dosage_form} ${x.dose} ${
                                        x.dosage_form_description
                                          ? ` | ${x.dosage_form_description}`
                                          : ''
                                      }`}
                                    </Typography>
                                  </Grid>
                                  <Grid container item xs={12}>
                                    <Grid item xs={3}>
                                      <AutocompleteLabel
                                        idValue={x.ndc}
                                        defaultIdValue=""
                                        label="NDC"
                                      />
                                    </Grid>
                                    <Grid item xs={3} className={classes.emrStatus}>
                                      <Field
                                        name={`emr_status_${x.id}`}
                                        label="Administration *"
                                        component={renderDropdown}
                                        className={classes.input}
                                        validate={[required]}
                                        fields={emrStatusDropdown}
                                        defaultValues={emrStatusDropdown[0]}
                                        id={`add_therapy_emr_status_${x.id}`}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Accordion className={classes.accordionStyle}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel1-content_${x.id}`}
                                        id={`panel1-header_${x.id}`}
                                      >
                                        Additional Therapy Information
                                      </AccordionSummary>
                                      <AccordionDetails className={classes.accordionDetailStyle}>
                                        <Grid container item xs={12} alignItems="flex-start">
                                          <Grid item xs={12} onBlur={this.handleTherapyBlur}>
                                            {this.renderDosisRegimenTextFieldDocOnly(x.id)}
                                          </Grid>
                                          <Grid container spacing={3} alignItems="flex-start">
                                            <Grid item xs={3}>
                                              <Field
                                                name={`start_date_${x.id}`}
                                                label="Start Date"
                                                component={renderDatePicker}
                                                validate={[validateDate]}
                                                id={`add_therapy_start_date_${x.id}`}
                                              />
                                            </Grid>
                                            <Grid item xs={3}>
                                              <Field
                                                name={`end_date_${x.id}`}
                                                label="End Date"
                                                component={renderDatePicker}
                                                validate={[validateDate]}
                                                id={`add_therapy_end_date_${x.id}`}
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            spacing={3}
                                            xs={12}
                                            style={{ marginTop: '20px' }}
                                          >
                                            <Field
                                              name={`source_${x.id}`}
                                              id={`source_${x.id}`}
                                              label="Source *"
                                              className={classes.radioGroup}
                                              radioMap={medicationCategoryCodes.map(category => ({
                                                label: category.category,
                                                value: category.code,
                                              }))}
                                              component={renderReactRadioGroup}
                                              validate={[required]}
                                              width="100%"
                                              qaId={`add_therapy_source_${x.id}`}
                                              initialValueName={
                                                medicationCategoryCodes.find(
                                                  opt => opt.code === 'patientspecified',
                                                )?.category
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={2} style={{ marginTop: '20px' }}>
                                            <Field
                                              name={`therapy_duration_${x.id}`}
                                              label="Therapy Duration"
                                              component={renderDaysSelector}
                                            />
                                          </Grid>
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                )}
                {/* Second Row */}
                {!selectedIsDocumentingOnly && (
                  <Grid container spacing={3} alignItems="center">
                    {!failedTherapyForm && (
                      <Grid item xs={12}>
                        {showEligibilityCheck ? <EligibilityCheck /> : <Divider />}
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <Field
                        name="therapy"
                        disabled={disableTherapyInput}
                        label="Therapy *"
                        component={AutocompleteMinigrid}
                        validate={[required]}
                        fetchOptions={this.handleFetchGpisOptions}
                        hint="Search by Therapy or GPI or NDC"
                        columnsToShow={{
                          gpi: 'GPI',
                          drug_name: 'Drug Name',
                          dosage_form_description: 'Form',
                          dose: 'Dose',
                          ldd: 'LDD',
                          specialty_type: 'Specialty Type',
                          ndc: 'NDC',
                        }}
                        minSearchLength={2}
                        qaId="therapy_name"
                        valueFormatter={specialtyTypeFormatter}
                        gpiLookupConfig={gpiLookupConfig}
                        onChange={drug => {
                          if (drug) {
                            this.setState({
                              isSpecialty: boolean(drug.is_specialty_drug),
                              requireDosingRegimen: isDosingRegimenRequired(drug.specialty_type),
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <AutocompleteLabel
                        idValue={selectedTherapy.ndc}
                        defaultIdValue=""
                        label="NDC"
                      />
                    </Grid>
                    {selectedIsDocumentingOnly && (
                      <Grid item xs={3}>
                        <Field
                          name="emr_status"
                          label="Administration *"
                          component={renderDropdown}
                          className={classes.input}
                          validate={[required]}
                          fields={emrStatusDropdown}
                          id="add_therapy_emr_status"
                        />
                      </Grid>
                    )}
                    {(isSpecialty || medsEnrollment) && !selectedIsDocumentingOnly && (
                      <Grid item xs={3}>
                        <Field
                          name="referral_dt"
                          label="Referral Date *"
                          component={renderDatePicker}
                          onChange={value =>
                            onValueChange && onValueChange(selectedDrug, 'referral_dt', value)
                          }
                          validate={[required, validateDate]}
                        />
                      </Grid>
                    )}
                    <Grid item xs="12">
                      <Divider />
                    </Grid>

                    {(arDrug || medsEnrollment) && (
                      <Grid item xs="12">
                        <EditTherapyEnrollment
                          enrollmentStatuses={enrollmentStatuses}
                          isSpecialty={isSpecialty}
                          diagnosisCode={selectedDiagnosis.code}
                          clinicalSupportStatusId={clinicalSupportStatusId}
                          pharmacyValues={selectedDispensingPharmacy}
                          transferPharmacyValues={selectedTransferPharmacy}
                          handlePharmacyNpiChange={this.handlePharmacyNpiChange}
                          handleTransferPharmacyNpiChange={this.handleTransferPharmacyNpiChange}
                          handleSaveColumnPharmacy={this.handleSaveColumnPharmacy}
                          patientId={patientId}
                          ndc={selectedDrug.ndc}
                          disabled={medsEnrollment}
                          selectedDispensingStatus={selectedDispensingStatus}
                          selectedClinicalStatus={selectedClinicalStatus}
                          selectedDispensingReason={selectedDispensingReason}
                          selectedClinicalReason={selectedClinicalReason}
                          onValueChange={(name, value) => {
                            if (onValueChange) {
                              onValueChange(selectedDrug, name, value);
                            }
                          }}
                        />
                      </Grid>
                    )}
                    <Grid container item alignItems="flex-start">
                      <Grid item xs={12} onBlur={this.handleTherapyBlur}>
                        {this.renderDosisRegimenTextField()}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {/* Third Row */}
                {!selectedIsDocumentingOnly && (
                  <Grid direction="column" container spacing={2} alignItems="flex-start">
                    <Grid container item xs={10} spacing={3}>
                      <Grid
                        item
                        xs={6}
                        className={this.state.showIcdIndicator ? classes.icdValidator : ''}
                      >
                        <Field
                          name="diagnosis"
                          label={`Diagnosis ${isSpecialty ? '*' : ''}`}
                          component={AutocompleteMinigrid}
                          validate={isSpecialty ? [required] : []}
                          fetchOptions={this.handleFetchDiagnosisOptions}
                          hint="Search by Diagnosis Name or Code"
                          columnsToShow={{
                            code: 'Code',
                            description: 'Description',
                          }}
                          clearable
                        />
                        {this.state.showIcdIndicator && (
                          <Tooltip
                            title={
                              <>
                                <Typography>This therapy is paired with a</Typography>
                                <Typography>NON RS ICD code</Typography>
                              </>
                            }
                            placement="right-start"
                          >
                            <InfoIcon className={classes.icdValidatorIcon} />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <AutocompleteLabel
                          idValue={selectedDiagnosis.code}
                          defaultIdValue=""
                          label="Diagnosis Code"
                        />
                      </Grid>
                      <Grid item xs={1} />
                      {(isSpecialty || medsEnrollment) && (
                        <Grid item xs={3}>
                          <FieldLabel fieldName="Medication Status *" />
                          <Field
                            name="rx_type_id"
                            component={RenderTherapyRxType}
                            validate={[required]}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container item xs={10} rowSpacing={0} columnSpacing={3}>
                      <Grid item xs={6}>
                        <Field
                          name="secondary_diagnosis"
                          label="Secondary Diagnosis"
                          component={AutocompleteMinigrid}
                          fetchOptions={this.handleFetchDiagnosisOptions}
                          hint="Search by Diagnosis Name or Code"
                          columnsToShow={{
                            code: 'Code',
                            description: 'Description',
                          }}
                          clearable
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <AutocompleteLabel
                          idValue={selectedSecondaryDiagnosis.code}
                          defaultIdValue=""
                          label="Diagnosis Code"
                        />
                      </Grid>
                    </Grid>
                    {tertiaryDiagnosisAdded && (
                      <Grid container xs={10} spacing={3} item>
                        <Grid item xs={6}>
                          <Field
                            name="tertiary_diagnosis"
                            label="Tertiary Diagnosis"
                            component={AutocompleteMinigrid}
                            fetchOptions={this.handleFetchDiagnosisOptions}
                            hint="Search by Diagnosis Name or Code"
                            columnsToShow={{
                              code: 'Code',
                              description: 'Description',
                            }}
                            clearable
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <AutocompleteLabel
                            idValue={selectedTertiaryDiagnosis.code}
                            defaultIdValue=""
                            label="Diagnosis Code"
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          this.setState({ tertiaryDiagnosisAdded: !tertiaryDiagnosisAdded })
                        }
                      >
                        {tertiaryDiagnosisAdded ? <EditDelete /> : <EditAdd />}
                        <Typography className={classes.addTertiaryDiagnosis}>
                          Tertiary Diagnosis
                        </Typography>
                      </Button>
                    </Grid>
                    <Box pt={2} />
                  </Grid>
                )}
                {(isSpecialty || medsEnrollment) && !selectedIsDocumentingOnly && (
                  <>
                    {/* Fourth Row */}
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FieldLabel fieldName="Referral Source *" />
                        <Field
                          name="lead_source_id"
                          component={RenderTherapyLeadSource}
                          onChange={value =>
                            onValueChange && onValueChange(selectedDrug, 'lead_source_id', value)
                          }
                          validate={[required]}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )}
                {!selectedIsDocumentingOnly && (
                  <Grid item xs={12}>
                    {showManagingClinicField && managingClinicField}
                  </Grid>
                )}
                {!selectedIsDocumentingOnly && (
                  <Grid container spacing={3} alignItems="flex-start">
                    {((!isSpecialty && !medsEnrollment) || selectedIsDocumentingOnly) && (
                      <Grid item xs={12}>
                        <Field
                          name="source"
                          id="source"
                          label="Source *"
                          className={classes.radioGroup}
                          radioMap={medicationCategoryCodes.map(category => ({
                            label: category.category,
                            value: category.code,
                          }))}
                          component={renderReactRadioGroup}
                          validate={[required]}
                          width="100%"
                          qaId="add_therapy_source"
                        />
                      </Grid>
                    )}
                    {(isSpecialty || medsEnrollment) && !selectedIsDocumentingOnly && (
                      <Grid item xs={12}>
                        <FieldLabel fieldName="Prescribing Clinic *" />
                        <Field
                          name="prescribing_method_id"
                          component={RenderTherapyPrescribingClinic}
                          onChange={value =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'prescribing_method_id', value)
                          }
                          validate={[required]}
                        />
                      </Grid>
                    )}
                    {((!isSpecialty && !medsEnrollment) ||
                      prescribingMethodId === PRESCRIBING_CLINIC_METHOD.USE_LOOKUP) &&
                      !selectedIsDocumentingOnly && (
                        <>
                          <Grid item xs={6} className={classes.npiLookupButtonContainer}>
                            <Field
                              name="referring_clinic"
                              label={`Prescribing Clinic${isSpecialty ? ' *' : ''}`}
                              component={AutocompleteMinigrid}
                              validate={isSpecialty ? [required] : null}
                              fetchOptions={this.handleFetchClinicOptions}
                              hint="Search by Clinic Name or NPI"
                              columnsToShow={{
                                name: 'Name',
                                address: 'Address',
                                npi: 'NPI',
                                is_internal: 'Is Internal',
                              }}
                              onChange={this.handleSaveColumnClinic}
                              minSearchLength={2}
                            />
                            <Button
                              className={classes.npiLookupButton}
                              onClick={() => this.setState({ npiLookupClinic: true })}
                            >
                              <SmallSearch />
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <AutocompleteLabel
                              idValue={selectedReferringClinic ? selectedReferringClinic.npi : null}
                              defaultIdValue=""
                              label="Clinic NPI"
                            />
                          </Grid>
                        </>
                      )}
                  </Grid>
                )}
                {!selectedIsDocumentingOnly && (
                  <Grid container rowSpacing={0} columnSpacing={3} alignItems="flex-start">
                    <Grid item xs={6}>
                      <Field
                        name="physician"
                        label={isSpecialty ? 'Prescriber *' : 'Prescriber'}
                        validate={isSpecialty ? [required] : null}
                        component={AutocompleteMinigrid}
                        fetchOptions={this.handleFetchPhysiciansOptions}
                        hint="Search by Physician Last, First Name or NPI"
                        simplistic
                        recentOptions={(recentProviders || []).map(icd10 => ({
                          ...icd10,
                          id: icd10.npi,
                          label: icd10.name,
                          description: icd10.address,
                        }))}
                        onChange={this.handleSaveColumnPhysician}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <AutocompleteLabel
                        idValue={selectedPhysician.npi}
                        defaultIdValue=""
                        label="Physician NPI"
                      />
                    </Grid>
                  </Grid>
                )}
                <Divider />
                {!isSpecialty && !medsEnrollment && !selectedIsDocumentingOnly && (
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={4}>
                      <Field
                        name="administration_status"
                        label="Administration *"
                        component={renderDropdown}
                        className={classes.input}
                        validate={[required]}
                        fields={statusDropdown}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name="dispensing_status"
                        id="dispensing_status"
                        label="Dispensing *"
                        className={classes.radioGroup}
                        radioMap={therapyClinicalDispensingStatuses}
                        component={renderReactRadioGroup}
                        validate={[required]}
                        width="100%"
                        qaId="dispensing_status"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {optIn && !selectedIsDocumentingOnly && managingClinicField}
                    </Grid>
                    {optIn && (
                      <>
                        <Grid item xs={6} className={classes.npiLookupButtonContainer}>
                          <Field
                            name="transfer_pharmacy"
                            label="Transfer From Pharmacy"
                            component={AutocompleteMinigrid}
                            fetchOptions={this.handleFetchPharmacyOptions}
                            hint="Search by Pharmacy Name or NPI"
                            simplistic
                            recentOptions={(recentPharmacies || []).map(icd10 => ({
                              ...icd10,
                              id: icd10.npi,
                              label: icd10.name,
                              description: icd10.address,
                            }))}
                            onChange={this.handleSaveColumnTransferPharmacy}
                          />
                          <Button
                            className={classes.npiLookupButton}
                            onClick={() => this.setState({ transferNpiLookup: true })}
                          >
                            <SmallSearch />
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <AutocompleteLabel
                            idValue={selectedTransferPharmacy.npi}
                            label="Pharmacy NPI"
                          />
                        </Grid>
                      </>
                    )}
                    {(optIn || optOut) && (
                      <>
                        <Grid item xs={6} className={classes.npiLookupButtonContainer}>
                          <Field
                            name="dispensing_pharmacy"
                            label={`Dispensing Pharmacy ${optIn ? ' *' : ''}`}
                            validate={optIn ? [required] : []}
                            component={AutocompleteMinigrid}
                            fetchOptions={this.handleFetchPharmacyOptions}
                            hint="Search by Pharmacy Name or NPI"
                            simplistic
                            recentOptions={(recentPharmacies || []).map(icd10 => ({
                              ...icd10,
                              id: icd10.npi,
                              label: icd10.name,
                              description: icd10.address,
                            }))}
                            onChange={this.handleSaveColumnPharmacy}
                          />
                          <Button
                            className={classes.npiLookupButton}
                            onClick={() => this.setState({ npiLookup: true })}
                          >
                            <SmallSearch />
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <AutocompleteLabel
                            idValue={selectedDispensingPharmacy.npi}
                            defaultIdValue=""
                            label="Pharmacy NPI"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
                {!selectedIsDocumentingOnly && (
                  <>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={2} id="nbd">
                        <Field
                          name="needsby_date"
                          label={isSpecialty || optIn ? 'Needs By Date *' : 'Needs By Date'}
                          component={renderDatePicker}
                          onChange={value =>
                            onValueChange && onValueChange(selectedDrug, 'needsby_date', value)
                          }
                          validate={
                            isSpecialty || optIn ? [required, validateDate] : [validateDate]
                          }
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Field
                          label={isSpecialty || optIn ? 'Days Supply *' : 'Days Supply'}
                          name="days_supply"
                          validate={(isSpecialty || optIn) && [required]}
                          component={renderNumberField}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          name="is_needsby_per_protocol"
                          label="Per Protocol"
                          component={renderCheckbox}
                          onChange={e =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'is_needsby_per_protocol', e.target.checked)
                          }
                        />
                      </Grid>
                      {window.FEATURES.one_time_fill &&
                        selectedTherapy &&
                        selectedTherapy.is_specialty_drug === 'No' &&
                        optIn && (
                          <Grid item xs={2}>
                            <Field
                              name="one_time_fill"
                              label="One-Time Fill"
                              component={renderCheckbox}
                              onChange={e => {
                                const value = e.currentTarget.checked;
                                onValueChange?.(selectedDrug, 'one_time_fill', value);
                              }}
                            />
                          </Grid>
                        )}
                      <Grid item xs={2}>
                        <Field
                          name="as_needed"
                          component={renderCheckbox}
                          label="As Needed"
                          onChange={e =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'as_needed', e.target.checked)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          name="clinic_administered"
                          component={renderCheckbox}
                          label="Clinic Administered"
                          onChange={e =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'clinic_administered', e.target.checked)
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name="service_group_name_and_id"
                          label="Service Group *"
                          validate={[required]}
                          component={ReactSelectForReduxSingle}
                          fields={serviceGroups.map(int => ({
                            label: int.display_name,
                            value: int.id,
                            isDisabled: !int.active,
                          }))}
                          state={serviceGroupDropdown}
                          onChange={value =>
                            onValueChange &&
                            onValueChange(selectedDrug, 'service_group_name_and_id', value)
                          }
                          disabled={showManagingClinicField}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )}
                {!handleNbdInDaysSupply() && (
                  <OverrideFieldSection
                    overrideField="needsby_dt"
                    overrideWarningText={`According to the needs by date you've entered, the patient needs ${selectedDaysSupply} days supply. Is this Needs By date correct?`}
                  />
                )}
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={3} md={2}>
                    <Field
                      name="therapy_duration"
                      label="Therapy Duration"
                      component={renderDaysSelector}
                    />
                  </Grid>
                </Grid>
                <Divider />
                {medSync && (
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={12}>
                      <Typography className={classes.medSyncLabel}>Med Sync</Typography>
                      <MedSynchronized className={classes.medSyncIconStyle} />
                      <Typography className={classes.medSyncInfo}>
                        The patient has a med sync ongoing.
                        <br />
                        This therapy can be synchronized from the patient demographics bar
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {/* Eighth Row */}
                <ConfirmationPanel
                  style={{ marginTop: 50 }}
                  cancelButtonName="add_therapy_cancel_button"
                  submitButtonName="add_therapy_submit_button"
                  handleCancel={this.handleCancelClick}
                  disableSubmit={submitting}
                  {...(back && {
                    leftAlignedButtons: [
                      {
                        button: <Button onClick={back}>Back</Button>,
                      },
                    ],
                  })}
                />
              </form>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { patient, therapyStatuses, medications } = state;
  const { pharmacies: recentPharmacies, providers: recentProviders } =
    state.userPreferences.recent_items;
  const {
    initialTherapyValues,
    selectedDrug,
    id,
    forceDocumentOnlyOption,
    failedTherapyForm,
    initialDocumentingOnly = false,
    fromMedicationList,
  } = props;
  const formId = selectedDrug
    ? `${ADD_PATIENT_THERAPY_FORM}-${selectedDrug.id || `${selectedDrug.gpi}-${patient.id}`}`
    : ADD_PATIENT_THERAPY_FORM;

  const selector = formValueSelector(formId);
  const managingClinicNameAndId = selector(state, 'managing_clinic_name_and_id');
  const prescribingMethodId = selector(state, 'prescribing_method_id');
  const selectedTherapy = selector(state, 'therapy') || {};
  const selectedDiagnosis = selector(state, 'diagnosis') || {};
  const selectedSecondaryDiagnosis = selector(state, 'secondary_diagnosis') || {};
  const selectedTertiaryDiagnosis = selector(state, 'tertiary_diagnosis') || {};
  const selectedPhysician = selector(state, 'physician') || {};
  const selectedDispensingPharmacy = selector(state, 'dispensing_pharmacy') || {};
  const selectedTransferPharmacy = selector(state, 'transfer_pharmacy') || {};
  const selectedReferringClinic = selector(state, 'referring_clinic') || {};
  const selectedDispensingStatus = selector(state, 'dispensing_status');
  const selectedClinicalStatus = selector(state, 'clinical_support_status');
  const selectedDispensingReason = selector(state, 'external_dispensing_reason');
  const selectedClinicalReason = selector(state, 'clinical_support_status_reason');
  const selectedPharmacyName = selector(state, 'dispensing_pharmacy_name');
  const selectedIsDocumentingOnly = selector(state, 'is_documenting_only');
  const selectedNeedsByDate = selector(state, 'needsby_date');
  const selectedDaysSupply = selector(state, 'days_supply');
  const selectedNeedsByDateOverride = selector(state, 'needsby_dt_override');
  const selectedNeedsByDateOverrideReason = selector(state, 'needsby_dt_override_reason');

  let drugSource = '';
  let startDt = null;
  let endDt = null;
  let statusCode = 'active';
  let initialRxTypeId = null;
  let initialLeadSourceId = null;
  let initialManagingClinicName = null;
  let initialReferringClinic = null;
  let initialManagingClinicNameAndId = null;
  let clinicalSupportStatusId = null;

  if (selectedDrug) {
    drugSource = selectedDrug.category_code;
    startDt = selectedDrug.start_dt;
    endDt = selectedDrug.end_dt;
    statusCode = selectedDrug.status_code;
    clinicalSupportStatusId = selectedDrug.clinical_support_status_id;
  }
  if (initialTherapyValues) {
    initialRxTypeId = initialTherapyValues.rxTypeId;
    initialLeadSourceId = initialTherapyValues.leadSourceId;
    initialManagingClinicName = initialTherapyValues.managingClinicName;
    initialReferringClinic = initialTherapyValues.referringClinic;
    initialManagingClinicNameAndId = initialTherapyValues.managingClinicNameAndId;
  }

  const medicationGpis = new Set();

  if (medications.medicationGroups && medications.medicationGroups.length > 0) {
    medications.medicationGroups.forEach(med => medicationGpis.add(med.gpi.substr(0, 10)));
  }

  const returnValues = {
    patient,
    fromMedicationList,
    therapyStatuses,
    selectedReferringClinic,
    prescribingMethodId,
    recentPharmacies,
    medicationList: Array.from(medicationGpis),
    initialValues: {
      referral_dt: convertToArborDate(getTodayDateTime()).getCustomerDate(true),
      is_needsby_per_protocol: null,
      needsby_date: null,
      lead_source_id: initialLeadSourceId,
      rx_type_id: initialRxTypeId,
      drug_name: null,
      referring_clinic: initialReferringClinic,
      managing_clinic_name: initialManagingClinicName,
      days_supply: null,
      service_group_name_and_id:
        initialTherapyValues && initialTherapyValues.serviceGroupId
          ? getInitialServiceGroupForReactSelect(
              initialTherapyValues.serviceGroupId,
              state.lookups.serviceGroups,
            )
          : '',
      managing_clinic_name_and_id: initialManagingClinicNameAndId,
      prescribing_method_id: initialManagingClinicNameAndId,
      diagnosis: null,
      physician: null,
      source: drugSource || 'patientspecified',
      administration_status: 'On Therapy',
      // eslint-disable-next-line camelcase
      dispensing_status: initialTherapyValues?.dispensing_status || DISPENSING_STATUSES.UNDECIDED,
      clinical_support_status: DISPENSING_STATUSES.UNDECIDED,
      is_documenting_only:
        patient.status_id === PatientStatusEnum.OnRecord ||
        !!forceDocumentOnlyOption ||
        initialDocumentingOnly,
      start_date: startDt && convertToArborDate(startDt).getUtcDate(true),
      end_date: endDt && convertToArborDate(endDt).getUtcDate(true),
      emr_status: failedTherapyForm ? 'completed' : statusCode, // warn
      dosis_regimen: null,
      as_needed: null,
      one_time_fill: !!initialTherapyValues?.one_time_fill,
    },
    serviceGroups: state.lookups.serviceGroups,
    managingClinics: state.lookups.customerClinics,
    managingClinicNameAndId:
      initialTherapyValues && initialTherapyValues.managingClinicNameAndId
        ? initialManagingClinicNameAndId
        : managingClinicNameAndId,
    enrollmentStatuses: state.lookups.enrollmentStatuses,
    recentProviders,
    selectedTherapy,
    selectedDiagnosis,
    selectedSecondaryDiagnosis,
    selectedTertiaryDiagnosis,
    selectedPhysician,
    selectedDispensingPharmacy,
    selectedTransferPharmacy,
    selectedDispensingStatus,
    selectedClinicalStatus,
    selectedDispensingReason,
    selectedClinicalReason,
    selectedPharmacyName,
    selectedIsDocumentingOnly,
    selectedNeedsByDate,
    selectedDaysSupply,
    selectedNeedsByDateOverride,
    selectedNeedsByDateOverrideReason,
    medicationCategoryCodes: state.lookups.medicationCategoryCodes,
    form: formId,
    destroyOnUnmount: !selectedDrug,
    clinicalSupportStatusId,
    medSync: windowFeatureIsEnabled('med_sync') && patient.med_sync === MED_SYNC_STATUS.OPT_IN,
  };

  if (selectedDrug && Object.keys(selectedDrug).length) {
    returnValues.initialValues[`start_date_${selectedDrug.ndc}`] = selectedDrug.start_dt;
    returnValues.initialValues[`end_date_${selectedDrug.ndc}`] = selectedDrug.end_dt;
  }

  return returnValues;
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    addTherapy,
    addTherapyLinkDrug,
    addMedication,
    fetchTaskCounts,
    getChecklistData,
    updateMedication,
    updateLocalUserPreferencesRecentClinics,
    updateLocalUserPreferencesRecentPrescribers,
    updateLocalUserPreferencesRecentPharmacies,
    addNote,
    change,
  }),
)(reduxForm()(AddPatientTherapy));
