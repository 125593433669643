import { all } from 'redux-saga/effects';
import {
  watcherPatientsSaga,
  watcherPatientChangeSaga,
  watcherTaskAddSaga,
} from './patients-sagas';

import { watcherWorkListSaga } from './worklist-sagas';
import { watcherOrdersSaga } from './orders-sagas';
import { watcherScheduleSaga } from './schedule-sagas';
import { watcherReportSaga } from './report-sagas';
import { watcherTherapySaga } from './therapy-sagas';
import { watcherTasksSaga } from './tasks-sagas';
import { watcherLinksSaga } from './links-sagas';
import { watcherFinancialSaga } from './financial-sagas';
import { watcherClinicalDataSaga } from './clinical-sagas';
import { watcherUserPreferencesSaga } from './user-preferences-sagas';
import { watcherReconciliationSaga } from './reconciliation-sagas';
import { watcherQuestionnaireSaga } from './ga/questionnaire-sagas';
import { watcherContactsSaga } from './contacts-sagas';
import { watcherDcProtocolsSaga } from './dc-protocols-sagas';
import { watcherDURSaga } from './drug-utilization-review-sagas';
import { watcherAllergiesSaga } from './allergies-saga';
import { watcherAuditSaga } from './audit_saga';
// eslint-disable-next-line max-len
import { watcherTherapyBenefitsInvestigationSaga } from './therapy-benefits-investigation-latest-sagas';
import { watcherCommunicationNotesSideBarSaga } from './communication-notes-sidebar-saga';

export default function* rootSaga(context) {
  yield all([
    watcherUserPreferencesSaga(context),
    watcherPatientsSaga(context),
    watcherWorkListSaga(context),
    watcherOrdersSaga(context),
    watcherScheduleSaga(context),
    watcherReportSaga(context),
    watcherTherapySaga(context),
    watcherPatientChangeSaga(context),
    watcherTaskAddSaga(context),
    watcherTasksSaga(context),
    watcherLinksSaga(context),
    watcherFinancialSaga(context),
    watcherClinicalDataSaga(context),
    watcherReconciliationSaga(context),
    watcherQuestionnaireSaga(context),
    watcherContactsSaga(context),
    watcherDcProtocolsSaga(context),
    watcherDURSaga(context),
    watcherAllergiesSaga(context),
    watcherTherapyBenefitsInvestigationSaga(context),
    watcherAuditSaga(context),
    watcherCommunicationNotesSideBarSaga(context),
  ]);
}
