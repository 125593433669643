import {
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Modal,
  Checkbox,
} from '@mui/material';
import History from 'containers/common/history';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import { accordianStyles } from 'components/accordian/accordian-styles';
import classNames from 'classnames';
import Divider from 'components/divider';
import DetailField from 'components/form/field/field';
import AccordionHeader from 'components/form/header/accordion-header';
import SubHeader from 'components/form/header/subheader';
import { SmallSpacer } from 'components/spacer/spacer';
import React, { useState } from 'react';
import { yesNoToText } from 'services/utils/formatting';
import { getLanguageDisplay } from 'services/utils/demographic-service';
import { AddressRow, EmailRow, PhoneRow } from 'components/form/subform/subform';
import { convertToArborDate } from 'models/time/arbor-date';
import { PhoneUtils } from 'utils/phone-utils';
import {
  CONTACT_FORM,
  CONTACT_RELATIONSHIPS,
  ENSURE_EMERGENCY_CONTACT_ADDRESSED,
} from '../../../constants';
import ContactForm from './contact-form';
import { styles } from './patient-demographics-styles';

export function ContactDetail(props) {
  const {
    classes,
    patient,
    contact,
    contactIndex,
    hideExpand,
    stretchContent,
    numberOfPotentialRxDeliveryAddresses,
  } = props;
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayEditHistory, setDisplayEditHistory] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleEdit = e => {
    if (expanded) {
      e.stopPropagation();
    }
    setDisplayEdit(!displayEdit);
  };

  const handleEditHistory = e => {
    e.stopPropagation();
    setDisplayEditHistory(!displayEditHistory);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleCancel = () => {
    setDisplayEdit(false);
  };

  const contactHeader = `Contact${contact.relationship ? ` (${contact.relationship})` : ''}`;

  const providerAvailableHoursRaw = contact.provider_available_hours
    ? { period: contact.provider_available_hours }
    : null;

  const tags = [];
  if (contact.is_emergency === 1) {
    tags.push(
      <Typography
        component="span"
        key="is_emergency"
        className={classNames(classes.contactTypeContainer, classes.emergency)}
      >
        Emergency Contact
      </Typography>,
    );
  }

  if (contact.is_responsible === 1) {
    tags.push(
      <Typography
        component="span"
        key="is_responsible"
        className={classNames(classes.contactTypeContainer, classes.responsible)}
      >
        Responsible Party
      </Typography>,
    );
  }

  const accordionPanelClass = classNames({
    [classes.accordianPanel]: !stretchContent,
    [classes.stretchedPanel]: stretchContent,
    [classes.hiddenExpandAccordionPanel]: hideExpand,
  });

  return (
    <>
      <SmallSpacer />
      <Accordion expanded={expanded} className={accordionPanelClass}>
        <AccordionSummary
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
          onClick={!hideExpand ? handleExpand : () => {}}
          expandIcon={!hideExpand ? <ExpandMoreIcon /> : <></>}
        >
          {displayEdit ? (
            <AccordionHeader
              name="Edit Contact"
              updatedDt={contact.updated}
              updatedBy={contact.updated_by}
              editHandler={handleEdit}
              id="edit_contact"
              auditRules={[ENSURE_EMERGENCY_CONTACT_ADDRESSED]}
              noLeftMargin={hideExpand}
            />
          ) : (
            <AccordionHeader
              name={contactHeader}
              withHistory
              updatedDt={contact.updated}
              updatedBy={contact.updated_by}
              editHandler={handleEdit}
              historyHandler={handleEditHistory}
              tags={tags}
              id={contactHeader}
              auditRules={[ENSURE_EMERGENCY_CONTACT_ADDRESSED]}
              noLeftMargin={hideExpand}
            />
          )}
          <Modal open={displayEditHistory} onClose={handleEditHistory}>
            <div style={getModalStyle()} className={classes.historyModal}>
              <History url={`/patients/${patient.id}/contacts/${contact.id}`} subType="patient" />
            </div>
          </Modal>
        </AccordionSummary>
        <AccordionDetails>
          {displayEdit ? (
            <ContactForm
              closeHandler={handleCancel}
              contact={contact}
              edit
              phoneRequired
              hasDoesntProvideContactField
              numberOfPotentialRxDeliveryAddresses={numberOfPotentialRxDeliveryAddresses}
            />
          ) : (
            <Grid container alignItems="center">
              {Boolean(
                contact[CONTACT_FORM.FIELD_NAMES.DOESNT_HAVE_EMERGENCY_CONTACT] ||
                  contact[CONTACT_FORM.FIELD_NAMES.DECLINED_TO_PROVIDE_EMERGENCY_CONTACT],
              ) && (
                <Grid item xs={12} className={classes.genericContactInfo}>
                  <DetailField
                    fieldName="Patient doesn't have additional contact / declined to provide additional contact"
                    component={() => (
                      <Checkbox
                        checked={
                          contact[CONTACT_FORM.FIELD_NAMES.DOESNT_HAVE_EMERGENCY_CONTACT] ||
                          contact[CONTACT_FORM.FIELD_NAMES.DECLINED_TO_PROVIDE_EMERGENCY_CONTACT]
                        }
                        disabled
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <SubHeader name="Personal Information" />
                  </Grid>
                </Grid>
                <Grid container spacing={7}>
                  {contact.provider_available_hours && (
                    <>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="Office Name"
                          field={contact.provider_office_name}
                          id={`contact_provider_office_name_${contactIndex}`}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="Office Availability"
                          field={PhoneUtils.formatAvailableTimes(providerAvailableHoursRaw) || '-'}
                          id={`contact_provider_available_hours_${contactIndex}`}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Last Name"
                      field={contact.last_name}
                      id={`contact_last_name_${contactIndex}`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="First Name"
                      field={contact.first_name}
                      id={`contact_first_name_${contactIndex}`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Middle Initial" field={contact.middle_name} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Suffix" field={contact.suffix} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Preferred Name" field={contact.nickname} />
                  </Grid>
                </Grid>
                <Grid container spacing={7}>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Date of Birth"
                      field={
                        contact.dob ? convertToArborDate(contact.dob, true).getUtcDate(true) : null
                      }
                      id={`contact_dob_${contact.contactIndex}`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="relationship" field={contact.relationship} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Languages"
                      field={getLanguageDisplay(contact.languages)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Needs Interpreter"
                      field={yesNoToText(contact.need_interpreter)}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <SubHeader name="Addresses" />
                  </Grid>
                </Grid>
                {contact.is_declined_to_provide_addresses ? (
                  <Grid item>
                    <Typography variant="body2">Declines to provide an address</Typography>
                  </Grid>
                ) : (
                  <>
                    {contact.filteredAddresses &&
                      contact.filteredAddresses
                        .slice(
                          0,
                          contact.relationship !== CONTACT_RELATIONSHIPS.PROVIDER
                            ? contact.relationship?.length
                            : 1,
                        )
                        .map((addr, index) => (
                          <AddressRow
                            key={addr.key}
                            index={index}
                            address={addr}
                            classes={classes}
                          />
                        ))}
                  </>
                )}
                <Divider />
                <Grid container spacing={7}>
                  <Grid item xs={10}>
                    <SubHeader name="Emails" />
                  </Grid>
                </Grid>
                {contact.email_options ? (
                  <Grid item>
                    {contact.email_options === 1 ? (
                      <Typography variant="body2">Declines to provide an email</Typography>
                    ) : (
                      <Typography variant="body2">No email to provide</Typography>
                    )}
                  </Grid>
                ) : (
                  <>
                    {contact.filteredEmails &&
                      contact.filteredEmails.map((email, index) => (
                        <EmailRow key={email.key} index={index} email={email} classes={classes} />
                      ))}
                  </>
                )}
                <Divider />
                <Grid container spacing={7}>
                  <Grid item xs={10}>
                    <SubHeader name="Phones" />
                  </Grid>
                </Grid>
                {contact.is_declined_to_provide_phones ? (
                  <Grid item>
                    <Typography variant="body2">Declines to provide a phone</Typography>
                  </Grid>
                ) : (
                  <>
                    {contact.filteredPhones &&
                      contact.filteredPhones.map((phone, index) => (
                        <PhoneRow
                          key={phone.key}
                          index={index}
                          phone={phone}
                          classes={classes}
                          owner={contact}
                          isTypeContact
                        />
                      ))}
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <SmallSpacer />
    </>
  );
}

const combinedStyles = combineStyles(styles, accordianStyles);

export default withStyles(combinedStyles)(ContactDetail);
