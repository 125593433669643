import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import JumpButton from 'components/anchor/jump-button';
import Anchor from 'components/anchor/anchor';
import { financialsIsActive } from 'services/utils/common-service';
import { fetchPbmInsurances } from 'actions/action-financials';
import { SmallSpacer } from 'components/spacer/spacer';
import { withRouter } from 'react-router';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { convertMapToList } from 'reducers/helper-reducer';
import { findAuditByCategoryRuleAndStatus } from 'services/utils/audit-service';
import { auditCategories, auditContext } from 'constants/lists';
import {
  ENSURE_PBM_INSURANCE_ADDRESSED,
  AUDIT_NOT_RESOLVED,
  ENSURE_MEDICAL_INSURANCE_ADDRESSED,
} from 'constants/index';
import MedicalDetail from './medical-insurances/medical-detail';
import PbmDetail from './pbm-insurances/pbm-detail';
import FinancialsGrouping from './financials-grouping';
import IncomeDetail from './incomes/income-detail';
import FinancialAssistanceDetail from './financial-assistances/financial-assistances-detail';
import AddIncome from './incomes/add-income';
import AddMedicalInsurance from './medical-insurances/add-medical-insurance';
import AddPbmInsurance from './pbm-insurances/add-pbm-insurance';
import EligibilityCheck from './eligibility/eligibility-check';
import { styles } from './financials-styles';
import AddFAData from '../../tasks/fa/add-fa-data';

import {
  ToggleDisplayAddIncome,
  ToggleDisplayAddMedicalInsurance,
  ToggleDisplayAddPBMInsurance,
  ToggleDisplayAddFinancialAssistance,
} from '../../../actions/action-form-displays';

class FinancialsList extends Component {
  constructor(props) {
    super(props);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  componentWillUnmount() {
    const { onCancelForms } = this.props;
    onCancelForms();
  }

  handleCancelClick() {
    const { onCancelForms } = this.props;
    onCancelForms();
  }

  render() {
    const {
      classes,
      patientId,
      fetchMedicalInsurances, // eslint-disable-line
      displayAddIncome,
      displayAddMedical,
      displayAddPbm,
      displayAddFinancialAssistance,
      loading,
      financials,
      patient,
      audit,
    } = this.props;

    const pbmAuditToResolve = findAuditByCategoryRuleAndStatus(
      audit,
      patient,
      auditCategories.Financials,
      auditContext.Warning,
      ENSURE_PBM_INSURANCE_ADDRESSED,
      AUDIT_NOT_RESOLVED,
    );

    const medAuditToResolve = findAuditByCategoryRuleAndStatus(
      audit,
      patient,
      auditCategories.Financials,
      auditContext.Warning,
      ENSURE_MEDICAL_INSURANCE_ADDRESSED,
      AUDIT_NOT_RESOLVED,
    );

    const medicalInsurances = convertMapToList(financials.medical_insurances.data);
    const pbmInsurances = convertMapToList(financials.pbm_insurances.data).sort(
      (a, b) => a.insurance_type_id - b.insurance_type_id,
    );

    const incomes = convertMapToList(financials.incomes.data).sort(
      (a, b) => a.insurance_type_id - b.insurance_type_id,
    );

    const financialAssistancesLength = convertMapToList(
      financials.financial_assistances.data,
    ).length;

    const renderIncome = yearlyIncomes => <IncomeDetail incomes={yearlyIncomes} />;

    const renderMedicalInsurance = (insurances, insuranceType) => (
      <MedicalDetail
        key={insuranceType}
        insuranceType={insuranceType}
        medicalInsurances={insurances}
        fetchMedicalInsurances={fetchMedicalInsurances}
        patientId={patientId}
      />
    );

    const renderPbmInsurance = (insurances, insuranceType) => (
      <PbmDetail
        key={insuranceType}
        insuranceType={insuranceType}
        pbmInsurances={insurances}
        fetchPbmInsurances={fetchPbmInsurances}
      />
    );

    return (
      <div className={classes.loadContainer}>
        <Loader loaded={!loading}>
          {incomes && incomes.length > 0 && <JumpButton name="Income" sessionId="#income" />}
          <Grid item className={classes.eligibilityContainer} xs={12}>
            <EligibilityCheck />
          </Grid>
          <Grid container alignItems="center">
            {displayAddIncome && (
              <AddIncome patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddMedical && (
              <AddMedicalInsurance patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddPbm && (
              <AddPbmInsurance patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddFinancialAssistance && (
              <AddFAData
                handleCancel={this.handleCancelClick}
                patientId={patientId}
                addingFromFinancials
              />
            )}
          </Grid>
          <SmallSpacer />
          {financialAssistancesLength > 0 && <FinancialAssistanceDetail />}
          <div>
            {pbmInsurances && (pbmInsurances.length > 0 || pbmAuditToResolve) && (
              <FinancialsGrouping
                type={`Pharmacy Benefit (${pbmInsurances.filter(financialsIsActive).length})`}
                renderMethod={renderPbmInsurance}
                financialItems={pbmInsurances}
                groupByKey="insurance_type"
                auditRules={[ENSURE_PBM_INSURANCE_ADDRESSED]}
              />
            )}
          </div>
          <div>
            {medicalInsurances && (medicalInsurances.length > 0 || medAuditToResolve) && (
              <FinancialsGrouping
                type={`Medical Benefit (${medicalInsurances.filter(financialsIsActive).length})`}
                renderMethod={renderMedicalInsurance}
                financialItems={medicalInsurances}
                groupByKey="insurance_type"
                auditRules={[ENSURE_MEDICAL_INSURANCE_ADDRESSED]}
              />
            )}
          </div>
          <Anchor id="income">
            <div>
              {incomes && incomes.length > 0 && (
                <FinancialsGrouping
                  type={`Income (${incomes.length})`}
                  renderMethod={renderIncome}
                  financialItems={incomes}
                  groupByKey="income_year"
                  sortFn={(a, b) => b - a}
                />
              )}
            </div>
          </Anchor>
        </Loader>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onToggleIncomeFormDisplay: bool => {
      dispatch(new ToggleDisplayAddIncome(bool));
    },
    onToggleMedicalInsuranceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddMedicalInsurance(bool));
    },
    onTogglePBMInsuranceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddPBMInsurance(bool));
    },
    onToggleFinancialAssistanceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddFinancialAssistance(bool));
    },
    onCancelForms: () => {
      dispatch(new ToggleDisplayAddIncome(false));
      dispatch(new ToggleDisplayAddMedicalInsurance(false));
      dispatch(new ToggleDisplayAddPBMInsurance(false));
      dispatch(new ToggleDisplayAddFinancialAssistance(false));
    },
  };
}

function mapStateToProps(state) {
  const { patient, financials, audit } = state;
  return {
    patient,
    audit,
    financials,
    displayAddIncome: state.formDisplays.displayAddIncome,
    displayAddMedical: state.formDisplays.displayAddMedicalInsurance,
    displayAddPbm: state.formDisplays.displayAddPBMInsurance,
    displayAddFinancialAssistance: state.formDisplays.displayAddFinancialAssistance,
    selectedPatientId: state.selectedPatientId,
    loading:
      financials.medical_insurances.loading ||
      financials.pbm_insurances.loading ||
      financials.incomes.loading,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(FinancialsList);
