import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { IPatientEnrollmentSectionDetailsProps } from '../types';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { IIncome } from '../../patient-merge-modal/interfaces';
import FinancialsGrouping from '../../../containers/patient/financials/financials-grouping';
import IncomeDetail from '../../../containers/patient/financials/incomes/income-detail';
import AddIncome from '../../../containers/patient/financials/incomes/add-income';

export function PatientEnrollmentIncomeSection(props: IPatientEnrollmentSectionDetailsProps) {
  const incomes: IIncome[] = useTypedSelector(state => Object.values(state.financials.incomes.data));
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const [displayAddButton, setDisplayAddButton] = useState(true);
  const [displayAddForm, setDisplayAddForm] = useState(false);

  const toggleAddForm = () => {
    setDisplayAddButton(!displayAddButton);
    setDisplayAddForm(!displayAddForm);
  };

  const renderIncome = (yearlyIncomes: IIncome[]) => <IncomeDetail incomes={yearlyIncomes} />;

  return (
    <>
      {incomes && incomes.length > 0 && (
        <FinancialsGrouping
          type={`Income (${incomes.length})`}
          renderMethod={renderIncome}
          financialItems={incomes}
          groupByKey="income_year"
          noSideMargin
          sortFn={(a: number, b: number) => b - a}
        />
      )}
      {displayAddForm && <AddIncome patientId={patientId} verifyOnSubmit cancel={toggleAddForm} />}
      {displayAddButton && (
        <Grid marginTop={1}>
          <Button
            variant="outlined"
            onClick={toggleAddForm}
            startIcon={<AddIcon />}
            sx={{ float: 'right' }}
          >
            Add Income
          </Button>
        </Grid>
      )}
    </>
  );
}
