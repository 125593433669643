export enum FillCoordinationStatus {
  Required = 4001,
  Completed = 4002,
  Canceled_MedicationDiscontinued = 4003,
  Canceled_MedicationPlacedOnHold = 4004,
  Canceled_PharmacyNoLongerFilling = 4005,
  Canceled_PatientUnreachable = 4006,
  Canceled_PatientDeceased = 4099,
  Canceled_TherapyComplete = 4100,
  Canceled = 4101,
  Canceled_Other = 4102,
  In_Progress = 4007,
  NeedsReview = 4008,
  In_Progress_Waiting_for_Patient_Response = 4009,
  In_Progress_Call_Patient = 4010,
  In_Progress_Patient_Response_Ready = 4011,
  In_Progress_Secure_Link_Expired = 4012,
}

const STATUS_LIST = [
  FillCoordinationStatus.Required,
  FillCoordinationStatus.Completed,
  FillCoordinationStatus.Canceled_MedicationDiscontinued,
  FillCoordinationStatus.Canceled_MedicationPlacedOnHold,
  FillCoordinationStatus.Canceled_PharmacyNoLongerFilling,
  FillCoordinationStatus.Canceled_PatientUnreachable,
  FillCoordinationStatus.Canceled_PatientDeceased,
  FillCoordinationStatus.Canceled_TherapyComplete,
  FillCoordinationStatus.Canceled,
  FillCoordinationStatus.Canceled_Other,
  FillCoordinationStatus.In_Progress,
  FillCoordinationStatus.In_Progress_Waiting_for_Patient_Response,
  FillCoordinationStatus.In_Progress_Call_Patient,
  FillCoordinationStatus.In_Progress_Patient_Response_Ready,
  FillCoordinationStatus.In_Progress_Secure_Link_Expired,
  FillCoordinationStatus.NeedsReview,
] as const;

export type FillCoordinationTaskStatusList = (typeof STATUS_LIST)[number];
