import { workListChanged } from 'actions/action-patient';
import { editFillDeliveryConfirmation, editTask } from 'actions/action-tasks';
import { TaskStatus } from 'interfaces/enums/TaskStatus';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { IPrintLabelButton } from './types';

export const updateFdcStatus = ({
  data,
  dispatch,
  setStatus,
  setCurrentStatus,
}: IPrintLabelButton) => {
  if (data?.status_id === FillDeliveryConfirmationStatus.Dispense) {
    const updatedTask = {
      id: data?.id,
      taskType: data?.taskType,
      therapy_id: data?.therapy_id,
      status_id: FillDeliveryConfirmationStatus.Packed,
    };
    dispatch && dispatch(editTask(updatedTask, data?.taskType));
    setStatus && setStatus(TaskStatus.Packed);
    setCurrentStatus && setCurrentStatus(updatedTask.status_id);
    dispatch && dispatch(workListChanged());
    dispatch && dispatch(editFillDeliveryConfirmation(updatedTask));
  }
};
