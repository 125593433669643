import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Grid, Modal } from '@mui/material';
import AccordionHeader from 'components/form/header/accordion-header';
import withStyles from '@mui/styles/withStyles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { numberToDollars } from 'services/utils/formatting';
import { accordianStyles } from 'components/accordian/accordian-styles';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import History from 'containers/common/history';
import classNames from 'classnames';
import { convertToArborDate } from 'models/time/arbor-date';
// eslint-disable-next-line max-len
import PrescriptionAssistanceProgramDetail from '../../../tasks/fa/prescription-assistance-program/prescription-assistance-program-detail';
import { styles } from '../financials-styles';

class FinancialAssistancePrescriptionAssistanceProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsShown: props.financialAssistance.status === 1,
      editHistoryModalOpen: false,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
    this.handleEditHistoryModal = this.handleEditHistoryModal.bind(this);
  }

  toggleExpand() {
    const { detailsShown, editHistoryModalOpen } = this.state;
    if (!editHistoryModalOpen) {
      this.setState({
        detailsShown: !detailsShown,
      });
    }
  }

  handleEditHistoryModal(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      editHistoryModalOpen: !prevState.editHistoryModalOpen,
    }));
  }

  render() {
    const { detailsShown, editHistoryModalOpen } = this.state;
    const { classes, financialAssistance, therapies, papStatuses, active } = this.props;

    const getHistoryURL = (patientId, id) =>
      `/patients/${patientId}/financials/patient-financial-assistance/${id}`;

    const accordionTitle = 'Prescription Assistance Program'
      .concat(
        financialAssistance.therapy_id && therapies[financialAssistance.therapy_id]
          ? ` - ${therapies[financialAssistance.therapy_id].drug_name}`
          : '',
      )
      .concat(
        financialAssistance.expiration_date
          ? ` - Expiration Date ${convertToArborDate(
              financialAssistance.expiration_date,
              true,
            ).getCustomerDate(true)}`
          : '',
      )
      .concat(
        financialAssistance.remaining_balance
          ? numberToDollars(financialAssistance.remaining_balance)
          : '',
      );

    const panelClassNames = classNames({
      [classes.stretchedPbmPanel]: !active,
      [classes.inactivePanel]: !active,
      [classes.inactiveHeader]: !active,
    });

    return (
      <Accordion expanded={!!detailsShown} elevation={0} className={panelClassNames}>
        <AccordionSummary
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
          onClick={this.toggleExpand}
          expandIcon={<ExpandMoreIcon />}
          className={panelClassNames}
        >
          <AccordionHeader
            smallHeader
            name={accordionTitle}
            editHandler={this.handleEdit}
            withHistory
            hideEdit
            historyHandler={this.handleEditHistoryModal}
            updatedDt={financialAssistance.updated}
            updatedBy={financialAssistance.updated_by}
            id={`financial-assistance-${financialAssistance.id}`}
          />
          <Modal open={editHistoryModalOpen} onClose={this.handleEditHistoryModal}>
            <div style={getModalStyle()} className={classes.Modal}>
              <History
                url={getHistoryURL(financialAssistance.patient_id, financialAssistance.id)}
              />
            </div>
          </Modal>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <PrescriptionAssistanceProgramDetail
                type={
                  papStatuses.find(f => f.id === financialAssistance.financial_assistance_type)
                    .sub_category
                }
                programName={financialAssistance.program_name}
                financialAssistance={financialAssistance}
                group={financialAssistance.group_id}
                phoneNumber={financialAssistance.phone_number}
                expDate={convertToArborDate(
                  financialAssistance.expiration_date,
                  true,
                ).getCustomerDate(true)}
                faxNumber={financialAssistance.fax_number}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const combinedStyles = combineStyles(styles, accordianStyles);

function mapStateToProps(state) {
  const { therapies } = state;
  return {
    therapies: therapies.data,
  };
}

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps),
)(FinancialAssistancePrescriptionAssistanceProgram);
