/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  Box,
} from '@mui/material';
import compose from 'recompose/compose';
import {
  Field,
  formValueSelector,
  reduxForm,
  getFormValues,
  change as changeActionCreator,
} from 'redux-form';
import { connect } from 'react-redux';
import { setTherapyId, editTasks } from 'actions/action-tasks';
import { resolveAudit } from 'actions/action-audit';
import { workListChanged } from 'actions/action-patient';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import Divider from 'components/divider';
import {
  renderCheckbox,
  renderNumberField,
  renderDropdown,
  renderTextField,
} from 'components/form/field/redux-field';
import {
  getInitialServiceGroupForReactSelect,
  getInitialManagingClinicForReactSelect,
  includeExistingSelectionInList,
  getTherapyFillCycleReasonOptions,
} from 'services/utils/task-service';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import FieldLabel from 'components/form/field/field-label';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  EDIT_PATIENT_THERAPY_FORM,
  THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE,
  THERAPY_SHOULD_HAVE_START_DATE,
  DOSIS_REGIMEN_CHARACTER_LIMIT,
  THERAPY_MED_SYNC,
  CDM,
  RS,
} from 'constants/index';
import { MedicationReferralStatus, TherapyWorkflowLabel } from 'constants/enums';
import { editTherapy } from 'actions/action-therapies';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import {
  fetchDiagnosisCodes,
  fetchGpis,
  checkGpi10Changed,
  specialtyTypeFormatter,
  isStartDateBeforeReferralDate,
} from 'services/utils/therapy-service';
import { resolveAuditDefinition } from 'services/utils/audit-service';
import Validation, { validateDate, required } from 'components/form/validation/validation';
import ChangeServiceGroupForm from 'components/dynamic-form/therapy-forms/change-service-group-form'; // eslint-disable-line
import renderDaysSelector from 'components/dynamic-form/renders/render-days-selector';
import { convertToArborDate } from 'models/time/arbor-date';
import RenderTherapyLeadSource from 'components/radio-button-group/render-therapy-lead-source';
import RenderTherapyRxType from 'components/radio-button-group/render-therapy-rx-type';
import { buildQaId } from 'utils/build-qa-id';
import { Info as InfoIcon } from '@mui/icons-material';
import { TherapyUtil } from 'utils/therapy-util';
import { windowFeatureIsEnabled } from 'config/window-features';
import { MedSyncStatusIcon } from 'containers/patient/med-sync/components/medSyncIcon';
import { MedSyncDisplayStatus } from 'containers/patient/med-sync/common';
import { styles } from './edit-patient-therapy-styles';
import NeedsByDateReasonDialog from '../needs-by-date-reason-dialog';
import renderPrescribingClinicField from '../fields/prescribing-clinic-field';
import renderPrescriberField from '../fields/prescriber-field';
import { PatientTherapiesClient } from '../../../../clients/patient-therapies';
import { isDosingRegimenRequired } from '../util';
import { fetchDosesRegimenReasons } from '../../../../actions/action-doses-regimen';

const qaIdBuilder = buildQaId('dosing-regimen', '.');

class EditPatientTherapy extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDosisRegimenChange = this.handleDosisRegimenChange.bind(this);
    this.handleAddTherapy = this.handleAddTherapy.bind(this);
    this.handleSaveColumnPharmacy = this.handleSaveColumnPharmacy.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.handleManagingClinicDropdown = this.handleManagingClinicDropdown.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModalIfTherapyWorkflowChanged =
      this.handleCloseModalIfTherapyWorkflowChanged.bind(this);
    this.handleResetTherapy = this.handleResetTherapy.bind(this);
    this.handleTherapyBlur = this.handleTherapyBlur.bind(this);
    this.handleCloseFillCycleModal = this.handleCloseFillCycleModal.bind(this);
    this.handleNeedsByDateChange = this.handleNeedsByDateChange.bind(this);
    this.renderDosisRegimenTextField = this.renderDosisRegimenTextField.bind(this);
    this.handleDaysSupplyConflict = this.handleDaysSupplyConflict.bind(this);
    this.handleDiagnosisBlur = this.handleDiagnosisBlur.bind(this);
    this.getTheNdcToBeUsed = this.getTheNdcToBeUsed.bind(this);
    this.getSpecialtyTypeByNdc = this.getSpecialtyTypeByNdc.bind(this);
    this.icdVerification = this.icdVerification.bind(this);
    this.icdVerificationDialogBox = this.icdVerificationDialogBox.bind(this);
    this.perProtocolCheckbox = React.createRef();
    this.therapy = React.createRef();
    this.diagnosis = React.createRef();

    this.state = {
      requireDosingRegimen: true,
      isSpecialty: true,
      serviceGroupDropdown: -1,
      managingClinicDropdown: -1,
      openModal: false,
      openFillCycleModal: false,
      serviceGroupSelectorOpen: null,
      // eslint-disable-next-line camelcase
      dosisRegimenValue: props.therapy?.dosis_regimen || '',
      daysSupplyInConflict: false,
      daysSupplyToolTip: '',
      therapyWorkflowChanged: false,
      currentWorkflow: '',
      newWorkflow: '',
      showIcdIndicator: false,
      submittingForm: false,
      dialogOpen: false,
      stateSubmitHandler: null,
      event: null,
    };
  }

  componentDidMount() {
    const {
      initialValues,
      change,
      dosisRegimenChangeReasonOptions,
      fetchDosesRegimenReasons,
      fillCycles,
    } = this.props;

    if (!dosisRegimenChangeReasonOptions.length) {
      fetchDosesRegimenReasons();
    }

    this.setState({
      isSpecialty: Boolean(initialValues.is_specialty),
      requireDosingRegimen: isDosingRegimenRequired(initialValues.specialty_type),
    });
    fetchGpis(initialValues.ndc, true, true).then(result => {
      const therapy = result.data.drugs[0];
      if (therapy) {
        change('therapy', {
          ...therapy,
          id: therapy.ndc,
          label: therapy.drug_info,
        });
        this.setState({
          isSpecialty: therapy.is_specialty_drug === 'Yes',
          requireDosingRegimen: isDosingRegimenRequired(therapy.specialty_type),
        });
      }
    });

    if (initialValues.diagnosis_code) {
      change('diagnosis', {
        code: initialValues.diagnosis_code,
        description: initialValues.diagnosis_description,
        id: initialValues.diagnosis_code,
        label: initialValues.diagnosis_description,
      });
    }
    if (initialValues.secondary_diagnosis_code) {
      change('diagnosis_secondary', {
        code: initialValues.secondary_diagnosis_code,
        description: initialValues.secondary_diagnosis_description,
        id: initialValues.secondary_diagnosis_code,
        label: initialValues.secondary_diagnosis_description,
      });
    }
    if (initialValues.tertiary_diagnosis_code) {
      change('diagnosis_tertiary', {
        code: initialValues.tertiary_diagnosis_code,
        description: initialValues.tertiary_diagnosis_description,
        id: initialValues.tertiary_diagnosis_code,
        label: initialValues.tertiary_diagnosis_description,
      });
    }
    const { therapy } = this.props;
    this.handleDaysSupplyConflict(therapy.days_supply);
  }

  componentDidUpdate(prevProps) {
    const { selectedDiagnosis: prevSelectedDiagnosis, selectedTherapy: prevSelectedTherapy } =
      prevProps;
    const { selectedDiagnosis, selectedTherapy } = this.props;
    this.icdVerification(
      selectedDiagnosis,
      selectedTherapy,
      prevSelectedDiagnosis,
      prevSelectedTherapy,
    );
  }

  icdVerification(selectedDiagnosis, selectedTherapy, prevSelectedDiagnosis, prevSelectedTherapy) {
    if (
      Object.keys(selectedDiagnosis).length &&
      Object.keys(selectedTherapy).length &&
      selectedTherapy.specialty_type.includes(CDM)
    ) {
      if (
        selectedDiagnosis.code &&
        selectedTherapy.specialty_type &&
        (Object.keys(prevSelectedDiagnosis).length || Object.keys(prevSelectedTherapy).length)
      ) {
        PatientTherapiesClient.getTherapyWorkflow(
          selectedDiagnosis.code,
          selectedTherapy.specialty_type,
        ).then(res => {
          const workflowLabel = TherapyWorkflowLabel[res.data?.therapyWorkflow] ?? '';
          if (workflowLabel !== RS) {
            if (!this.state.submittingForm) this.resetIcdIndicator(true);
          } else {
            this.resetIcdIndicator(false);
          }
        });
      }
    } else if (this.state.showIcdIndicator) {
      this.resetIcdIndicator(false);
    }
  }

  resetIcdIndicator(flag) {
    if (this.state.showIcdIndicator !== flag) {
      this.setState({ showIcdIndicator: flag });
    }
  }

  handleDosisRegimenChange(event) {
    if (this.state.dosisRegimenValue === event.target.value) {
      return;
    }
    this.setState({ dosisRegimenValue: event.target.value, dosisRegimenChanged: true });
  }

  getTheNdcToBeUsed(selectedTherapy, therapy) {
    return checkGpi10Changed(selectedTherapy.gpi, therapy.gpi) ? therapy.ndc : selectedTherapy.ndc;
  }

  getSpecialtyTypeByNdc() {
    const currentNdc = this.props.formValues.ndc;
    const specialtyTypeByNdc =
      this.props.therapy.ndc === currentNdc
        ? this.props.therapy.specialty_type
        : this.props.selectedTherapy.specialty_type;
    return specialtyTypeByNdc;
  }

  handleResetTherapy() {
    const { reset } = this.props;
    this.handleCloseModal();
    this.handleCloseModalIfTherapyWorkflowChanged();
    if (this.props.formValues.diagnosis?.code !== this.props.formValues.diagnosis_code) {
      this.props.formValues.diagnosis.code = this.props.formValues.diagnosis_code;
      this.props.formValues.diagnosis.description = this.props.formValues.diagnosis_description;
      // eslint-disable-next-line react/no-find-dom-node
      findDOMNode(this.diagnosis.current).querySelector('input').value =
        this.props.formValues.diagnosis_description;
    }
    if (this.props.initialValues.ndc !== this.props.selectedTherapy.ndc) {
      // eslint-disable-next-line react/no-find-dom-node
      findDOMNode(this.therapy.current).querySelector('input').value =
        this.props.initialTherapyName;
    }
  }

  handleCloseModal() {
    this.setState({ openModal: false });
  }

  handleCloseModalIfTherapyWorkflowChanged() {
    this.setState({ therapyWorkflowChanged: false });
  }

  handleCloseFillCycleModal() {
    this.setState({ openFillCycleModal: false });
  }

  handleAddTherapy() {
    const { addTherapyHandler, cancel } = this.props;
    this.handleCloseModal();
    this.handleCloseModalIfTherapyWorkflowChanged();
    addTherapyHandler();
    cancel();
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  doHandleSubmit(values, tasksToUpdate) {
    const {
      therapy,
      patientId,
      editTherapy, // eslint-disable-line no-shadow
      cancel,
      isTaskSidebar,
      workListChanged, // eslint-disable-line no-shadow
      initialValues,
      editTasks, // eslint-disable-line no-shadow
      resolveAudit, // eslint-disable-line no-shadow
      auditState,
      patient,
    } = this.props;
    const { isSpecialty, dosisRegimenValue, dosisRegimenChanged } = this.state;

    const payload = {
      patient_id: patientId || therapy.patient_id,
      ndc: values.therapy ? this.getTheNdcToBeUsed(values.therapy, therapy) : therapy.ndc,
      managing_clinic_id: values.managing_clinic_name_and_id.value,
      managing_clinic_name: values.managing_clinic_name_and_id.label,
      medication_id: therapy.medication_id,
      referral_dt: convertToArborDate(values.referral_dt).getUtcDatetime(),
      needsby_date: convertToArborDate(values.needsby_date).getUtcDate(),
      fill_cycle_reason_id: values.fill_cycle_reason_id,
      therapy_id: therapy ? therapy.id : null,
      last_filled_date: convertToArborDate(values.last_filled_date).getUtcDate(),
      days_supply: values.days_supply ? values.days_supply : null,
      is_needsby_per_protocol: values.is_needsby_per_protocol,
      as_needed: values.as_needed,
      clinic_administered: values.clinic_administered,
      service_group_id: values.service_group_name_and_id?.value,
      therapy_duration: values.therapy_duration || null,
      dispensing_pharmacy_npi: therapy.dispensing_pharmacy_npi,
      dispensing_pharmacy_name: therapy.dispensing_pharmacy_name,
      diagnosis_code: values.diagnosis ? values.diagnosis.code : null,
      secondary_diagnosis_code: values.diagnosis_secondary ? values.diagnosis_secondary.code : null,
      tertiary_diagnosis_code: values.diagnosis_tertiary ? values.diagnosis_tertiary.code : null,
      lead_source_id: values.lead_source_id || therapy.lead_source_id,
      rx_type_id: values.rx_type_id || therapy.rx_type_id,
      external_dispensing_reason: therapy.external_dispensing_reason,
      external_dispensing_additional_reason: therapy.external_dispensing_additional_reason,
      is_specialty: Boolean(isSpecialty),
      one_time_fill: Boolean(values.one_time_fill ?? therapy.one_time_fill),
      prescribing_physician_npi: values.prescribing_physician
        ? values.prescribing_physician.npi
        : null,
      prescribing_physician_name: values.prescribing_physician
        ? values.prescribing_physician.name
        : null,
      prescribing_physician_credential: values.prescribing_physician
        ? values.prescribing_physician.credential
        : null,
      referring_clinic_npi: values.referring_clinic ? values.referring_clinic.npi : null,
      referring_clinic_name: values.referring_clinic ? values.referring_clinic.name : null,
      referring_clinic_internal_id: values.referring_clinic ? values.referring_clinic.id : null,
      ...(dosisRegimenChanged
        ? {
            dosis_regimen: dosisRegimenValue,
            dosis_regimen_reason_id: values.dosis_regimen_reason_id,
            dosis_regimen_effective_date: convertToArborDate(
              values.dosis_regimen_effective_date,
            ).getUtcDate(),
          }
        : {}),
    };
    // We need to check all medication related field, if these are not changed don't send to service
    // otherwise it will trigger the medication workflow
    if (values.source !== initialValues.source) {
      payload.category_code = values.source;
    }
    const startUtcDate = convertToArborDate(values.start_date).getUtcDate();
    if (startUtcDate !== convertToArborDate(initialValues.start_date).getUtcDate()) {
      payload.start_date = startUtcDate;
    }
    const discontinuedUtcDate = convertToArborDate(values.discontinued_date).getUtcDate();
    if (discontinuedUtcDate !== convertToArborDate(initialValues.discontinued_date).getUtcDate()) {
      payload.discontinued_date = discontinuedUtcDate;
    }
    // If drug is non-sp it may not have nbd at initial state.
    // Need to insert a new fill cycle if they set a nbd in the edit form
    if (!therapy.fill_cycle && values.needsby_date) {
      payload.first_fill_cycle = true;
    }

    if (payload.start_date) {
      resolveAuditDefinition({
        auditDefinitionType: THERAPY_SHOULD_HAVE_START_DATE,
        auditState,
        patient,
        resolverFunction: resolveAudit,
      });
    }
    if (
      (therapy.start_date &&
        payload.referral_dt &&
        !isStartDateBeforeReferralDate(
          convertToArborDate(therapy.start_date).getUtcDate(),
          convertToArborDate(payload.referral_dt).getUtcDate(),
        )) ||
      +payload.rx_type_id === MedicationReferralStatus.Existing
    ) {
      resolveAuditDefinition({
        auditDefinitionType: THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE,
        auditState,
        patient,
        resolverFunction: resolveAudit,
      });
    }

    return new Promise((resolve, reject) => {
      editTherapy(
        payload,
        () => {
          if (tasksToUpdate && tasksToUpdate.length) {
            editTasks(payload.patient_id, tasksToUpdate);
          }
          if (isTaskSidebar) {
            workListChanged();
          }
          setTherapyId(therapy.id);
          cancel();
          resolve();
        },
        reject,
      );
    });
  }

  handleSubmit(values) {
    const { initialValues } = this.props;
    if (
      initialValues.service_group_name_and_id &&
      values.service_group_name_and_id &&
      initialValues.service_group_name_and_id.label !== values.service_group_name_and_id.label
    ) {
      return new Promise(resolve => {
        this.setState({
          serviceGroupSelectorOpen: values,
          serviceGroupSelectorOnComplete: resolve,
        });
      });
    }
    return this.doHandleSubmit(values);
  }

  handleTherapyBlur() {
    const { selectedTherapy, therapy } = this.props;
    const { gpi } = selectedTherapy;
    if (checkGpi10Changed(gpi, therapy.gpi)) {
      this.setState({
        openModal: true,
      });
    } else {
      this.handleDiagnosisBlur();
    }
  }

  handleDiagnosisBlur = async () => {
    if (
      this.props.formValues.diagnosis.code &&
      this.props.formValues.diagnosis?.code !== this.props.initialValues.diagnosis_code &&
      Object.keys(this.props.selectedTherapy).length
    ) {
      const currentTherapyWorkflow = await PatientTherapiesClient.getTherapyWorkflow(
        this.props.therapy.diagnosis_code,
        this.props.therapy.specialty_type,
      );
      const newTherapyWorkflow = await PatientTherapiesClient.getTherapyWorkflow(
        this.props.formValues.diagnosis.code,
        this.getSpecialtyTypeByNdc(),
      );
      if (
        currentTherapyWorkflow.data?.therapyWorkflow !== newTherapyWorkflow.data?.therapyWorkflow
      ) {
        this.setState({
          therapyWorkflowChanged: true,
          currentWorkflow:
            TherapyWorkflowLabel[currentTherapyWorkflow.data?.therapyWorkflow] ?? 'Non SP',
          newWorkflow: TherapyWorkflowLabel[newTherapyWorkflow.data?.therapyWorkflow] ?? 'Non SP',
        });
      } else {
        this.setState({
          therapyWorkflowChanged: false,
          currentWorkflow: '',
          newWorkflow: '',
        });
      }
    }
  };

  handleSaveColumnPharmacy(callbackValue) {
    this.setState(prevState => ({
      pharmacyValues: {
        displayColumn: prevState.pharmacyValues.displayColumn,
        displayValue: callbackValue[prevState.pharmacyValues.displayColumn],
        idColumn: prevState.pharmacyValues.idColumn,
        idValue: callbackValue[prevState.pharmacyValues.idColumn],
      },
    }));
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  handleManagingClinicDropdown(e) {
    this.setState({
      managingClinicDropdown: e.target.value,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchDiagnosisOptions(searchText) {
    // eslint-disable-next-line no-confusing-arrow
    return fetchDiagnosisCodes(searchText).then(result =>
      result.data
        ? result.data.icd10s.map(icd10 => ({
            ...icd10,
            id: icd10.code,
            label: icd10.description,
          }))
        : [],
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchGpisOptions(searchText) {
    // eslint-disable-next-line no-confusing-arrow
    return fetchGpis(searchText).then(result =>
      result.data
        ? result.data.drugs.map(therapy => ({
            ...therapy,
            id: therapy.ndc,
            label: therapy.drug_info,
          }))
        : [],
    );
  }

  handleNeedsByDateChange() {
    const { formValues, therapy } = this.props;

    const hasChanged =
      convertToArborDate(formValues.needsby_date).getUtcDate() !==
      convertToArborDate(therapy.needsby_date, true).getUtcDate();

    if (formValues.needsby_date && therapy.fill_cycle && hasChanged) {
      // we force focusing on next input after selecting needs by date change reason
      // to prevent issue of focusing again into datepicker
      // eslint-disable-next-line react/no-find-dom-node
      findDOMNode(this.perProtocolCheckbox.current).querySelector('input').focus();
      this.setState({
        openFillCycleModal: true,
      });
    }
  }

  handleDaysSupplyConflict(daysSupplyValue) {
    const { therapy, patient } = this.props;
    const daysSupplyChecking = TherapyUtil.checkDaysSupply(daysSupplyValue, therapy, patient);
    this.setState({
      daysSupplyInConflict: daysSupplyChecking.daysSupplyInConflict,
      daysSupplyToolTip: daysSupplyChecking.daysSupplyToolTip,
    });
  }

  renderDosisRegimenTextField() {
    const { classes, dosisRegimenChangeReasonOptions, therapy } = this.props;
    const { dosisRegimenValue, dosisRegimenChanged, requireDosingRegimen } = this.state;
    return (
      <>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            Dosing Regimen {requireDosingRegimen ? '*' : ''}
          </Typography>
          <Field
            component={renderTextField}
            validate={requireDosingRegimen ? [required] : []}
            variant="standard"
            name="dosis_regimen"
            fullWidth
            placeholder="Text about the dosing regimen for this therapy..."
            inputProps={{
              maxLength: DOSIS_REGIMEN_CHARACTER_LIMIT,
            }}
            value={dosisRegimenValue}
            onChange={this.handleDosisRegimenChange}
            helperText={`${dosisRegimenValue.length}/${DOSIS_REGIMEN_CHARACTER_LIMIT}`}
            margin="normal"
            data-qa-id={qaIdBuilder('text-field')}
          />
        </Grid>
        {dosisRegimenChanged && (
          <>
            <Grid item xs={3}>
              <Field
                name="dosis_regimen_reason_id"
                label="Reason for Change *"
                component={renderDropdown}
                fields={dosisRegimenChangeReasonOptions}
                validate={[required]}
                isOptionDisabled={opt => opt.disabled === true}
                data-qa-id="dosis-reason-for-change"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                label="Effective Date *"
                name="dosis_regimen_effective_date"
                component={renderDatePicker}
                validate={[required, validateDate]}
                data-qa-id="dosis-effective-date"
              />
            </Grid>
          </>
        )}
      </>
    );
  }

  renderModal() {
    const { classes, selectedTherapy, therapy } = this.props;
    const { openModal } = this.state;
    let initialDrugName;
    let newDrugName;
    if (therapy) {
      initialDrugName = therapy.drug_name;
    }
    if (selectedTherapy) {
      newDrugName = selectedTherapy.drug_name;
    }
    const modalTitle = `You've changed therapy from ${initialDrugName} to ${newDrugName}`;
    return (
      <Dialog open={openModal} onClose={this.handleCloseModal} disableEscapeKeyDown>
        <Grid container>
          <Grid item xs={12} align="center">
            <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
          </Grid>
          <Grid item xs={12} className={classes.modalContentContainer}>
            <DialogContent>
              <DialogContentText className={classes.alertWording} id="alert-dialog-description">
                Is this a New Therapy?
                <br />
                Please use Add a New Therapy Instead.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.modalActionBtnContainer}>
              <Button variant="contained" onClick={this.handleResetTherapy} color="primary">
                Cancel
              </Button>
              <Button variant="contained" onClick={this.handleAddTherapy} color="primary">
                Add a New Therapy
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    );
  }

  renderModalIfTherapyWorkflowChanged() {
    const { classes, initialValues, formValues } = this.props;
    const { therapyWorkflowChanged } = this.state;
    const initialDiagnosisCode = initialValues?.diagnosis_code;
    const currentDiagnosisCode = formValues?.diagnosis?.code;
    const modalTitle = `You've changed diagnosis code from ${initialDiagnosisCode} to ${currentDiagnosisCode} which causes the therapy to be changed from ${this.state.currentWorkflow} to ${this.state.newWorkflow}`;
    return (
      <Dialog
        open={therapyWorkflowChanged}
        onClose={this.handleCloseModalIfTherapyWorkflowChanged}
        disableEscapeKeyDown
      >
        <Grid container>
          <Grid item xs={12} align="center">
            <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
          </Grid>
          <Grid item xs={12} className={classes.modalContentContainer}>
            <DialogContent>
              <DialogContentText className={classes.alertWording} id="alert-dialog-description">
                Is this a New Therapy?
                <br />
                Please use Add a New Therapy Instead.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.modalActionBtnContainer}>
              <Button variant="contained" onClick={this.handleResetTherapy} color="primary">
                Cancel
              </Button>
              <Button variant="contained" onClick={this.handleAddTherapy} color="primary">
                Add a New Therapy
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    );
  }

  icdVerificationDialogBox(diagnosisCode, diagnosisDescription) {
    const { classes } = this.props;
    const message = `You are pairing this Therapy with a NON RS ICD Code (${diagnosisCode} ${diagnosisDescription})`;
    const question = 'Do you want to continue?';
    return (
      <Dialog open={this.state.dialogOpen} maxWidth="md" data-qa-id="icd_verification_dialogbox">
        <DialogContent>
          <DialogContentText>
            <Typography className={classes.icdValidatorText}>{message}</Typography>
            <Typography className={classes.icdValidatorText}>{question}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => {
              this.setState({
                dialogOpen: false,
                submittingForm: false,
              });
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({
                showIcdIndicator: false,
                submittingForm: false,
              });
              this.state.stateSubmitHandler(this.state.event);
            }}
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      cancel,
      therapy,
      managingClinicNameAndId,
      managingClinics,
      serviceGroups,
      change, // eslint-disable-line
      lookups,
      selectedDiagnosis,
      selectedSecondaryDiagnosis,
      selectedTertiaryDiagnosis,
      selectedTherapy,
      therapyFillCycleReasonOptions,
    } = this.props;

    const {
      serviceGroupDropdown,
      managingClinicDropdown,
      openFillCycleModal,
      isSpecialty,
      serviceGroupSelectorOpen,
      serviceGroupSelectorOnComplete,
      daysSupplyInConflict,
      daysSupplyToolTip,
    } = this.state;

    const inMedSync = therapy.in_med_sync === THERAPY_MED_SYNC.IN_MED_SYNC;

    // Must be pre-defined for redux-forms
    const renderSourceRadio = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup horizontal name="source" id="source" {...input} className={classes.radioGroup}>
          {lookups.medicationCategoryCodes.map(category => (
            <RadioButton value={category.code} key={category.code}>
              {category.category}
            </RadioButton>
          ))}
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );

    const showDialogBox = () => {
      return this.icdVerificationDialogBox(selectedDiagnosis.code, selectedDiagnosis.description);
    };

    const isOptInDispensing = therapy.dispensing_status === 'Opt in';
    return (
      <Grid
        container
        justifyContent="flex-start"
        spacing={7}
        // eslint-disable-next-line no-sequences
        className={(classes.formRowContainer, classes.formContainer)}
      >
        {this.renderModal()}
        {this.renderModalIfTherapyWorkflowChanged()}
        {serviceGroupSelectorOpen && (
          <ChangeServiceGroupForm
            therapyId={therapy.id}
            onClose={() => {
              this.setState({
                serviceGroupSelectorOpen: false,
              });
              if (serviceGroupSelectorOnComplete) {
                serviceGroupSelectorOnComplete();
              }
            }}
            onSubmit={tasks => {
              this.doHandleSubmit(serviceGroupSelectorOpen, tasks);
              if (serviceGroupSelectorOnComplete) {
                serviceGroupSelectorOnComplete();
              }
            }}
            newServiceGroup={serviceGroupSelectorOpen.service_group_name_and_id}
          />
        )}
        {this.state.showIcdIndicator && this.state.submittingForm ? showDialogBox() : null}
        <Grid item xs={12}>
          <form
            onSubmit={e => {
              const submitHandler = handleSubmit(this.handleSubmit);
              if (!this.state.showIcdIndicator) {
                submitHandler(e);
                return false;
              }
              this.setState({
                submittingForm: true,
                dialogOpen: true,
                stateSubmitHandler: submitHandler,
                event: e,
              });
              e.preventDefault();
            }}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            {/* First Row */}
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={6} onBlur={this.handleTherapyBlur}>
                <Field
                  name="therapy"
                  label="Therapy"
                  component={AutocompleteMinigrid}
                  validate={[required]}
                  fetchOptions={this.handleFetchGpisOptions}
                  hint="Search by Therapy or GPI or NDC"
                  columnsToShow={{
                    gpi: 'GPI',
                    drug_name: 'Drug Name',
                    dosage_form: 'Form',
                    dose: 'Dose',
                    ldd: 'LDD',
                    specialty_type: 'Specialty Type',
                    ndc: 'NDC',
                  }}
                  minSearchLength={2}
                  valueFormatter={specialtyTypeFormatter}
                  ref={this.therapy}
                />
              </Grid>
              <Grid item xs={3}>
                <AutocompleteLabel
                  idValue={this.getTheNdcToBeUsed(selectedTherapy, therapy)}
                  defaultIdValue={therapy.ndc}
                  label="NDC"
                />
              </Grid>
              {isSpecialty && (
                <Grid item xs={3}>
                  <Field
                    label="Referral Date *"
                    name="referral_dt"
                    component={renderDatePicker}
                    validate={[required, validateDate]}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              className={classes.dosisRegimenField}
            >
              {this.renderDosisRegimenTextField()}
            </Grid>
            <Divider />
            {/* Second Row */}
            <Grid container spacing={3} alignItems="flex-start">
              <Grid
                item
                xs={6}
                onBlur={this.handleDiagnosisBlur}
                className={this.state.showIcdIndicator ? classes.icdValidator : ''}
              >
                <Field
                  name="diagnosis"
                  label={`Diagnosis ${isSpecialty ? '*' : ''}`}
                  component={AutocompleteMinigrid}
                  validate={isSpecialty ? [required] : []}
                  hint="Search by Diagnosis Name or Code"
                  columnsToShow={{
                    code: 'Code',
                    description: 'Description',
                  }}
                  clearable
                  fetchOptions={this.handleFetchDiagnosisOptions}
                  ref={this.diagnosis}
                />
                {this.state.showIcdIndicator && (
                  <Tooltip
                    title={
                      <>
                        <Typography>This therapy is paired with a</Typography>
                        <Typography>NON RS ICD code</Typography>
                      </>
                    }
                    placement="right-start"
                  >
                    <InfoIcon className={classes.icdValidatorIcon} />
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={2}>
                <AutocompleteLabel idValue={selectedDiagnosis.code} label="Diagnosis Code" />
              </Grid>
              <Grid item xs={1} />
              {isSpecialty && (
                <Grid item xs={3}>
                  <FieldLabel fieldName="Medication Status *" />
                  <Field name="rx_type_id" component={RenderTherapyRxType} validate={[required]} />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={6}>
                <Field
                  name="diagnosis_secondary"
                  label="Secondary Diagnosis"
                  component={AutocompleteMinigrid}
                  validate={[]}
                  hint="Search by Diagnosis Name or Code"
                  columnsToShow={{
                    code: 'Code',
                    description: 'Description',
                  }}
                  clearable
                  fetchOptions={this.handleFetchDiagnosisOptions}
                />
              </Grid>
              <Grid item xs={2}>
                <AutocompleteLabel
                  idValue={selectedSecondaryDiagnosis.code}
                  label="Diagnosis Code"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={6}>
                <Field
                  name="diagnosis_tertiary"
                  label="Tertiary Diagnosis"
                  component={AutocompleteMinigrid}
                  validate={[]}
                  hint="Search by Diagnosis Name or Code"
                  columnsToShow={{
                    code: 'Code',
                    description: 'Description',
                  }}
                  clearable
                  fetchOptions={this.handleFetchDiagnosisOptions}
                />
              </Grid>
              <Grid item xs={2}>
                <AutocompleteLabel
                  idValue={selectedTertiaryDiagnosis.code}
                  label="Diagnosis Code"
                />
              </Grid>
            </Grid>
            {isSpecialty && (
              <>
                {/* Third Row */}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FieldLabel fieldName="Referral Source *" />
                    <Field
                      name="lead_source_id"
                      component={RenderTherapyLeadSource}
                      className={classes.input}
                      validate={[required]}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!isSpecialty && (
              <Grid item xs={12}>
                <FieldLabel fieldName="Source *" />
                <Field name="source" component={renderSourceRadio} validate={[required]} />
              </Grid>
            )}
            <Divider />

            {/* Fourth Row */}
            <Grid item xs={12} className={classes.wideRow}>
              <Field
                name="referring_clinic"
                label="Prescribing Clinic"
                component={renderPrescribingClinicField}
                therapy={therapy}
              />
            </Grid>
            {(isSpecialty || isOptInDispensing) && (
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={3}>
                  <Field
                    name="managing_clinic_name_and_id"
                    label="Managing Clinic *"
                    validate={[required]}
                    component={ReactSelectForReduxSingle}
                    fields={managingClinics.map(int => ({
                      label: int.name,
                      value: int.id,
                      npi: int.npi,
                    }))}
                    state={managingClinicDropdown}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutocompleteLabel
                    idValue={managingClinicNameAndId ? managingClinicNameAndId.npi : null}
                    defaultIdValue=""
                    label="Managing Clinic NPI"
                    useLargeLabel
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Field
                name="prescribing_physician"
                label="Prescriber"
                component={renderPrescriberField}
                therapy={therapy}
              />
            </Grid>
            <Divider />
            {/* Fifth Row */}
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={2} id="nbd_edit">
                <Tooltip
                  title={
                    inMedSync
                      ? 'Therapy is part of med sync, change the Anchor Date to move the NBD'
                      : ''
                  }
                  placement="top-start"
                >
                  <Box>
                    <Field
                      label={isSpecialty || isOptInDispensing ? 'Needs By Date *' : 'Needs By Date'}
                      name="needsby_date"
                      component={renderDatePicker}
                      validate={
                        isSpecialty || isOptInDispensing ? [required, validateDate] : [validateDate]
                      }
                      disabled={openFillCycleModal || inMedSync}
                      onCalendarClose={this.handleNeedsByDateChange}
                    />
                  </Box>
                </Tooltip>
              </Grid>
              {inMedSync ? null : (
                <NeedsByDateReasonDialog
                  open={openFillCycleModal}
                  handleClose={this.handleCloseFillCycleModal}
                  change={change}
                  options={therapyFillCycleReasonOptions}
                  getInitialValue={prop => {
                    const { initialValues } = this.props;
                    return initialValues[prop];
                  }}
                />
              )}
              <Grid item xs={2}>
                <Field
                  name="is_needsby_per_protocol"
                  component={renderCheckbox}
                  label="Per Protocol"
                  ref={this.perProtocolCheckbox}
                />
              </Grid>
              {window.FEATURES.one_time_fill && !isSpecialty && (
                <Grid item xs={2}>
                  <Field name="one_time_fill" label="One-Time Fill" component={renderCheckbox} />
                </Grid>
              )}
              <Grid item xs={2}>
                <Field name="as_needed" component={renderCheckbox} label="As Needed" />
              </Grid>
              <Grid item xs={2}>
                <Field
                  name="clinic_administered"
                  component={renderCheckbox}
                  label="Clinic Administered"
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={
                  windowFeatureIsEnabled('med_sync') && daysSupplyInConflict
                    ? classes.daySupplyConflict
                    : ''
                }
              >
                <Field
                  label={isSpecialty || isOptInDispensing ? 'Days Supply *' : 'Days Supply'}
                  name="days_supply"
                  validate={(isSpecialty || isOptInDispensing) && [required]}
                  component={renderNumberField}
                  onChange={e => this.handleDaysSupplyConflict(e.target.value)}
                />
                {windowFeatureIsEnabled('med_sync') && daysSupplyToolTip.length > 0 && (
                  <Tooltip title={daysSupplyToolTip} placement="top">
                    <Box
                      className={classes.daysSupplyInfo}
                      sx={{ opacity: daysSupplyInConflict ? 1 : 0.48, paddingTop: '2px' }}
                    >
                      <MedSyncStatusIcon
                        size="xs"
                        displayStatus={
                          daysSupplyInConflict
                            ? MedSyncDisplayStatus.InConflict
                            : MedSyncDisplayStatus.Default
                        }
                      />
                    </Box>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={isSpecialty ? 3 : 2}>
                <Field
                  name="service_group_name_and_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                    isDisabled: !int.active,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={2}>
                <Field
                  label="Last Filled Date"
                  name="last_filled_date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  label="Start Date"
                  name="start_date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="discontinued_date"
                  label="Discontinued Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <Field
                  name="therapy_duration"
                  label="Therapy Duration"
                  component={renderDaysSelector}
                />
              </Grid>
            </Grid>
            <Divider />
            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(therapy) {
  if (!therapy) {
    return null;
  }
  const name = `${EDIT_PATIENT_THERAPY_FORM}_${therapy.patient_id}_${therapy.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const { patient, lookups, audit, dosesRegimenReasons, fillCycles } = state;
  const { therapy, medication } = props;
  const name = handleFormName(therapy);
  const selector = formValueSelector(name);
  const managingClinicNameAndId = selector(state, 'managing_clinic_name_and_id');
  const selectedTherapy = selector(state, 'therapy') || {};
  const selectedDiagnosis = selector(state, 'diagnosis') || {};
  const selectedSecondaryDiagnosis = selector(state, 'diagnosis_secondary') || {};
  const selectedTertiaryDiagnosis = selector(state, 'diagnosis_tertiary') || {};
  const formValues = getFormValues(name)(state);
  const clinicDefinedInTherapy = {
    label: therapy?.managing_clinic_name, // eslint-disable-line
    value: therapy?.managing_clinic_id, // eslint-disable-line
    npi: therapy?.managing_clinic_npi, // eslint-disable-line
  };

  const needsbyDate =
    fillCycles[therapy.id] && fillCycles[therapy.id][therapy.fill_cycle]
      ? fillCycles[therapy.id][therapy.fill_cycle].needsby_date
      : therapy.needsby_date;

  return {
    auditState: audit,
    patient,
    form: name,
    formValues,
    lookups,
    initialValues: {
      referral_dt: convertToArborDate(therapy.referral_dt, true).getCustomerDate(true),
      is_needsby_per_protocol: therapy.is_needsby_per_protocol,
      fill_cycle_reason_id: 1,
      needsby_date: convertToArborDate(needsbyDate, true).getUtcDate(true),
      lead_source_id: therapy.lead_source_id ? `${therapy.lead_source_id}` : null,
      rx_type_id: therapy.rx_type_id ? `${therapy.rx_type_id}` : null,
      start_date: convertToArborDate(therapy.start_date, true).getUtcDate(true),
      last_filled_date: convertToArborDate(therapy.last_filled_date, true).getUtcDate(true),
      days_supply: therapy.days_supply ? therapy.days_supply : null,
      diagnosis_description: therapy.diagnosis_description,
      diagnosis_code: therapy.diagnosis_code,
      secondary_diagnosis_code: therapy.secondary_diagnosis_code,
      tertiary_diagnosis_code: therapy.tertiary_diagnosis_code,
      secondary_diagnosis_description: therapy.secondary_diagnosis_description,
      tertiary_diagnosis_description: therapy.tertiary_diagnosis_description,
      therapy: null,
      ndc: therapy.ndc,
      managing_clinic_name_and_id: therapy.managing_clinic_id
        ? getInitialManagingClinicForReactSelect(
            therapy.managing_clinic_id,
            state.lookups.customerClinics,
            clinicDefinedInTherapy,
          )
        : '',
      service_group_name_and_id: therapy.service_group_id
        ? getInitialServiceGroupForReactSelect(
            therapy.service_group_id,
            state.lookups.serviceGroups,
          )
        : '',
      discontinued_date: convertToArborDate(therapy.discontinued_date, true).getUtcDate(true),
      therapy_duration: therapy.therapy_duration,
      service_group_name: therapy.service_groups_name,
      source: medication ? medication.category_code : 'patientspecified',
      referring_clinic: {
        npi: therapy.referring_clinic_npi,
        name: therapy.referring_clinic_name,
        id: therapy.referring_clinic_internal_id,
      },
      prescribing_physician: {
        npi: therapy.prescribing_physician_npi,
        name: therapy.prescribing_physician_name,
      },
      dosis_regimen: therapy.dosis_regimen ? therapy.dosis_regimen : null,
      as_needed: therapy.as_needed,
      clinic_administered: therapy.clinic_administered,
      dosis_regimen_effective_date: therapy.dosis_regimen_effective_date,
      one_time_fill: !!therapy.one_time_fill,
    },
    serviceGroups: state.lookups.serviceGroups,
    managingClinics: includeExistingSelectionInList(state.lookups.customerClinics, therapy),
    therapyFillCycleReasonOptions: getTherapyFillCycleReasonOptions(
      state.lookups.therapyFillCycleReasons,
      therapy,
    ),
    dosisRegimenChangeReasonOptions: dosesRegimenReasons.reasons
      .filter(item => item.is_active)
      .map(item => ({
        label: item.name,
        value: item.id,
      })),
    selectedTherapy,
    selectedDiagnosis,
    selectedSecondaryDiagnosis,
    selectedTertiaryDiagnosis,
    managingClinicNameAndId,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    editTherapy,
    setTherapyId,
    workListChanged,
    change: changeActionCreator,
    editTasks,
    resolveAudit,
    fetchDosesRegimenReasons,
  }),
)(reduxForm()(EditPatientTherapy));
