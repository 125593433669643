// eslint-disable-next-line
import debounce from 'debounce-promise';
import moment from 'moment';
import { DEBOUNCE_IN_MS } from 'constants/config';
import {
  getMedicalInsuranceHeader,
  getPharmacyInsuranceHeader,
} from 'services/utils/financials-service';
import { convertMapToList, convertListToMap } from 'reducers/helper-reducer';
import { PRESCRIBING_CLINIC_METHOD, THERAPY_ADMIN_STATUS_DISCONTINUED } from 'constants/index';
import { TherapyTypes, isDispensingUndecided, isClinicalSupportUndecided } from 'constants/enums';
import { isEmpty } from 'lodash';
import HTTP from '../http';
import {
  THERAPY_ADMINISTRATION_ARCHIVED,
  THERAPY_ADMIN_STATUS_PRE_THERAPY,
  THERAPY_ADMIN_STATUS_ON_THERAPY,
  THERAPY_ADMIN_STATUS_ON_HOLD,
  THERAPY_ADMIN_STATUS_NO_GO,
  taskAbbreviationMap,
  OPT_IN,
  OPT_OUT,
} from '../../constants';
import { specialtyTypeNames, TherapyLookupStatusReasons } from '../../constants/lists';
import { taskTypeToPropName } from './task-service';
import { convertLinkWithDirection } from './reducer-service';

// Check if gpi10 is changed
export const checkGpi10Changed = (gpi1, gpi2) => {
  if (!gpi1 || !gpi2) {
    return false;
  }
  return `${gpi1}`.slice(0, 10) !== `${gpi2}`.slice(0, 10);
};

// eslint-disable-next-line
export const getTherapyStatuses = therapyStatuses =>
  therapyStatuses.reduce((result, therapyStatus) => {
    const { status } = therapyStatus;
    if (!result.includes(status)) {
      result.push(status);
    }
    return result;
  }, []);

// eslint-disable-next-line
export const getTherapyStatusId = (therapyStatuses, status, reason) =>
  therapyStatuses.find(therapyStatus => {
    if (reason) {
      return therapyStatus.status === status && therapyStatus.reason === reason;
    }
    return therapyStatus.status === status;
  })?.id;

// eslint-disable-next-line
export const getTherapyStatusById = (therapyStatuses, statusId) => {
  if (therapyStatuses) {
    return therapyStatuses.find(therapyStatus => therapyStatus.id === statusId);
  }
  return null;
};

export const getTherapyReasonById = (therapyStatuses, statusId) => {
  const status = getTherapyStatusById(therapyStatuses, statusId);
  if (status) {
    return status.reason;
  }
  return null;
};

// eslint-disable-next-line no-confusing-arrow
export const toCheckboxFormatTherapies = arr =>
  arr
    ? getTherapyStatuses(arr).map((s, i) => ({ text: s, id: Math.pow(2, i) })) // eslint-disable-line
    : [];

// eslint-disable-next-line no-confusing-arrow
export const toCheckboxFormatTasks = arr =>
  arr
    ? arr.map(s => ({
        text: s.name,
        id: Math.pow(2, s.id - 1), // eslint-disable-line
      }))
    : [];

export const allChecked = arr => 2 ** arr.length - 1; // eslint-disable-line

export const getReasonsByStatus = (statusArray, desiredStatus) => {
  const reasonsByStatus = statusArray //eslint-disable-line
    .filter(s => s.status === desiredStatus)
    .sort((a, b) => {
      if (a.reason === 'Other') {
        return 1;
      }
      if (a.reason < b.reason) {
        return -1;
      }
      if (a.reason > b.reason) {
        return 1;
      }
      return 0;
    })
    .map(s => ({ label: s.reason, value: s.reason, id: s.id }));
  return desiredStatus === THERAPY_ADMIN_STATUS_DISCONTINUED
    ? reasonsByStatus.filter(it => it.id !== TherapyLookupStatusReasons.patientDeceased)
    : reasonsByStatus;
};

export const getInitialStatusCodeByStatus = (statusArray, desiredStatus) =>
  statusArray //eslint-disable-line
    .filter(s => s.status === desiredStatus)[0].id;

export const statusArrayForEdit = statusArray =>
  statusArray.map(status => ({
    label: status,
    value: status,
  }));

export const statusArrayForDispensingEdit = (statusArray, current_status) => {
  const decisionMadeOpts = [OPT_IN, OPT_OUT];
  return statusArray.map(status => ({
    label: status,
    value: status,
    disabled:
      decisionMadeOpts.filter(t => t.toLowerCase() === current_status.toLowerCase()).length &&
      !decisionMadeOpts.map(t => t.toLowerCase()).includes(status.toLowerCase()),
  }));
};

export const statusArrayWithIds = statusArray =>
  statusArray.map((status, index) => ({
    label: status,
    value: index + 1,
  }));

// eslint-disable-next-line no-confusing-arrow
export const handlePrescribingClinicAddId = clinicId =>
  clinicId === 'useNull' ? null : clinicId.npi;

export const handlePrescribingClinic = (methodId, managingClinic, clinicValues, isSpecialty) => {
  if (!isSpecialty && clinicValues) {
    return {
      id: clinicValues.id,
      name: clinicValues.label,
      npi: clinicValues.npi,
    };
  }
  switch (methodId) {
    case PRESCRIBING_CLINIC_METHOD.USE_LOOKUP:
      if (clinicValues) {
        return {
          id: clinicValues.id,
          name: clinicValues.label,
          npi: clinicValues.npi,
        };
      }
      break;
    case PRESCRIBING_CLINIC_METHOD.USE_SAME:
      if (managingClinic) {
        return {
          id: managingClinic.value,
          name: managingClinic.label,
          npi: managingClinic.npi,
        };
      }
      break;
    case PRESCRIBING_CLINIC_METHOD.USE_NULL:
      return {};
    default:
      return undefined;
  }
  return undefined;
};

// eslint-disable-next-line no-confusing-arrow
export const handlePrescribingClinicAddName = clinicId =>
  clinicId === 'useNull' ? null : clinicId.label;

export const filterObjectForCompare = (filterArr, status) => filterArr.find(x => x.text === status);

// eslint-disable-next-line
export const filterObjectForMultiCompare = (filterArr, statusA, statusB) =>
  filterArr.find(x => x.text === statusA || x.text === statusB);

export const fetchDiagnosisCodesNoDebounce = searchString =>
  HTTP.get(`/lookup/icd10?q=${searchString}`, {});

export const fetchDiagnosisCodes = debounce(fetchDiagnosisCodesNoDebounce, DEBOUNCE_IN_MS);

export const fetchPhysicians = debounce(searchString => {
  const API = `/providers/physicians?q=${searchString}`;
  return HTTP.get(API, {});
}, DEBOUNCE_IN_MS);

export const fetchPharmaciesNoDebounce = searchString => {
  if (searchString && searchString.length >= 2) {
    const API = `/providers/pharmacies?q=${searchString}`;
    return HTTP.get(API, {});
  }
  return [];
};

export const fetchExternalPharmaciesNoDebounce = searchString => {
  if (searchString && searchString.length >= 2) {
    const API = `/providers/external_pharmacies?q=${searchString}`;
    return HTTP.get(API, {});
  }
  return [];
};

export const fetchInternalPharmaciesNoDebounce = () => {
  const API = '/providers/internal_pharmacies';
  return HTTP.get(API, {});
};

export const fetchTransferPharmaciesNoDebounce = searchString => {
  if (searchString && searchString.length > 2) {
    const API = `/providers/transfer_pharmacies?q=${searchString}`;
    return HTTP.get(API, {});
  }
  return [];
};

export const fetchPharmacies = debounce(fetchPharmaciesNoDebounce, DEBOUNCE_IN_MS);
export const fetchTransferPharmacies = debounce(fetchTransferPharmaciesNoDebounce, DEBOUNCE_IN_MS);
export const fetchExternalPharmacies = debounce(fetchExternalPharmaciesNoDebounce, DEBOUNCE_IN_MS);
export const fetchInternalPharmacies = debounce(fetchInternalPharmaciesNoDebounce, DEBOUNCE_IN_MS);

// eslint-disable-next-line no-confusing-arrow
const sanitizeFilterValue = filterValue =>
  filterValue ? encodeURIComponent(filterValue.trim()) : '';

export const fetchSearch = debounce(filters => {
  if (filters) {
    const taxonomy = sanitizeFilterValue(filters.taxonomy);
    const organization = sanitizeFilterValue(filters.organization);
    const npi = sanitizeFilterValue(filters.npi);
    const state = sanitizeFilterValue(filters.state);
    const city = sanitizeFilterValue(filters.city);
    const postal = sanitizeFilterValue(filters.postal);
    const phone = sanitizeFilterValue(filters.phone_number);
    const fax = sanitizeFilterValue(filters.fax_number);
    // eslint-disable-next-line max-len
    const API = `/providers?taxonomy=${taxonomy}&organization=${organization}&npi=${npi}&state=${state}&city=${city}&postal=${postal}&phone=${phone}&fax=${fax}`;
    return HTTP.get(API, {});
  }
  return [];
}, DEBOUNCE_IN_MS);

export const fetchClinics = debounce((searchString, internal = false, internalId = null) => {
  if ((searchString && searchString.length >= 2) || internalId) {
    const API = internalId
      ? `/providers/clinics?internalId=${internalId}`
      : `/providers/clinics?q=${searchString}&internal=${internal}`;
    return HTTP.get(API, {});
  }
  return [];
}, DEBOUNCE_IN_MS);

export const fetchServiceGroups = debounce(() => {
  const API = '/service-groups';
  return HTTP.get(API, {});
}, DEBOUNCE_IN_MS);

export const fetchGpisNoDebounce = (searchString, all = false, obsoleteIncluded = false) => {
  const API = `/lookup/gpi?q=${searchString}&all=${all}&obsoleteIncluded=${obsoleteIncluded}`;
  return HTTP.get(API, {});
};

export const fetchGpis = debounce(fetchGpisNoDebounce, DEBOUNCE_IN_MS);

export const getInitialPayerLockout = (
  payerLockoutVals,
  type,
  insuranceArr,
  textFormat = false,
) => {
  if (insuranceArr) {
    if (payerLockoutVals) {
      const valsArr = payerLockoutVals.split(',');
      const descriptionArr = insuranceArr.filter(insurance => valsArr.includes(`${insurance.id}`));
      if (type === 'medical') {
        if (textFormat) {
          return descriptionArr.map(description => getMedicalInsuranceHeader(description));
        }
        return descriptionArr.map(description => ({
          value: description.id,
          label: getMedicalInsuranceHeader(description),
        }));
      }
      if (type === 'pbm') {
        if (textFormat) {
          return descriptionArr.map(description => getPharmacyInsuranceHeader(description));
        }
        return descriptionArr.map(description => ({
          value: description.id,
          label: getPharmacyInsuranceHeader(description),
        }));
      }
    } else {
      if (type === 'medical') {
        if (insuranceArr.length === 1) {
          if (textFormat) {
            return getMedicalInsuranceHeader(insuranceArr[0]);
          }
          return [
            {
              value: insuranceArr[0].id,
              label: getMedicalInsuranceHeader(insuranceArr[0]),
            },
          ];
        }
      }
      if (type === 'pbm') {
        if (insuranceArr.length === 1) {
          if (textFormat) {
            return getPharmacyInsuranceHeader(insuranceArr[0]);
          }
          return [
            {
              value: insuranceArr[0].id,
              label: getPharmacyInsuranceHeader(insuranceArr[0]),
            },
          ];
        }
      }
    }
  }
  return null;
};

export const getSubmittingPayerLockout = insuranceArr => {
  if (insuranceArr && insuranceArr.length > 0) {
    const insuranceIds = insuranceArr.map(insurance => insurance.value);
    return insuranceIds.join();
  }
  return null;
};

export const filterTherapies = (therapies, status) => therapies.filter(t => t.status === status);

export const isTherapyComplete = (therapy, therapyStatuses) => {
  // If patient discharged from clinic, treat therapy as complete
  // https://trellisrx.atlassian.net/browse/ARBOR-3623 - See comments
  if (therapy.managing_clinic_id && therapy.managing_clinic_discharge_dt) return true;

  if (!Array.isArray(therapyStatuses)) {
    return false;
  }

  const preTherapyStatuses = filterTherapies(therapyStatuses, THERAPY_ADMIN_STATUS_PRE_THERAPY);
  const onTherapyStatuses = filterTherapies(therapyStatuses, THERAPY_ADMIN_STATUS_ON_THERAPY);
  const onHoldStatuses = filterTherapies(therapyStatuses, THERAPY_ADMIN_STATUS_ON_HOLD);
  const initiatedStatuses = onTherapyStatuses.concat(onHoldStatuses);

  let result =
    !preTherapyStatuses.some(t => t.id === therapy.administration_status_id) && // eslint-disable-line
    (!initiatedStatuses.some(t => t.id === therapy.administration_status_id) ||
      ((!isClinicalSupportUndecided(therapy.clinical_support_status_id) ||
        therapy.therapy_type === TherapyTypes.NonSpecialty) &&
        !isDispensingUndecided(therapy.dispensing_status_id)));

  if (result && onHoldStatuses.some(t => t.id === therapy.administration_status_id)) {
    result = false;
  }
  return result;
};

export const isTherapyArchived = therapy =>
  THERAPY_ADMINISTRATION_ARCHIVED.includes(therapy.administration_status_id); // eslint-disable-line

export const getStatusCategoryId = (therapy, statuses) =>
  statuses.find(s => s.id === therapy.administration_status_id).category_id; // eslint-disable-line

export const isSpecialtyByTheraphyId = (therapies, therapyId) =>
  (therapies[therapyId] || { is_specialty: true }).is_specialty;

export const getTherapiesIncludedInOrderId = (therapies, orderId) => {
  const result = [];
  therapies.forEach(therapy => {
    therapy.tasks.fill_coordination_list.forEach(fc => {
      if (fc.order_id === orderId && result.indexOf(therapy) === -1) {
        result.push(therapy);
      }
    });
  });
  return result;
};

export const getStateTherapiesIncludedInOrderId = (therapies, orderId) => {
  const result = [];
  Object.keys(therapies).forEach(therapyId => {
    const therapy = therapies[therapyId];
    therapy.fillDeliveryConfirmationList.forEach(fc => {
      if (fc.order_id === orderId && result.indexOf(therapy) === -1) {
        result.push(therapy);
      }
    });
  });
  return result;
};

export const getTasksOfATherapy = (taskList, therapyId, excludeTherapyIds = false) =>
  taskList.filter(task => {
    if (task.therapy_id && Number(task.therapy_id) === Number(therapyId)) {
      return true;
    }
    if (task.therapy_ids && !excludeTherapyIds) {
      const ids = task.therapy_ids.split(',');
      if (ids.includes(String(therapyId))) {
        return true;
      }
    }
    return false;
  });

export const combineTherapyAndTasks = (therapy, taskMap) => {
  const taskList = convertMapToList(taskMap);
  const convertedTherapy = Object.keys(taskTypeToPropName).reduce((acc, type) => {
    acc[taskTypeToPropName[type]] = getTasksOfATherapy(taskList, therapy.id).filter(
      task => task.taskType === type,
    );
    return acc;
  }, therapy);
  return convertedTherapy;
};

export const combineTherapyTasksAndLinks = (therapy, taskMap, linkMap) => {
  const taskList = convertMapToList(taskMap);
  const links = convertMapToList(linkMap)
    .map(link => ({
      ...link,
      meta: JSON.parse(link.meta),
    }))
    .filter(link => link.meta.therapy_id === therapy.id);
  const convertedTherapy = Object.keys(taskTypeToPropName).reduce(
    (acc, type) => {
      acc[taskTypeToPropName[type]] = getTasksOfATherapy(taskList, therapy.id)
        .filter(task => task.taskType === type)
        .map(task => ({
          ...task,
          links: links
            .map(link => {
              if (
                link.resource_one_id === task.id &&
                taskAbbreviationMap[link.resource_one_type] === task.taskType
              ) {
                return convertLinkWithDirection(link, false);
              }
              if (
                link.resource_two_id === task.id &&
                taskAbbreviationMap[link.resource_two_type] === task.taskType
              ) {
                return convertLinkWithDirection(link, true);
              }
              return null;
            })
            .filter(link => link),
        }));
      return acc;
    },
    { ...therapy },
  );
  convertedTherapy.links = [];

  return convertedTherapy;
};

export const combineTherapiesAndTasks = (therapyMap, taskMap) => {
  const therapyList = convertMapToList(therapyMap);
  const convertedTherapyList = therapyList.map(therapy => combineTherapyAndTasks(therapy, taskMap));
  return convertListToMap(convertedTherapyList, 'id');
};

export const getCompletedStatus = () => [4];

export const isStatusCompletedTherapy = (statuses, adminsStatusId) => {
  const completedStatus = getCompletedStatus();
  const status = statuses.find(x => x.id === adminsStatusId);
  return status && completedStatus.includes(status.category_id);
};

export const specialtyTypeFormatter = (therapy, columnName, value) => {
  if (columnName === 'specialty_type' && specialtyTypeNames[value]) {
    return specialtyTypeNames[value];
  }
  return value;
};

export const getAdminStatusesByCurrentStatus = (
  therapyCurrentStatus,
  adminStatuses,
  selectedUnknownStartDate,
) => {
  // Always include current status
  let statusesToShow = [therapyCurrentStatus];
  switch (therapyCurrentStatus) {
    case THERAPY_ADMIN_STATUS_PRE_THERAPY:
      statusesToShow.push(THERAPY_ADMIN_STATUS_ON_THERAPY, THERAPY_ADMIN_STATUS_NO_GO);
      break;
    case THERAPY_ADMIN_STATUS_ON_THERAPY:
      if (selectedUnknownStartDate) {
        statusesToShow.push(THERAPY_ADMIN_STATUS_ON_HOLD, THERAPY_ADMIN_STATUS_DISCONTINUED);
      } else {
        statusesToShow = adminStatuses.map(({ label }) => label);
      }
      break;
    case THERAPY_ADMIN_STATUS_ON_HOLD:
      statusesToShow.push(THERAPY_ADMIN_STATUS_ON_THERAPY, THERAPY_ADMIN_STATUS_DISCONTINUED);
      break;
    default:
      statusesToShow = adminStatuses.map(({ label }) => label);
      break;
  }

  return adminStatuses.filter(s => statusesToShow.includes(s.label));
};

export const isStartDateBeforeReferralDate = (startDate, referralDate) =>
  moment(startDate).isBefore(moment(referralDate));

export const formatTherapyNameLong = (
  drugName,
  dosageForm,
  strength,
  strengthUnitOfMeasure,
  dosageFormDescription,
  ndc,
) => {
  const therapyNameParts = [
    drugName,
    dosageForm,
    strength,
    strengthUnitOfMeasure,
    dosageFormDescription ? `| ${dosageFormDescription}` : '',
    ndc ? `| NDC ${ndc}` : '',
  ];
  const therapyNamePartsNotEmpty = therapyNameParts.filter(part => !isEmpty(part));
  return therapyNamePartsNotEmpty.join(' ');
};

export const isAnySpecialtyTherapy = therapy =>
  [
    TherapyTypes.Specialty,
    TherapyTypes.SpecialtyLite,
    TherapyTypes.SpecialtyProviderAdministered,
  ].includes(therapy.therapy_type);
