export enum FieldTypes {
  YesNo,
  TherapeuticClass,
  SpecialityType,
  DatePicker,
  ActiveInactive,
  Licensed,
  ActiveDeprecated,
  TherapeuticArea,
  HighLow,
  LockedUnlockedForm,
}
