import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { editIncome } from 'actions/action-financials';
import { financialsIsActive } from 'services/utils/common-service';
import AccordionHeader from 'components/form/header/accordion-header';
import { accordianStyles } from 'components/accordian/accordian-styles';
import { combineStyles } from 'services/utils/styles-service';
import { convertMapToList } from 'reducers/helper-reducer';

import FinancialAssistanceCopayCard from './copay-card';
import PrescriptionAssistanceProgram from './prescription-assistance-program';
import FoundationList from './foundation-list';

import { styles } from '../financials-styles';

import { FinancialAssistanceTypes } from '../../../../constants/enums';

const getActiveInactiveLists = (items, desiredStatuses) => {
  const activeItems = items.filter(f => {
    let found = desiredStatuses.some(e => e.id === f.financial_assistance_type) && f.status === 1;
    if (f.expiration_date !== null) {
      found = found && new Date(f.expiration_date) > new Date();
    }
    return found;
  });

  const inactiveItems = items.filter(f => {
    let found = desiredStatuses.some(e => e.id === f.financial_assistance_type);
    if (f.expiration_date !== null) {
      found = found && (f.status === 0 || new Date(f.expiration_date) <= new Date());
    } else {
      found = found && f.status === 0;
    }
    return found;
  });
  return [activeItems, inactiveItems];
};

class FinancialAssistanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDetail: false,
    };

    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand() {
    const { displayDetail } = this.state;
    this.setState({
      displayDetail: !displayDetail,
    });
  }

  renderInactivePanel(items, type) {
    const { classes, copayCardStatuses, mpapStatuses } = this.props;

    const renderItems = () => {
      switch (type) {
        case FinancialAssistanceTypes.CopayCard:
          return items.map(t => (
            <FinancialAssistanceCopayCard
              key={t.id}
              classes={classes}
              financialAssistance={t}
              copayCardStatuses={copayCardStatuses}
              active={false}
            />
          ));
        case FinancialAssistanceTypes.Mpap:
        case FinancialAssistanceTypes.Pap:
          return items.map(t => (
            <PrescriptionAssistanceProgram
              key={t.id}
              classes={classes}
              financialAssistance={t}
              papStatuses={mpapStatuses}
              active={false}
            />
          ));
        case FinancialAssistanceTypes.Foundation:
        case FinancialAssistanceTypes.InternalFoundation:
        case FinancialAssistanceTypes.ExternalFoundation:
          return items.map(t => (
            <FoundationList key={t.id} classes={classes} financialAssistance={t} active={false} />
          ));
        default:
          return null;
      }
    };

    return (
      <Accordion className={classes.inactivePanel} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
          className={classes.inativeHeader}
        >
          <AccordionHeader
            name={`Inactive ${type} (${items.length})`}
            hideHistory
            hideEdit
            smallHeader
            inactiveView
          />
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.compactPanel,
          }}
        >
          <Grid item xs={12}>
            {renderItems()}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    const {
      classes,
      financials,
      copayCardStatuses,
      mpapStatuses,
      foundationStatuses,
      internalFoundationStatuses,
      externalFoundationStatuses,
    } = this.props;

    const financialAssistances = convertMapToList(financials.financial_assistances.data);

    const [activeCopayCards, inactiveCopayCards] = getActiveInactiveLists(
      financialAssistances,
      copayCardStatuses,
    );

    const [activePaps, inactivePaps] = getActiveInactiveLists(financialAssistances, mpapStatuses);

    const [activeFoundations, inactiveFoundations] = getActiveInactiveLists(financialAssistances, [
      ...foundationStatuses,
      ...internalFoundationStatuses,
      ...externalFoundationStatuses,
    ]);

    const inactiveOldFoundations = inactiveFoundations.filter(
      ({ financial_assistance_type_category: faType }) =>
        faType === FinancialAssistanceTypes.Foundation,
    );
    const inactiveInternalFoundations = inactiveFoundations.filter(
      ({ financial_assistance_type_category: faType }) =>
        faType === FinancialAssistanceTypes.InternalFoundation,
    );
    const inactiveExternalFoundations = inactiveFoundations.filter(
      ({ financial_assistance_type_category: faType }) =>
        faType === FinancialAssistanceTypes.ExternalFoundation,
    );

    return (
      <Grid item xs={12} className={classes.financialGroup}>
        <Accordion className={classes.accordianPanel} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              expandIconWrapper: classes.expandIcon,
            }}
          >
            <AccordionHeader
              // eslint-disable-next-line max-len
              name={`Financial Assistances (${
                financialAssistances.filter(financialsIsActive).length
              })`}
              hideHistory
              hideEdit
              smallHeader
            />
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.compactPanel,
            }}
          >
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  {activeCopayCards.map(t => (
                    <FinancialAssistanceCopayCard
                      key={t.id}
                      classes={classes}
                      financialAssistance={t}
                      copayCardStatuses={copayCardStatuses}
                      active
                    />
                  ))}
                </Grid>
                <Grid item xs={12}>
                  {activePaps.map(t => (
                    <PrescriptionAssistanceProgram
                      key={t.id}
                      classes={classes}
                      financialAssistance={t}
                      papStatuses={mpapStatuses}
                      active
                    />
                  ))}
                </Grid>
                <Grid item xs={12}>
                  {activeFoundations.map(t => (
                    <FoundationList key={t.id} classes={classes} financialAssistance={t} active />
                  ))}
                </Grid>
              </Grid>
              <Grid container>
                {inactiveCopayCards.length > 0 && (
                  <Grid item xs={12}>
                    {this.renderInactivePanel(
                      inactiveCopayCards,
                      FinancialAssistanceTypes.CopayCard,
                    )}
                  </Grid>
                )}
                {inactivePaps.length > 0 && (
                  <Grid item xs={12}>
                    {this.renderInactivePanel(inactivePaps, FinancialAssistanceTypes.Mpap)}
                  </Grid>
                )}
                {inactiveOldFoundations.length > 0 && (
                  <Grid item xs={12}>
                    {this.renderInactivePanel(
                      inactiveOldFoundations,
                      FinancialAssistanceTypes.Foundation,
                    )}
                  </Grid>
                )}
                {inactiveInternalFoundations.length > 0 && (
                  <Grid item xs={12}>
                    {this.renderInactivePanel(
                      inactiveInternalFoundations,
                      FinancialAssistanceTypes.InternalFoundation,
                    )}
                  </Grid>
                )}
                {inactiveExternalFoundations.length > 0 && (
                  <Grid item xs={12}>
                    {this.renderInactivePanel(
                      inactiveExternalFoundations,
                      FinancialAssistanceTypes.ExternalFoundation,
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editIncome,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const { financials } = state;

  const copayCardStatuses = financials?.financial_assistance_category_statuses.filter(
    f => f.category === FinancialAssistanceTypes.CopayCard,
  );

  const mpapStatuses = financials?.financial_assistance_category_statuses.filter(
    f => f.category === FinancialAssistanceTypes.Mpap,
  );

  // Old PAP FA types will not longer show

  const foundationStatuses = financials?.financial_assistance_category_statuses.filter(
    f => f.category === FinancialAssistanceTypes.Foundation,
  );

  const internalFoundationStatuses = financials?.financial_assistance_category_statuses.filter(
    f => f.category === FinancialAssistanceTypes.InternalFoundation,
  );

  const externalFoundationStatuses = financials?.financial_assistance_category_statuses.filter(
    f => f.category === FinancialAssistanceTypes.ExternalFoundation,
  );

  return {
    financials,
    copayCardStatuses,
    mpapStatuses,
    foundationStatuses,
    internalFoundationStatuses,
    externalFoundationStatuses,
  };
}

const combinedStyles = combineStyles(styles, accordianStyles);

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(FinancialAssistanceDetail);
