import { ITask } from 'interfaces/redux/ITasks';
import React from 'react';
import { PaymentMethodResponse } from 'containers/add-payment-method/types';
import { UseLabelScanState } from './use-shipping-scan';

export interface ITherapyNames {
  id: number;
  name: string;
  therapy_type: number;
}

export interface IContentsOfPackage {
  currentMedicationInformation?: ITherapyNames[];
  currentAncillarySupply?: any;
  orderId?: number;
  scanProps?: UseLabelScanState;
  isPickup?: boolean;
}
export interface IShippingVerification extends UseLabelScanState {
  orderContainsColdChain?: boolean;
}

export interface IPrintLabelButton {
  orderId: string;
  currentDeliveryInformation?: ICurrentDeliveryInformation;
  currentPrintInformation?: IOrderLabelResponse;
  currentFdcInformation?: IFdcInformation;
  statusId?: number;
  setPrintLabelClicked?: React.Dispatch<boolean>;
  setFirstTimePrinting?: React.Dispatch<boolean>;
  setShowManualVoidMessage?: React.Dispatch<boolean>;
  setShowManualPrintLabelMessage?: React.Dispatch<boolean>;
  setShowManualReprintLabelMessage?: React.Dispatch<boolean>;
  printDetails?: string;
  data?: ITask;
  dispatch?: React.Dispatch<any>;
  setStatus?: React.Dispatch<string>;
  deliveryMethod?: number;
  setCurrentStatus?: React.Dispatch<React.SetStateAction<number | undefined>>;
  disabled?: boolean;
  setFdcOrderDetails?: Function;
}

export interface IPickUpButton {
  orderId: string;
  currentDeliveryInformation?: ICurrentDeliveryInformation;
  currentPrintInformation?: IOrderLabelResponse;
  currentFdcInformation?: IFdcInformation;
  statusId?: number;
  setReadyForPickupClicked?: React.Dispatch<boolean>;
  setFirstTimePrinting?: React.Dispatch<boolean>;
  printDetails?: string;
  data?: ITask;
  dispatch?: React.Dispatch<any>;
  setStatus?: React.Dispatch<string>;
  deliveryMethod?: number;
  setCurrentStatus?: React.Dispatch<React.SetStateAction<number | undefined>>;
  disabled?: boolean;
}
export interface ICurrentPaymentInformation {
  copayAmount?: string;
  paymentMethodOnFile?: boolean;
  paymentMethods?: PaymentMethodResponse[];
  orderNotes?: string;
}

export interface IFdcInformation {
  id: number;
  statusId: number;
  therapyId: number;
  deliveryDate: Date;
  shippedDate: Date;
}

export interface IPaymentInformation {
  currentPaymentInformation?: ICurrentPaymentInformation;
}

export interface ICurrentDeliveryInformation {
  deliveryMethod?: number;
  shippingMethod?: string;
  shipDate?: Date;
  oldShipDate?: Date;
  deliveryDt?: Date;
  shippingAddress?: string;
  email?: string;
  coldChain?: boolean;
  signature?: boolean;
  upsPackagingType?: number;
  specialInstructions?: string;
  phoneNumber?: string;
  orderTypeAddress?: string;
  dispensingPharmacyNpi?: string;
  dispensingPharmacyName?: string;
  courierType?: number;
  oldDeliveryDt?: Date;
  oldShippingAddress?: string;
  patientAdvisedPickup?: number;
  toMd?: boolean;
  toPatient?: boolean;
  patientId?: number;
  ccPackageCount?: number;
  nrPackageCount?: number;
}

export interface IDeliveryInformation {
  currentDeliveryInformation?: ICurrentDeliveryInformation;
  currentPrintInformation?: IOrderLabelResponse;
  currentFdcInformation?: IFdcInformation;
  scanProps?: UseLabelScanState;
  isMissingCourierConfiguration?: boolean;
}
export interface IOrderLabelResponse {
  order_id: number;
  lab_logistics_order_id: number;
  associated_courier_order_id: number;
  nuvizz_courier_order_id: number;
  delivery_dt: Date;
  pod: string;
  pod_comment: string;
  tracking_number: string;
  delivery_file_name: string;
  signature_document_id: number;
  delivery_code: string;
  left_at: string;
  received_by: string;
  created_dt: Date;
  created_by: number;
  updated_dt: Date;
  updated_by: number;
}
export interface IComponentStateItem {
  therapiesNames: ITherapyNames[];
  ancillarySupplies: {};
  deliveryInformation: ICurrentDeliveryInformation;
  printInformation: IOrderLabelResponse;
  paymentInformation?: ICurrentPaymentInformation;
  fdcInformation?: IFdcInformation;
  error?: string;
}

export interface IFetchLabel {
  labelBase64: string;
  printInformation: IOrderLabelResponse;
  fileType: string;
  labelStatus?: number;
}

export type ComponentState = IComponentStateItem;

export interface IValuesEdited {
  deliveryInformation?: any;
}

/** Shipping Verification & Label Scanning */
export interface IShippingVerificationScanHistoryPayload {
  updated_dt: string;
  field_name: string;
  status_message: string;
  code_value: string;
}
export interface IShippingVerificationScanHistoryItem {
  username: string;
  updated_dt: string;
  field_name: string;
  status_message: string;
  code_value: string;
}
export interface IShippingVerificationScanHistoryResponse {
  order_id: number;
  history: IShippingVerificationScanHistoryItem[];
}

export enum ShippingLabelScanStatus {
  Pending = 1,
  Complete = 2,
  Incorrect = 3,
}
export enum PackageLabelScanStatus {
  Pending = 1,
  Complete = 2,
}
