import React, { Component } from 'react';
import { Grid, Modal, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import AccordionHeader from 'components/form/header/accordion-header';
import History from 'containers/common/history';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withStyles from '@mui/styles/withStyles';
import { accordianStyles } from 'components/accordian/accordian-styles';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { numberToDollars } from 'services/utils/formatting';
import { connect } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import FoundationDetail from '../../../tasks/fa/foundation/foundation-detail';
import { styles } from '../financials-styles';

class FinancialAssistanceFoundationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsShown: props.financialAssistance.status === 1,
      editHistoryModalOpen: false,
    };
    this.toggleExpand = this.toggleExpand.bind(this);
    this.handleEditHistoryModal = this.handleEditHistoryModal.bind(this);
  }

  toggleExpand() {
    const { detailsShown } = this.state;

    this.setState({
      detailsShown: !detailsShown,
    });
  }

  handleEditHistoryModal(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      editHistoryModalOpen: !prevState.editHistoryModalOpen,
    }));
  }

  render() {
    const { detailsShown, editHistoryModalOpen } = this.state;
    const { classes, financialAssistance, therapies, active } = this.props;

    const accordionTitle = `Foundation - ${financialAssistance.financial_assistance_type_category}`
      .concat(
        financialAssistance.therapy_id && therapies[financialAssistance.therapy_id]
          ? ` - ${therapies[financialAssistance.therapy_id].drug_name}`
          : '',
      )
      .concat(
        financialAssistance.expiration_date
          ? ` - Expiration Date ${convertToArborDate(
              financialAssistance.expiration_date,
              true,
            ).getCustomerDate(true)}`
          : '',
      )
      .concat(
        financialAssistance.remaining_balance
          ? ` - Remaining Balance ${numberToDollars(financialAssistance.remaining_balance)}`
          : '',
      );

    const panelClassNames = classNames({
      [classes.stretchedPbmPanel]: !active,
      [classes.inactivePanel]: !active,
      [classes.inactiveHeader]: !active,
    });
    return (
      <div>
        <Accordion expanded={detailsShown} elevation={0} className={panelClassNames}>
          <AccordionSummary
            classes={{
              expandIconWrapper: classes.expandIcon,
            }}
            onClick={this.toggleExpand}
            expandIcon={<ExpandMoreIcon />}
            className={panelClassNames}
          >
            <AccordionHeader
              smallHeader
              name={accordionTitle}
              withHistory
              historyHandler={this.handleEditHistoryModal}
              hideEdit
              updatedDt={financialAssistance.updated}
              updatedBy={financialAssistance.updated_by}
              id={`financial-assistance-${financialAssistance.id}`}
            />
            <Modal open={editHistoryModalOpen} onClose={this.handleEditHistoryModal}>
              <div style={getModalStyle()} className={classes.Modal}>
                <History
                  // eslint-disable-next-line max-len
                  url={`/patients/${financialAssistance.patient_id}/financials/patient-financial-assistance/${financialAssistance.id}`}
                />
              </div>
            </Modal>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <FoundationDetail
                  financialAssistance={financialAssistance}
                  subCategory={financialAssistance.financial_assistance_type_sub_category}
                  type={financialAssistance.foundation_name}
                  spendDown={financialAssistance.spend_down}
                  group={financialAssistance.group_id}
                  phoneNumber={financialAssistance.phone_number}
                  expDate={convertToArborDate(
                    financialAssistance.expiration_date,
                    true,
                  ).getCustomerDate(true)}
                  foundationProgram={financialAssistance.foundation_program}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

const combinedStyles = combineStyles(styles, accordianStyles);

function mapStateToProps(state) {
  const { therapies } = state;
  return {
    therapies: therapies.data,
  };
}

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps),
)(FinancialAssistanceFoundationList);
