import React from 'react';
import Gtd from '../../../containers/application-manager/gtd/index';
import CtdView from '../../../containers/application-manager/ctd/view/index';
import CtdManage from '../../../containers/application-manager/ctd/manage/index';
import Ar from '../../../containers/application-manager/ar/index';
import TtcView from '../../../containers/application-manager/ttc/view/index';
import { Users } from '../../../containers/application-manager/users/index';
import CpmpData from '../../../containers/application-manager/cpmp/data/index';
import CpmpCsl from '../../../containers/application-manager/cpmp/csl/index';
import CpmpRsProtocol from '../../../containers/application-manager/cpmp/rs-protocol/index';
import CpmpClinicalItemProtocol from '../../../containers/application-manager/cpmp/clinical-item-protocol/index';
import CpmpIcd from '../../../containers/application-manager/cpmp/icd/index';
import CpmpGpi from '../../../containers/application-manager/cpmp/gpi/index';
import CpmpDiseaseStates from '../../../containers/application-manager/cpmp/disease-states/index';
import CpmpPayor from '../../../containers/application-manager/cpmp/payor/index';
import { IWindowFeatures } from '../../../interfaces/window/IWindowFeatures';
import { BulkSms } from '../../bulk-sms/bulk-sms';
import { UserPermissions } from '../../../interfaces/user/Permissions';
import RulesEngineManager from '../../../containers/application-manager/rules-engine';
import HolidayManager from '../../../containers/application-manager/holidays';

/**
 * routeSlug: used to define the path in the route
 * title: shown in the left side menu bar navigation
 * component: component to render when this one is selected
 */
export interface IMenuItem {
  routeSlug: string;
  title: string;
  hide?: () => boolean;
  component?: JSX.Element;
  featureFlag?: keyof IWindowFeatures;
  items?: IMenuItem[];
}

/**
 * If the subsection only has 1 item children won't be rendered
 */
export interface ISubsectionConfig {
  title: string;
  featureFlag?: keyof IWindowFeatures;
  permission?: UserPermissions;
  items: IMenuItem[];
  expanded?: boolean;
  singleOpt?: boolean;
}

/**
 * The key here for each of the items the slug used in the route path. So 'gtd' will be
 * /application-manager/gtd
 */
export const MenuStructure: Record<string, ISubsectionConfig> = {
  gtd: {
    title: 'Global Targeted Drugs',
    items: [
      {
        routeSlug: 'global-gtd',
        title: 'Manage Targeted Drugs',
        component: <Gtd />,
      },
    ],
  },
  ctd: {
    title: 'Customer Targeted Drugs',
    items: [
      {
        routeSlug: 'ctd-view',
        title: 'View Targeted Drugs',
        component: <CtdView />,
      },
      {
        routeSlug: 'ctd-manage',
        title: 'Manage Targeted Drugs',
        component: <CtdManage />,
      },
    ],
  },
  cpmp: {
    title: 'Clinical Patient Management Program',
    featureFlag: 'application_manager_dc',
    items: [
      {
        routeSlug: 'cpmp-disease-states',
        title: 'Disease States',
        component: <CpmpDiseaseStates />,
      },
      {
        routeSlug: 'cpmp-csl',
        title: 'Counseling (CSL)',
        component: <CpmpCsl />,
      },
      {
        routeSlug: 'cpmp-data-header',
        title: 'Data Collect (DC)',
        items: [
          {
            routeSlug: 'cpmp-data',
            title: 'DC Items',
            component: <CpmpData />,
          },
          {
            routeSlug: 'cpmp-icd',
            title: 'ICD-10 Protocol',
            component: <CpmpIcd />,
          },
          {
            routeSlug: 'cpmp-gpi',
            title: 'GPI-10 Protocol',
            component: <CpmpGpi />,
          },
        ],
      },
      {
        routeSlug: 'cpmp-cip',
        title: 'Clinical Item Protocol',
        component: <CpmpClinicalItemProtocol />,
      },
      {
        routeSlug: 'cpmp-rs',
        title: 'RS Protocols',
        component: <CpmpRsProtocol />,
      },
    ],
    singleOpt: true,
  },
  users: {
    title: 'User Management',
    items: [
      {
        routeSlug: 'manage',
        title: 'User Management',
        component: <Users />,
      },
    ],
  },
  payor: {
    title: 'Payor',
    items: [
      {
        routeSlug: 'payor',
        title: 'Payor',
        component: <CpmpPayor />,
      },
    ],
  },
  ttc: {
    title: 'Trellis Therapeutic Class',
    items: [
      {
        routeSlug: 'view',
        title: 'TTC',
        component: <TtcView />,
      },
    ],
  },
  ar: {
    title: 'Appointment Referral',
    items: [
      {
        routeSlug: 'ar',
        title: 'AR',
        component: <Ar />,
      },
    ],
  },
  bulkSms: {
    title: 'Bulk SMS',
    permission: UserPermissions.SmsBatch,
    items: [
      {
        routeSlug: 'bulk-sms',
        title: 'Bulk SMS',
        component: <BulkSms />,
      },
    ],
  },
  rulesEngine: {
    title: 'Workflow Manager',
    items: [
      {
        routeSlug: 'workflow-manager',
        title: 'Workflow Manager',
        component: <RulesEngineManager />,
      },
    ],
  },
  holiday: {
    title: 'Holidays Manager',
    items: [
      {
        routeSlug: 'holiday-manager',
        title: 'Holidays Manager',
        component: <HolidayManager />,
      },
    ],
  },
};
