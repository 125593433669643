import React, { FC } from 'react';
import cx from 'classnames';
import { Grid, GridOwnProps } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';

// Icons
import { APIcon, SMSIcon } from 'components/icons/icons';
import { RecordVoiceOverRounded } from '@mui/icons-material';
import { MedSyncStatusIcon } from 'containers/patient/med-sync/components/medSyncIcon';

import { styles } from './display-only-bar.style';
import DisplayOnlyItem from './display-only-item';

interface DisplayOnlyProps extends WithStyles<typeof styles>, Omit<GridOwnProps, 'classes'> {
  adherencePackaging?: boolean;
  needsInterpreter?: boolean;
  medSync?: boolean;
  enrolledInSMS?: boolean;
}

const DisplayOnlyBar: React.FC<DisplayOnlyProps> = ({
  classes,
  adherencePackaging,
  needsInterpreter,
  medSync,
  enrolledInSMS,
  ...props
}) => {
  return (
    <Grid {...props}>
      {adherencePackaging && (
        <DisplayOnlyItem
          title="Adherence Packaging"
          className={classes.adherencePackaging}
          Icon={APIcon}
        />
      )}
      {needsInterpreter && (
        <DisplayOnlyItem
          title="Needs Interpreter"
          className={classes.needsInterpreter}
          Icon={RecordVoiceOverRounded}
        />
      )}
      {medSync && (
        <DisplayOnlyItem
          title="Med Sync"
          className={classes.medSync}
          Icon={MedSyncStatusIcon as FC}
        />
      )}
      {enrolledInSMS && (
        <DisplayOnlyItem title="Enrolled in SMS" className={classes.enrolledInSMS} Icon={SMSIcon} />
      )}
    </Grid>
  );
};

export default withStyles(styles)(DisplayOnlyBar);
