import { CounselingMethod, TaskTypesEnum } from './enums';

export const DATA_TASKS_REQUEST = 'DATA_TASKS_REQUEST';
export const DATA_TASKS_SUCCESS = 'DATA_TASKS_SUCCESS';
export const DATA_TASKS_FAILURE = 'DATA_TASKS_FAILURE';
export const CLEAR_ARCHIVED_TASKS = 'CLEAR_ARCHIVED_TASKS';

export const DATA_THERAPIES_REQUEST = 'DATA_THERAPIES_REQUEST';
export const DATA_THERAPIES_SUCCESS = 'DATA_THERAPIES_SUCCESS';
export const DATA_THERAPIES_FAILURE = 'DATA_THERAPIES_FAILURE';

export const DATA_LINKS_REQUEST = 'DATA_LINKS_REQUEST';
export const DATA_LINKS_SUCCESS = 'DATA_LINKS_SUCCESS';
export const DATA_LINKS_FAILURE = 'DATA_LINKS_FAILURE';

export const DATA_TASK_COUNTS_FETCH = 'DATA_TASK_COUNTS_FETCH';
export const DATA_TASK_COUNTS_REQUEST = 'DATA_TASK_COUNTS_REQUEST';
export const DATA_TASK_COUNTS_SUCCESS = 'DATA_TASK_COUNTS_SUCCESS';
export const FETCH_SPECIFIC_QUEUE_TASK_COUNT_SUCCESS = 'FETCH_SPECIFIC_QUEUE_TASK_COUNT_SUCCESS';
export const DATA_TASK_COUNTS_FAILURE = 'DATA_TASK_COUNTS_FAILURE';

export const DATA_THERAPY_ARCHIVED_TASKS = 'DATA_THERAPY_ARCHIVED_TASKS';
export const DATA_PATIENT_ARCHIVED_TASKS = 'DATA_PATIENT_ARCHIVED_TASKS';

export const DATA_RELATED_TASKS = 'DATA_RELATED_TASKS';

export const DUR_INT_OUTCOME_MED_DISCONTINUED_ID = 9001;

export const SELECTED_PATIENT_ID = 'SELECTED_PATIENT_ID';
export const FETCH_BI_INFO = 'FETCH_BI_INFO';

export const FETCH_HIDDEN_QUESTIONS = 'FETCH_HIDDEN_QUESTIONS';
export const CLEAR_PATIENT_DATA = 'CLEAR_PATIENT_DATA';
export const SELECTED_THERAPY_ID = 'SELECTED_THERAPY_ID';
export const SELECTED_TASK_ID = 'SELECTED_TASK_ID';
export const SELECTED_TASK_TYPE = 'SELECTED_TASK_TYPE';
export const SET_SELECTED_TASK_KEYS = 'SET_SELECTED_TASK_KEYS';
export const SET_WAG_SELECTED_TASK_KEYS = 'SET_WAG_SELECTED_TASK_KEYS';
export const CLEAR_WAG_SELECTED_TASK_KEY = 'CLEAR_WAG_SELECTED_TASK_KEY';
export const TOGGLE_SELECTED_TASK_ROW_KEY = 'TOGGLE_SELECTED_TASK_ROW_KEY';
export const CLEAR_SELECTED_TASK_KEY = 'CLEAR_SELECTED_TASK_KEY';
export const EXCLUDE_SELECTED_TASK_KEY = 'EXCLUDE_SELECTED_TASK_KEY';
export const SELECT_TASK_KEY = 'SELECT_TASK_KEY';
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE';
export const SIDEBAR_TASK_TYPE = 'SIDEBAR_TASK_TYPE';
export const SET_SHOW_SET_SELECTED_TASKS = 'SET_SHOW_SET_SELECTED_TASKS';
export const SET_MERGE_INTERVENTION_TASKS = 'SET_MERGE_INTERVENTION_TASKS';

// Actions - Adherence
export const FETCH_PATIENT_ADHERENCE_DATA = 'FETCH_PATIENT_ADHERENCE_DATA';
export const CLEAR_PATIENT_ADHERENCE_DATA = 'CLEAR_PATIENT_ADHERENCE_DATA';
export const FETCH_PATIENT_ADHERENCE_DATA_SUCCESS = 'FETCH_PATIENT_ADHERENCE_DATA_SUCCESS';
export const FETCH_PATIENT_ADHERENCE_HISTORY = 'FETCH_PATIENT_ADHERENCE_HISTORY';
export const FETCH_PATIENT_ADHERENCE_HISTORY_SUCCESS = 'FETCH_PATIENT_ADHERENCE_HISTORY_SUCCESS';
export const FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS =
  'FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS';
export const FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS_SUCCESS =
  'FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS_SUCCESS';

// Actions - Filters
export const CLEAR_TASK_FILTERS_BY_KEYS = 'CLEAR_TASK_FILTERS_BY_KEYS';
export const UPDATE_TASK_FILTERS = 'UPDATE_TASK_FILTERS';
export const UPDATE_TASK_FILTER_FROM_DATE = 'UPDATE_TASK_FILTER_FROM_DATE';
export const UPDATE_TASK_FILTER_TO_DATE = 'UPDATE_TASK_FILTER_TO_DATE';
export const UPDATE_TASK_SERVICE_GROUP = 'UPDATE_TASK_SERVICE_GROUP';
export const UPDATE_TASK_FILTER_COMPLETED = 'UPDATE_TASK_FILTER_COMPLETED';
export const ADD_TASK_FILTER_SERVICE_GROUP = 'ADD_TASK_FILTER_SERVICE_GROUP';
export const DELETE_TASK_FILTER_SERVICE_GROUP = 'DELETE_TASK_FILTER_SERVICE_GROUP';
export const UPDATE_SCHEDULE_FILTER_FROM_TO_DATES = 'UPDATE_SCHEDULE_FILTER_FROM_TO_DATES';
export const UPDATE_SCHEDULE_FILTER_FROM_DATE = 'UPDATE_SCHEDULE_FILTER_FROM_DATE';
export const UPDATE_SCHEDULE_FILTER_TO_DATE = 'UPDATE_SCHEDULE_FILTER_TO_DATE';
export const UPDATE_SCHEDULE_SERVICE_GROUP = 'UPDATE_SCHEDULE_SERVICE_GROUP';
export const UPDATE_SCHEDULE_CLINICS = 'UPDATE_SCHEDULE_CLINICS';
export const UPDATE_SCHEDULE_FILTERS = 'UPDATE_SCHEDULE_FILTERS';
export const UPDATE_TASK_CLINICS = 'UPDATE_TASK_CLINICS';
export const UPDATE_REPORT_FILTER_FROM_DATE = 'UPDATE_REPORT_FILTER_FROM_DATE';
export const UPDATE_REPORT_FILTER_TO_DATE = 'UPDATE_REPORT_FILTER_TO_DATE';
export const UPDATE_REPORT_SERVICE_GROUP = 'UPDATE_REPORT_SERVICE_GROUP';
export const UPDATE_REPORT_CLINICS = 'UPDATE_REPORT_CLINICS';
export const UPDATE_REPORT_SIDEBAR = 'UPDATE_REPORT_SIDEBAR';
export const UPDATE_REPORT_FILTERS = 'UPDATE_REPORT_FILTERS';
export const LOAD_REPORTS = 'LOAD_REPORTS';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const FETCH_SHIPPING_HOLIDAYS = 'FETCH_SHIPPING_HOLIDAYS';
export const TOGGLE_PER_PROTOCOL_FILTER = 'TOGGLE_PER_PROTOCOL_FILTER';
export const FETCH_PATIENT_METRICS_SUCCESS = 'FETCH_PATIENT_METRICS_SUCCESS';

// Actions - Sidebar Filters
export const SIDEBAR_FILTERS_CLEAR = 'SIDEBAR_FILTERS_CLEAR';
export const SIDEBAR_FILTERS_CLEAR_IDS = 'SIDEBAR_FILTERS_CLEAR_IDS';
export const SIDEBAR_FILTERS_SET = 'SIDEBAR_FILTERS_SET';

// Actions - Task Table Date Filters
export const TASK_TABLE_DATE_FILTER_CHANGE = 'TASK_TABLE_DATE_FILTER_CHANGE';

// Actions - User Selections
export const PATIENT_CHANGE = 'PATIENT_CHANGE';
export const SELECT_PATIENT = 'SELECT_PATIENT';
export const SELECT_PATIENT_REQUEST = 'SELECT_PATIENT_REQUEST';
export const SELECT_PATIENT_SUCCESS = 'SELECT_PATIENT_SUCCESS';
export const SELECT_PATIENT_FAILURE = 'SELECT_PATIENT_FAILURE';
export const NEED_PATIENT_REFRESH = 'NEED_PATIENT_REFRESH';

export const PATIENT_CHECKLIST_FETCH = 'PATIENT_CHECKLIST_FETCH';
export const PATIENT_CHECKLIST_REQUEST = 'PATIENT_CHECKLIST_REQUEST';
export const PATIENT_CHECKLIST_SUCCESS = 'PATIENT_CHECKLIST_SUCCESS';
export const PATIENT_CHECKLIST_FAILURE = 'PATIENT_CHECKLIST_FAILURE';

export const SELECT_FIRST_TASK = 'SELECT_FIRST_TASK';
export const SELECT_PATIENT_TASK = 'SELECT_PATIENT_TASK';
export const SELECT_PATIENT_TASK_FORCED = 'SELECT_PATIENT_TASK_FORCED';

export const NOTES_VIEW_SET = 'NOTES_VIEW_SET';

// Actions - Auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT = 'LOGOUT';

// Actions - User
export const FETCH_USER_PREFERENCES = 'FETCH_USER_PREFERENCES';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ENROLLMENT =
  'UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ENROLLMENT';
export const UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ADMINISTRATION =
  'UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ADMINISTRATION';
export const UPDATE_LOCAL_USER_PREFERENCES_TASKS = 'UPDATE_LOCAL_USER_PREFERENCES_TASKS';
export const UPDATE_LOCAL_USER_PREFERENCES_TASKLIST = 'UPDATE_LOCAL_USER_PREFERENCES_TASKLIST';
export const RESET_LOCAL_USER_PREFERENCES_TASKS_THERAPIES =
  'RESET_LOCAL_USER_PREFERENCES_TASKS_THERAPIES';
export const UPDATE_LOCAL_USER_PREFERECENES_RECENT_PROVIDERS =
  'UPDATE_LOCAL_USER_PREFERECENES_RECENT_PROVIDERS';
export const UPDATE_LOCAL_USER_PREFERECENES_RECENT_PHARMACIES =
  'UPDATE_LOCAL_USER_PREFERECENES_RECENT_PHARMACIES';
export const UPDATE_LOCAL_USER_PREFERECENES_RECENT_CLINICS =
  'UPDATE_LOCAL_USER_PREFERECENES_RECENT_CLINICS';
export const UPDATE_LOCAL_USER_PREFERENCES_BI_PHARMACY_ID =
  'UPDATE_LOCAL_USER_PREFERENCES_BI_PHARMACY_ID';
export const USER_PREFERENCES_SAVE_REQUEST = 'USER_PREFERENCES_SAVE_REQUEST';
export const USER_PREFERENCES_SAVE_SUCCESS = 'USER_PREFERENCES_SAVE_SUCCESS';
export const USER_PREFERENCES_SAVE_FAILURE = 'USER_PREFERENCES_SAVE_FAILURE';
export const USER_PREFERENCES_SELECT_RECENT_PATIENT = 'USER_PREFERENCES_SELECT_RECENT_PATIENT';
export const RECENT_PATIENTS_REQUEST = 'RECENT_PATIENTS_REQUEST';
export const RECENT_PATIENTS_REQUEST_SUCCESS = 'RECENT_PATIENTS_REQUEST_SUCCESS';
export const RECENT_PATIENTS_REQUEST_FAILURE = 'RECENT_PATIENTS_REQUEST_FAILURE';
export const SET_REPORT_DUPLICATE_PROFILE_BUTTON_STATUS =
  'SET_REPORT_DUPLICATE_PROFILE_BUTTON_STATUS';
export const REPORT_DUPLICATE_PATIENT_PROFILES = 'REPORT_DUPLICATE_PATIENT_PROFILES';
export const PATIENTS_ADVANCED_SEARCH_REQUEST_SUCCESS = 'PATIENTS_ADVANCED_SEARCH_REQUEST_SUCCESS';
export const CLEAR_DUPLICATE_PROFILE_INFORMATION = 'CLEAR_DUPLICATE_PROFILE_INFORMATION';

// Forms - Auth
export const DO_LOGIN_REQUEST = 'UserLogin';

// Actions - Therapy
export const FETCH_THERAPY_STATUSES = 'FETCH_THERAPY_STATUSES';
export const ADD_THERAPY = 'ADD_THERAPY';
export const ADD_THERAPY_AR = 'ADD_THERAPY_AR';
export const ADD_THERAPY_LINK_DRUG = 'ADD_THERAPY_LINK_DRUG';
export const EDIT_THERAPY = 'EDIT_THERAPY';
export const UPDATE_THERAPY_PROPERTIES = 'UPDATE_THERAPY_PROPERTIES';
export const UPDATE_THERAPIES_DISPENSING_PHARMACY = 'UPDATE_THERAPIES_DISPENSING_PHARMACY';
export const EDIT_THERAPY_FLOW = 'EDIT_THERAPY_FLOW';
export const EDIT_THERAPIES_NBD = 'EDIT_THERAPIES_NBD';

// Actions - vaccine
export const ADD_VACCINE_TO_PATIENT = 'ADD_VACCINE_TO_PATIENT';

// Actions - Resource Links
export const FETCH_RESOURCE_LINK = 'FETCH_RESOURCE_LINK';

// Actions - Financials
export const DATA_MEDICAL_INSURANCES_REQUEST = 'DATA_MEDICAL_INSURANCES_REQUEST';
export const DATA_MEDICAL_INSURANCES_SUCCESS = 'DATA_MEDICAL_INSURANCES_SUCCESS';
export const DATA_MEDICAL_INSURANCES_FAILURE = 'DATA_MEDICAL_INSURANCES_FAILURE';
export const DATA_PBM_INSURANCES_REQUEST = 'DATA_PBM_INSURANCES_REQUEST';
export const DATA_PBM_INSURANCES_SUCCESS = 'DATA_PBM_INSURANCES_SUCCESS';
export const DATA_PBM_INSURANCES_FAILURE = 'DATA_PBM_INSURANCES_FAILURE';
export const DATA_INCOMES_REQUEST = 'DATA_INCOMES_REQUEST';
export const DATA_INCOMES_SUCCESS = 'DATA_INCOMES_SUCCESS';
export const DATA_INCOMES_FAILURE = 'DATA_INCOMES_FAILURE';
export const DATA_FINANCIAL_ASSISTANCE_REQUEST = 'DATA_FINANCIAL_ASSISTANCE_REQUEST';
export const DATA_FINANCIAL_ASSISTANCE_SUCCESS = 'DATA_FINANCIAL_ASSISTANCE_SUCCESS';
export const DATA_FINANCIAL_ASSISTANCE_FAILURE = 'DATA_FINANCIAL_ASSISTANCE_FAILURE';
export const DATA_FINANCIAL_ASSISTANCE_DETAIL_REQUEST = 'DATA_FINANCIAL_ASSISTANCE_DETAIL_REQUEST';
export const DATA_FINANCIAL_ASSISTANCE_DETAIL_SUCCESS = 'DATA_FINANCIAL_ASSISTANCE_DETAIL_SUCCESS';
export const DATA_FINANCIAL_ASSISTANCE_DETAIL_FAILURE = 'DATA_FINANCIAL_ASSISTANCE_DETAIL_FAILURE';
export const FETCH_MEDICAL_INSURANCES = 'FETCH_MEDICAL_INSURANCES';
export const FETCH_MEDICAL_INSURANCES_SUCCESS = 'FETCH_MEDICAL_INSURANCES_SUCCESS';
export const FETCH_HAS_PRIMARY_MEDICAL_INSURANCE = 'FETCH_HAS_PRIMARY_MEDICAL_INSURANCE';
export const FETCH_HAS_PRIMARY_PBM_INSURANCE = 'FETCH_HAS_PRIMARY_PBM_INSURANCE';
export const FETCH_PBM_INSURANCES = 'FETCH_PBM_INSURANCES';
export const FETCH_PBM_INSURANCES_SUCCESS = 'FETCH_PBM_INSURANCES_SUCCESS';
export const ADD_PBM_INSURANCE = 'ADD_PBM_INSURANCES';
export const EDIT_PBM_INSURANCE = 'EDIT_PBM_INSURANCE';
export const ADD_MEDICAL_INSURANCE = 'ADD_MEDICAL_INSURANCE';
export const EDIT_MEDICAL_INSURANCE = 'EDIT_MEDICAL_INSURANCE';
export const FETCH_INCOMES = 'FETCH_INCOMES';
export const ADD_INCOME = 'ADD_INCOME';
export const EDIT_INCOME = 'EDIT_INCOME';
export const FETCH_EXISTING_INCOME_YEARS = 'FETCH_EXISTING_INCOME_YEARS';
export const FETCH_FINANCIAL_ASSISTANCE = 'FETCH_FINANCIAL_ASSISTANCE';
export const ADD_FINANCIAL_ASSISTANCE = 'ADD_FINANCIAL_ASSISTANCE';
export const EDIT_FINANCIAL_ASSISTANCE = 'EDIT_FINANCIAL_ASSISTANCE';
export const ASSOCIATE_FINANCIAL_ASSISTANCE = 'ASSOCIATE_FINANCIAL_ASSISTANCE';
export const LINK_FINANCIAL_ASSISTANCE = 'LINK_FINANCIAL_ASSISTANCE';

// Forms - Search
export const SEARCH_FORM = 'Search';

// Forms - Medication
export const MEDICATION_FORM = 'Medication';
export const MERGE_MEDICATION_FORM = 'MERGE_MEDICATION_FORM';
export const EDIT_MEDICATION_FORM_FULL = 'Edit_Medication_Form_Full';

// Forms - Allergy
export const ALLERGY_FORM = 'Allergy';
export const EDIT_ALLERGY_FORM = 'EDIT_ALLERGY_FORM';

// Forms - Special Population
export const SPECIAL_POPULATION_FORM = 'Special_Population';
export const EDIT_SPECIAL_POPULATION_FORM = 'EDIT_SPECIAL_POPULATION_FORM';

// Forms - Appointment Referral
export const EXCLUDE_THERAPY_FORM = 'ExcludeTherapy';
export const APPOINTMENT_REFERRAL_TASK_ID = 21;
export const DRUG_UTILIZATION_REVIEW_TASK_ID = 26;

export const APPOINTMENT_REFERRAL_VALIDATE_ID = 10001;
export const APPOINTMENT_REFERRAL_SCHEDULED_ID = 10002;
export const APPOINTMENT_REFERRAL_COMPLETED_MET_WITH_PATIENT_IN_PERSON_ID = 10007;
export const APPOINTMENT_REFERRAL_COMPLETED_SPOKE_WITH_PATIENT_ON_THE_PHONE_ID = 10008;
export const APPOINTMENT_REFERRAL_WILL_NOT_MEET_WITH_PATIENT_PROVIDER_PREFERENCE_ID = 10015;
export const APPOINTMENT_REFERRAL_NO_VALID_THERAPIES_ID = 10016;
export const APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_ON_THE_PHONE_ID = 10017;
export const APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_WITH_ANOTHER_PATIENT_ID = 10018;
export const APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_MISSED_PATIENT_IN_OFFICE_ID = 10019;
export const APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_DOING_OTHER_WORK_ID = 10020;
// eslint-disable-next-line max-len
export const APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_INAPPROPRIATE_APPOINTMENT_TYPE_ID = 10021;
export const APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_APPOINTMENT_CANCELED_ID = 10022;
export const APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_NO_SHOW_ID = 10023;
export const APPOINTMENT_REFERRAL_PATIENT_UNREACHABLE_ID = 10024;
export const APPOINTMENT_REFERRAL_WILL_NOT_MEET_WITH_PATIENT_PATIENT_DECLINED_ID = 10025;
export const APPOINTMENT_REFERRAL_CANCELED_PATIENT_DECEASED_ID = 10099;
export const APPOINTMENT_REFERRAL_CANCELED_ID = 10100;

export const ON_GOING_APPOINTMENT_REFERRAL = [
  APPOINTMENT_REFERRAL_VALIDATE_ID,
  APPOINTMENT_REFERRAL_SCHEDULED_ID,
  APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_ON_THE_PHONE_ID,
  APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_WITH_ANOTHER_PATIENT_ID,
  APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_MISSED_PATIENT_IN_OFFICE_ID,
  APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_DOING_OTHER_WORK_ID,
  APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_INAPPROPRIATE_APPOINTMENT_TYPE_ID,
  APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_APPOINTMENT_CANCELED_ID,
  APPOINTMENT_REFERRAL_UNABLE_TO_MEET_WITH_PATIENT_NO_SHOW_ID,
];

// Forms - Patient
export const ADD_PATIENT_FORM = 'AddPatient';

// Forms - Financials
export const ADD_MEDICAL_INSURANCE_FORM = 'AddMedicalInsurance';
export const EDIT_MEDICAL_INSURANCE_FORM = 'EditMedicalInsurance';
export const ADD_PBM_INSURANCE_FORM = 'AddPbmInsurance';
export const EDIT_PBM_INSURANCE_FORM = 'EditPbmInsurance';
export const ADD_INCOME_FORM = 'AddIncome';
export const EDIT_INCOME_FORM = 'EditIncome';
export const ADD_FINANCIAL_ASSISTANCE_FORM = 'ADD_FINANCIAL_ASSISTANCE_FORM';
export const EDIT_FINANCIAL_ASSISTANCE_FORM = 'EDIT_FINANCIAL_ASSISTANCE_FORM';

// Forms - Notes
export const ADD_NOTE_FORM = 'ADD_NOTE_FORM';
export const COMMUNICATION_FORM = 'COMMUNICATION_FORM';
export const COMMUNICATION_FORM_PHONE_CALL = 'COMMUNICATION_FORM_PHONE_CALL';

// Forms - Clinical Data
export const ADD_CLINICAL_DATA_FORM = 'ADD_CLINICAL_DATA_FORM';
export const EDIT_CLINICAL_DATA_FORM = 'EDIT_CLINICAL_DATA_FORM';

// Forms - Appointment Referral
export const ADD_TARGET = 'ADD_TARGET';

// Actions - Work list
export const FETCH_WORK_LIST_REQUEST = 'FETCH_WORK_LIST_REQUEST';
export const FETCH_WORK_LIST_SUCCESS = 'FETCH_WORK_LIST_SUCCESS';
export const FETCH_WORK_LIST_FAILURE = 'FETCH_WORK_LIST_FAILURE';
export const FETCH_WORK_LIST_SEARCH_REQUEST = 'FETCH_WORK_LIST_SEARCH_REQUEST';
export const FETCH_WORK_LIST_SEARCH_SUCCESS = 'FETCH_WORK_LIST_SEARCH_SUCCESS';
export const FETCH_WORK_LIST_SEARCH_FAILURE = 'FETCH_WORK_LIST_SEARCH_FAILURE';
export const WORK_LIST_CHANGED = 'WORK_LIST_CHANGED';
export const SEARCH_WORK_LIST = 'SEARCH_WORK_LIST';
export const SET_WORK_LIST = 'SET_WORK_LIST';

// Actions - Patient
export const FETCH_PATIENT = 'FETCH_PATIENT';
export const ADD_PATIENT = 'ADD_PATIENT';
export const EDIT_PATIENT = 'EDIT_PATIENT';
export const RESET_ANCHOR_THERAPY_SETTINGS = 'RESET_ANCHOR_THERAPY_SETTINGS';
export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST';
export const UPDATE_PATIENT_PREFERENCE_REQUEST = 'UPDATE_PATIENT_PREFERENCE_REQUEST';
export const UPDATE_PATIENT_PREFERENCE_SUCCESS = 'UPDATE_PATIENT_PREFERENCE_SUCCESS';
export const UPDATE_PATIENT_PROPERTIES = 'UPDATE_PATIENT_PROPERTIES';
export const CLEAR_WORK_LIST = 'CLEAR_WORK_LIST';
export const SELECT_PATIENT_TAB = 'SELECT_PATIENT_TAB';
export const TOGGLE_TAB_REFRESH = 'TOGGLE_TAB_REFRESH';
export const DISCHARGE_PATIENT = 'DISCHARGE_PATIENT';
export const REENROLL_PATIENT = 'REENROLL_PATIENT';
export const MARK_PATIENT_DECEASED = 'MARK_PATIENT_DECEASED';
export const UPDATE_PATIENT_SEARCH_INDEX = 'UPDATE_PATIENT_SEARCH_INDEX';
export const REFRESH_THERAPIES_AND_TASKS = 'REFRESH_THERAPIES_AND_TASKS';
export const REFRESH_TASKS = 'REFRESH_TASKS';
export const REFRESH_QUEUE_WORKLIST = 'REFRESH_QUEUE_WORKLIST';
export const REFRESH_SMS_MESSAGES = 'REFRESH_SMS_MESSAGES';
export const SEND_PATIENT_OPT_IN_SMS = 'SEND_PATIENT_OPT_IN_SMS';
export const FETCH_PATIENT_PROBLEMS = 'FETCH_PATIENT_PROBLEMS';
export const FETCH_PATIENT_PROBLEMS_SUCCESS = 'FETCH_PATIENT_PROBLEMS_SUCCESS';
export const FETCH_PATIENT_PROBLEMS_FAILED = 'FETCH_PATIENT_PROBLEMS_FAILED';
export const ADD_PROBLEM_SUCCESS = 'ADD_PROBLEM_SUCCESS';
export const EDIT_PROBLEM_SUCCESS = 'EDIT_PROBLEM_SUCCESS';
export const EDIT_PROBLEM_THERAPIES_ENROLLMENT_SUCCESS =
  'EDIT_PROBLEM_THERAPIES_ENROLLMENT_SUCCESS';
export const VERIFY_PROBLEMS_SUCCESS = 'VERIFY_PROBLEMS_SUCCESS';
export const UNVERIFY_PROBLEMS = 'UNVERIFY_PROBLEMS';
export const UPDATE_PROBLEMS_VERIFICATION_STATUS = 'UPDATE_PROBLEMS_VERIFICATION_STATUS';

export const FETCH_PATIENT_SMS_MESSAGES_SUCCESS = 'FETCH_PATIENT_SMS_MESSAGES_SUCCESS';
export const UPDATE_SMS_MESSAGE_TAGS_SUCCESS = 'UPDATE_SMS_MESSAGE_TAGS_SUCCESS';
export const SEND_SMS_TO_PATIENT_SUCCESS = 'SEND_SMS_TO_PATIENT_SUCCESS';
export const MARK_SMS_AS_READ_SUCCESS = 'MARK_SMS_AS_READ_SUCCESS';
export const UPDATE_TASK_FOLLOWUP_DATE = 'UPDATE_TASK_FOLLOWUP_DATE';
export const MARK_SMS_AS_UNREAD_SUCCESS = 'MARK_SMS_AS_UNREAD_SUCCESS';
export const REFRESH_PATIENT_PHONES = 'REFRESH_PATIENT_PHONES';
export const REFRESH_PATIENT_PHONES_SUCCESS = 'REFRESH_PATIENT_PHONES_SUCCESS';
export const REFRESH_PATIENT_QUESTIONS_SUCCESS = 'REFRESH_PATIENT_QUESTIONS_SUCCESS';

// Actions - Schedule
/* eslint-disable @typescript-eslint/naming-convention */
export const SET_SCHEDULES_ISLOADING = 'SET_SCHEDULES_ISLOADING';
export const UPDATE_SCHEDULES = 'UPDATE_SCHEDULES';
export const LOAD_SCHEDULES = 'LOAD_SCHEDULES';
export const FETCH_SCHEDULES_REQUEST = 'FETCH_SCHEDULES_REQUEST';
export const FETCH_SCHEDULES_SUCCESS = 'FETCH_SCHEDULES_SUCCESS';
export const FETCH_SCHEDULES_FAILURE = 'FETCH_SCHEDULES_FAILURE';
export const RESET_SCHEDULES = 'RESET_SCHEDULES';

// Actions - Report
export const SET_REPORT_ISLOADING = 'SET_REPORT_ISLOADING';
export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';
export const FETCH_REPORT_DATA = 'FETCH_REPORT_DATA';
export const UPDATE_REPORT_MODAL = 'UPDATE_REPORT_MODAL';
export const PRIOR_AUTHORIZATION_TAT_OVERRIDE_DATA = 'PRIOR_AUTHORIZATION_TAT_OVERRIDE_DATA';

// Actions - Note
export const ADD_NOTE = 'ADD_NOTE';
export const ADD_NOTE_COMMUNICATION = 'ADD_NOTE_COMMUNICATION';
export const OPEN_NOTE_COMMUNICATION_SIDEBAR = 'OPEN_NOTE_COMMUNICATION_SIDEBAR';
export const TRIM_NOTES = 'TRIM_NOTES';
export const UPDATE_NOTE_BAR = 'UPDATE_NOTE_BAR';
export const NOTE_PAGE_LIMIT = 9988;
export const CLEAR_NOTE_BAR_SMS_MESSAGE_FOCUS = 'CLEAR_NOTE_BAR_SMS_MESSAGE_FOCUS';
export const TASK_CARD_CONTACT_ICON = 'taskCardContactIcon';
// set to this while we have pagination disabled -ANM

// Actions - Documents
export const UPDATE_DOCUMENT_BAR = 'UPDATE_DOCUMENT_BAR';

// Actions - Eligibility Check
export const UPDATE_ELIGIBILITY_CHECK = 'UPDATE_ELIGIBILITY_CHECK';
export const CLOSE_ELIGIBILITY_CHECK = 'CLOSE_ELIGIBILITY_CHECK';

// Actions - Contact
export const PATIENT_CONTACT_LIST_REQUEST = 'PATIENT_CONTACT_LIST_REQUEST';
export const PATIENT_CONTACT_LIST_SUCCESS = 'PATIENT_CONTACT_LIST_SUCCESS';
export const PATIENT_CONTACT_LIST_FAILURE = 'PATIENT_CONTACT_LIST_FAILURE';
export const ADD_CONTACT = 'ADD_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const EDIT_CONTACT_AND_UPDATE_PATIENT = 'EDIT_CONTACT_AND_UPDATE_PATIENT';
export const CLEAR_CONTACT = 'CLEAR_CONTACT';
export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
export const FETCH_PREFERRED_CONTACT = 'FETCH_PREFERRED_CONTACT';
export const ADD_DELIVERY_PROVIDER = 'ADD_DELIVERY_PROVIDER';
export const ADDRESS_USE_MAILING = 'Mailing';
export const PHONE_USE_BUSINESS = 'Business';

// Actions - Clinical Data
export const EDIT_CLINICAL_DATA = 'EDIT_CLINICAL_DATA';
export const SEND_QUESTIONNAIRE = 'SEND_QUESTIONNAIRE';

// Actions - Task
export const TASK_TYPES = {
  PRIOR_AUTHORIZATION: 'PRIOR_AUTHORIZATION',
  FINANCIAL_ASSISTANCE: 'FINANCIAL_ASSISTANCE',
  BENEFITS_INVESTIGATION: 'BENEFITS_INVESTIGATION',
  FILL_COORDINATION: 'FILL_COORDINATION',
  FILL_DELIVERY_CONFIRMATION: 'FILL_DELIVERY_CONFIRMATION',
  DATA_COLLECT: 'DATA_COLLECT',
  INTERVENTION: 'INTERVENTION',
  INCIDENT: 'INCIDENT',
  INTERVENTION_PATIENT: 'INTERVENTION_PATIENT',
  APPOINTMENT_REFERRAL: 'APPOINTMENT_REFERRAL',
  OUTREACH: 'OUTREACH',
  OUTREACH_PATIENT: 'OUTREACH_PATIENT',
  COUNSELING: 'COUNSELING',
  SCHEDULE_OUTREACH: 'SCHEDULE_OUTREACH',
  MEDICATION_REVIEW: 'MEDICATION_REVIEW',
  DRUG_UTILIZATION_REVIEW: 'DRUG_UTILIZATION_REVIEW',
  RISK_STRAT: 'RISK_STRAT',
};

export const ADD_APPOINTMENT_REFERRAL = 'ADD_APPOINTMENT_REFERRAL';

export const TASK_LIFECYCLE_ACTIONS = {
  FETCH: {},
  FETCH_STATUSES: {},
  ADD: {},
  EDIT: {},
};

Object.keys(TASK_TYPES).forEach(taskType => {
  const actionIndexes = Object.keys(TASK_LIFECYCLE_ACTIONS);
  actionIndexes.forEach(actionIndex => {
    TASK_LIFECYCLE_ACTIONS[actionIndex][taskType] = `${actionIndex}_${taskType}`;
  });
});

export const REDUX_FORM = {
  ALIAS_FIELD: '_ALIAS',
};

export const BULK_TASKS_UPDATE_REQUEST = 'BULK_TASKS_UPDATE_REQUEST';
export const BULK_TASKS_UPDATED = 'BULK_TASKS_UPDATED';
export const FETCH_TASK = 'FETCH_TASK';
export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FOR_THERAPY_BY_TYPE = 'FETCH_TASKS_FOR_THERAPY_BY_TYPE';
export const FETCH_STATUSES_FOR_TYPE = 'FETCH_STATUSES_FOR_TYPE';
export const UPDATE_FC_DISPENSING_PHARMACY = 'UPDATE_FC_DISPENSING_PHARMACY';
export const UPDATE_TASK_LIST_ITEMS = 'UPDATE_TASK_LIST_ITEMS';
export const ADD_TASK = 'ADD_TASK';
export const EDIT_TASK = 'EDIT_TASK';
export const ADD_TASK_TO_PATIENT = 'ADD_TASK_TO_PATIENT';
export const FETCH_TASK_STATUSES_CATEGORIES = 'FETCH_TASK_STATUSES_CATEGORIES';
export const ADD_TASK_PA_FORM = 'ADD_TASK_PA_FORM';
export const ADD_TASK_FA_FORM = 'ADD_TASK_FA_FORM';
export const ADD_THIRD_PARTY_REFERRAL = 'ADD_THIRD_PARTY_REFERRAL';
export const ADD_MEDICATION_REVIEW = 'ADD_MEDICATION_REVIEW';
export const ADD_TASK_FILL_COORDINATION_FORM = 'ADD_TASK_FILL_COORDINATION_FORM';
export const ADD_TASK_INTERVENTION_FORM = 'ADD_TASK_INTERVENTION_FORM';
export const DC_INTERVENTION_FORM = 'dcIntervention';
export const ADD_TASK_INCIDENT_FORM = 'ADD_TASK_INCIDENT_FORM';
export const ADD_TASK_OUTREACH_FORM = 'ADD_TASK_OUTREACH_FORM';
export const ADD_TASK_COUNSELING_FORM = 'ADD_TASK_COUNSELING_FORM';
export const ADD_TASK_FILL_DELIVERIY_CONFIRMATION_FORM =
  'ADD_TASK_FILL_DELIVERIY_CONFIRMATION_FORM';
export const ADD_TASK_DATA_COLLECT_FORM = 'ADD_TASK_DATA_COLLECT_FORM';
export const ADD_TASK_MEDICATION_REVIEW_FORM = 'ADD_TASK_MEDICATION_REVIEW_FORM';
export const ADD_TASK_RISK_STRAT_FORM = 'ADD_TASK_RISK_STRAT_FORM';

export const EDIT_PA = 'EDIT_PA';
export const EDIT_FA = 'EDIT_FA';
export const EDIT_THIRD_PARTY_REFERRAL = 'EDIT_THIRD_PARTY_REFERRAL';
export const EDIT_MEDICATION_REVIEW = 'EDIT_MEDICATION_REVIEW';
// TODO dynamically generate link actions
export const LINK_SCHEDULE_OUTREACH_THERAPIES = 'LINK_SCHEDULE_OUTREACH_THERAPIES';
export const UPDATE_SCHEDULE_DRUG_OUTCOME = 'UPDATE_SCHEDULE_DRUG_OUTCOME';
export const ADD_SCHEDULE_DRUG_OUTCOME = 'ADD_SCHEDULE_DRUG_OUTCOME';
export const UPDATE_TASK_PROPERTIES = 'UPDATE_TASK_PROPERTIES';
export const UPDATE_TASKS_PROPERTIES = 'UPDATE_TASKS_PROPERTIES';
export const INTEGRATE_UPDATED_TASKS = 'INTEGRATE_UPDATED_TASKS';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

// Filers - Task
export const FILTER_TASKS_BY_DATE = 'FILTER_TASKS_BY_DATE';
export const SET_THERAPY_ID = 'SET_THERAPY_ID';

// Forms - Task
export const EDIT_PA_FORM = 'EditPaForm';
export const EDIT_FA_FORM = 'EditFaForm';
export const EDIT_FILL_DELIVERY_CONFIRMATION_FORM = 'EDIT_FILL_DELIVERY_CONFIRMATION_FORM';
export const CANCEL_PA_TASK = 'CANCEL_PA_TASK';
export const FA_GRANT_NOTIFICATION = 'FA_GRANT_NOTIFICATION';

export const EDIT_THIRD_PARTY_REFERRAL_FORM = 'EditThirdPartyReferralForm';
export const EDIT_MEDICATION_REVIEW_FORM = 'EditMedicationReviewForm';
export const EDIT_DATA_COLLECT_FORM = 'EditDataCollectForm';
export const EDIT_FILL_COORDINATION_FORM = 'EditFillCoordinationForm';
export const EDIT_INTERVENTION_FORM = 'EditFillCoordinationForm';
export const EDIT_OUTREACH_FORM = 'EditFillCoordinationForm';
export const EDIT_COUNSELING_FORM = 'EditCounselingForm';
export const EDIT_SCHEDULE_OUTREACH_FORM = 'EditScheduleOutreachForm';

// Generalized Task Constants

export const ADD_TASK_FORM_PREFIX = 'ADD_TASK_FORM_PREFIX';
export const EDIT_TASK_FORM_PREFIX = 'EDIT_TASK_FORM_PREFIX';

// Constants - Task
export const SMS_RELATED_TASK = 'SMS';
export const THERAPIES = 'therapies';
export const PATIENTS = 'patients';
export const {
  PATIENT,
  PA,
  FA,
  TPR,
  MR,
  BI,
  CA,
  CI,
  FC,
  FDC,
  RS,
  DC,
  INT,
  CSL,
  OR,
  AR,
  QRE,
  DUR,
  SMS,
} = TaskTypesEnum;
export const STAR = 'STAR';
export const CDM = 'CDM';

export const TASK_PRIOR_AUTHORIZATION = 'task_prior_authorization';
export const TASK_FINANCIAL_ASSISTANCE = 'task_financial_assistance';
export const TASK_THIRD_PARTY_REFERRAL = 'task_third_party_referral';
export const TASK_MEDICATION_REVIEW = 'task_medication_review';
export const TASK_FILL_COORDINATION = 'task_fill_coordination';
export const TASK_FILL_DELIVERY_CONFIRMATION = 'task_fill_delivery_confirmation';
export const TASK_DATA_COLLECT = 'task_data_collect';
export const TASK_RISK_STRAT = 'task_risk_strat';
export const TASK_INTERVENTIONS = 'task_interventions';
export const TASK_OUTREACH = 'task_outreach';
export const TASK_COUNSELING = 'task_counseling';
export const TASK_SCHEDULE_OUTREACH = 'task_schedule_outreach';
export const TASK_DRUG_UTILIZATION_REVIEW = 'task_drug_utilization_review';

export const FA_CHART_REVIEW = 3;

export const taskAbbreviationMap = {
  task_prior_authorization: 'PA',
  task_financial_assistance: 'FA',
  task_third_party_referral: 'TPR',
  task_medication_review: 'MR',
  task_fill_coordination: 'FC',
  task_fill_delivery_confirmation: 'FDC',
  task_data_collect: 'DC',
  task_interventions: 'INT',
  task_counseling: 'CSL',
  task_outreach: 'OR',
  task_incidents: 'QRE',
  task_drug_utilization_review: 'DUR',
};

export const TASK_STATUS_OUTSTANDING = 1;
export const TASK_STATUS_IN_PROGRESS = 2;
export const TASK_STATUS_DONE = 3;
export const TASK_STATUS_ARCHIVED = 4;

// Constants - PA Status
export const PA_STATUS_INVESTIGATE = 'Investigate';
export const PA_STATUS_REQURIED = 'Required';
export const PA_STATUS_SUBMITTED = 'Submitted';
export const PA_STATUS_APPROVED = 'Approved';
export const PA_STATUS_DENIED = 'Denied';
export const PA_STATUS_DENIED_NO_APPEAL = 'Denied - No Appeal';
export const PA_STATUS_PHARMACIST_REVIEW_NEEDED = 'Pharmacist Review Needed';
export const PA_STATUS_APPEALED = 'Appealed';
export const PA_STATUS_EXPIRED = 'Expired';
export const PA_STATUS_NOT_REQUIRED = 'Not Required';
export const PA_STATUS_CANCELED = 'Canceled';
export const PA_STATUS_TIME_OUT = 'Time Out';

// Constants - FA Status
export const FA_STATUS_INVESTIGATE = 'Investigate';
export const FA_STATUS_REQUESTED = 'Requested';
export const FA_STATUS_SUBMITTED = 'Submitted';
export const FA_STATUS_APPROVED = 'Approved';
export const FA_STATUS_DENIED = 'Denied';
export const FA_STATUS_DENIED_NO_APPEAL = 'Denied - No Appeal';
export const FA_STATUS_APPEALED = 'Appealed';
export const FA_STATUS_EXPIRED = 'Expired';
export const FA_STATUS_NOT_REQUESTED = 'Not Requested';
export const FA_STATUS_CANCELED = 'Canceled';
export const FA_STATUS_TIME_OUT = 'Time Out';
export const FA_STATUS_WAITING_FOR_PATIENT = 'Waiting for Patient';
export const FA_STATUS_EXHAUSTED = 'Exhausted';
export const FA_FUNDING_AVAILABLE = 'Funding Available';

// Actions - Financial Assistance
export const ADD_COPAY_CARD = 'ADD_COPAY_CARD';
export const ADD_PAP = 'ADD_PAP';
export const ADD_FOUNDATION = 'ADD_FOUNDATION';
export const EDIT_COPAY_CARD = 'EDIT_COPAY_CARD';
export const EDIT_PAP = 'EDIT_PAP';
export const EDITFOUNDATION = 'EDIT_FOUNDATION';

// Constants - Third Party Referral Status
export const THIRD_PARTY_REFERRAL_STATUS_REQUIRED = 'Required';
export const THIRD_PARTY_REFERRAL_STATUS_SUBMITTED = 'Submitted';
export const THIRD_PARTY_REFERRAL_STATUS_COMPLETED = 'Completed';
export const THIRD_PARTY_REFERRAL_STATUS_ATTENTION = 'Requires Attention';
export const THIRD_PARTY_REFERRAL_STATUS_CANCELED = 'Canceled';

// Constants - Medication Review Status
export const MEDICATION_REVIEW_STATUS_REQUIRED = 'Required';
export const MEDICATION_REVIEW_STATUS_MEDS_REVIEWED = 'Meds reviewed';
export const MEDICATION_REVIEW_STATUS_DDI_COMPLETED = 'DDI generated';
export const MEDICATION_REVIEW_STATUS_DDI_REVIEWED = 'DDI reviewed';
export const MEDICATION_REVIEW_STATUS_CANCELED = 'Canceled';

// Constants - Fill Coordination Status
export const FILL_COORDINATION_STATUS_REQUIRED = 'Required';
export const FILL_COORDINATION_STATUS_COMPLETED = 'Completed';
export const FILL_COORDINATION_STATUS_CANCELED = 'Canceled';

// Constants - Intervention Status
export const INTERVENTION_STATUS_REQUIRED = 'Required';
export const INTERVENTION_STATUS_IN_PROGRESS = 'In Progress';
export const INTERVENTION_STATUS_COMPLETED = 'Completed';
export const INTERVENTION_STATUS_CANCELED = 'Canceled';

// Constants - Counseling Status
export const COUNSELING_STATUS_REQUIRED = 'Required';
export const COUNSELING_STATUS_SCHEDULED = 'Scheduled';
export const COUNSELING_STATUS_ATTEMPTED_TO_REACH_PATIENT = 'Attempted to reach patient';
export const COUNSELING_STATUS_COMPLETED = 'Completed';
export const COUNSELING_STATUS_CANCELED = 'Canceled';

// Constants - Fill Delivery Confirmation
export const FILL_DELIVERY_CONFIRMATION_STATUS_SHIPPED = 'Shipped';
export const FILL_DELIVERY_CONFIRMATION_STATUS_WILL_PICK_UP = 'Will Pick Up';
export const FILL_DELIVERY_CONFIRMATION_STATUS_DELIVERED = 'Delivered';
export const FILL_DELIVERY_CONFIRMATION_STATUS_PICKED_UP = 'Picked Up';
export const FILL_DELIVERY_CONFIRMATION_STATUS_PICK_UP = 'Pick Up';
export const FILL_DELIVERY_CONFIRMATION_STATUS_DELIVERY_EXCEPTION = 'Delivery Exception';
export const FILL_DELIVERY_CONFIRMATION_STATUS_DISPENSE = 'Dispense';
export const FILL_DELIVERY_CONFIRMATION_STATUS_PACKED = 'Packed';
export const FILL_DELIVERY_CONFIRMATION_STATUS_CANCELED = 'Canceled';
export const FILL_DELIVERY_CONFIRMATION_STATUS_NEEDS_REVIEW = 'Needs Review';

// Constants - Data Collect Status
export const DATA_COLLECT_STATUS_REQUIRED = 'Required';
export const DATA_COLLECT_STATUS_IN_PROGRESS = 'In Progress';
export const DATA_COLLECT_STATUS_DATA_COLLECTED = 'Data Collected';
export const DATA_COLLECT_STATUS_DATA_REVIEWED = 'Data Reviewed';
export const DATA_COLLECT_STATUS_CANCELED = 'Canceled';

// Constants - Schedule Outreach Status
export const SCHEDULE_OUTREACH_STATUS_REQUIRED = 'Required';
export const SCHEDULE_OUTREACH_STATUS_SCHEDULED = 'Scheduled';
export const SCHEDULE_OUTREACH_STATUS_ON_HOLD = 'On Hold';
export const SCHEDULE_OUTREACH_STATUS_WILL_NOT_MEET = 'Will not meet with patient';
export const SCHEDULE_OUTREACH_STATUS_COMPLETED = 'Completed';
export const SCHEDULE_OUTREACH_STATUS_UNABLE_TO_MEET = 'Unable to meet with patient';
export const SCHEDULE_OUTREACH_STATUS_OFFER_LATER_DATE = 'Offer at a Later Date';
export const SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_INPERSON_ID = 10007;
export const SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_PHONE_ID = 10008;
export const SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID = '1';
export const SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID = '4';

// Constants - Schedule Outreach Status
export const OUTREACH_STATUS_REQUIRED = 'Required';
export const OUTREACH_STATUS_SCHEDULED = 'Scheduled';
export const OUTREACH_STATUS_ON_HOLD = 'On Hold';
export const OUTREACH_STATUS_CANCELED = 'Canceled';
export const OUTREACH_STATUS_COMPLETED = 'Completed';

export const SET_ACTIVE_SIDEBAR = 'SET_ACTIVE_SIDEBAR';
export const SET_INITIAL_URL = 'SET_INITIAL_URL';

// Forms - Therapy
export const ADD_PATIENT_THERAPY_FORM = 'AddPatientTherapy';
export const EDIT_PATIENT_THERAPY_FORM = 'EditPatientTherapy';
export const EDIT_THERAPY_ENROLLMENT = 'EditTherapyEnrollment';
export const EDIT_THERAPY_ADMINISTRATION = 'EditTherapyAdministration';

export const EDIT_PATIENT_INFO_FORM = 'EditPatientInfo';
export const EDIT_PATIENT_PREFERENCE_FORM = 'EditPatientPreference';
export const ADD_CONTACT_INFO_FORM = 'AddContactInfo';
export const EDIT_CONTACT_INFO_FORM = 'EditContactInfo';

// Forms - Upload Document
export const UPLOAD_DOCUMENT_FORM = 'UPLOAD_DOCUMENT_FORM';
export const UPLOAD_DOC_FILENAME_FIELD = 'UPLOAD_DOC_FILENAME_FIELD';
export const UPLOAD_DOC_FILETYPE_FIELD = 'UPLOAD_DOC_FILETYPE_FIELD';
export const UPLOAD_DOC_FILENOTE_FIELD = 'UPLOAD_DOC_FILENOTE_FIELD';

// Patient Documents
export const EDIT_PATIENT_DOCUMENTS_FORM = 'EditPatientDocuments';
export const FETCH_PATIENT_DOCUMENTS = 'FETCH_PATIENT_DOCUMENTS';
export const UPDATE_PATIENT_DOCUMENTS = 'UPDATE_PATIENT_DOCUMENTS';
export const DOCUMENT_ARCHIVE_REASON = 'DOCUMENT_ARCHIVE_REASON';

// Constants - Therapy Administration Status
export const THERAPY_ADMIN_STATUS_PRE_THERAPY = 'Pre-Therapy';
export const THERAPY_ADMIN_STATUS_ON_THERAPY = 'On Therapy';
export const THERAPY_ADMIN_STATUS_ON_HOLD = 'On Hold';
export const THERAPY_ADMIN_STATUS_DISCONTINUED = 'Discontinued';
export const THERAPY_ADMIN_STATUS_NO_GO = 'No-Go';

export const THERAPY_AS_NEEDED_PROPERTY = 'as_needed';
export const THERAPY_ONE_TIME_FILL_PROPERTY = 'one_time_fill';

// Constants - Therapy rx type id
export const THERAPY_MEDICATION_STATUS_NEW = 1;
export const THERAPY_MEDICATION_STATUS_EXISTING = 2;

// Constants - AR reason types
export const AR_PROVIDER_REFERRAL = 7;

// Constants - Therapy Lead Source
export const THERAPY_LEAD_SOURCE_EMR_LEAD = '1';
export const THERAPY_LEAD_SOURCE_CLINICIAN_REFERRAL = '2';
export const THERAPY_LEAD_SOURCE_HEALTH_PLAN = '3';
export const THERAPY_LEAD_SOURCE_HUB = '4';
export const THERAPY_LEAD_SOURCE_RPH_INITIATED_REFERRAL = '5';

// Constants - Therapy Enrollment Status
export const THERAPY_ENROLLMENT_STATUS_REFERRAL_ID = 12001;
export const THERAPY_ENROLLMENT_STATUS_ENROLLED_ID = 12002;
export const THERAPY_ENROLLMENT_STATUS_UNENROLLED_ID = 12003;

/**
 * @deprecated please use ClinicalSupportStatusValue.OptOut values from src/constants/enums.ts
 */
export const THERAPY_ENROLLMENT_OPTOUT = 3;

/**
 * @deprecated please use DispensingStatusValue.OptIn values from src/constants/enums.ts
 */
export const THERAPY_DISPENSING_OPTIN = 2;
/**
 * @deprecated please use DispensingStatusValue.OptOut values from src/constants/enums.ts
 */
export const THERAPY_DISPENSING_OPTOUT = 3;

export const FETCH_FILL_CYCLES_REQUEST = 'FETCH_FILL_CYCLES_REQUEST';
export const FETCH_FILL_CYCLES_SUCCESS = 'FETCH_FILL_CYCLES_SUCCESS';
export const FETCH_FILL_CYCLES_FAILURE = 'FETCH_FILL_CYCLES_FAILURE';

export const STATUS_FA_TIMEOUT_OTHER = 3024;
export const STATUS_FA_APPROVED = 3003;
export const STATUS_FA_ALREADY_IN_PLACE = 3033;
export const STATUS_FA_CANCELED = 3029;
// Google Analytics
export const GA_TRACKING_ID = 'UA-126110512-1';

// Actions - Lookups
export const FETCH_PENDING_ACCREDITATIONS = 'FETCH_PENDING_ACCREDITATIONS';
export const FETCH_ACCREDITATIONS_STATUS = 'FETCH_ACCREDITATIONS_STATUS';
export const FETCH_LOOKUPS = 'FETCH_LOOKUPS';
export const FETCH_ICD10S = 'FETCH_ICD10S';
export const FETCH_GPIS = 'FETCH_GPIS';
export const FETCH_PHYSICIANS = 'FETCH_PHYSICIANS';
export const FETCH_INSURANCE_TYPES = 'FETCH_INSURANCE_TYPES';
export const FETCH_INCOME_TIME_PERIODS = 'FETCH_INCOME_TIME_PERIODS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_ME = 'FETCH_ME';
export const FETCH_SERVICE_GROUPS = 'FETCH_SERVICE_GROUPS';
export const FETCH_CUSTOMER_INFO = 'FETCH_CUSTOMER_INFO';
export const FETCH_CUSTOMER_CLINICS = 'FETCH_CUSTOMER_CLINICS';
export const FETCH_APPOINTMENT_CLINICS = 'FETCH_APPOINTMENT_CLINICS';
export const FETCH_EMRS = 'FETCH_EMRS';
export const ADD_DATA_COLLECT_ITEM = 'ADD_DATA_COLLECT_ITEM';
export const EDIT_DATA_COLLECT_ITEM = 'EDIT_DATA_COLLECT_ITEM';
export const FETCH_CDM_PROGRAMS = 'FETCH_CDM_PROGRAMS';
export const ADD_CDM_PROGRAMS_CONDITIONS = 'ADD_CDM_PROGRAMS_CONDITIONS';
export const EDIT_CDM_PROGRAMS_CONDITIONS = 'EDIT_CDM_PROGRAMS_CONDITIONS';

// Actions - Notifications
export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_SUCCESS_ACTION = 'NOTIFY_SUCCESS_ACTION';
export const NOTIFY_WARNING = 'NOTIFY_WARNING';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';
export const PROCESS_NOTIFICATION = 'PROCESS_NOTIFICATION';

// Actions - Notes
export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_NEW_NOTES = 'FETCH_NEW_NOTES';
export const CREATE_NOTES_NEW_PATIENT = 'CREATE_NOTES_NEW_PATIENT';
export const ADD_TO_TREE_VIEW = 'ADD_TO_TREE_VIEW';

// Actions - Uploaded Documents
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const ADD_PATIENT_DOCUMENT = 'ADD_PATIENT_DOCUMENT';
export const ADD_PATIENT_DOCUMENT2 = 'ADD_PATIENT_DOCUMENT2';
export const UPDATE_PATIENT_DOCUMENT = 'UPDATE_PATIENT_DOCUMENT';
export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';

// Actions - medication list and groups
export const REFRESH_MEDICATIONS = 'REFRESH_MEDICATIONS';
export const FETCH_MEDICATION_LIST_ONLY_CURSOR = 'FETCH_MEDICATION_LIST_ONLY_CURSOR';
export const FETCH_MEDICATION_GROUPS_ONLY = 'FETCH_MEDICATION_GROUPS_ONLY';
export const FETCH_MEDICATION_GROUPS_ONLY_CURSOR = 'FETCH_MEDICATION_GROUPS_ONLY_CURSOR';
export const FETCH_MEDICATION_LIST_FOR_NDC = 'FETCH_MEDICATION_LIST_FOR_NDC';
export const FETCH_MEDICATION_LIST_FOR_IDENTIFIER_SUCCESS =
  'FETCH_MEDICATION_LIST_FOR_IDENTIFIER_SUCCESS';
export const FETCH_MEDICATION_LIST_FOR_GPI = 'FETCH_MEDICATION_LIST_FOR_GPI';

export const UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS =
  'UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS';
export const ADD_MEDICATION = 'ADD_MEDICATION';
export const ADD_MEDICATION_SUCCESS = 'ADD_MEDICATION_SUCCESS';
export const UPDATE_MEDICATION = 'UPDATE_MEDICATION';
export const UPDATE_MEDICATION_SUCCESS = 'UPDATE_MEDICATION_SUCCESS';
export const MERGE_MEDICATIONS = 'MERGE_MEDICATIONS';
export const SET_MEDICATIONS_VERIFICATION = 'SET_MEDICATIONS_VERIFICATION';

// Actions - Allergies
export const UPDATE_ALLERGY_VERIFICATION_STATUS_SUCCESS =
  'UPDATE_ALLERGY_VERIFICATION_STATUS_SUCCESS';
export const FETCH_ALLERGIES = 'FETCH_ALLERGIES';
export const FETCH_ALLERGIES_REQUEST = 'FETCH_ALLERGIES_REQUEST';
export const FETCH_ALLERGIES_SUCCESS = 'FETCH_ALLERGIES_SUCCESS';
export const ADD_ALLERGY = 'ADD_ALLERGY';
export const ADD_ALLERGY_FLOW = 'ADD_ALLERGY_FLOW';
export const EDIT_ALLERGY = 'EDIT_ALLERGY';
export const EDIT_ALLERGY_FLOW = 'EDIT_ALLERGY_FLOW';

// Actions - Special Populations
export const UPDATE_SPECIAL_POPULATIONS_VERIFICATION_STATUS_SUCCESS =
  'UPDATE_SPECIAL_POPULATIONS_VERIFICATION_STATUS_SUCCESS';
export const FETCH_SPECIAL_POPULATIONS = 'FETCH_SPECIAL_POPULATIONS';
export const FETCH_SPECIAL_POPULATIONS_REQUEST = 'FETCH_SPECIAL_POPULATIONS_REQUEST';
export const FETCH_SPECIAL_POPULATIONS_SUCCESS = 'FETCH_SPECIAL_POPULATIONS_SUCCESS';
export const ADD_SPECIAL_POPULATION = 'ADD_SPECIAL_POPULATION';
export const EDIT_SPECIAL_POPULATION = 'EDIT_SPECIAL_POPULATION';
export const VERIFY_SPECIAL_POPULATIONS = 'VERIFY_SPECIAL_POPULATIONS';

// Actions - Doses Regimen Reasons
export const FETCH_DOSES_REGIMEN_REASONS = 'FETCH_DOSES_REGIMEN_REASONS';

// Actions - Clinical Data
export const FETCH_CLINICAL_DATA = 'FETCH_CLINICAL_DATA';
export const SAVE_CLINICAL_DATA = 'SAVE_CLINICAL_DATA';
export const CLEAR_CLINICAL_DATA = 'CLEAR_CLINICAL_DATA';
export const SAVE_QUESTIONNAIRE_ANSWERS = 'SAVE_QUESTIONNAIRE_ANSWERS';
export const SUBMIT_QUESTIONNAIRE = 'SUBMIT_QUESTIONNAIRE';
export const CLINICAL_DATA_TYPE_STATUS = [
  { label: 'Active', value: 1 },
  { label: 'Deprecated', value: 0 },
];
export const CLINICAL_DATA_TYPE_OPTIONS = [
  { label: 'Free text', value: 'free text' },
  { label: 'Integer', value: 'integer' },
  { label: 'Multiselect', value: 'multiselect' },
  { label: 'Numeric', value: 'numeric' },
  { label: 'Options', value: 'list' },
];

export const TEXT_CLINICAL_DATA_TYPE_OPTIONS = ['free text', 'text'];

// Actions - Document Viewer
export const SET_DOCUMENT_VIEWER = 'SET_DOCUMENT_VIEWER';

// Error Codes
export const ASYNC_REDUX_ERROR = 'ASYNC_REDUX_ERROR';

export const FETCH_NOTES_SIDEBAR = 'FETCH_NOTES_SIDEBAR';
export const FETCH_NOTES_SIDEBAR_FAILURE = 'FETCH_NOTES_SIDEBAR_FAILURE';
export const UPDATE_NOTES_SIDEBAR = 'UPDATE_NOTES_SIDEBAR';
export const TOGGLE_PIN = 'TOGGLE_PIN';
export const TOGGLE_PIN_WORKFLOW = 'TOGGLE_PIN_WORKFLOW';
export const ARCHIVE_NOTE = 'ARCHIVE_NOTE';
export const RESOLVE_COMMUNICATION_NOTE = 'RESOLVE_COMMUNICATION_NOTE';
export const ARCHIVE_NOTE_FORM = 'ARCHIVE_NOTE_FORM';
export const FETCH_NOTES_ARCHIVED = 'FETCH_NOTES_ARCHIVED';
export const PIN_NOTE_FORM = 'PIN_NOTE_FORM';

// Side Bars
export const PATIENT_SIDEBAR = 'patient';
export const TASK_SIDEBAR = 'tasks';
export const SEARCH_SIDEBAR = 'search';
export const SCHEDULE_SIDEBAR = 'schedule';
export const REPORT_SIDEBAR = 'report';
export const NO_SIDEBAR = 'no_sidebar';
export const TOGGLE_LEFT_DRAWER = 'TOGGLE_LEFT_DRAWER';
export const TOGGLE_FAB_PANEL = 'TOGGLE_FAB_PANEL';
export const TOGGLE_PHONE_PANEL = 'TOGGLE_PHONE_PANEL';
export const TOGGLE_ONGOING_PHONE_CALL = 'TOGGLE_ONGOING_PHONE_CALL';
export const SET_PHONE_NOTE_TAGS = 'SET_PHONE_NOTE_TAGS';

// View
export const SCREEN_RESIZE = 'SCREEN_RESIZE';

// Form Displays
export const TOGGLE_DISPLAY_ADD_THERAPY = 'TOGGLE_DISPLAY_ADD_THERAPY';
export const TOGGLE_DISPLAY_ADD_THERAPY_MODAL = 'TOGGLE_DISPLAY_ADD_THERAPY_MODAL';
export const TOGGLE_DISPLAY_ADD_CONTACT = 'TOGGLE_DISPLAY_ADD_CONTACT';
export const TOGGLE_DISPLAY_ADD_INCOME = 'TOGGLE_DISPLAY_ADD_INCOME';
export const TOGGLE_DISPLAY_ADD_MEDICAL_INSURANCE = 'TOGGLE_DISPLAY_ADD_MEDICAL_INSURANCE';
export const TOGGLE_DISPLAY_ADD_PBM_INSURANCE = 'TOGGLE_DISPLAY_ADD_PBM_INSURANCE';
export const TOGGLE_DISPLAY_ADD_FINANCIAL_ASSISTANCE = 'TOGGLE_DISPLAY_ADD_FINANCIAL_ASSISTANCE';

export const FETCH_DATA_DC_PROTOCOLS = 'FETCH_DATA_DC_PROTOCOLS';
export const DATA_DC_PROTOCOLS_REQUEST = 'DATA_DC_PROTOCOLS_REQUEST';
export const DATA_DC_PROTOCOLS_SUCCESS = 'DATA_DC_PROTOCOLS_SUCCESS';
export const DATA_DC_PROTOCOLS_FAILURE = 'DATA_DC_PROTOCOLS_FAILURE';
export const DATA_DC_PROTOCOLS_RESET = 'DATA_DC_PROTOCOLS_RESET';
export const RESET_DC_PROTOCOLS = 'RESET_DC_PROTOCOLS';

// Tasklist Sort
export const TOGGLE_TASKLIST_SORT = 'TOGGLE_TASKLIST_SORT';
export const SET_TASKLIST_SORT = 'SET_TASKLIST_SORT';

// User Custom Lists
export const FETCH_USER_CUSTOM_LISTS = 'FETCH_USER_CUSTOM_LISTS';
export const FETCH_USER_CUSTOM_LISTS_SUCCESS = 'FETCH_USER_CUSTOM_LISTS_SUCCESS';
export const FETCH_USER_CUSTOM_LISTS_FAILURE = 'FETCH_USER_CUSTOM_LISTS_FAILURE';
export const ADD_USER_CUSTOM_LIST = 'ADD_USER_CUSTOM_LIST';
export const DELETE_USER_CUSTOM_LIST_ITEM = 'DELETE_USER_CUSTOM_LIST_ITEM';
export const ADD_USER_CUSTOM_LIST_ITEM = 'ADD_USER_CUSTOM_LIST_ITEM';
export const LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST =
  'LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST';
export const LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT =
  'LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT';
export const LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST_SUCCESS =
  'LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST_SUCCESS';
export const LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT_SUCCESS =
  'LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT_SUCCESS';
export const LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_FAILURE =
  'LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_FAILURE';
export const ADD_USER_CUSTOM_LIST_WORKFLOW = 'ADD_USER_CUSTOM_LIST_WORKFLOW';
export const ADD_USER_CUSTOM_LIST_WORKFLOW_FAILURE = 'ADD_USER_CUSTOM_LIST_WORKFLOW_FAILURE';
export const ADD_USER_CUSTOM_LIST_WORKFLOW_SUCCESS = 'ADD_USER_CUSTOM_LIST_WORKFLOW_SUCCESS';
export const UPDATE_ADDED_USER_CUSTOM_LIST_ITEM = 'UPDATE_ADDED_USER_CUSTOM_LIST_ITEM';
export const UPDATE_ADDED_USER_CUSTOM_LIST_ITEM_FAILURE =
  'UPDATE_ADDED_USER_CUSTOM_LIST_ITEM_FAILURE';

// Local Storage
export const LOCAL_STORAGE_KEYS = {
  SCHEDULE: 'schedule',
  REPORT: 'report',
  WORKLIST: 'worklist',
};

// Action History
export const FETCH_HISTORY = 'FETCH_HISTORY';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';

// Period units
export const PERIOD_UNITS = {
  1: 'day(s)',
  7: 'week(s)',
  29: 'month(s)',
  30: 'month(s)',
  31: 'month(s)',
  365: 'year(s)',
  366: 'year(s)',
};

export const INTERVENTION_SEVERITIES = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const INTERVENTION_TYPES = {
  ADHERENCE: 1,
  CARE_PLAN: 2,
  DDI: 3,
  ADR: 4,
  REGIMEN: 5,
  REFERRAL_OF_SERVICE: 6,
  LAB: 7,
  OTHER_CLINICAL: 8,
  DUR: 9,
  LINKAGE_TO_CARE: 10,
  PRODUCT_ISSUE: 11,
  DRUG_INFORMATION: 12,
  VACCINE: 13,
  HOSPITALIZATION: 14,
  INFECTION_CONTROL: 15,
};

// Date names
export const DATE_OPTIONS = {
  ALL: 'ALL',
  LAST_90_DAYS: 'LAST_90_DAYS',
  OVERDUE: 'OVERDUE',
  YESTERDAY: 'YESTERDAY',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  NEXT_3_DAYS: 'NEXT_3_DAYS',
  NEXT_7_DAYS: 'NEXT_7_DAYS',
  NEXT_14_DAYS: 'NEXT_14_DAYS',
  NEXT_30_DAYS: 'NEXT_30_DAYS',
  LAST_WEEK: 'LAST_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  NEXT_6_MONTHS: 'NEXT_6_MONTHS',
};

export const PERIOD_DAYS_UNITS = 'days';
export const PERIOD_WEEKS_UNITS = 'weeks';

export const PERIOD_DAYS_SELECTOR_UNITS = [
  { label: 'Days', value: PERIOD_DAYS_UNITS },
  { label: 'Weeks', value: PERIOD_WEEKS_UNITS },
];

export const PERIOD_DAYS_SELECTOR_UNITS_TASKLIST = [
  { label: 'All', value: DATE_OPTIONS.ALL },
  { label: 'Next 7 days', value: DATE_OPTIONS.NEXT_7_DAYS },
  { label: 'Next 14 days', value: DATE_OPTIONS.NEXT_14_DAYS },
  { label: 'Next 30 days', value: DATE_OPTIONS.NEXT_30_DAYS },
  { label: 'Next 6 Months', value: DATE_OPTIONS.NEXT_6_MONTHS },
];

export const PERIOD_NAMES = {
  thisWeek: 'This week',
  nextWeek: 'Next Week',
  nextMonth: 'This Month',
  next6Months: 'Next 6 Months',
  other: 'Other',
};

export const CATEGORY_OUTSTANDING = 1;
export const CATEGORY_IN_PROGRESS = 2;
export const CATEGORY_DONE = 3;
export const CATEGORY_ARCHIVED = 4;

export const THERAPY_ADMINISTRATION_ARCHIVED = [1024, 1025, 1026, 1027, 1028, 1029, 1030];

export const RELATIONSHIP_SELF_ID = 8;
export const NONE_INSURANCE_TYPE_ID = 5;

export const TOGGLE_RECONCILIATION_MODAL = 'TOGGLE_RECONCILIATION_MODAL';
export const TOGGLE_NEEDS_RECONCILIATION = 'TOGGLE_NEEDS_RECONCILIATION';
export const RESOLVE_RECONCILIATION_MEDICATION_REQUEST =
  'RESOLVE_RECONCILIATION_MEDICATION_REQUEST';
export const RESOLVE_RECONCILIATION_MEDICATION_SUCCESS =
  'RESOLVE_RECONCILIATION_MEDICATION_SUCCESS';
export const LINK_RECONCILIATION_MEDICATION_REQUEST = 'LINK_RECONCILIATION_MEDICATION_REQUEST';
export const LINK_RECONCILIATION_MEDICATION_SUCCESS = 'LINK_RECONCILIATION_MEDICATION_SUCCESS';
export const RESOLVE_RECONCILIATION_PATIENT_REQUEST = 'RESOLVE_RECONCILIATION_PATIENT_REQUEST';
export const RESOLVE_RECONCILIATION_PATIENT_SUCCESS = 'RESOLVE_RECONCILIATION_PATIENT_SUCCESS';
export const RESOLVE_RECONCILIATION_ALLERGY_REQUEST = 'RESOLVE_RECONCILIATION_ALLERGY_REQUEST';
export const RESOLVE_RECONCILIATION_ALLERGY_SUCCESS = 'RESOLVE_RECONCILIATION_ALLERGY_SUCCESS';
export const RESOLVE_RECONCILIATION_SUCCESS = 'RESOLVE_RECONCILIATION_SUCCESS';
export const FETCH_RECONCILIATIONS_REQUEST = 'FETCH_RECONCILIATIONS_REQUEST';
export const FETCH_RECONCILIATION_PATIENT_SUCCESS = 'FETCH_RECONCILIATION_PATIENT_SUCCESS';
export const FETCH_RECONCILIATION_MEDICATION_SUCCESS = 'FETCH_RECONCILIATION_MEDICATION_SUCCESS';
export const FETCH_RECONCILIATION_ALLERGY_SUCCESS = 'FETCH_RECONCILIATION_ALLERGY_SUCCESS';

export const ACCEPTED_FILE_EXTENSIONS = {
  doc: {},
  docx: {},
  png: { print: true, printAs: 'image' },
  gif: { print: true, printAs: 'image' },
  jpg: { print: true, printAs: 'image' },
  xls: { viewingDisabled: true },
  xlsx: { viewingDisabled: true },
  ppt: {},
  pptx: {},
  pdf: { print: true, printAs: 'pdf' },
};

export const UPLOADED_DOCUMENT_LANGUAGE = {
  unscanned: ['This file has not yet been scanned for potential viruses.'],
  unsafe: [
    'This file has been identified as malware or spam.',
    'Archive this file and try re-uploading.',
  ],
};

// Income and Benefits
export const INCOME = 'Income';
export const MEDICAL_INSURANCE = 'Medical Insurance';
export const PBM_INSURANCE = 'PBM Insurance';
export const FINANCIAL = 'Financial';

export const PRIOR_AUTHORIZATION_TAT_OVERRIDE_TOTAL = 'TOTAL';
export const PRIOR_AUTHORIZATION_TAT_OVERRIDE_ERROR = 'ERROR';

export const BENEFITS_INVESTIGATION_FORM = 'BENEFITS_INVESTIGATION_FORM';
export const FETCH_ELIGIBILITY_CHECK_HISTORY = 'FETCH_ELIGIBILITY_CHECK_HISTORY';
export const UPDATE_PATIENT_INSURANCES = 'UPDATE_PATIENT_INSURANCES';
export const FETCH_BENEFITS_INVESTIGATION_HISTORY = 'FETCH_BENEFITS_INVESTIGATION_HISTORY';
export const FETCH_PHARMACIES = 'FETCH_PHARMACIES';

export const TASK_TYPES_MANAGING_CLINIC = ['PA', 'FA', 'DC', 'INT', 'OR', 'MR', 'CSL', 'QRE'];

export const SPECIAL_POPULATIONS_NOT_VERIFIED = 1;
export const SPECIAL_POPULATIONS_VERIFIED = 2;
export const SPECIAL_POPULATIONS_NEED_REVERIFY = 3;
export const SPECIAL_POPULATIONS_VERIFIED_AND_REVIEWED = 4;

export const SPECIAL_POPULATIONS_ADHERENCE_PACKAGING = 1;
export const SPECIAL_POPULATIONS_PREGNANT = 2;
export const SPECIAL_POPULATIONS_GERIATRIC = 4;
export const SPECIAL_POPULATIONS_PEDIATRIC = 5;

export const ALLERGIES_NOT_VERIFIED = 1;
export const ALLERGIES_VERIFIED = 2;
export const ALLERGIES_NEED_REVERIFY = 3;
export const ALLERGIES_VERIFIED_AND_REVIEWED = 4;

export const ALLERGIES_ACTIVE = 1;

// Action communication
export const SEND_SMS = 'SEND_SMS';
export const VALIDATE_PHI = 'VALIDATE_PHI';
export const INVALIDATE_QUESTIONNAIRE = 'INVALIDATE_QUESTIONNAIRE';
export const QUESTIONNAIRE_LINK = '<questionnaire-link>';
export const QUESTIONNAIRE = 'questionnaire';
// eslint-disable-next-line max-len
export const SMS_COMPLETE_QUESTIONNAIRE_LINK_MSG = `#PHARMACY#Please complete the following health-related questionnaire ${QUESTIONNAIRE_LINK}`;
export const SMS_STATUS_DELETED = 'DELETED';
export const SMS_STATUS_PENDING = 'PENDING';
export const SMS_STATUS_OPENED = 'OPENED';
export const OPT_IN = 'Opt in';
export const OPT_OUT = 'Opt out';
export const SMS_MOBILE = 'Mobile';
export const SUBMITTED_BY_PATIENT = 'Submitted by Patient';
export const SUBMITTED_BY_ARBOR_USER = 'Submitted by Arbor User';

export const TASK_TITLE_COLORS = {
  SUCCESS: 'Success',
  DANGER: 'Danger',
  INFO: 'Info',
};

export const MESSAGE_TYPE = {
  SMS: 'SMS',
  EMAIL: 'Email',
};

export const DECLINES_TO_PROVIDE_EMAIL = 1;
export const NO_EMAIL = 2;

const NEW_PATIENT_DUPLICATE_NAME = 'duplicate_name';
const NEW_PATIENT_DUPLICATE_MRN = 'duplicate_mrn';
const NEW_PATIENT_NO_MRN = 'no_mrn';
const NEW_PATIENT_DUPLICATE_INACTIVE = 'duplicate_patient_inactive';

const DEFAULT_CONTACT_SUPPORT_LINK = 'https://trellisrx.atlassian.net/servicedesk/customer/portals';
export const DEFAULT_SUPPORT_TICKET_URL =
  'https://trellisrx.atlassian.net/servicedesk/customer/portal/3/group/10/create/35';

const PATIENT_ALLERGY_SEVERITY_SEVERE_ID = 5;
const PATIENT_ALLERGY_STATUS_ACTIVE = 1;
const MEDICATION_THERAPY_STATUS_ACTIVE = 1;
const PATIENT_SPECIAL_POPULATION_STATUS_ACTIVE = 1;

const NO_ALLERGY_INFORMATION_AVAILABLE_ID = 69993;
const NO_KNOWN_ALLERGIES = 'No Known Allergies';
const NO_ALLERGY_INFORMATION_AVAILABLE = 'No Allergy Information Available';

const FETCH_ALLERGY_LOOKUPS = 'FETCH_ALLERGY_LOOKUPS';
const FETCH_ALLERGY_REACTION_TYPES = 'FETCH_ALLERGY_REACTION_TYPES';
// Drug utilization review

const DUR_NOT_VERIFIED = 'DUR_NOT_VERIFIED'; // TODO replace with actual constant
const DUR_VERIFIED = 'DUR_VERIFIED'; // TODO replace with actual constant
const DUR_NEED_REVERIFY = 'DUR_NEED_REVERIFY'; // TODO replace with actual constant

const DUR_SEVERITY_SEVERE = 1;
const DUR_SEVERITY_SERIOUS = 2;
const DUR_SEVERITY_MODERATE = 3;
const DUR_SEVERITY_UNKNOWN = 4;

export const DUR_INTERACTION_TYPE_DDI = 'DDI';
export const DUR_INTERACTION_TYPE_DAI = 'DAI';
export const DUR_INTERACTION_TYPE_DFI = 'DFI';
export const DUR_INTERACTION_TYPE_DDC = 'DDCI';
export const DUR_DUPLICATE_THERAPY_ALERT = 'DPT';

export const DUR_TRIGGER_CHECK_INTERACTION_REQUEST = 'DUR_TRIGGER_CHECK_INTERACTION_REQUEST';
export const DUR_TRIGGER_CHECK_INTERACTION_SUCCESS = 'DUR_TRIGGER_CHECK_INTERACTION_SUCCESS';
export const DUR_TRIGGER_CHECK_INTERACTION_FAILURE = 'DUR_TRIGGER_CHECK_INTERACTION_FAILURE';
export const DUR_STATUS_TRANSITION_REQUEST = 'DUR_STATUS_TRANSITION_REQUEST';
export const DUR_STATUS_TRANSITION_SUCCESS = 'DUR_STATUS_TRANSITION_SUCCESS';
export const DUR_STATUS_TRANSITION_FAILURE = 'DUR_STATUS_TRANSITION_FAILURE';
export const DUR_CREATE_NEW_REQUEST = 'DUR_CREATE_NEW_REQUEST';
export const DUR_CREATE_NEW_SUCCESS = 'DUR_CREATE_NEW_SUCCESS';
export const DUR_CREATE_NEW_FAILURE = 'DUR_CREATE_NEW_FAILURE';

const DUR_FETCH_HISTORY_REQUEST = 'DUR_FETCH_HISTORY_REQUEST';
const DUR_FETCH_HISTORY_SUCCESS = 'DUR_FETCH_HISTORY_SUCCESS';
const DUR_FETCH_HISTORY_FAILURE = 'DUR_FETCH_HISTORY_FAILURE';

export const DUR_FETCH_BY_ID = 'DUR_FETCH_BY_ID';
export const DUR_FETCH_BY_ID_SUCCESS = 'DUR_FETCH_BY_ID_SUCCESS';

const DUR_TOGGLE_SHOW_NEW_INTERACTIONS = 'DUR_TOGGLE_SHOW_NEW_INTERACTIONS';

const INTERACTION_FINDER_TRIGGER = {
  Therapy: 'Therapy',
  Medication: 'Medication',
  Allergy: 'Allergy',
  SpecialPopulation: 'SpecialPopulation',
  PatientProblem: 'PatientProblem',
  User: 'User',
  Unknown: 'Unknown',
};

const VERIFICATION_STATUS_UNTOUCHED = 0;
const VERIFICATION_STATUS_TOUCHED = 1;
const VERIFICATION_STATUS_VERIFIED = 2;
const VERIFICATION_STATUS_REVERIFIED = 3;
const MARK_EMR_CONFIRMED_MEDICATIONS_SUCCESS = 'MARK_EMR_CONFIRMED_MEDICATIONS_SUCCESS';
const MARK_EMR_CONFIRMED_ALLERGIES_SUCCESS = 'MARK_EMR_CONFIRMED_ALLERGIES_SUCCESS';
const MARK_PATIENT_CONFIRMED_MEDICATIONS_SUCCESS = 'MARK_PATIENT_CONFIRMED_MEDICATIONS_SUCCESS';
const MARK_PATIENT_CONFIRMED_ALLERGIES_SUCCESS = 'MARK_PATIENT_CONFIRMED_ALLERGIES_SUCCESS';
const MARK_PATIENT_CONFIRMED_SPECIAL_POPULATIONS = 'MARK_PATIENT_CONFIRMED_SPECIAL_POPULATIONS';

export {
  NEW_PATIENT_DUPLICATE_NAME,
  NEW_PATIENT_DUPLICATE_MRN,
  NEW_PATIENT_NO_MRN,
  NEW_PATIENT_DUPLICATE_INACTIVE,
  DEFAULT_CONTACT_SUPPORT_LINK,
  PATIENT_ALLERGY_SEVERITY_SEVERE_ID,
  PATIENT_SPECIAL_POPULATION_STATUS_ACTIVE,
  NO_ALLERGY_INFORMATION_AVAILABLE_ID,
  NO_KNOWN_ALLERGIES,
  NO_ALLERGY_INFORMATION_AVAILABLE,
  PATIENT_ALLERGY_STATUS_ACTIVE,
  MEDICATION_THERAPY_STATUS_ACTIVE,
  FETCH_ALLERGY_LOOKUPS,
  FETCH_ALLERGY_REACTION_TYPES,
  DUR_NOT_VERIFIED,
  DUR_VERIFIED,
  DUR_NEED_REVERIFY,
  DUR_SEVERITY_SEVERE,
  DUR_SEVERITY_SERIOUS,
  DUR_SEVERITY_MODERATE,
  DUR_SEVERITY_UNKNOWN,
  VERIFICATION_STATUS_UNTOUCHED,
  VERIFICATION_STATUS_TOUCHED,
  VERIFICATION_STATUS_VERIFIED,
  VERIFICATION_STATUS_REVERIFIED,
  INTERACTION_FINDER_TRIGGER,
  DUR_FETCH_HISTORY_REQUEST,
  DUR_FETCH_HISTORY_SUCCESS,
  DUR_FETCH_HISTORY_FAILURE,
  DUR_TOGGLE_SHOW_NEW_INTERACTIONS,
  MARK_EMR_CONFIRMED_MEDICATIONS_SUCCESS,
  MARK_EMR_CONFIRMED_ALLERGIES_SUCCESS,
  MARK_PATIENT_CONFIRMED_MEDICATIONS_SUCCESS,
  MARK_PATIENT_CONFIRMED_ALLERGIES_SUCCESS,
  MARK_PATIENT_CONFIRMED_SPECIAL_POPULATIONS,
};

export const GA_QUESTIONNAIRE_PATIENT_VIEW = 'GA_QUESTIONNAIRE_PATIENT_VIEW';
export const GA_QUESTIONNAIRE_PATIENT_SUBMIT = 'GA_QUESTIONNAIRE_PATIENT_SUBMIT';
export const GA_QUESTIONNAIRE_PATIENT_ANSWER_QUESTION = 'GA_QUESTIONNAIRE_PATIENT_ANSWER_QUESTION';
export const GA_QUESTIONNAIRE_SEND_LINK = 'GA_QUESTIONNAIRE_SEND_LINK';
export const PATIENT_QUESTIONNAIRE = 'PATIENT_QUESTIONNAIRE';
export const PATIENT_QUESTIONNAIRE_ACTIONS = {
  VIEW: 'VIEW',
  UPDATE: 'UPDATE',
  SUBMIT: 'SUBMIT',
  SEND: 'SEND',
};
export const SEND_INVOICING_FIELD = 'SEND_INVOICING_FIELD';
export const UPDATE_LOCAL_USER_PREFERENCES_INVOICING = 'UPDATE_LOCAL_USER_PREFERENCES_INVOICING';
// CSL complete Form
export const COMPLETE_COUNSELING_FORM = 'COMPLETE_COUNSELING_FORM';
export const COUNSELING_METHODS = [
  { label: 'In Person', value: CounselingMethod.InPerson },
  { label: 'On The Phone', value: CounselingMethod.OnThePhone },
  { label: 'Chart Review', value: CounselingMethod.ChartReview },
];

export const EDIT_COUNSELING_DRAFT = 'EDIT_COUNSELING_DRAFT';

export const DATABASE_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATABASE_DATETIME_FORMAT_WITHOUT_SECONDS = 'YYYY-MM-DD HH:mm';
export const DATABASE_DATE_FORMAT = 'YYYY-MM-DD';
export const ALLERGY_ACTIVE_STRING = 'Active';

// Audit
export const FETCH_AUDIT_SUCCESS = 'FETCH_AUDIT_SUCCESS';
export const RESOLVE_AUDIT = 'RESOLVE_AUDIT';
export const RESOLVE_AUDIT_FLOW = 'RESOLVE_AUDIT_FLOW';

export const FETCH_AUDITS_REQUEST = 'FETCH_AUDITS_REQUEST';
export const AUDIT_NOT_RESOLVED = 'Not Resolved';

export const AUDIT_DEMOGRAPHICS = 1;
export const AUDIT_INB = 2;
export const AUDIT_ALLERGIES = 3;
export const AUDIT_THERAPIES = 4;

export const AUDIT_DEF_MEDLIST = 7;

export const ENSURE_EMAIL_ADDRESSED = 'Ensure Email Addressed';
export const ENSURE_SMS_ADDRESSED = 'Ensure SMS Addressed';
export const ENSURE_EMERGENCY_CONTACT_ADDRESSED = 'Ensure Emergency Contact Addressed';
export const ENSURE_PATIENT_INFORMATION_VERIFIED = 'Ensure Patient Information Verified';
export const ENSURE_PBM_INSURANCE_ADDRESSED = 'Ensure PBM Insurance Addressed';
export const ENSURE_MEDICAL_INSURANCE_ADDRESSED = 'Ensure Medical Insurance Addressed';
export const ENSURE_ALLERGIES_VERIFIED = 'Ensure Allergies are Verified';
export const ENSURE_MEDICATION_LIST_VERIFIED = 'Ensure Medication List is Verified';
export const THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE =
  'Therapy Start Date should be after Referral Date for New Therapies';
export const ENSURE_THERAPY_MUST_MOVE_TO_UPDATE_STATUS =
  'Ensure Therapy must be moved to On Therapy Status';

export const THERAPY_SHOULD_NOT_BE_ON_ON_HOLD_FOR_TOO_LONG =
  'Therapy should not be on On Hold state for too long';

export const SERVICE_ENROLLMENT_SHOULD_BE_SET = 'Service enrollment should be set';

export const THERAPY_SHOULD_HAVE_START_DATE = 'Therapy should have a start date';

export const PRESCRIBING_CLINIC_METHOD = {
  USE_SAME: 'useSame',
  USE_LOOKUP: 'useLookup',
  USE_NULL: 'useNull',
};
export const VACCINATION_SOURCES = [
  {
    label: 'Caregiver',
    value: 1,
  },
  {
    label: 'EMR',
    value: 2,
  },
  {
    label: 'Patient',
    value: 3,
  },
];

export const DUR_VACCINATION_FORM = 'DUR_VACCINATION_FORM';

export const FAILED_THERAPIES_ID = 346;

export const INVOICING_SUMMARY_TYPES = {
  PERCENTAGE: 'percentage',
  CURRENCY: 'currency',
  NUMBER: 'number',
};

export const INVOICING_STAGES = {
  PREPPED: 'prepped',
  REJECTED: 'rejected',
  VALIDATED: 'validated',
  FINANCE_REVIEW: 'finance_review',
  FINALIZED: 'finalized',
  NONE: 'None',
};

export const SOURCE_TYPES = {
  MAIN: 'main',
  ADJUSTMENT: 'adjustment',
};

export const PENDING_DOCUMENT_LOSS = {
  header: 'Wait! You might lose some data.',
  body: 'If you continue you will lose the following documents:',
};

export const ADD_ADDRESS_FORM = {
  line2: {
    label: 'Line 2',
    placeholder: 'APT, STE, BLDG, ETC.',
  },
};

export const RECENT_PATIENT_LIMIT = 10;

export const SEARCH_SETTINGS = {
  MIN_CHARS_TO_SEARCH: 2,
  ADVANCED_SEARCH_RESULT_TITLE: 'Advanced Search Patients',
  RECENT_PATIENTS_RESULT_TITLE: 'Recent Patients',
  GLOBAL_SEARCH_RESULT_TITLE: 'Patients',
  NO_RESULTS_MESSAGE: 'No Results',
};

export const CONTACT_FORM = {
  LABELS: {
    DECLINED_TO_PROVIDE_EMERGENCY_CONTACT: 'Patient declined to provide an emergency contact',
    DOESNT_HAVE_EMERGENCY_CONTACT: "Patient doesn't have an emergency contact",
  },
  FIELD_NAMES: {
    DECLINED_TO_PROVIDE_EMERGENCY_CONTACT: 'declined_to_provide_emergency_contact',
    DOESNT_HAVE_EMERGENCY_CONTACT: 'doesnt_have_emergency_contact',
  },
};

export const CONTACT_TYPE_OPTIONS = {
  LABELS: {
    EMERGENCY_CONTACT: 'Emergency Contact',
    RESPONSIBLE_CONTACT: 'Responsible Party',
    ADDITIONAL_CONTACT: 'Additional Contact',
  },
  FIELD_NAMES: {
    EMERGENCY_CONTACT: 'is_emergency',
    RESPONSIBLE_CONTACT: 'is_responsible',
    ADDITIONAL_CONTACT: 'is_additional',
  },
};

export const IS_ADDITIONAL_FIELD = {
  label: CONTACT_TYPE_OPTIONS.LABELS.ADDITIONAL_CONTACT,
  value: CONTACT_TYPE_OPTIONS.FIELD_NAMES.ADDITIONAL_CONTACT,
};

export const CONTACT_RELATIONSHIPS = {
  CHILD: 'Child',
  PARENT: 'Parent',
  SPOUSE: 'Spouse',
  OTHER: 'Other',
  PROVIDER: 'Provider',
  SIBLING: 'Sibling',
  SIGNIFICANT_OTHER: 'Significant Other',
};

export const FREQUENCY = {
  MONTH: 30,
};

export const OTHER_PRONOUN = 'Other';

export const REPORTS_TAB = 0;
export const INVOICING_TAB = 1;

export const THERAPY_IN_PROGRESS_CATEGORIES = [CATEGORY_OUTSTANDING, CATEGORY_IN_PROGRESS];
export const THERAPY_STATUS_FILTERS = {
  IN_PROGRESS_THERAPIES: 1,
  INACTIVE_THERAPIES: 2,
};

export const TASK_CONTEXT = 'task';
export const THERAPY_CONTEXT = 'therapy';

export const FILL_COORDINATION_STATUS = {
  COMPLETED: 4002,
  IN_PROGRESS_PATIENT_RESPONSE_READY: 4011,
  IN_PROGRESS_CALL_PATIENT: 4010,
};

export const FILL_COORDINATION_STATUS_SECURE_LINK = [4009, 4010, 4011, 4012];

export const PATIENT_RX_DELIVERY_PREFERENCES = {
  PAYMENT_METHODS: {
    VIEW_MODE: {
      PREFIX: '****-****-****-',
      LABEL: 'Payment Method (Credit Card) on File',
    },
    ERRORS: {
      LENGTH_4: 'Please enter a 4 digit card number.',
    },
    PRIMARY_FIELD: 'payment_methods',
    LABELS: {
      LAST_4: 'Credit Card 4 last digits *',
      SECTION_TITLE: 'Payment Method on File',
      // Also used on the FC form to keep consistant
      ADD_BUTTON: 'Payment Method',
      PAYMENT_TYPE: 'Payment Method Type',
      EXPIRATION_DATE: 'Expiration Date',
      THERAPY_ASSOCIATED: 'Therapy Associated *',
      ORDER: 'Order',
    },
    FIELD_MASK: '****-****-****-####',
    FIELDS: {
      ID: 'id',
      CARD_NUM: 'card_num',
      DELETED: 'deleted',
      UPDATED: 'updated',
      TYPE: 'payment_type',
      EXPIRATION: 'expiration_date',
      ORDER: 'payment_order',
    },
  },
};

export const BIN_LENGTH_VALIDATION = 'Must be 6 characters';

export const POWERED_BY = 'Powered by Trellis';

export const BENEFITS_INVESTIGATION_DEFAULT_SOFTWARE_CERT = 'VD0TQR';

export const ROUTES = {
  APPLICATION_MANAGER: '/application-manager',
};

export const DISPENSING_STATUSES = {
  UNDECIDED: 'Undecided',
  OPT_IN: 'Opt in',
  OPT_OUT: 'Opt out',
};

export const DISPENSING_UNDECIDED_REASONS = {
  NO_YET_OFFERED: 'Not yet offered to patient',
  PATIENT_DEFERRED: 'Patient deferred decision',
};

export const DIPENSING_ENROLLMENT_STATUS_OPT_IN = 2;

export const GTD_SPECIALITY_TYPES = [
  { label: 'CDM', value: 'CDM' },
  { label: 'L', value: 'L' },
  { label: 'REMOVED', value: 'REMOVED' },
  { label: 'SP', value: 'SP' },
  { label: 'SP-PA', value: 'SP-PA' },
];

export const OTHER = 'Other';
export const COMMERCIAL = 'Commercial';

export const ELIGIBILITY_CHECK_MESSAGE = {
  lis_level: {
    position: 8,
    length: 1,
  },
  lis_effective_date: {
    position: 17,
    length: 8,
  },
  lis_termination_date: {
    position: 34,
    length: 8,
  },
  lis_medicare_plan_type: {
    position: 48,
    length: 4,
  },
  lis_medicare_beneficiary_id: {
    position: 57,
    length: 11,
  },
  lis_effective_date_mbi: {
    position: 72,
    length: 8,
  },
};

export const PERSON_CODE_DEFAULT_VALUE = '001';
export const RELATION_CODE_DEFAULT_VALUE = '1';

export const ADD_TRELLIS_THERAPEUTIC_CLASS = 'ADD_TRELLIS_THERAPEUTIC_CLASS';
export const DOSIS_REGIMEN_CHARACTER_LIMIT = 255;
export const RX_TYPES = [
  { value: 'SP', label: 'SP' },
  { value: '', label: 'Non-GTD' },
  { value: 'CDM', label: 'CDM' },
];
export const INSURANCE_RELATION_CODE_MAPPING = {
  11: '0',
  8: '1',
  1: '2',
  9: '3',
};
export const MIDNIGHT_24_HOUR = 0;
export const ONE_MIN_BEFORE_MIDNIGHT_24_HOUR = 2359;

export const EIGHT_AM = '08:00';
export const EIGHT_PM = '20:00';
// SMS send errors
export const PHI_ENTRIES_FOUND = 'phi_entries_found';
export const PATIENT_WITH_RECEIVING_NUMBER_NOT_FOUND = 'patient_with_number_not_found';
export const PATIENT_IS_NOT_OPTED_IN = 'patient_is_not_opted_in';
export const PATIENT_INACTIVE = 11003;

export const EXPIRATION_DATE_FORMAT = 'MM/YYYY';

export const USER_STATUS = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

export const TRELLIS_USER = 1;

// Actions  - ICD/GPI Protocols

export const PROTOCOL_FREQUENCY = [
  { label: 'Ongoing', value: 'ongoing' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Suggested', value: 'suggested' },
];

export const PROTOCOL_FREQUENCY_UNIT = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
  { label: '24', value: 24 },
  { label: '25', value: 25 },
  { label: '26', value: 26 },
  { label: '27', value: 27 },
  { label: '28', value: 28 },
  { label: '29', value: 29 },
  { label: '30', value: 30 },
];

export const PROTOCOL_FREQUENCY_TYPE = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
];

export const CUSTOMER_BRANCH_TTC_HIGH_DISPLAY_TEXT = 'High';
export const CUSTOMER_BRANCH_TTC_LOW_DISPLAY_TEXT = 'Low';

export const DEFAULT_AR_TASKS_LIMIT = 20;
export const MAX_AR_TASKS_LIMIT = 99;

export const MED_SYNC_STATUS = {
  UNDECIDED: 1,
  OPT_IN: 2,
  OPT_OUT: 3,
};

export const THERAPY_MED_SYNC = {
  NOT_IN_MED_SYNC: 0,
  IN_MED_SYNC: 1,
};

export const THERAPY_ADHERENCE_PACKAGE = {
  ADHERENCE_PACKAGE_REQUIRED: 1,
  ADHERENCE_PACKAGE_NOT_REQUIRED: 0,
};

export const ACTIVE = 'active';
export const ARCHIVED = 'archived';
export const CDM_WORKFLOW = 2;

export const OUTREACH_DEPRECATED_TYPES = [
  'adhoc',
  'post discharge',
  'schedule labs',
  'service enrollment',
];

export const DRUG_INFORMATION_INT_ITEMS = [398];
export const LINKAGE_TO_CARE_INT_ITEMS = [81, 77, 71];
export const NULLISH_DATE = '01/01/1970';
export const PROMISS_ID_QUESTIONNAIRE = 21;

// Special case sites

export const HAWAII_PACIFIC_HEALTH = 18;

export const SET_DUR_HAS_INTERVENTION = 'SET_DUR_HAS_INTERVENTION';

export const DUR_TASK_TYPE = 9;

export const FA_FUNDING_AVAILABLE_STATUS_ID = 3032;
export const COVERAGE_ELIGIBILITIES = ['Commercially insured', 'Underinsured', 'UnInsured'];

// --------------
export const ADDED_PAYMENT_METHOD = 'ADDED_PAYMENT_METHOD';
export const UPDATE_ORDER_PAYMENT_METHODS = 'UPDATE_ORDER_PAYMENT_METHODS';
export const FETCH_PAYMENT_METHODS_ERROR = 'FETCH_PAYMENT_METHODS_ERROR';
export const INTERVENTION_REASON_TYPE = {
  PROACTIVE: 'Proactive',
};

export const EDIT_FC_SUCCESS = 'EDIT_FC_SUCCESS';

export const SET_DRAFT_TASK = 'SET_DRAFT_TASK';
export const CLEAR_DRAFT_TASK = 'CLEAR_DRAFT_TASK';
export const RESET_DRAFT_TASK_STATE = 'RESET_DRAFT_TASK_STATE';
export const REQUEST_DRAFT_TASK = 'REQUEST_DRAFT_TASK';
export const FETCH_RELATED_TASK_CLINICAL_DATA = 'FETCH_RELATED_TASK_CLINICAL_DATA';

export const fillCycleFilterOptions = [
  { label: 'All', width: 40 },
  { label: 'FC#1', width: 80 },
  { label: 'FC#2 onwards', width: 155 },
];
// Protocols trigger type
export const EFFICACY_TRIGGER_TYPE = 'efficacy';
export const SAFETY_TRIGGER_TYPE = 'safety';

export const AUTOMATION_USER_ID = 1;

export const ONCOLOGY_THERAPEUTIC_CLASS = 10;

export const DAYS = {
  FIVE_MONTH: 152,
};

// This list is an exception of the following ticket: https://trellisrx.atlassian.net/browse/ARBOR-12689
export const EXCEPTIONS_FOR_DEFAULT_VALUES = ['data_collect_followup'];
