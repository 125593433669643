import moment from 'moment';
import React, { FC } from 'react';
import composeClassNames from 'classnames';
import { useForm, useFieldArray, useWatch, SubmitHandler } from 'react-hook-form';
import { Grid, Divider, Typography, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import { DeepPartial, nameOfFactory } from 'utils/types-util';
import { useTypedSelector } from 'hooks/use-typed-selector';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { getClinicalSupportIcon } from 'components/icons/icons';
import { useClinicalData } from 'containers/patient/clinical/use-clinical-data';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { updateTherapy } from 'actions/action-therapies';
import {
  addProblemSuccess,
  editProblemSuccess,
  editProblemTherapiesEnrollmentSuccess,
} from 'actions/action-patient';

import {
  fetchDiagnosisCodes,
  getTherapyStatuses,
  statusArrayWithIds,
} from 'services/utils/therapy-service';

import {
  createPatientProblem,
  updatePatientProblem,
} from 'services/utils/patient-problems-service';

import { buildQaIdProp, buildQaId } from 'utils/build-qa-id';

import { IPatientProblem, ICdmCondition } from 'interfaces/redux/IPatient';
import {
  cdmOptInClinicalReasons,
  getUserSelectableTherapyClinicalDispensingReasons,
} from 'constants/lists';
import {
  ClinicalSupportOptInReasonValue,
  ClinicalSupportOptOutReasonValue,
  ClinicalSupportStatusLabel,
  ClinicalSupportStatusValue,
  TherapyWorkflowLabel,
} from 'constants/enums';

import {
  ControlledText,
  ControlledTextArea,
  ControlledDropdown,
  ControlledDatePicker,
  ControlledMultipleDropdown,
  ControlledAutoCompleteMiniGrid,
  ControlledRadioGroup,
  ControlledMultipleDropdownAsync,
} from 'components/react-hook-form-fields';

import { windowFeatureIsEnabled } from 'config/window-features';
import { RS } from 'constants/index';
import { styles } from './form.styles';
import {
  IFormProps,
  IFormFields,
  IFetchDiagnosisResponse,
  IDiagnosis,
  ProblemStatus,
  IRiskStratLabel,
  IFormFieldsList,
} from './interfaces';
import {
  formToPatientProblem,
  patientProblemToFormFields,
  shouldRenderCdmInformation,
  buildRiskStratLabels,
  buildGoalsActions,
  execGoalActions,
  updateProblemEnrollment,
  decideUndecidedActualStatus,
} from './utils';
import { logger } from '../../winston-logger';
import { PatientTherapiesClient } from '../../clients/patient-therapies';

const qaIdProp = buildQaIdProp('problems-form');
const qaId = buildQaId('problems-form');

const getFieldName: any = nameOfFactory<IFormFields>();
const getFieldArrayName = (field: keyof IFormFields, attribute: string, index: number): string => {
  return `${field}[${index}].${attribute}`;
};
const getFieldListName = (field: keyof IFormFields, index: number): string => {
  return `${field}[${index}]`;
};

const requiredErrorMessage = 'Required';

const ProblemsForm: FC<IFormProps> = (props: IFormProps): JSX.Element => {
  const { classes } = props;

  // #region component state
  const dispatch = useDispatch();
  const listOfTherapies = useTypedSelector(state => state.therapies.data);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [riskStratLabels, setRiskStratLabels] = React.useState<IRiskStratLabel[]>([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = React.useState<IDiagnosis[]>([]);
  const [goalsByLabNameMap, setGoalsByLabNameMap] = React.useState<Record<string, ICdmCondition>>(
    {},
  );
  const [filterClinicalSupportOptions, setFilterClinicalSupportOptions] =
    React.useState<boolean>(false);

  const shouldFilterClinicalSupportOptions = React.useMemo(() => {
    const shouldFilterOpts = async () => {
      try {
        if (
          props.editingProblem?.diagnosis?.code &&
          props.editingProblem?.therapies &&
          props.editingProblem.therapies.length
        ) {
          const tempTherapyIds = props.editingProblem.therapies
            .filter(t => t.active === true)
            .map(t => t.id);
          if (listOfTherapies && Object.values(listOfTherapies).length) {
            const therapyDataToCheck = Object.values(listOfTherapies).reduce((acc, it) => {
              if (tempTherapyIds.includes(it.id)) {
                acc.push({
                  diagnosisCode: it.diagnosis_code,
                  specialtyType: it.specialty_type,
                });
              }
              return acc;
            }, [] as { diagnosisCode: string; specialtyType: string }[]);
            const therapyWks = await Promise.all(
              therapyDataToCheck.map(async a => {
                const res = await PatientTherapiesClient.getTherapyWorkflow(
                  a.diagnosisCode,
                  a.specialtyType,
                );
                return (
                  TherapyWorkflowLabel[
                    res?.data?.therapyWorkflow as keyof typeof TherapyWorkflowLabel
                  ] ?? ''
                );
              }),
            );
            return therapyWks.some(tw => tw !== RS);
          }
        }
        return true;
      } catch (err) {
        logger.error(`Error while determining therapies workflow: ${err}`);
        return true;
      }
    };
    return shouldFilterOpts();
  }, [props.editingProblem?.diagnosis, props.editingProblem?.therapies]);

  const {
    handleSubmit,
    control,
    setValue,

    formState: { errors },
  } = useForm<any>({
    defaultValues: props.editingProblem
      ? patientProblemToFormFields(props.editingProblem)
      : undefined,
  });

  const fieldsAppendObj: any = useFieldArray({
    control,
    name: getFieldName('patientGoals'),
  });

  const { fields, append } = fieldsAppendObj;

  const formValues = useWatch({ control });

  const enrollmentStatusesLookup = useTypedSelector(state => state.lookups.enrollmentStatuses);
  const therapies = useTypedSelector(state => state.therapies.data);
  const therapyClinicalDispensingStatuses = statusArrayWithIds(
    getTherapyStatuses(enrollmentStatusesLookup),
  );

  const problemTherapyIds: number[] = (props.editingProblem?.therapies || []).map(
    problemTherapy => problemTherapy.id,
  );

  const problemTherapies = Object.values(therapies).filter(therapy =>
    problemTherapyIds.includes(therapy.id),
  );

  const [firstTherapy] = problemTherapies;

  const clinicalData = useClinicalData(props.patientId);

  const undecidedReasons = enrollmentStatusesLookup
    .filter(statusLookup => statusLookup.status === ClinicalSupportStatusLabel.Undecided)
    .map(reason => ({ label: reason.reason as string, value: reason.id }));

  // #endregion component state

  // #region side-effects
  // This just happen on first rendering (as a component did mount)
  React.useEffect(() => {
    const goalFields: any[] = (
      props.editingProblem?.cdm_encounter?.graduation?.conditions || []
    ).map(condition => ({
      name: condition.clinical_data_type.name,
      value: condition.clinical_data_value,
    }));

    const goalsByLabName = (
      props.editingProblem?.cdm_encounter?.graduation?.conditions || []
    ).reduce((accum, condition) => {
      return {
        ...accum,
        [condition.clinical_data_type.name]: condition,
      };
    }, {});
    setGoalsByLabNameMap(goalsByLabName);
    append(goalFields);
  }, []);

  React.useEffect(() => {
    if (clinicalData?.length) {
      setRiskStratLabels(buildRiskStratLabels(clinicalData, props.editingProblem));
    }
  }, [clinicalData]);

  React.useEffect(() => {
    if (
      props.editingProblem?.diagnosis?.code &&
      props.editingProblem?.therapies &&
      props.editingProblem.therapies.length
    ) {
      shouldFilterClinicalSupportOptions.then((res: boolean) => {
        setFilterClinicalSupportOptions(res);
      });
    }
  }, [props.editingProblem?.diagnosis, props.editingProblem?.therapies]);

  // #endregion side-effects

  // #region handlers/helpers
  const createProblem = async (formValues: IFormFields): Promise<void> => {
    const problem = await createPatientProblem(props.patientId, formToPatientProblem(formValues));
    dispatch(addProblemSuccess(problem));
  };

  const updateProblem = async (problemId: number, formValues: IFormFields): Promise<void> => {
    const editedProblem = await updatePatientProblem(props.patientId, {
      ...(formToPatientProblem(formValues) as IPatientProblem),
      id: problemId,
    });

    dispatch(notifySuccess('Diagnosis updated successfully'));
    dispatch(
      editProblemSuccess(editedProblem.problem, editedProblem.should_unverify_problems_list),
    );
  };

  const getTherapiesIdsToEdit = async (therapiesToValidate: any[]) => {
    const allTherapiesArray = Object.values(therapies) || [];
    const therapiesIds = (therapiesToValidate || []).map(item => item.id);
    const therapiesSpecialtyTypes = allTherapiesArray.reduce((acc, item) => {
      if (therapiesIds.includes(item.id)) {
        acc.push({
          id: item.id,
          specialtyType: item.specialty_type,
          diagnosisCode: item.diagnosis_code,
        });
      }
      return acc;
    }, [] as { id: number; specialtyType: string; diagnosisCode: string }[]);
    const tempList: number[] = [];
    for (const it of therapiesSpecialtyTypes) {
      const res = await PatientTherapiesClient.getTherapyWorkflow(
        it.diagnosisCode,
        it.specialtyType,
      );
      const label =
        TherapyWorkflowLabel[res?.data?.therapyWorkflow as keyof typeof TherapyWorkflowLabel] ?? '';
      if (label === RS) {
        tempList.push(it.id);
      }
    }
    const listOfRsTherapies = (therapiesToValidate || []).reduce((acc, th) => {
      if (tempList.includes(th.id) && th.active === true) {
        acc.push(th.id);
      }
      return acc;
    }, [] as number[]);
    return listOfRsTherapies.length > 0 ? listOfRsTherapies : therapiesToValidate.map(it => it.id);
  };

  const wereReasonsSelected = async (formValues: IFormFields): Promise<boolean> => {
    let reasonsWereSelected = true;
    switch (formValues.clinicalSupport) {
      case 1:
        if (formValues?.undecidedReason === null || formValues?.undecidedReason === undefined) {
          reasonsWereSelected = false;
        }
        break;
      case 2:
        if (formValues?.optInReason?.length === 0) {
          reasonsWereSelected = false;
        }
        break;
      case 3:
        if (formValues?.optOutReason?.length === 0) {
          reasonsWereSelected = false;
        }
        break;
      default:
        reasonsWereSelected = true;
        break;
    }
    return reasonsWereSelected;
  };

  const onSubmit: SubmitHandler<IFormFields> = async (formValues: IFormFields) => {
    setIsLoading(true);
    let cancelFlag = true;
    try {
      let updatedProblems: any = [];
      if (props.editingProblem) {
        if (props.editingProblem?.cdm_program_id) {
          const selectedReasons = await wereReasonsSelected(formValues);
          if (!selectedReasons) {
            cancelFlag = selectedReasons;
            return;
          }
          const goalActions = buildGoalsActions(goalsByLabNameMap, formValues.patientGoals);
          await execGoalActions(props.patientId, props.editingProblem.cdm_program_id, goalActions);

          if (props.editingProblem.clinical_support_status !== formValues.clinicalSupport) {
            const updates = await updateProblemEnrollment({
              cdmEncounterId: props.editingProblem.cdm_encounter?.id || null,
              cdmProgramId: props.editingProblem.cdm_program_id,
              patientId: props.patientId,
              problemId: props.editingProblem.id,
              therapyIds: await getTherapiesIdsToEdit(props.editingProblem.therapies || []),
              formValues,
            });

            // update therapies in redux state
            updates.updated_therapies.forEach(therapy => {
              dispatch(updateTherapy(therapy.id, therapy));
            });

            if (updates.updated_tasks) {
              dispatch(editProblemTherapiesEnrollmentSuccess(updates.updated_tasks));
              updatedProblems = [props.editingProblem];
            }
          }
        }
        await updateProblem(props.editingProblem.id, formValues);
      } else {
        const promises: Promise<void>[] = [];
        const formValuesList = formValues as unknown as IFormFieldsList;
        selectedDiagnosis.forEach((diagnosis, index) => {
          const formValue = {
            diagnosis: diagnosis,
            onsetDate: formValuesList.onsetDate[index],
            status: formValuesList.status[index],
            notes: formValuesList.notes[index],
          } as IFormFields;

          promises.push(createProblem(formValue));
        });

        updatedProblems = await Promise.all(promises);
        dispatch(notifySuccess('Diagnosis added successfully'));
      }
      if (props.onSubmit) {
        props.onSubmit(updatedProblems);
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Error saving the Diagnosis'));
    } finally {
      setIsLoading(false);
      if (cancelFlag) props?.handleCancel();
    }
  };

  const selectDiagnosis = (diagnosis: IDiagnosis): void => {
    const diagnosisCode = diagnosis?.code ?? '';
    setValue(getFieldName('diagnosisCode'), diagnosisCode);
  };

  const handleFetchDiagnosisOptions = async (searchText: string): Promise<IDiagnosis[]> => {
    const response: AxiosResponse<IFetchDiagnosisResponse> = await fetchDiagnosisCodes(searchText);

    const alreadyAddedDiagnosisCodes = (props.patientProblems || []).map(
      problem => problem.diagnosis.code,
    );

    // Filtering codes that are already included in a patient problem
    return response.data.icd10s.filter(
      diagnosis => !alreadyAddedDiagnosisCodes.includes(diagnosis.code),
    );
  };
  // #endregion handlers/helpers

  // #region renderers
  const renderRiskStratLabelSection = (riskStratLabels: IRiskStratLabel[]): JSX.Element => {
    return (
      <>
        {riskStratLabels && riskStratLabels.length ? (
          riskStratLabels.map(label => (
            <>
              <Typography className={classes.riskStratLabelFragment}>
                {label.labItemName}
              </Typography>
              <Tooltip title={`Latest Data Collected Lab Value from ${label.lastDataCollect.date}`}>
                <Typography
                  className={composeClassNames(classes.riskStratLabelFragment, classes.bold)}
                >
                  {label.lastDataCollect.value}
                </Typography>
              </Tooltip>
              <Typography className={classes.riskStratLabelFragment}>/</Typography>
              <Tooltip title="Patient Goal">
                <Typography className={classes.riskStratLabelFragment}>
                  {label.patientGoalValue}
                </Typography>
              </Tooltip>
            </>
          ))
        ) : (
          <Tooltip title="No Risk Stratification lab items available">
            <Typography className={classes.riskStratLabelFragment}>Pending</Typography>
          </Tooltip>
        )}
      </>
    );
  };

  const clinicalSupportInitialValue =
    props.editingProblem?.clinical_support_status === ClinicalSupportStatusValue.OptOut &&
    firstTherapy?.clinical_support_status_reason
      ? firstTherapy?.clinical_support_status_reason.split(',').map(Number)
      : [];
  const clinicalSupportOptions = getUserSelectableTherapyClinicalDispensingReasons(
    clinicalSupportInitialValue,
    filterClinicalSupportOptions,
  );

  const renderEditForm = (): JSX.Element => {
    return (
      <>
        <Grid item xs={6}>
          <ControlledAutoCompleteMiniGrid<IDiagnosis>
            defaultValue={null}
            control={control}
            name={getFieldName('diagnosis')}
            label="Diagnosis *"
            placeholder="Search by Diagnosis Name or Code"
            validations={{ required: true }}
            selectValue={selectDiagnosis}
            fetchOptions={handleFetchDiagnosisOptions}
            columnsToShow={{
              code: 'Code',
              description: 'Description',
            }}
            labelField="description"
            clearable={!props?.editingProblem?.therapies?.length}
            disabled={Boolean(props?.editingProblem?.therapies?.length)}
            labelWrapperClassName={classes.diagnosisNameLabelWrapper}
            inputMetaData={{ touched: Boolean(errors.diagnosis), error: requiredErrorMessage }}
            qaId={qaId('name')}
          />
        </Grid>
        <Grid item xs={3}>
          <ControlledText
            defaultValue=""
            control={control}
            label="Diagnosis Code"
            name={getFieldName('diagnosisCode')}
            disabled
            percentWith={100}
            qaId={qaId('code')}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ControlledDatePicker
                label="Onset Date"
                defaultValue={null}
                control={control}
                name={getFieldName('onsetDate')}
                fullWidth
                qaId={qaId('onset-date')}
              />
            </Grid>
            <Grid item xs={3}>
              <ControlledDropdown<ProblemStatus>
                defaultValue={null}
                label="Status *"
                name={getFieldName('status')}
                percentWith={100}
                control={control}
                validations={{ required: true }}
                options={[
                  { label: ProblemStatus.Active, value: ProblemStatus.Active },
                  { label: ProblemStatus.Inactive, value: ProblemStatus.Inactive },
                ]}
                disabled={props?.editingProblem?.therapies?.some(({ active }) => !!active)}
                qaId={qaId('status')}
                inputMetaData={{
                  touched: Boolean(errors.status),
                  error: requiredErrorMessage,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {windowFeatureIsEnabled('cdm_customer') &&
          shouldRenderCdmInformation(props.editingProblem) && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/* Clinical Support section */}
                <Grid item xs={3} className={classes.clinicalSupportWrapper}>
                  {getClinicalSupportIcon(props?.editingProblem?.clinical_support_status)}
                  <Typography className={classes.clinicalSupportLabel}>Clinical Support</Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={classes.sectionWrapper}
                  {...qaIdProp('clinical-support-wrapper')}
                >
                  <ControlledRadioGroup<number>
                    defaultValue={decideUndecidedActualStatus(
                      props.editingProblem?.clinical_support_status,
                    )}
                    // If there is no labs information for this disease state, then don't allow to manually enroll
                    disabled={!(riskStratLabels && riskStratLabels.length)}
                    control={control}
                    name={getFieldName('clinicalSupport')}
                    options={therapyClinicalDispensingStatuses}
                    qaId="clinical_support_status"
                  />
                </Grid>
                <Grid item xs={2} className={classes.sectionWrapper}>
                  <Typography className={classes.riskTratificationLabel}>
                    Risk Stratification
                  </Typography>
                  {renderRiskStratLabelSection(riskStratLabels)}
                </Grid>
                {formValues.clinicalSupport ===
                  ClinicalSupportStatusValue.UndecidedNotYetOffered && (
                  <>
                    <Grid item xs={7} className={classes.optInReasonWrapper}>
                      <ControlledDropdown
                        defaultValue={
                          props.editingProblem?.clinical_support_status &&
                          [
                            ClinicalSupportStatusValue.UndecidedDeferredDecision,
                            ClinicalSupportStatusValue.UndecidedNotYetOffered,
                          ].includes(props.editingProblem?.clinical_support_status)
                            ? props.editingProblem?.clinical_support_status
                            : ClinicalSupportStatusValue.UndecidedNotYetOffered
                        }
                        control={control}
                        label="Reason *"
                        disabled={!(riskStratLabels && riskStratLabels.length)}
                        name={getFieldName('undecidedReason')}
                        validations={{ required: true }}
                        options={undecidedReasons}
                        percentWith={100}
                        inputMetaData={{
                          touched: Boolean(errors.undecidedReason),
                          error: requiredErrorMessage,
                        }}
                        qaId="clinical_support_undecided_reason_select"
                      />
                    </Grid>
                    {formValues.undecidedReason ===
                    ClinicalSupportStatusValue.UndecidedDeferredDecision ? (
                      <Grid item xs={3}>
                        <ControlledDatePicker
                          label="Follow up Date *"
                          validations={{ required: true }}
                          defaultValue={null}
                          control={control}
                          name={getFieldName('followUpdate')}
                          fullWidth
                          qaId={qaId('follow-up-date')}
                          inputMetaData={{
                            touched: Boolean(errors.followUpdate),
                            error: requiredErrorMessage,
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={5} />
                    )}
                  </>
                )}
                {formValues.clinicalSupport === ClinicalSupportStatusValue.OptIn && (
                  <>
                    <Grid item xs={7} className={classes.optInReasonWrapper}>
                      <ControlledMultipleDropdown<ClinicalSupportOptInReasonValue>
                        defaultValue={
                          props.editingProblem?.clinical_support_status ===
                            ClinicalSupportStatusValue.OptIn &&
                          firstTherapy?.clinical_support_status_reason
                            ? firstTherapy?.clinical_support_status_reason.split(',').map(Number)
                            : [ClinicalSupportOptInReasonValue.ProviderReferredIntoCdm]
                        }
                        control={control}
                        name={getFieldName('optInReason')}
                        label="Reason *"
                        disabled
                        percentWith={100}
                        validations={{ required: true }}
                        options={cdmOptInClinicalReasons}
                        inputMetaData={{
                          touched: Boolean(errors.optInReason),
                          error: requiredErrorMessage,
                        }}
                        qaId={qaId('optInReason')}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ControlledDatePicker
                        defaultValue={
                          props.editingProblem?.cdm_encounter?.enrollment.date
                            ? moment(props.editingProblem?.cdm_encounter?.enrollment.date)
                            : moment()
                        }
                        label="Enrollment Date"
                        control={control}
                        name={getFieldName('enrollmentDate')}
                        fullWidth
                        qaId={qaId('enrollment-date')}
                      />
                    </Grid>
                  </>
                )}
                {formValues.clinicalSupport === ClinicalSupportStatusValue.OptOut && (
                  <Grid item xs={12} className={classes.optOutReasonWrapper}>
                    <Grid container spacing={1}>
                      <Grid item xs={7}>
                        <ControlledMultipleDropdown
                          defaultValue={clinicalSupportInitialValue}
                          control={control}
                          name={getFieldName('optOutReason')}
                          label="Reason *"
                          options={clinicalSupportOptions}
                          percentWith={100}
                          validations={{ required: true }}
                          inputMetaData={{
                            touched: Boolean(errors.optOutReason),
                            error: requiredErrorMessage,
                          }}
                          qaId="clinical_support_opt_out_reason_select"
                        />
                      </Grid>
                      {formValues.optOutReason?.includes(
                        ClinicalSupportOptOutReasonValue.CdmGraduationClinicianDecision,
                      ) && (
                        <Grid item xs={2}>
                          <ControlledDatePicker
                            defaultValue={
                              props.editingProblem?.cdm_encounter?.graduation.date
                                ? moment(props.editingProblem?.cdm_encounter?.graduation.date)
                                : moment()
                            }
                            label="Graduation Date"
                            control={control}
                            name={getFieldName('graduationDate')}
                            fullWidth
                            qaId={qaId('graduation-date')}
                          />
                        </Grid>
                      )}
                      {formValues.optOutReason?.includes(ClinicalSupportOptOutReasonValue.Other) ? (
                        <Grid item xs={3}>
                          <ControlledText
                            defaultValue={firstTherapy?.clinical_support_additional_reason || ''}
                            control={control}
                            validations={{ required: true }}
                            name={getFieldName('otherOptOutReason')}
                            label="Other Reason *"
                            qaId="clinical_support_other_reason"
                            percentWith={100}
                            inputMetaData={{
                              touched: Boolean(errors.otherOptOutReason),
                              error: requiredErrorMessage,
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={3} />
                      )}
                      <Grid item xs={12} {...qaIdProp('opt-out-warning-message')}>
                        <Typography className={classes.optOutMessage}>
                          {props.editingProblem?.cdm_encounter?.specialty_type?.name
                            ? `Opting out from this therapy will affect clinical support enrollment \
                        of all therapies that are part of the CDM Program \
                        "${props.editingProblem.cdm_encounter.specialty_type.name}".`
                            : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/* Patient goals section */}
                {Boolean(fields.length) && props.editingProblem?.cdm_encounter && (
                  <>
                    <Grid item xs={12} className={classes.sectionWrapper}>
                      <Typography className={classes.patientGoalsTitle}>Patient Goals</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container {...qaIdProp('goals-fields-container')}>
                        {fields.map((item: any, index: any) => (
                          <Grid key={item.id} item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <ControlledText
                                  defaultValue={item.name}
                                  control={control}
                                  name={getFieldArrayName('patientGoals', 'labName', index)}
                                  label="Lab"
                                  disabled
                                  qaId={qaId('lab-name')}
                                  percentWith={100}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <ControlledText
                                  defaultValue={item.value}
                                  control={control}
                                  name={getFieldArrayName('patientGoals', 'labValue', index)}
                                  label="Value"
                                  qaId={qaId('lab-value')}
                                  percentWith={100}
                                  disabled={Boolean(
                                    props.editingProblem?.cdm_encounter?.graduation.date,
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        <Grid item xs={12}>
          <ControlledTextArea
            defaultValue=""
            control={control}
            name={getFieldName('notes')}
            percentWith={100}
            placeHolder="Notes"
            qaId={qaId('notes')}
          />
        </Grid>
      </>
    );
  };

  const renderAddForm = (): JSX.Element => {
    return (
      <>
        <Grid item xs={12}>
          <div
            className={composeClassNames({
              [classes.newProblemsContainer]: !selectedDiagnosis.length,
            })}
          >
            <ControlledMultipleDropdownAsync
              defaultValue={null}
              control={control}
              name={getFieldName('diagnosis')}
              label="Diagnosis *"
              placeholder="Search by Diagnosis Name or Code"
              validations={{ required: true }}
              onChange={setSelectedDiagnosis}
              provider={handleFetchDiagnosisOptions}
              disabled={Boolean(props?.editingProblem?.therapies?.length)}
              inputMetaData={{ touched: Boolean(errors.diagnosis), error: requiredErrorMessage }}
              qaId={qaId('name')}
              getOptionLabel={option => `${option.code} - ${option.description}`}
              getOptionValue={option => option.code}
            />
          </div>
        </Grid>
        {selectedDiagnosis.map((diagnosis, index) => (
          <>
            <Grid item xs={12}>
              <Typography className={classes.diagnosisDescription}>
                {diagnosis.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ControlledText
                    defaultValue={diagnosis.code}
                    control={control}
                    label="Diagnosis Code"
                    name={getFieldListName('diagnosisCode', index)}
                    disabled
                    percentWith={100}
                    qaId={qaId('code')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControlledDatePicker
                    label="Onset Date"
                    defaultValue={null}
                    control={control}
                    name={getFieldListName('onsetDate', index)}
                    fullWidth
                    qaId={qaId('onset-date')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControlledDropdown<ProblemStatus>
                    defaultValue={ProblemStatus.Active}
                    label="Status *"
                    name={getFieldListName('status', index)}
                    percentWith={100}
                    control={control}
                    validations={{ required: true }}
                    options={[
                      { label: ProblemStatus.Active, value: ProblemStatus.Active },
                      { label: ProblemStatus.Inactive, value: ProblemStatus.Inactive },
                    ]}
                    qaId={qaId('status')}
                    inputMetaData={{
                      touched: Boolean(errors.status),
                      error: requiredErrorMessage,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ControlledTextArea
                defaultValue=""
                control={control}
                name={getFieldListName('notes', index)}
                percentWith={100}
                placeHolder="Notes"
                qaId={qaId('notes')}
              />
            </Grid>
          </>
        ))}
      </>
    );
  };

  return (
    <form>
      <Grid container spacing={2} className={classes.formWrapper}>
        {props.editingProblem ? renderEditForm() : renderAddForm()}
        <Grid item xs={12}>
          <Divider />
          <ConfirmationPanel
            cancelButtonName="problems_form_cancel_button"
            submitButtonName="problems_form_submit_button"
            hideSubmit={false}
            hideCancel={false}
            submitButtonText="Save"
            handleCancel={() => {
              props?.handleCancel();
            }}
            handleSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            loadingText="Saving"
            saveButtonQaId={qaId('save')}
            cancelButtonQaId={qaId('cancel')}
          />
        </Grid>
      </Grid>
    </form>
  );
  // #endregion renderers
};

export default withStyles(styles)(ProblemsForm);
