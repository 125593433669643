import { Grid, Divider, Modal } from '@mui/material';
import { InfoIcon } from 'components/icons/icons';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { editIncome } from 'actions/action-financials';
import SubHeader from 'components/form/header/subheader';
import { accordianStyles } from 'components/accordian/accordian-styles';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import AccordionHeader from 'components/form/header/accordion-header';
import { SmallSpacer, LargeSpacer } from 'components/spacer/spacer';
import History from 'containers/common/history';
import { getYesNoHyphenValue } from 'services/utils/formatting';
import { convertToArborDate } from 'models/time/arbor-date';
import { VerificationPanel } from 'components/form/verification/verification-panel';
import { getUserById } from 'services/utils/users-service';
import DocumentDisplay from 'components/document/document-display';
import { getDocumentsForDisplay } from 'services/utils/document-service';
import { styles } from '../financials-styles';
import EditIncome from './edit-income';
import DetailField from '../../../../components/form/field/field';
import MoneyField from '../../../../components/form/field/money-field';
import { FederalPovertyTable } from './federal-poverty-table';
import { INCOME } from '../../../../constants';
import { noteTagTypes } from '../../../../constants/lists';

const tagType = noteTagTypes.find(tag => tag.label === INCOME);

class IncomeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEdit: false,
      povertyModalOpen: false,
      editHistoryModalOpen: false,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.handleEditHistoryModal = this.handleEditHistoryModal.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
    this.handlePovertyModal = this.handlePovertyModal.bind(this);
  }

  handleEdit(e) {
    e.stopPropagation();
    this.setState({
      displayEdit: true,
    });
  }

  handleEditClose() {
    this.setState({
      displayEdit: false,
    });
  }

  handleExpand() {
    const { displayDetail, editHistoryModalOpen } = this.state;
    if (!editHistoryModalOpen) {
      this.setState({
        displayDetail: !displayDetail,
      });
    }
  }

  handleEditHistoryModal(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      editHistoryModalOpen: !prevState.editHistoryModalOpen,
    }));
  }

  handlePovertyModal() {
    this.setState(prevState => ({
      povertyModalOpen: !prevState.povertyModalOpen,
    }));
  }

  handleVerify(income) {
    const {
      editIncome, //eslint-disable-line
    } = this.props;
    const payload = {
      ...income,
      unemployment_start_date: income.unemployment_start_date
        ? convertToArborDate(income.unemployment_start_date).getUtcDate()
        : null,
      unemployment_end_date: income.unemployment_end_date
        ? convertToArborDate(income.unemployment_end_date).getUtcDate()
        : null,
      is_verified: 1, // set is_verified to 1
      verified_dt: convertToArborDate(moment()).getUtcDatetime(),
    };
    editIncome(payload);
  }

  renderIncome(income) {
    const { classes, users, theme, uploadedDocuments } = this.props;
    const { displayEdit, displayDetail, editHistoryModalOpen, povertyModalOpen } = this.state;

    const getDocuments = (resourceId, tagTypeId) =>
      getDocumentsForDisplay(uploadedDocuments.documents, resourceId, tagTypeId);

    const getHistoryURL = (patientId, id) =>
      `/patients/${patientId}/financials/patient-incomes/${id}`;

    const totalSumIncome =
      income.taxable_income +
      income.unemployment_income +
      income.patient_ssi +
      income.dependent_ssi +
      income.spouse_ssi +
      income.longterm_disability_income;

    const verifiedUser = getUserById(income.verified_by, users);

    return (
      <div>
        <Accordion expanded={!!displayDetail} elevation={0}>
          <AccordionSummary
            classes={{
              expandIconWrapper: classes.expandIcon,
            }}
            onClick={this.handleExpand}
            expandIcon={<ExpandMoreIcon />}
          >
            <AccordionHeader
              smallHeader
              name={`Household Income ${income.income_year}`}
              updatedDt={income.updated}
              updatedBy={income.updated_by_user}
              editHandler={this.handleEdit}
              withHistory
              historyHandler={this.handleEditHistoryModal}
              id={`household_income_${income.income_year}`}
            />
            <Modal open={editHistoryModalOpen} onClose={this.handleEditHistoryModal}>
              <div style={getModalStyle()} className={classes.Modal}>
                <History url={getHistoryURL(income.patient_id, income.id)} />
              </div>
            </Modal>
          </AccordionSummary>
          <AccordionDetails>
            {!displayEdit ? (
              <Grid container direction="column">
                <Grid container justifyContent="flex-end">
                  <Grid item xs={3}>
                    <DocumentDisplay
                      containerClass={classes.innerDocumentContainer}
                      taskIdResourceIds={[{ tagTypeId: tagType.value, resourceId: income.id }]}
                      tagTypeId={tagType.value}
                      resourceId={income.id}
                      tagTypeLabel={INCOME}
                      drugName={income.income_year}
                      documents={getDocuments(income.id, tagType.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.containerDiv}>
                    <Grid container alignItems="flex-end">
                      <Grid item xs={12}>
                        <SubHeader name="Income" />
                        <SmallSpacer />
                      </Grid>
                      <Grid item xs={3}>
                        <MoneyField
                          fieldName="Total Income From Wages"
                          field={income.taxable_income}
                          renderDetailFieldSize
                          defaultValue="No Income"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="Gross Business Income (Schedule C)"
                          field={getYesNoHyphenValue(income.gross_business_income)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="4506-T Completed"
                          field={getYesNoHyphenValue(income.transcript_tax_form_completed)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="Extension Filed"
                          field={getYesNoHyphenValue(income.extension_filed)}
                        />
                      </Grid>
                    </Grid>
                    <SmallSpacer />
                    <Divider light />
                    <SmallSpacer />

                    <Grid container alignItems="flex-end">
                      <Grid item xs={12}>
                        <SubHeader name="Unemployment" />
                        <SmallSpacer />
                      </Grid>
                      <Grid item xs={3}>
                        <MoneyField
                          fieldName="Unemployment Income"
                          field={income.unemployment_income}
                          defaultValue="No Income"
                          suffix={income.unemployment_income_period}
                          renderDetailFieldSize
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="Benefit Dates"
                          field={
                            income.unemployment_start_date
                              ? `${convertToArborDate(
                                  income.unemployment_start_date,
                                  true,
                                ).getUtcDate(true)} - ${
                                  income.unemployment_end_date
                                    ? convertToArborDate(
                                        income.unemployment_end_date,
                                        true,
                                      ).getUtcDate(true)
                                    : 'Present'
                                }`
                              : 'N/A'
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MoneyField
                          fieldName="Patient SSI"
                          field={income.patient_ssi}
                          defaultValue="No Income"
                          suffix={income.patient_ssi_income_period}
                          renderDetailFieldSize
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MoneyField
                          fieldName="Dependent SSI"
                          field={income.dependent_ssi}
                          defaultValue="No Income"
                          suffix={income.dependent_ssi_income_period}
                          renderDetailFieldSize
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MoneyField
                          fieldName="Spouse SSI"
                          field={income.spouse_ssi}
                          defaultValue="No Income"
                          suffix={income.spouse_ssi_income_period}
                          renderDetailFieldSize
                        />
                      </Grid>
                    </Grid>
                    <SmallSpacer />
                    <Divider light />
                    <SmallSpacer />

                    <Grid container alignItems="flex-end">
                      <Grid item xs={12}>
                        <SubHeader name="Retirement" />
                        <SmallSpacer />
                      </Grid>
                      <Grid item xs={3}>
                        <MoneyField
                          fieldName="Pension/401K/Retirement Income"
                          field={income.pension_401k_retirement_income}
                          defaultValue="No Income"
                          renderDetailFieldSize
                        />
                      </Grid>
                    </Grid>
                    <SmallSpacer />
                    <Divider light />
                    <SmallSpacer />

                    <Grid container alignItems="flex-end">
                      <Grid item xs={12}>
                        <SubHeader name="Long Term Disability" />
                        <SmallSpacer />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="Insurer"
                          field={income.longterm_disability_company}
                          defaultValue="No Insurer"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MoneyField
                          fieldName="Benefit"
                          field={income.longterm_disability_income}
                          defaultValue="No Income"
                          suffix={income.longterm_disability_income_period}
                          renderDetailFieldSize
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="Family Size"
                          field={income.family_size}
                          defaultValue="Not Provided"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DetailField
                          fieldName="Dependents"
                          field={income.number_of_dependents}
                          defaultValue="Not Provided"
                        />
                      </Grid>
                    </Grid>
                    <SmallSpacer />
                    <Grid container alignItems="flex-end">
                      <Grid item xs={12}>
                        <SubHeader name="Total Income" />
                        <SmallSpacer />
                      </Grid>
                      <SmallSpacer />
                      <Grid item xs={3}>
                        <MoneyField
                          fieldName="Total Income"
                          field={totalSumIncome}
                          defaultValue="-"
                          renderDetailFieldSize
                        />
                      </Grid>
                      <Grid item xs={3} onClick={this.handlePovertyModal}>
                        <div className={classes.povertyInfoDetailWrapper}>
                          <InfoIcon color={theme.palette.primary.formLabel} />
                          <p className={classes.povertyInfoLabel}>Federal Poverty Levels</p>
                        </div>
                      </Grid>
                    </Grid>
                    <LargeSpacer />
                    <Grid container>
                      <Grid item xs={12}>
                        <VerificationPanel
                          type="income"
                          isVerified={income.is_verified}
                          handleVerify={() => this.handleVerify(income)}
                          verifiedBy={verifiedUser}
                          verifiedDate={income.verified_dt}
                        />
                      </Grid>
                    </Grid>
                    <Modal open={povertyModalOpen} onClose={this.handlePovertyModal}>
                      <div style={getModalStyle()} className={classes.Modal}>
                        <FederalPovertyTable />
                      </div>
                    </Modal>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <EditIncome income={income} cancel={this.handleEditClose} />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

  render() {
    const { incomes } = this.props;
    return incomes.map(income => this.renderIncome(income));
  }
}

const combinedStyles = combineStyles(styles, accordianStyles);

function mapStateToProps(state) {
  const { lookups, uploadedDocuments } = state;

  return {
    users: lookups.users,
    uploadedDocuments,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editIncome,
    },
    dispatch,
  );
}

export default compose(
  withStyles(combinedStyles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(IncomeDetail);
