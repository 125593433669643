import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { windowFeatureIsEnabled } from 'config/window-features';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import SubHeader from 'components/form/header/subheader';
import DatetimePicker from 'components/form/datepicker/datetime-picker';
import { MediumSpacer, SmallSpacer } from 'components/spacer/spacer';
import { ReactSelect } from 'components/form/field/react-select';
import { setSidebarFilter } from 'actions/action-sidebar-filters';
import {
  DATE_OPTIONS,
  PA,
  RS,
  FA,
  CSL,
  OR,
  QRE,
  FDC,
  AR,
  DUR,
  FC,
  CATEGORY_ARCHIVED,
  CATEGORY_DONE,
  THERAPIES,
  SMS_RELATED_TASK,
  STAR,
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID,
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID,
  FILL_COORDINATION_STATUS_SECURE_LINK,
  DC,
} from 'constants/index';
import {
  methodOfDeliveryWithCourierTypes,
  methodOfDelivery,
  financialTypeFilterValues,
  getFinancialTypeFilterValueByKey,
  internalFoundationFilterValues,
  fcInProgressReasons,
  taskStatusesExceptionToShowInFilters,
} from 'constants/lists';
import { DeliveryMethodValue } from 'constants/enums';

import { getAuditCategory, auditCategoriesRaw } from 'services/utils/audit-service';
import {
  getDatesFromSidebarFilters,
  getRadioCheckedDateFilter,
  evaluateDate,
  outreachTypeByNameForFilters,
  calcIncidentCombinedResponsibles,
} from 'services/utils/filters-service';
import { styles } from './task-filter-panel-styles';
import FillCycleFilter from './filter-components/fill-coordination/fill-cycle-filter';
import DispensingPharmacyFilter from './filter-components/fill-coordination/dispensing-pharmacy-filter';

const arListValues = [
  {
    label: 'Automatic',
    value: 'automatic',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
];

const arCommunicationTypeValues = [
  {
    label: 'Phone',
    value: SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID,
  },
  {
    label: 'In person',
    value: SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID,
  },
];

const queueAllowsAuditFilters = [];

const TaskFilterPanel = props => {
  const {
    classes,
    lookups,
    sidebarFilters,
    sidebarTaskType,
    taskStatuses,
    selectedPatientId,
    setSidebarFilter, // eslint-disable-line
  } = props;
  // #region component variables

  const methodOfDeliveryForCustomer = windowFeatureIsEnabled('courier_type')
    ? methodOfDeliveryWithCourierTypes
    : methodOfDelivery;

  const defaultMethodOfDelivery =
    sidebarFilters.fdcMethodsOfDelivery && sidebarFilters.fdcMethodsOfDelivery.length > 0
      ? sidebarFilters.fdcMethodsOfDelivery.map(category => ({
          value: category,
          label: methodOfDeliveryForCustomer
            .filter(val => !val.disabled)
            .find(val => val.value === category)
            ? methodOfDeliveryForCustomer
                .filter(val => !val.disabled)
                .find(val => val.value === category).label
            : '',
        })).length > 0 ?? []
      : [];

  const serviceGroups = sidebarFilters.serviceGroups || [];
  const therapyTypes = sidebarFilters.therapyTypes || [];
  const serviceGroupsIds = serviceGroups.map(serviceGroup => serviceGroup.idValue);
  const taskClinics = sidebarFilters.clinics || [];
  const radioCheckedDate = getRadioCheckedDateFilter(sidebarFilters);

  const qreResponsibles = lookups ? calcIncidentCombinedResponsibles(lookups) : [];

  const { from: fromDateValue, to: toDateValue } = getDatesFromSidebarFilters(sidebarFilters);

  useEffect(() => {
    if (!setSidebarFilter) {
      return;
    }

    if (!sidebarFilters.from && !sidebarFilters.to && !sidebarFilters.date) {
      setSidebarFilter('task', {
        date: DATE_OPTIONS.TODAY,
        from: null,
        to: null,
      });
    }
  }, [sidebarFilters.from, sidebarFilters.to, sidebarFilters.date, setSidebarFilter]);

  const dateChipsEnabled = !sidebarFilters.completed;
  const clinicsForServiceGroups =
    sidebarFilters.serviceGroups.length > 0
      ? lookups.customerClinics.filter(clinic =>
          sidebarFilters.serviceGroups.some(
            serviceGroup => serviceGroup.idValue === clinic.service_group_id,
          ),
        )
      : lookups.customerClinics;

  const outreachTypeByName = outreachTypeByNameForFilters(taskStatuses.outreach_types);

  const onlyActiveUserQRE =
    sidebarFilters &&
    sidebarFilters.incidentResponsibleParty &&
    sidebarFilters.incidentResponsibleParty.length === 1 &&
    sidebarFilters.incidentResponsibleParty[0] === `u${window.USER.id}`;

  const buildOutreachReasons = () => {
    return lookups.lookupScheduleOutreachReasons.map(reason => ({
      value: reason.id,
      label: reason.name,
    }));
  };

  const arReasonFilterValues =
    buildOutreachReasons().filter(reason => sidebarFilters.arReasonIds?.includes(reason.value)) ||
    [];
  // #endregion component variables

  // #region  component state

  const [statusOptions, setStatusOptions] = useState([]);
  const [statusIdsLookup, setStatusIdsLookup] = useState({});
  const [taskStatusValues, setTaskStatusValues] = useState([]);
  const [enrollmentStatusValues, setEnrollmentStatusValues] = useState([]);
  const [administrationStatusValues, setAdministrationStatusValues] = useState([]);

  const [arReasonValues, setARReasonValues] = useState(arReasonFilterValues);

  const [isSelectAllSecureLinkFC, setIsSelectAllSecureLinkFC] = useState(false);
  // #endregion component state

  // #region component functions

  const initialTypesForOR = () => {
    const retArr = [];
    const allTypeVals =
      sidebarFilters.orType && sidebarFilters.orType.length > 0
        ? sidebarFilters.orType.map(type =>
            outreachTypeByName.find(x =>
              x.related
                .split(',')
                .map(strVal => Number(strVal))
                .some(typeObj => typeObj === type),
            ),
          )
        : [];
    allTypeVals.forEach(allType => {
      if (!retArr.some(retType => retType.label === allType.name) || retArr.length === 0) {
        retArr.push({ label: allType.name, value: allType.related });
      }
    });
    return retArr;
  };

  const initialTypesForAuditRule = () => {
    const allTypeVals =
      sidebarFilters.auditWarningsRule && sidebarFilters.auditWarningsRule.length > 0
        ? sidebarFilters.auditWarningsRule.map(auditRule => ({
            value: auditRule,
            label:
              lookups.auditConstants &&
              lookups.auditConstants.find(rule => rule.arbor_audit_definition_id === auditRule)
                ? lookups.auditConstants.find(rule => rule.arbor_audit_definition_id === auditRule)
                    .audit_item
                : null,
          }))
        : [];
    return allTypeVals;
  };

  const buildTaskStatusValues = () => {
    const uniqStatusNamesArr = taskStatuses?.statuses[sidebarTaskType?.toLowerCase()] ?? [];
    const uniqStatusNames = uniqStatusNamesArr.reduce((accum, status) => {
      if (sidebarFilters.statusIds?.[sidebarTaskType]?.includes(status.id)) {
        accum.add(status.status);
      }
      return accum;
    }, new Set());

    return [...uniqStatusNames].map(statusName => ({
      label: statusName,
      value: statusName,
    }));
  };

  const buildTaskStatusLookupOptions = () => {
    return (taskStatuses?.statuses[sidebarTaskType?.toLowerCase()] || []).reduce(
      (accum, statusWithCategory) => {
        if (
          taskStatusesExceptionToShowInFilters.includes(statusWithCategory.id) ||
          ![CATEGORY_DONE, CATEGORY_ARCHIVED].includes(statusWithCategory.category_id)
        ) {
          if (!accum.statusIdsOptionLookup[statusWithCategory.status]) {
            accum.statusSelectorOptions.push({
              label: statusWithCategory.status,
              value: statusWithCategory.status,
            });
            accum.statusIdsOptionLookup[statusWithCategory.status] = [statusWithCategory.id];
          } else {
            accum.statusIdsOptionLookup[statusWithCategory.status].push(statusWithCategory.id);
          }
        }
        return accum;
      },
      {
        statusSelectorOptions: [],
        statusIdsOptionLookup: {},
      },
    );
  };

  const buildEnrollmentStatusOptions = () => {
    const statuses = new Set();
    return lookups.enrollmentStatuses.reduce((accum, enrollmentStatus) => {
      if (!statuses.has(enrollmentStatus.status)) {
        statuses.add(enrollmentStatus.status);
        accum.push({
          label: enrollmentStatus.status,
          value: enrollmentStatus.id,
        });
      }
      return accum;
    }, []);
  };

  const buildAdministrationStatusOptions = () => {
    const statuses = new Set();
    const initialAdminStatuses = lookups.lookupStatusReasons.filter(
      s => s.resource === 'therapy_administration',
    );
    return initialAdminStatuses.reduce((accum, administrationStatus) => {
      if (!statuses.has(administrationStatus.status)) {
        statuses.add(administrationStatus.status);
        accum.push({
          label: administrationStatus.status,
          value: administrationStatus.status,
        });
      }
      return accum;
    }, []);
  };

  const buildInitialEnrollmentStatusValues = selectedStatusIds =>
    selectedStatusIds.map(statusId => {
      const lookupStatus = lookups.enrollmentStatuses.find(status => status.id === statusId);
      return {
        value: lookupStatus.id,
        label: lookupStatus.status,
      };
    });

  const buildInitialAdministrationStatusValues = selectedStatusIds =>
    selectedStatusIds.map(statusName => {
      const lookupStatus = lookups.lookupStatusReasons
        .filter(s => s.resource === 'therapy_administration')
        .find(statusVar => statusVar?.status === statusName);
      return {
        value: lookupStatus?.status,
        label: lookupStatus?.status,
      };
    });

  const handleToDateChange = e => {
    const newDate =
      e instanceof moment
        ? // is a moment if the datepicker is clicked
          moment(e).format('YYYY-MM-DD')
        : evaluateDate(e);
    setSidebarFilter('task', {
      to: newDate === 'noDate' ? null : newDate,
    });
  };

  const handleHideInternalFundingFA = e => {
    const value = e.target.checked;
    setSidebarFilter('task', {
      hideInternalFundingFA: value,
    });
  };

  const handleOnlyUserTasksQre = e => {
    if (!onlyActiveUserQRE) {
      setSidebarFilter('task', {
        incidentResponsibleParty: [`u${window.USER.id}`],
      });
    } else {
      setSidebarFilter('task', {
        incidentResponsibleParty: [],
      });
    }
  };

  const handleFromDateChange = e => {
    const newDate =
      e instanceof moment
        ? // is a moment if the datepicker is clicked
          moment(e).format('YYYY-MM-DD')
        : evaluateDate(e);
    setSidebarFilter('task', {
      from: newDate === 'noDate' ? null : newDate,
    });
  };

  const handleExcludeOverdueARsChange = e => {
    const value = e.target.checked;
    setSidebarFilter('task', { excludeOverdueARs: value });
  };

  const handleColdChain = e => {
    const value = e.target.checked;
    setSidebarFilter('task', { coldChain: value });
  };

  const handleUrgent = e => {
    const value = e.target.checked;
    setSidebarFilter('task', { urgent: value });
  };

  const handleAdherencePackaging = e => {
    const value = e.target.checked;

    setSidebarFilter('task', { adherencePackaging: value });
  };

  const handleDisplayOnlyFC = value => {
    const selectedDisplayOnly = (value || []).map(it => it.value);

    setSidebarFilter('task', {
      displayOnlyFC: selectedDisplayOnly,
    });
  };

  const handleDisplayOnlyDC = value => {
    const selectedDisplayOnly = (value || []).map(it => it.value);

    setSidebarFilter('task', {
      displayOnlyDC: selectedDisplayOnly,
    });
  };

  const handleServiceGroupChange = value => {
    const selectedServiceGroups = value
      ? value.map(it => ({
          displayValue: it.label,
          idValue: it.value,
        }))
      : [];
    setSidebarFilter('task', {
      serviceGroups: selectedServiceGroups,
      clinics: sidebarFilters.clinics.filter(clinic =>
        (value || []).some(serviceGroup => serviceGroup.value === clinic.serviceGroupId),
      ),
    });
  };

  const handleTherapyTypeChange = value => {
    const selectedTherapyTypes = value
      ? value.map(it => ({
          displayValue: it.label,
          idValue: it.value,
        }))
      : [];
    setSidebarFilter('task', {
      therapyTypes: selectedTherapyTypes,
    });
  };

  const handleTaskClinicChange = value => {
    const selectedTaskClinics = value
      ? value.map(clinic => ({
          displayValue: clinic.label,
          idValue: clinic.value,
          serviceGroupId: clinic.service_group_id,
        }))
      : [];
    setSidebarFilter('task', {
      clinics: selectedTaskClinics,
    });
  };

  const handleRadioButtonChange = value => {
    setSidebarFilter('task', {
      date: value,
      from: null,
      to: null,
    });
    if (value === 'ALL') {
      setSidebarFilter('task', { excludeOverdueARs: false });
    }
  };

  const handleAuditChange = value => {
    let retArrRule = [];
    let retArrCategory = [];
    if (value && value.rule && value.rule.length > 0) {
      retArrRule = retArrRule.concat(value.rule.map(v => v.value));
    } else if (
      !value.rule &&
      value.rule !== null &&
      sidebarFilters.auditWarningsRule &&
      sidebarFilters.auditWarningsRule.length > 0
    ) {
      retArrRule = sidebarFilters.auditWarningsRule;
    }
    if (value && value.category && Array.isArray(value.category) && value.category.length > 0) {
      retArrCategory = value.category.map(v => v.value);
    } else if (
      !value.category &&
      value.category !== null &&
      sidebarFilters.auditWarningsCategory.length > 0
    ) {
      retArrCategory = sidebarFilters.auditWarningsCategory;
    }
    retArrRule = retArrRule.flat(Infinity).map(val => Number(val));
    setSidebarFilter('task', {
      auditWarningsCategory: retArrCategory.sort((a, b) => a - b),
      auditWarningsRule: retArrRule.sort((a, b) => a - b),
    });
  };

  const handleStatusChange = statusSelectedOptions => {
    const reasonStatus = statusSelectedOptions?.filter(opt => opt.value > 0);
    const taskStatusWithLabel = statusSelectedOptions?.filter(opt => !(opt.value > 0));

    const statusIds = (taskStatusWithLabel || []).reduce(
      (accum, statusSelectedOption) => [...accum, ...statusIdsLookup[statusSelectedOption.value]],
      [],
    );
    setTaskStatusValues(taskStatusWithLabel || []);

    setSidebarFilter('task', {
      statusIds: {
        ...(sidebarFilters.statusIds || {}),
        [sidebarTaskType]: statusIds.length ? statusIds : undefined,
      },
    });

    setSidebarFilter('task', {
      fcInProgressReason: (reasonStatus || []).map(reason => reason.value),
    });
  };

  const handleSelectAllSecureLinkFCFilters = useCallback(
    ({ target: { checked } }) => {
      setTaskStatusValues(() =>
        checked
          ? Object.keys(statusIdsLookup).reduce((acc, key) => {
              if (
                statusIdsLookup[key].some(id => FILL_COORDINATION_STATUS_SECURE_LINK.includes(id))
              ) {
                acc.push({ value: key, label: key });
              }
              return acc;
            }, [])
          : [],
      );

      setSidebarFilter('task', {
        statusIds: {
          ...(sidebarFilters.statusIds || {}),
          [sidebarTaskType]: checked ? FILL_COORDINATION_STATUS_SECURE_LINK : undefined,
        },
      });
    },
    [setSidebarFilter, sidebarFilters.statusIds, sidebarTaskType, statusIdsLookup],
  );

  const handleReasonInProgressChange = reasonSelectedOptions => {
    setSidebarFilter('task', {
      fcInProgressReason: (reasonSelectedOptions || []).map(reason => reason.value),
    });
  };

  const handleDispensingStatusChange = selectedEnrollmentStatuses => {
    setEnrollmentStatusValues(selectedEnrollmentStatuses);

    setSidebarFilter('task', {
      dispensingEnrollmentStatusIds: (selectedEnrollmentStatuses || []).map(
        enrollmentStatus => enrollmentStatus.value,
      ),
    });
  };

  const handleAdministrationStatusChange = selectedAdministrationStatuses => {
    setAdministrationStatusValues(selectedAdministrationStatuses);
    setSidebarFilter('task', {
      administrationStatusIds: (selectedAdministrationStatuses || []).map(
        adminStatus => adminStatus.value,
      ),
    });
  };

  const handleARReasonChange = selectedReason => {
    setARReasonValues(selectedReason);
  };

  useEffect(() => {
    setSidebarFilter('task', {
      arReasonIds: (arReasonValues || []).map(reasons => reasons.value),
    });
  }, [arReasonValues]);

  const handleMethodOfDelivery = value => {
    let methodOfDeliveryArr = [];
    let deliveryMethodCourierTypeArr = [];
    if (value && value.length > 0) {
      methodOfDeliveryArr = value.filter(
        ({ value: selectedValue }) =>
          ![DeliveryMethodValue.InternalCourier, DeliveryMethodValue.ExternalCourier].includes(
            selectedValue,
          ),
      );

      if (
        value.find(({ value: selectedValue }) =>
          [DeliveryMethodValue.InternalCourier, DeliveryMethodValue.ExternalCourier].includes(
            selectedValue,
          ),
        )
      ) {
        methodOfDeliveryArr.push(
          methodOfDelivery.find(
            ({ value: courierValue }) => courierValue === DeliveryMethodValue.Courier,
          ),
        );
      }

      // add courier type
      deliveryMethodCourierTypeArr = value
        .filter(({ value: selectedValue }) =>
          [DeliveryMethodValue.InternalCourier, DeliveryMethodValue.ExternalCourier].includes(
            selectedValue,
          ),
        )
        .map(selectedOption => {
          switch (selectedOption.value) {
            case DeliveryMethodValue.InternalCourier:
              return { ...selectedOption, value: 0 };
            case DeliveryMethodValue.ExternalCourier:
            default:
              return { ...selectedOption, value: 1 };
          }
        });
    }
    // here
    setSidebarFilter('task', {
      fdcMethodsOfDelivery: methodOfDeliveryArr.map(type => type.value).sort((a, b) => a - b),
      deliveryMethodCourierType: deliveryMethodCourierTypeArr,
    });
  };

  const handleAssistanceProgramChange = value => {
    setSidebarFilter('task', {
      assistanceProgram: value?.flatMap(v => v.value),
    });
  };

  const handleInternalFoundationProgramChange = value => {
    setSidebarFilter('task', {
      internalFoundation: value?.flatMap(v => v.value),
    });
  };

  const assistanceProgramDefaulValues = sidebarFilters.assistanceProgram?.flatMap(ap => {
    const current = getFinancialTypeFilterValueByKey(ap);
    return {
      label: current.label,
      value: current.key,
    };
  });

  const internalFoundationDefaultValues = sidebarFilters.internalFoundation?.flatMap(ap => {
    const [current] = internalFoundationFilterValues.filter(x => x.value === ap);
    return {
      label: current.label,
      value: current.value,
    };
  });

  const handleTaskTagChange = value => {
    switch (sidebarTaskType) {
      case PA:
        setSidebarFilter('task', {
          paNewRenewal: value,
        });
        break;
      case FA:
        setSidebarFilter('task', {
          faNewRenewal: value,
        });
        break;
      case CSL:
        setSidebarFilter('task', {
          cslInitialOngoing: value,
        });
        break;
      case OR: {
        let retArrType = [];
        let retArrCategory = [];
        if (value && value.type && value.type.length > 0) {
          retArrType = retArrType.concat(value.type.map(v => v.value.split(',')));
        } else if (
          !value.type &&
          value.type !== null &&
          sidebarFilters.orType &&
          sidebarFilters.orType.length > 0
        ) {
          retArrType = sidebarFilters.orType;
        }
        if (value && value.category && Array.isArray(value.category) && value.category.length > 0) {
          retArrCategory = value.category.map(v => v.value);
        } else if (
          !value.category &&
          value.category !== null &&
          sidebarFilters.orCategory.length > 0
        ) {
          retArrCategory = sidebarFilters.orCategory;
        }
        retArrType = retArrType.flat(Infinity).map(val => Number(val));
        setSidebarFilter('task', {
          orCategory: retArrCategory.sort((a, b) => a - b),
          orType: retArrType.sort((a, b) => a - b),
        });
        break;
      }
      case QRE: {
        let qreRetArrType = [];
        let qreRetArrCategory = [];
        let qreRetArrResponsibleParty = [];
        if (value && value.type && value.type.length > 0) {
          qreRetArrType = qreRetArrType.concat(value.type.map(v => v.value));
        } else if (
          !value.type &&
          value.type !== null &&
          sidebarFilters.incidentType &&
          sidebarFilters.incidentType.length > 0
        ) {
          qreRetArrType = sidebarFilters.incidentType;
        }
        if (value && value.category && Array.isArray(value.category) && value.category.length > 0) {
          qreRetArrCategory = value.category.map(v => v.value);
        } else if (
          !value.category &&
          value.category !== null &&
          sidebarFilters.incidentCategory.length > 0
        ) {
          qreRetArrCategory = sidebarFilters.incidentCategory;
        }
        if (
          value &&
          value.responsibleParty &&
          Array.isArray(value.responsibleParty) &&
          value.responsibleParty.length > 0
        ) {
          qreRetArrResponsibleParty = value.responsibleParty.map(v => v.value);
        } else if (
          !value.responsibleParty &&
          value.responsibleParty !== null &&
          sidebarFilters.incidentResponsibleParty.length > 0
        ) {
          qreRetArrResponsibleParty = sidebarFilters.incidentResponsibleParty;
        }
        qreRetArrType = qreRetArrType.flat(Infinity).map(val => Number(val));
        setSidebarFilter('task', {
          incidentCategory: qreRetArrCategory.sort((a, b) => a - b),
          incidentType: qreRetArrType.sort((a, b) => a - b),
          incidentResponsibleParty: qreRetArrResponsibleParty.sort((a, b) => a - b),
        });
        break;
      }
      case AR: {
        // / eslint-disable-next-line no-case-declarations
        let arTypeArr = [];
        let arCommunicationTypeArr = [];
        if (value && value.arType && Array.isArray(value.arType) && value.arType.length > 0) {
          arTypeArr = value.arType.map(v => v.value);
        } else if (!value.arType && value.arType !== null && sidebarFilters.arType.length > 0) {
          arTypeArr = sidebarFilters.arType;
        }
        if (
          value &&
          value.arCommunicationType &&
          Array.isArray(value.arCommunicationType) &&
          value.arCommunicationType.length > 0
        ) {
          arCommunicationTypeArr = value.arCommunicationType.map(v => v.value);
        } else if (
          !value.arCommunicationType &&
          value.arCommunicationType !== null &&
          sidebarFilters &&
          sidebarFilters.arCommunicationType &&
          sidebarFilters.arCommunicationType.length > 0
        ) {
          arCommunicationTypeArr = sidebarFilters.arCommunicationType;
        }
        setSidebarFilter('task', {
          arType: arTypeArr.sort((a, b) => a - b),
          arCommunicationType: arCommunicationTypeArr.sort((a, b) => a - b),
        });

        break;
      }
      default:
        break;
    }
  };

  // #endregion component functions

  // #region  side effects

  useEffect(() => {
    if (
      sidebarTaskType &&
      taskStatuses.statuses &&
      sidebarTaskType !== THERAPIES &&
      sidebarTaskType !== SMS_RELATED_TASK
    ) {
      const { statusIdsOptionLookup, statusSelectorOptions } = buildTaskStatusLookupOptions();
      setStatusIdsLookup(statusIdsOptionLookup);
      setStatusOptions(statusSelectorOptions);
      setTaskStatusValues(buildTaskStatusValues());
    }

    if (sidebarTaskType === THERAPIES) {
      setEnrollmentStatusValues(
        buildInitialEnrollmentStatusValues(sidebarFilters?.dispensingEnrollmentStatusIds || []),
      );
      setAdministrationStatusValues(
        buildInitialAdministrationStatusValues(sidebarFilters?.administrationStatusIds || []),
      );
    }
  }, [sidebarTaskType]);

  useEffect(() => {
    const taskLabels = taskStatusValues.map(item => item.label);
    const expectedLabels = Object.keys(statusIdsLookup).filter(key =>
      statusIdsLookup[key].some(id => FILL_COORDINATION_STATUS_SECURE_LINK.includes(id)),
    );

    const allPresent = expectedLabels.every(label => taskLabels.includes(label));
    const onlyExpectedLabels = taskLabels.every(label => expectedLabels.includes(label));

    setIsSelectAllSecureLinkFC(allPresent && onlyExpectedLabels);
  }, [statusIdsLookup, taskStatusValues]);

  // #endregion side effects

  // #region renderers

  const taskTagFilters = () => {
    const labelArr = [];
    switch (sidebarTaskType) {
      case PA:
        return (
          <Grid item xs={12} className={classes.serviceGroupContainer}>
            <RadioGroup horizontal wrap onChange={value => handleTaskTagChange(value)}>
              <RadioButton value={0} checked={sidebarFilters.paNewRenewal === 0}>
                New
              </RadioButton>
              <RadioButton
                value={1}
                checked={sidebarFilters.paNewRenewal && sidebarFilters.paNewRenewal === 1}
                disabled={!dateChipsEnabled}
              >
                Renewal
              </RadioButton>
              <RadioButton
                value={-1}
                checked={sidebarFilters.paNewRenewal && sidebarFilters.paNewRenewal === -1}
                disabled={!dateChipsEnabled}
              >
                All
              </RadioButton>
            </RadioGroup>
          </Grid>
        );
      case FA:
        return (
          <>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <RadioGroup horizontal wrap onChange={value => handleTaskTagChange(value)}>
                <RadioButton value={0} checked={sidebarFilters.faNewRenewal === 0}>
                  New
                </RadioButton>
                <RadioButton
                  value={1}
                  checked={sidebarFilters.faNewRenewal && sidebarFilters.faNewRenewal === 1}
                  disabled={!dateChipsEnabled}
                >
                  Renewal
                </RadioButton>
                <RadioButton
                  value={-1}
                  checked={sidebarFilters.faNewRenewal && sidebarFilters.faNewRenewal === -1}
                  disabled={!dateChipsEnabled}
                >
                  All
                </RadioButton>
              </RadioGroup>
            </Grid>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                defaultValues={assistanceProgramDefaulValues}
                name="assistanceProgram"
                label="Assistance Programs"
                handleOnChange={value => handleAssistanceProgramChange(value)}
                fields={financialTypeFilterValues.map(x => ({
                  label: x.label,
                  value: x.key,
                }))}
              />
            </Grid>
          </>
        );
      case FC:
        return (
          <Grid item xs={12} className={classes.serviceGroupContainer}>
            <ReactSelect
              defaultValues={internalFoundationDefaultValues}
              name="assistanceProgram"
              label="Internal Foundation"
              handleOnChange={value => handleInternalFoundationProgramChange(value)}
              fields={internalFoundationFilterValues.map(x => ({
                label: x.label,
                value: x.value,
              }))}
            />
          </Grid>
        );
      case CSL:
        return (
          <Grid item xs={12} className={classes.serviceGroupContainer}>
            <RadioGroup horizontal wrap onChange={value => handleTaskTagChange(value)}>
              <RadioButton
                value={1}
                checked={sidebarFilters.cslInitialOngoing && sidebarFilters.cslInitialOngoing === 1}
              >
                Initial
              </RadioButton>
              <RadioButton
                value={2}
                checked={sidebarFilters.cslInitialOngoing && sidebarFilters.cslInitialOngoing === 2}
              >
                Ongoing
              </RadioButton>
              <RadioButton
                value={3}
                checked={sidebarFilters.cslInitialOngoing && sidebarFilters.cslInitialOngoing === 3}
              >
                Check-In
              </RadioButton>
              <RadioButton
                value={-1}
                checked={
                  sidebarFilters.cslInitialOngoing && sidebarFilters.cslInitialOngoing === -1
                }
              >
                All
              </RadioButton>
            </RadioGroup>
          </Grid>
        );
      case OR:
        return (
          <>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                defaultValues={
                  sidebarFilters.orCategory && sidebarFilters.orCategory.length > 0
                    ? sidebarFilters.orCategory.map(category => ({
                        value: category,
                        label: taskStatuses.outreach_categories.find(val => val.id === category)
                          ? taskStatuses.outreach_categories.find(val => val.id === category).name
                          : '',
                      }))
                    : null
                }
                name="outreachCategory"
                label="Outreach Categories"
                handleOnChange={value => handleTaskTagChange({ category: value })}
                fields={taskStatuses.outreach_categories
                  .filter(cat => !taskStatuses.outreach_categories.includes(cat.id))
                  .map(cat => ({
                    value: cat.id,
                    label: cat.name,
                  }))}
              />
            </Grid>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                defaultValues={initialTypesForOR()}
                name="outreachType"
                label="Outreach Type"
                handleOnChange={value => handleTaskTagChange({ type: value })}
                fields={outreachTypeByName.map(type => ({
                  value: type.related,
                  label: type.name,
                }))}
              />
            </Grid>
          </>
        );
      case QRE:
        return (
          <>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                defaultValues={
                  sidebarFilters.incidentCategory && sidebarFilters.incidentCategory.length > 0
                    ? sidebarFilters.incidentCategory.map(category => ({
                        value: category,
                        label: lookups.incidentCategories.find(val => val.id === category)
                          ? lookups.incidentCategories.find(val => val.id === category).name
                          : '',
                      }))
                    : null
                }
                name="incidentCategory"
                label="Incident Categories"
                handleOnChange={value => handleTaskTagChange({ category: value })}
                fields={lookups.incidentCategories
                  .filter(cat => !lookups.incidentCategories.includes(cat.id))
                  .map(cat => ({
                    value: cat.id,
                    label: cat.name,
                  }))}
              />
            </Grid>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                defaultValues={
                  sidebarFilters.incidentType && sidebarFilters.incidentType.length > 0
                    ? sidebarFilters.incidentType.map(type => ({
                        value: type,
                        label: lookups.incidentTypes.find(val => val.id === type)
                          ? lookups.incidentTypes.find(val => val.id === type).name
                          : '',
                      }))
                    : null
                }
                name="incidentType"
                label="Incident Type"
                handleOnChange={value => handleTaskTagChange({ type: value })}
                fields={lookups.incidentTypes.map(type => ({
                  value: type.id,
                  label: type.name,
                }))}
              />
            </Grid>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                defaultValues={
                  qreResponsibles &&
                  sidebarFilters.incidentResponsibleParty &&
                  sidebarFilters.incidentResponsibleParty.length > 0
                    ? sidebarFilters.incidentResponsibleParty.map(f =>
                        qreResponsibles.find(v => v.value === f),
                      )
                    : null
                }
                isDisabled={onlyActiveUserQRE}
                name="responsibleParty"
                label="Responsible Party"
                handleOnChange={value => handleTaskTagChange({ responsibleParty: value })}
                fields={qreResponsibles}
              />
            </Grid>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <FormControlLabel
                control={<Checkbox />}
                checked={onlyActiveUserQRE}
                onChange={e => handleOnlyUserTasksQre(e)}
                label="Show only QRE's assigned to me"
              />
            </Grid>
          </>
        );
      case AR:
        return (
          <>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                defaultValues={
                  sidebarFilters.arType && sidebarFilters.arType.length > 0
                    ? sidebarFilters.arType.map(category => ({
                        value: category,
                        label: arListValues.some(val => val.value === category)
                          ? arListValues.find(val => val.value === category).label
                          : '',
                      }))
                    : null
                }
                name="arType"
                label="Creation type"
                handleOnChange={value => handleTaskTagChange({ arType: value })}
                fields={arListValues}
              />
            </Grid>
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                defaultValues={
                  sidebarFilters.arCommunicationType &&
                  sidebarFilters.arCommunicationType.length > 0
                    ? sidebarFilters.arCommunicationType.map(category => ({
                        value: category,
                        label: arCommunicationTypeValues.some(val => val.value === category)
                          ? arCommunicationTypeValues.find(val => val.value === category).label
                          : '',
                      }))
                    : null
                }
                name="arCommunicationType"
                label="Communication type"
                handleOnChange={value => handleTaskTagChange({ arCommunicationType: value })}
                fields={arCommunicationTypeValues}
              />
            </Grid>
          </>
        );
      default:
        break;
    }
    if (labelArr.length > 0) {
      return labelArr;
    }
    return null;
  };

  const disableExcludeOverdueARCheckbox =
    (sidebarFilters.date === 'ALL' || sidebarFilters.date === null) &&
    !sidebarFilters.to &&
    !sidebarFilters.from;

  const sideBarTaskFilters = (
    <Grid container direction="column" className={classes.filterPanelContainer}>
      {sidebarTaskType !== STAR ? (
        <>
          <SubHeader name="TIME" />
          <Grid item xs={12}>
            <RadioGroup horizontal wrap onChange={value => handleRadioButtonChange(value)}>
              <RadioButton
                value={DATE_OPTIONS.YESTERDAY}
                checked={dateChipsEnabled && radioCheckedDate[DATE_OPTIONS.YESTERDAY]}
                disabled={!dateChipsEnabled}
              >
                Yesterday
              </RadioButton>
              <RadioButton
                value={DATE_OPTIONS.TODAY}
                checked={dateChipsEnabled && radioCheckedDate[DATE_OPTIONS.TODAY]}
                disabled={!dateChipsEnabled}
              >
                Today
              </RadioButton>
              <RadioButton
                value={DATE_OPTIONS.TOMORROW}
                checked={dateChipsEnabled && radioCheckedDate[DATE_OPTIONS.TOMORROW]}
                disabled={!dateChipsEnabled}
              >
                Tomorrow
              </RadioButton>
              <RadioButton
                value={DATE_OPTIONS.NEXT_7_DAYS}
                checked={dateChipsEnabled && radioCheckedDate[DATE_OPTIONS.NEXT_7_DAYS]}
                disabled={!dateChipsEnabled}
              >
                Next 7 Days
              </RadioButton>
              <RadioButton
                value={DATE_OPTIONS.NEXT_14_DAYS}
                checked={dateChipsEnabled && radioCheckedDate[DATE_OPTIONS.NEXT_14_DAYS]}
                disabled={!dateChipsEnabled}
              >
                Next 14 Days
              </RadioButton>
            </RadioGroup>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography variant="caption" component="div">
                From
              </Typography>
              <DatetimePicker
                value={fromDateValue}
                onChange={e => handleFromDateChange(e)}
                dateFormat="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" component="div">
                To
              </Typography>
              <DatetimePicker
                value={toDateValue}
                onChange={e => handleToDateChange(e)}
                dateFormat="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
              />
            </Grid>
          </Grid>
          {sidebarTaskType === FC && (
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <MediumSpacer />
              <ReactSelect
                data-qa-id=""
                label="Display only"
                defaultValues={null}
                isMulti
                value={(sidebarFilters.displayOnlyFC || []).map(it =>
                  (lookups.lookupDisplayOnlyFillCoordination || []).find(l => it === l.value),
                )}
                handleOnChange={handleDisplayOnlyFC}
                fields={lookups.lookupDisplayOnlyFillCoordination}
              />
              <MediumSpacer />
              <FillCycleFilter />
            </Grid>
          )}
          {sidebarTaskType === DC && (
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <MediumSpacer />
              <ReactSelect
                data-qa-id=""
                label="Display only"
                defaultValues={null}
                isMulti
                value={(sidebarFilters.displayOnlyDC || []).map(it =>
                  (lookups.lookupDisplayOnlyDataCollect || []).find(l => it === l.value),
                )}
                handleOnChange={handleDisplayOnlyDC}
                fields={lookups.lookupDisplayOnlyDataCollect}
              />
              <MediumSpacer />
              <FillCycleFilter />
            </Grid>
          )}
          {sidebarTaskType === 'AR' && (
            <Grid sx={{ mt: 2.5 }}>
              <FormControlLabel
                control={<Checkbox disabled={disableExcludeOverdueARCheckbox} />}
                checked={sidebarFilters.excludeOverdueARs}
                onChange={e => handleExcludeOverdueARsChange(e)}
                label="Exclude Overdue ARs"
              />
            </Grid>
          )}
          <SmallSpacer />
          <Grid item xs={12} className={classes.serviceGroupContainer}>
            <SubHeader name="SERVICE GROUP" />
            <SmallSpacer />
            <ReactSelect
              defaultValues={serviceGroups.map(serviceGroup => ({
                value: serviceGroup.idValue,
                label: `${serviceGroup.displayValue}`,
              }))}
              name="serviceGroups"
              label="Service Groups"
              handleOnChange={e => handleServiceGroupChange(e)}
              fields={lookups.serviceGroups
                .filter(serviceGroup => !serviceGroupsIds.includes(serviceGroup.id))
                .map(serviceGroup => ({
                  value: serviceGroup.id,
                  label: serviceGroup.display_name,
                }))}
            />
            <SmallSpacer />
            {sidebarTaskType !== SMS_RELATED_TASK &&
              sidebarTaskType !== DUR &&
              sidebarTaskType !== RS &&
              serviceGroups.length > 0 && (
                <ReactSelect
                  defaultValues={taskClinics.map(clinic => ({
                    value: `${clinic.idValue}`,
                    label: `${clinic.displayValue}`,
                  }))}
                  name="taskClinics"
                  label="Task Clinics"
                  handleOnChange={e => handleTaskClinicChange(e)}
                  fields={clinicsForServiceGroups
                    .filter(clinic => !sidebarFilters.clinics.some(x => x.idValue === clinic.id))
                    .map(clinic => ({
                      value: clinic.id,
                      label: clinic.name,
                      service_group_id: clinic.service_group_id,
                    }))}
                />
              )}
            <MediumSpacer />
          </Grid>
          {sidebarTaskType === FA && (
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <FormControlLabel
                control={<Checkbox />}
                checked={sidebarFilters.hideInternalFundingFA}
                onChange={e => handleHideInternalFundingFA(e)}
                label="Hide FAs with Internal Funding or Free Drugs"
              />
            </Grid>
          )}
          {sidebarTaskType !== SMS_RELATED_TASK &&
            sidebarTaskType !== AR &&
            sidebarTaskType !== DUR &&
            sidebarTaskType !== RS &&
            sidebarTaskType !== QRE && (
              <Grid item xs={12} className={classes.serviceGroupContainer}>
                <SubHeader name="Therapies" />
                <SmallSpacer />
                <ReactSelect
                  defaultValues={therapyTypes.map(therapyType => ({
                    value: therapyType.idValue,
                    label: `${therapyType.displayValue}`,
                  }))}
                  name="therapyTypes"
                  label="Therapy Types"
                  handleOnChange={e => handleTherapyTypeChange(e)}
                  fields={lookups.therapyTypes.map(therapyType => ({
                    value: therapyType.id,
                    label: therapyType.type,
                  }))}
                />
              </Grid>
            )}
          {taskTagFilters()}
          {queueAllowsAuditFilters.indexOf(sidebarTaskType) > -1 && (
            <>
              <Grid item xs={12} className={classes.serviceGroupContainer}>
                <ReactSelect
                  defaultValues={
                    sidebarFilters.auditWarningsCategory &&
                    sidebarFilters.auditWarningsCategory.length > 0
                      ? sidebarFilters.auditWarningsCategory.map(category => ({
                          value: category,
                          label: getAuditCategory(category),
                        }))
                      : null
                  }
                  name="auditCategory"
                  label="Audit Categories"
                  handleOnChange={value => handleAuditChange({ category: value })}
                  fields={auditCategoriesRaw(lookups).map(cat => ({
                    value: cat,
                    label: getAuditCategory(cat),
                  }))}
                />
              </Grid>
              <Grid item xs={12} className={classes.serviceGroupContainer}>
                <ReactSelect
                  defaultValues={initialTypesForAuditRule()}
                  name="auditRule"
                  label="Audit Rule"
                  handleOnChange={value => handleAuditChange({ rule: value })}
                  fields={
                    lookups.auditConstants
                      ? lookups.auditConstants.map(cat => ({
                          value: cat.arbor_audit_definition_id,
                          label: cat.audit_item,
                        }))
                      : []
                  }
                />
              </Grid>
            </>
          )}
          {sidebarTaskType === FC && (
            <>
              <SmallSpacer />
              <FormControlLabel
                control={<Checkbox />}
                checked={isSelectAllSecureLinkFC}
                onChange={e => handleSelectAllSecureLinkFCFilters(e)}
                label="Select All Secure Link FC Filters"
              />
              <SmallSpacer />
            </>
          )}
          {sidebarTaskType !== THERAPIES && sidebarTaskType !== SMS_RELATED_TASK && (
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                value={[...taskStatusValues]}
                name="taskStatus"
                label="Task Status"
                handleOnChange={handleStatusChange}
                fields={[...statusOptions]}
              />
              {sidebarTaskType === FC &&
                taskStatusValues?.findIndex(taskStatus => taskStatus.value === 'In Progress') >=
                  0 && (
                  <>
                    <SmallSpacer />
                    <Grid item xs={12} className={classes.serviceGroupContainer}>
                      <ReactSelect
                        value={fcInProgressReasons.filter(
                          lkp =>
                            sidebarFilters.fcInProgressReason.findIndex(
                              value => lkp.value === value,
                            ) >= 0,
                        )}
                        name="inProgressReason"
                        label="In Progress Reason"
                        handleOnChange={handleReasonInProgressChange}
                        fields={fcInProgressReasons}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          )}
          {sidebarTaskType === FC && (
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <DispensingPharmacyFilter />
              <MediumSpacer />
            </Grid>
          )}
          {sidebarTaskType === THERAPIES && (
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                value={enrollmentStatusValues}
                name="dispensingStatus"
                label="Dispensing Status"
                handleOnChange={handleDispensingStatusChange}
                fields={buildEnrollmentStatusOptions()}
              />
            </Grid>
          )}
          {sidebarTaskType === THERAPIES && (
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                value={administrationStatusValues}
                name="administrationStatus"
                label="Administration Status"
                handleOnChange={handleAdministrationStatusChange}
                fields={buildAdministrationStatusOptions()}
              />
            </Grid>
          )}
          {sidebarTaskType === FDC && (
            <>
              <Grid item xs={12} className={classes.serviceGroupContainer}>
                <SubHeader name="METHOD OF DELIVERY" />
                <SmallSpacer />
                <ReactSelect
                  defaultValues={defaultMethodOfDelivery}
                  name="fdcMethodsOfDelivery"
                  label="Method of Delivery"
                  handleOnChange={handleMethodOfDelivery}
                  fields={methodOfDeliveryForCustomer.filter(val => !val.disabled)}
                />
              </Grid>
              <Grid item xs={12} className={classes.serviceGroupContainer}>
                <SubHeader name="PACKAGE OPTIONS" />
                <SmallSpacer />

                <FormControlLabel
                  control={<Checkbox />}
                  checked={sidebarFilters.coldChain}
                  onChange={e => handleColdChain(e)}
                  label="Cold Chain"
                />

                <FormControlLabel
                  control={<Checkbox />}
                  checked={sidebarFilters.urgent}
                  onChange={e => handleUrgent(e)}
                  label="Urgent"
                />

                <FormControlLabel
                  control={<Checkbox />}
                  checked={sidebarFilters.adherencePackaging}
                  onChange={e => handleAdherencePackaging(e)}
                  label="Adherence Packaging"
                />
              </Grid>
            </>
          )}
          {sidebarTaskType === 'AR' && (
            <Grid item xs={12} className={classes.serviceGroupContainer}>
              <ReactSelect
                value={arReasonValues}
                name="arReasonValues"
                label="Reason"
                handleOnChange={handleARReasonChange}
                fields={buildOutreachReasons()}
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12} className={classes.noFiltersContainer}>
          Filters are not available for the Starred Patients queue
        </Grid>
      )}
    </Grid>
  );

  return sideBarTaskFilters;

  // #endregion renders
};

function mapStateToProps(state) {
  const { lookups, sidebarFilters, sidebarTaskType, taskStatuses, selectedPatientId } = state;
  return {
    lookups,
    sidebarFilters: sidebarFilters.data.task,
    selectedPatientId,
    sidebarTaskType,
    taskStatuses,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    setSidebarFilter,
  }),
)(TaskFilterPanel);
