import { ITherapyBenefitsInvestigationLatest } from 'interfaces/redux/ITherapyBenefitsInvestigationLatest';
import { SELECTED_PATIENT_ID } from '../constants';
import {
  BenefitInvestigationLatestActionType,
  BenefitInvestigationLatestAction,
} from '../actions/action-therapy-benefits-investigation-latest';

export const initialState: ITherapyBenefitsInvestigationLatest = {
  loading: false,
  lastLoadFailed: false,
  data: {},
};

export const therapyBenefitsInvestigationLatestReducer = (
  state: ITherapyBenefitsInvestigationLatest = initialState,
  action: BenefitInvestigationLatestAction | { type: string; payload: number },
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECTED_PATIENT_ID:
      return {
        ...state,
        loading: true,
      };
    case BenefitInvestigationLatestActionType.GetByPatientIdSuccess:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case BenefitInvestigationLatestActionType.GetByPatientIdFailed:
      // @TODO - define what to do when fetching latest bi fails
      return {
        loading: false,
        lastLoadFailed: true,
        data: {},
      };
    case BenefitInvestigationLatestActionType.UpdateByTherapyId:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.therapyId]: payload,
        },
      };
    default:
      return state;
  }
};
