import React from 'react';
import { compose } from 'recompose';
import { Typography, Grid, Button, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { required } from 'components/form/validation/validation';
import { Field, reduxForm } from 'redux-form';
import {
  UPLOAD_DOC_FILETYPE_FIELD,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
} from '../../../constants';
import { styles } from '../document-bar-styles';
import DocumentFileIcon from '../document-file-icon';
import { renderMultipleDropdown, renderDebouncedTextField } from '../../form/field/redux-field';

const FileSelectedForm = props => {
  const {
    classes,
    isProcessing,
    errorMessage,
    successMessage,
    fileName,
    documentLabels,
    submitButtonText,
    cancelDisabled,
    submitDisabled,
    onCancel,
    formSubmit,
    noteFieldDisabled,
    fileExtension,
    handleSubmit,
  } = props;

  React.useEffect(() => {
    props.change([UPLOAD_DOC_FILENAME_FIELD], props.fileName);
  }, [props.fileName]);

  const renderErrorMessage = () =>
    errorMessage && (
      <h3 className={classes.uploadError} data-qa-id="error-message">
        {errorMessage}
      </h3>
    );

  const renderSuccessMessage = () =>
    successMessage && (
      <h3 className={classes.uploadSuccess} data-qa-id="success-message">
        {successMessage}
      </h3>
    );

  const renderForm = () => (
    <form className={classes.uploadForm}>
      {renderErrorMessage()}
      {renderSuccessMessage()}
      <Grid container className={classes.fileSelectedFormContainer} justifyContent="space-between">
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Grid container alignContent="center">
                <Grid item>
                  <DocumentFileIcon
                    fileNameExtension={fileExtension}
                    className={classes.fileTypeIcon}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h3"
                    className={classes.fileName}
                    data-qa-id="filename-display"
                  >
                    {`${fileName}.${fileExtension}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.fileType}
                    data-qa-id="filename-caption"
                  >
                    {`.${fileExtension} file`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Form Fields */}
        <Grid item xs={12}>
          <Field
            name={UPLOAD_DOC_FILENAME_FIELD}
            label="File name*"
            width="100%"
            component={renderDebouncedTextField}
            validate={[required]}
          />
          <Field
            name={UPLOAD_DOC_FILETYPE_FIELD}
            label="Type of attachment*"
            multiple
            fields={documentLabels}
            component={renderMultipleDropdown}
            validate={[required]}
          />
          <Field
            name={UPLOAD_DOC_FILENOTE_FIELD}
            label="Note"
            width="100%"
            multiline
            component={renderDebouncedTextField}
            rows={2}
            disabled={noteFieldDisabled}
          />
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button
            onClick={() => onCancel()}
            className={classNames(classes.fileButton, classes.cancelButton)}
            disabled={cancelDisabled}
            data-qa-id="cancel-button"
          >
            Cancel
          </Button>
          <Button
            className={classes.fileButton}
            disabled={submitDisabled}
            onClick={handleSubmit(formSubmit)}
            data-qa-id="submit-button"
          >
            {submitButtonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  const renderSpinner = () => {
    const containerClassName = classNames(classes.fileUploadingContainer);
    return (
      <Grid container className={containerClassName} justifyContent="center" data-qa-id="spinner">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  };

  return isProcessing ? renderSpinner() : renderForm();
};

function mapStateToProps(state, props) {
  return {
    initialValues: {
      [UPLOAD_DOC_FILENAME_FIELD]: props.fileName,
    },
  };
}

export default compose(withStyles(styles), reduxForm({}, mapStateToProps))(FileSelectedForm);
