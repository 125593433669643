import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);

  const headerColumnPadding = {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  };

  const actionIcon = {
    width: '21px',
    padding: '2px',
    cursor: 'pointer',
    transform: 'translateX(-15%) translateY(-2px)',
  };

  return createStyles({
    noteCountText: {
      color: palette.primary.steelGrey,
      fontWeight: 500,
    },
    table: {
      borderRadius: theme.spacing(0.5),
      minWidth: theme.spacing(80),
      border: `1px solid ${palette.primary.grey25}`,
    },
    filterDateLabel: {
      fontSize: '11px',
      fontWeight: 400,
    },
    fieldLabel: {
      fontSize: '11px',
      fontWeight: 400,
      minHeight: 'inherit',
      WebkitTransform: 'inherit',
      lineHeight: 1.66,
      color: '#44484c',
    },
    filterSpacing: {
      paddingRight: '20px',
    },
    filterSpacingPeriod: {
      paddingRight: '20px',
      paddingTop: '25px',
    },
    filterTherapyStyling: {
      'paddingRight': '20px',
      '& > div > div > div > div': {
        marginTop: '12px',
      },
      '& label': {
        fontSize: '11px',
        fontWeight: 400,
        minHeight: 'inherit',
        WebkitTransform: 'inherit',
        lineHeight: 1.66,
        color: '#44484c',
      },
    },
    filterTaskStyling: {
      'paddingRight': '20px',
      '& > div > div > div > div': {
        marginTop: '12px',
      },
      '& label': {
        fontSize: '11px',
        fontWeight: 400,
        minHeight: 'inherit',
        WebkitTransform: 'inherit',
        lineHeight: 1.66,
        color: '#44484c',
      },
    },
    header: {
      backgroundColor: palette.primary.grey2,
      height: 10,
    },
    oddRow: {
      backgroundColor: palette.primary.white,
    },
    evenRow: {
      backgroundColor: palette.primary.grey14,
    },
    completedRow: {
      backgroundColor: palette.primary.patinaWhite,
    },
    highlightRow: {
      backgroundColor: palette.primary.trellisLightBlue,
    },
    actionIconTaskInProgress: {
      ...actionIcon,
      '& *': {
        fill: palette.primary.rollingStone,
      },
    },
    actionIconTaskCompleted: {
      ...actionIcon,
      '& *': {
        fill: palette.primary.rollingStone,
        opacity: 0.8,
      },
    },
    headerColumn: {
      ...headerColumnPadding,
    },
    headerNarrowColumn: {
      ...headerColumnPadding,
      width: theme.spacing(1),
    },
    headerNoteColumn: {
      ...headerColumnPadding,
      minWidth: theme.spacing(37.5),
      width: theme.spacing(37.5),
    },
    noteColumn: {
      backgroundColor: palette.primary.grey2,
      borderRadius: theme.spacing(0.25),
    },
    emptyNoteColumn: {
      width: theme.spacing(38.75),
      height: theme.spacing(5.4375),
      backgroundColor: palette.primary.grey2,
    },
    outstandingText: {
      color: palette.primary.valenciaRed,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },
    inProgressText: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      color: palette.primary.trellisBlue,
      fontWeight: 'bold',
    },
    dcPromIcon: {
      color: palette.primary.rollingStone,
      marginLeft: '5px',
    },
    completedText: {
      display: 'flex',
      gap: '5px',
      color: palette.primary.patina,
      fontWeight: 'bold',
    },
    therapyNameFull: {
      fontWeight: 'bold',
      fontSize: font.smallFontSize,
    },
    dosingIconDisabled: {
      '& path': {
        opacity: '0.20',
      },
    },
    prnIcon: {
      width: '25px',
    },
    dispensingIconDisabled: {
      '& path': {
        opacity: '0.20',
      },
    },
    fancyTooltip: {
      fontSize: '0.58rem',
      fontWeight: 'bold',
    },
    partialTaskSelectionSet: {
      color: 'grey !important',
    },
    showFormButton: {
      margin: 'auto',
    },
    messageContainer: {
      textAlign: 'center',
      height: '100px',
      verticalAlign: 'middle',
      lineHeight: '100px',
      fontSize: '20px',
      backgroundColor: palette.primary.trellisLightBlue,
    },
    therapyEllipsis: {
      cursor: 'default',
    },
    diagnosisText: {
      fontSize: '11px',
    },
    diagnosisTextBold: {
      fontSize: '11px',
      fontWeight: 'bold',
    },
    therapyTag: {
      'borderRadius': '3px',
      'color': palette.primary.white,
      'padding': '3px 4px',
      'marginLeft': '4px',
      'backgroundColor': palette.primary.success,
      'fontWeight': font.weight.thick,
      'fontSize': '0.6rem',
      'lineHeight': 'normal',
      'position': 'relative',
      'top': '-1px',
      '&.CDM': {
        backgroundColor: palette.primary.yellow,
        color: palette.primary.deepBlue,
      },
      '&.OTF': {
        color: palette.primary.white,
        backgroundColor: palette.primary.deepBlue,
      },
    },
    plusSignButton: {
      textAlign: 'center',
      lineHeight: '20px',
      cursor: 'pointer',
      height: '20px',
      width: '20px',
      backgroundColor: '#5D87AF',
      color: 'white',
      borderRadius: '5px',
    },
  });
};

const Styles = {
  addTaskButton: {
    'backgroundColor': 'primary.background',
    'border': '1px solid #C4C4C4',
    'borderRadius': '4px',
    'boxShaddow': 'none',
    'color': '#44484C',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: 'primary.background',
      border: '1px solid #C4C4C4',
      borderRadius: '4px',
      color: 'primary.steelGrey',
      boxShaddow: 'none',
      boxShadow: 'none',
    },
  },
};

export { styles, Styles };
