import moment from 'moment-business-days';
import {
  AR,
  CSL,
  DATE_OPTIONS,
  DC,
  DEFAULT_AR_TASKS_LIMIT,
  FA,
  FC,
  FDC,
  OR,
  PA,
  QRE,
} from 'constants/index';
import { convertToArborDate } from 'models/time/arbor-date';
import { Timezone } from 'models/time/timezone';
import { TaskTypesEnum } from 'constants/enums';
import { getFinancialTypeFilterValueByKey } from '../../constants/lists';

const DATE_FORMAT_1 = 'YYYY-MM-DD';
const DATE_FORMAT_2 = 'MM/DD/YYYY';

export const getDateRangeFromName = name => {
  let from = null;
  let to = null;
  const customerTimezone = Timezone.getInstance().timezone;
  switch (name) {
    case DATE_OPTIONS.ALL:
      from = null;
      to = null;
      break;
    case DATE_OPTIONS.LAST_90_DAYS:
      from = moment().tz(customerTimezone).subtract(90, 'days');
      to = moment().tz(customerTimezone);
      break;
    case DATE_OPTIONS.TODAY:
      from = moment().tz(customerTimezone);
      to = moment().tz(customerTimezone);
      break;
    case DATE_OPTIONS.YESTERDAY:
      from = moment().tz(customerTimezone).subtract(1, 'days');
      to = moment().tz(customerTimezone).subtract(1, 'days');
      break;
    case DATE_OPTIONS.OVERDUE:
      from = null;
      to = moment().tz(customerTimezone).subtract(1, 'days');
      break;
    case DATE_OPTIONS.TOMORROW:
      from = moment().tz(customerTimezone).add(1, 'days');
      to = moment().tz(customerTimezone).add(1, 'days');
      break;
    case DATE_OPTIONS.NEXT_3_DAYS:
      from = moment().tz(customerTimezone);
      to = moment().tz(customerTimezone).add(3, 'days');
      break;
    case DATE_OPTIONS.NEXT_7_DAYS:
      from = moment().tz(customerTimezone);
      to = moment().tz(customerTimezone).add(7, 'days');
      break;
    case DATE_OPTIONS.NEXT_14_DAYS:
      from = moment().tz(customerTimezone);
      to = moment().tz(customerTimezone).add(14, 'days');
      break;
    case DATE_OPTIONS.NEXT_30_DAYS:
      from = moment().tz(customerTimezone);
      to = moment().tz(customerTimezone).add(30, 'days');
      break;
    case DATE_OPTIONS.LAST_WEEK:
      from = moment().tz(customerTimezone).subtract(7, 'days').startOf('week');
      to = moment().tz(customerTimezone).subtract(7, 'days').endOf('week');
      break;
    case DATE_OPTIONS.LAST_MONTH:
      // eslint-disable-next-line newline-per-chained-call
      from = moment().tz(customerTimezone).startOf('month').subtract(1, 'days').startOf('month');
      // eslint-disable-next-line newline-per-chained-call
      to = moment().tz(customerTimezone).startOf('month').subtract(1, 'days').endOf('month');
      break;
    case DATE_OPTIONS.NEXT_6_MONTHS:
      from = moment().tz(customerTimezone);
      to = moment().tz(customerTimezone).add(6, 'month');
      break;
    default:
      from = moment().tz(customerTimezone);
      to = moment().tz(customerTimezone);
      break;
  }
  return {
    from: from ? from.startOf('day') : null,
    to: to ? to.startOf('day') : null,
  };
};

export const getDatesFromSidebarFilters = sidebarFilters => {
  const range = getDateRangeFromName(sidebarFilters.date);
  let from;
  let to;
  if (sidebarFilters.from) {
    from = moment(sidebarFilters.from, DATE_FORMAT_1).isValid()
      ? moment(sidebarFilters.from, DATE_FORMAT_1).format(DATE_FORMAT_2)
      : null;
  } else {
    from = sidebarFilters.date && range.from ? range.from.format(DATE_FORMAT_2) : null;
  }
  if (sidebarFilters.to) {
    to = moment(sidebarFilters.to, DATE_FORMAT_1).isValid()
      ? moment(sidebarFilters.to).format(DATE_FORMAT_2)
      : null;
  } else {
    to = sidebarFilters.date && range.to ? range.to.format(DATE_FORMAT_2) : null;
  }
  return {
    from,
    to,
  };
};

export const getQueryFilters = (
  sidebarFilters,
  taskType,
  { addEncodedTaskTypeStatusIds = false, addStatusIds = false },
) => {
  let safeTaskType = taskType;
  const taskTypes = Object.values(TaskTypesEnum).concat(['STAR', 'therapies']);
  if (!taskTypes.includes(taskType)) {
    safeTaskType = 'therapies';
  }

  const query = { task: safeTaskType };
  if (sidebarFilters) {
    const { from, to } = getDatesFromSidebarFilters(sidebarFilters);

    if (safeTaskType === 'therapies') {
      query.date_from = from && convertToArborDate(from, false).getUtcDate();
      query.date_to = to && convertToArborDate(to, false).getUtcDate();
      query.audit_warnings_category = sidebarFilters.auditWarningsCategory;
      query.audit_warnings_rule = sidebarFilters.auditWarningsRule;
      // Dispensing enrollement status id
      if (sidebarFilters?.dispensingEnrollmentStatusIds?.length) {
        // eslint-disable-next-line max-len
        query.dispensing_enrollment_status_ids =
          sidebarFilters?.dispensingEnrollmentStatusIds?.join(',');
      }
      if (sidebarFilters?.administrationStatusIds?.length) {
        // eslint-disable-next-line max-len
        query.administration_status_ids = sidebarFilters?.administrationStatusIds?.join(',');
      }

      // if there is a user preference for it always use it
      if (sidebarFilters?.arTasksLimit) {
        query.ar_tasks_limit = sidebarFilters.arTasksLimit || DEFAULT_AR_TASKS_LIMIT;
      }
    } else {
      query.date_from = from && convertToArborDate(from, false).getUtcDatetime();
      query.date_to = to && convertToArborDate(to, false).getUtcDatetime();
      query.task_type = taskType;
      query.audit_warnings_category = sidebarFilters.auditWarningsCategory;
      query.audit_warnings_rule = sidebarFilters.auditWarningsRule;

      // status ids as comma separated values are used to fetch worklist patients
      if (addStatusIds && sidebarFilters?.statusIds?.[taskType]?.length) {
        query.status_ids = sidebarFilters.statusIds[taskType].join(',');
      }

      // encoded task type + status ids are used to fetch task count based on filters
      if (addEncodedTaskTypeStatusIds && Object.keys(sidebarFilters?.statusIds || {}).length) {
        query.task_type_status_ids = encodeURIComponent(JSON.stringify(sidebarFilters.statusIds));
      }

      // if there is a user preference for it always use it
      if (sidebarFilters?.arTasksLimit) {
        query.ar_tasks_limit = sidebarFilters.arTasksLimit || DEFAULT_AR_TASKS_LIMIT;
      }

      switch (taskType) {
        case PA:
          query.pa_new_renewal = sidebarFilters.paNewRenewal;
          break;
        case FA:
          query.fa_new_renewal = sidebarFilters.faNewRenewal;
          if (sidebarFilters.hideInternalFundingFA) {
            query.hide_internal_funding_fa = true;
          }
          break;
        case FDC:
          if (sidebarFilters.fdcMethodsOfDelivery?.length > 0) {
            query.preferred_rx_delivery_method = sidebarFilters.fdcMethodsOfDelivery
              .map(group => group.idValue || group.value || group)
              .join(',');
            if (sidebarFilters.deliveryMethodCourierType?.length > 0) {
              query.preferred_rx_delivery_method_courier_type =
                sidebarFilters.deliveryMethodCourierType.map(({ value }) => value).join(',');
            }
          }

          if (sidebarFilters.coldChain) {
            query.cold_chain_packing_required = true;
          }
          if (sidebarFilters.urgent) {
            query.urgent = true;
          }
          if (sidebarFilters.adherencePackaging) {
            query.adherence_packaging = true;
          }
          break;
        case CSL:
          query.csl_initial_ongoing = sidebarFilters.cslInitialOngoing;
          break;
        case OR:
          if (!sidebarFilters.orType) {
            sidebarFilters.orType = [];
          }
          if (!sidebarFilters.orCategory) {
            sidebarFilters.orCategory = [];
          }
          query.or_type = sidebarFilters.orType.join(',');
          query.or_category = sidebarFilters.orCategory.join(',');
          break;
        case AR:
          if (!sidebarFilters.arType) {
            sidebarFilters.arType = [];
          }
          query.ar_type = sidebarFilters.arType.join(',');
          if (sidebarFilters.excludeOverdueARs) {
            query.exclude_overdue_ars = true;
          }
          if (!sidebarFilters.arCommunicationType) {
            query.ar_communication_type = [];
          }
          query.ar_communication_type = sidebarFilters.arCommunicationType;
          break;
        case QRE:
          if (!sidebarFilters.incidentType) {
            sidebarFilters.incidentType = [];
          }
          if (!sidebarFilters.incidentCategory) {
            sidebarFilters.incidentCategory = [];
          }
          if (!sidebarFilters.incidentResponsibleParty) {
            sidebarFilters.incidentResponsibleParty = [];
          }
          query.incident_type = sidebarFilters.incidentType.join(',');
          query.incident_category = sidebarFilters.incidentCategory.join(',');
          query.incident_responsible_party = sidebarFilters.incidentResponsibleParty.join(',');

          break;
        case FC:
          if (sidebarFilters.displayOnlyFC && sidebarFilters.displayOnlyFC.length > 0) {
            query.display_only_fc = sidebarFilters.displayOnlyFC.join(',');
          }
          if (sidebarFilters.fcInProgressReason && sidebarFilters.fcInProgressReason.length > 0) {
            query.fc_in_progress_reason = sidebarFilters.fcInProgressReason.join(',');
          }
          if (sidebarFilters.fillCycle && sidebarFilters.fillCycle > 1) {
            query.fill_cycle = sidebarFilters.fillCycle;
          }
          query.dispensing_pharmacies = sidebarFilters.dispensingPharmacies
            ?.map(({ id }) => id)
            .join(',');

          break;
        case DC:
          if (sidebarFilters.displayOnlyDC && sidebarFilters.displayOnlyDC.length > 0) {
            query.display_only_dc = sidebarFilters.displayOnlyDC.join(',');
          }
          break;
        default:
          break;
      }
    }

    if (sidebarFilters.serviceGroups && sidebarFilters.serviceGroups.length) {
      query.service_groups = sidebarFilters.serviceGroups
        .map(group => group.idValue || group.value || group)
        .join(',');
    }
    if (sidebarFilters.therapyTypes && sidebarFilters.therapyTypes.length) {
      query.therapy_types = sidebarFilters.therapyTypes
        .map(therapyType => {
          if (therapyType.idValue !== undefined) return therapyType.idValue;
          return therapyType;
        })
        .join(',');
    }
    if (sidebarFilters.clinics && sidebarFilters.clinics.length) {
      query.clinics = sidebarFilters.clinics
        .map(group => group.idValue || group.value || group)
        .join(',');
    }
    if (safeTaskType === AR) {
      query.all_tasks = sidebarFilters.allTasks;
    }
    query.are_completed_tasks = sidebarFilters.completed;

    if (taskType === AR) {
      query.ar_reasons = sidebarFilters.arReasonIds;
    }

    if (taskType === FC) {
      query.fc_with_interpreter = sidebarFilters.onlyFCWhichNeedInterpreter;
      if (sidebarFilters.internalFoundation?.length > 0) {
        query.internal_foundation = sidebarFilters.internalFoundation;
      }
    }
    if (taskType === FA && sidebarFilters.assistanceProgram?.length > 0) {
      const filterValues = sidebarFilters.assistanceProgram.flatMap(
        x => getFinancialTypeFilterValueByKey(x).label,
      );
      query.assistance_program = filterValues;
    }
  }

  const queryParameterNames = Object.keys(query);
  const queryParameterNamesWithValue = queryParameterNames.filter(
    queryParamName =>
      query[queryParamName] || query[queryParamName] === false || query[queryParamName] === 0,
  );
  return queryParameterNamesWithValue
    .map(queryParamName => {
      const queryParamValue = query[queryParamName];
      return `${queryParamName}=${encodeURIComponent(queryParamValue)}`;
    })
    .join('&');
};

export const getRadioCheckedDateFilter = filters => {
  const all = getDateRangeFromName(DATE_OPTIONS.ALL);
  const last90Days = getDateRangeFromName(DATE_OPTIONS.LAST_90_DAYS);
  const today = getDateRangeFromName(DATE_OPTIONS.TODAY);
  const overdue = getDateRangeFromName(DATE_OPTIONS.OVERDUE);
  const yesterday = getDateRangeFromName(DATE_OPTIONS.YESTERDAY);
  const tomorrow = getDateRangeFromName(DATE_OPTIONS.TOMORROW);
  const next3days = getDateRangeFromName(DATE_OPTIONS.NEXT_3_DAYS);
  const next7days = getDateRangeFromName(DATE_OPTIONS.NEXT_7_DAYS);
  const next14Days = getDateRangeFromName(DATE_OPTIONS.NEXT_14_DAYS);
  const next30Days = getDateRangeFromName(DATE_OPTIONS.NEXT_30_DAYS);
  const lastWeek = getDateRangeFromName(DATE_OPTIONS.LAST_WEEK);
  const next6Months = getDateRangeFromName(DATE_OPTIONS.NEXT_6_MONTHS);
  const lastMonth = getDateRangeFromName(DATE_OPTIONS.LAST_MONTH);

  const from = filters.from ? moment(filters.from) : null;
  const to = filters.to ? moment(filters.to) : null;

  if (!from && !to && filters.date) {
    return [
      DATE_OPTIONS.ALL,
      DATE_OPTIONS.LAST_90_DAYS,
      DATE_OPTIONS.TODAY,
      DATE_OPTIONS.OVERDUE,
      DATE_OPTIONS.YESTERDAY,
      DATE_OPTIONS.TOMORROW,
      DATE_OPTIONS.NEXT_3_DAYS,
      DATE_OPTIONS.NEXT_7_DAYS,
      DATE_OPTIONS.NEXT_14_DAYS,
    ].reduce((acc, key) => {
      acc[key] = filters.date === key;
      return acc;
    }, {});
  }

  return {
    [DATE_OPTIONS.ALL]: from === all.from && to === all.to,
    [DATE_OPTIONS.LAST_90_DAYS]: from === last90Days.from && to === last90Days.to,
    [DATE_OPTIONS.TODAY]: from && to && from.isSame(today.from, 'd') && to.isSame(today.to, 'd'),
    [DATE_OPTIONS.YESTERDAY]:
      from && to && from.isSame(yesterday.from, 'd') && to.isSame(yesterday.to, 'd'),
    [DATE_OPTIONS.OVERDUE]:
      from && to && to.isSame(overdue.from, 'd') && to.isSame(overdue.to, 'd'),
    [DATE_OPTIONS.TOMORROW]:
      from && to && from.isSame(tomorrow.from, 'd') && to.isSame(tomorrow.to, 'd'),
    [DATE_OPTIONS.NEXT_3_DAYS]:
      from && to && from.isSame(next3days.from, 'd') && to.isSame(next3days.to, 'd'),
    [DATE_OPTIONS.NEXT_7_DAYS]:
      from && to && from.isSame(next7days.from, 'd') && to.isSame(next7days.to, 'd'),
    [DATE_OPTIONS.NEXT_14_DAYS]:
      from && to && from.isSame(next14Days.from, 'd') && to.isSame(next14Days.to, 'd'),
    [DATE_OPTIONS.NEXT_30_DAYS]:
      from && to && from.isSame(next30Days.from, 'd') && to.isSame(next30Days.to, 'd'),
    [DATE_OPTIONS.LAST_WEEK]:
      from && to && from.isSame(lastWeek.from, 'd') && to.isSame(lastWeek.to, 'd'),
    [DATE_OPTIONS.NEXT_6_MONTHS]:
      from && to && from.isSame(next6Months.from, 'd') && to.isSame(next6Months.to, 'd'),
    [DATE_OPTIONS.LAST_MONTH]:
      from && to && from.isSame(lastMonth.from, 'd') && to.isSame(lastMonth.to, 'd'),
  };
};

export const evaluateDate = date => {
  if (moment(date, DATE_FORMAT_1).isValid() && date.length === 10) {
    return date;
  }
  if (!date) {
    return 'noDate';
  }
  return null;
};

export const outreachTypeByNameForFilters = outreachTypes => {
  let retArr = [];
  outreachTypes.forEach(typeVar => {
    if (!retArr.some(val => val.name === typeVar.name)) {
      retArr.push({
        name: typeVar.name,
        related: [typeVar.id],
      });
    } else {
      const idx = retArr.findIndex(obj => obj.name === typeVar.name);
      retArr[idx].related.push(typeVar.id);
    }
  });
  retArr = retArr.map(val => ({
    ...val,
    related: val.related.sort((a, b) => a - b).join(','),
  }));
  return retArr;
};

export const taskStatusByNameForFilters = taskStatuses => {
  let retArr = [];
  taskStatuses.forEach(entry => {
    if (!retArr.some(val => val.name === entry.status)) {
      retArr.push({
        name: entry.status,
        related: [entry.id],
      });
    } else {
      const idx = retArr.findIndex(obj => obj.name === entry.status);
      retArr[idx].related.push(entry.id);
    }
  });
  retArr = retArr.map(val => ({
    ...val,
    related: val.related.sort((a, b) => a - b).join(','),
  }));
  return retArr;
};

export const incidentTypeByNameForFilters = incidentTypes => {
  let retArr = [];
  incidentTypes.forEach(typeVar => {
    if (!retArr.some(val => val.name === typeVar.name)) {
      retArr.push({
        name: typeVar.name,
        id: [typeVar.id],
      });
    } else {
      const idx = retArr.findIndex(obj => obj.name === typeVar.name);
      retArr[idx].id.push(typeVar.id);
    }
  });
  retArr = retArr.map(val => ({
    ...val,
    id: val.id.sort((a, b) => a - b).join(','),
  }));
  return retArr;
};

export const getTasksAtAGlanceFilters = sidebarFilters => {
  let dateFrom;
  let dateTo;
  let dateValue;
  const { from, to } = getDatesFromSidebarFilters(sidebarFilters);
  if (!from && getDateRangeFromName(DATE_OPTIONS.OVERDUE).to.format(DATE_FORMAT_2) === to) {
    dateValue = DATE_OPTIONS.OVERDUE;
    dateFrom = from && convertToArborDate(DATE_OPTIONS.OVERDUE, false).getUtcDate();
    dateTo = to && convertToArborDate(DATE_OPTIONS.OVERDUE, false).getUtcDate();
  } else {
    dateValue = DATE_OPTIONS.NEXT_3_DAYS;
    dateFrom =
      from &&
      convertToArborDate(getDateRangeFromName(DATE_OPTIONS.NEXT_3_DAYS).from, false).getUtcDate();
    dateTo =
      to &&
      convertToArborDate(getDateRangeFromName(DATE_OPTIONS.NEXT_3_DAYS).to, false).getUtcDate();
  }
  return {
    date: dateValue,
    from: dateFrom,
    to: dateTo,
    clinics: [],
    completed: sidebarFilters.completed,
    serviceGroups: sidebarFilters.serviceGroups,
    orCategory: [],
    orType: [],
    incidentCategory: [],
    incidentType: [],
    paNewRenewal: [],
    faNewRenewal: [],
    cslInitialOngoing: [],
    auditWarningsCategory: [],
    auditWarningsRules: [],
    arType: [],
  };
};

export const calcIncidentCombinedResponsibles = lookups => {
  let retArr = [];
  let externalArr = [];
  let userArr = [];
  if (lookups && lookups.incidentExternalResponsibles) {
    externalArr = lookups.incidentExternalResponsibles.map(responsible => ({
      value: `e${responsible.id}`,
      label: responsible.name,
      type: 'external',
    }));
  }
  if (lookups && lookups.users) {
    userArr = lookups.users.map(user => ({
      value: `u${user.id}`,
      label: user.display_name,
      type: 'user',
    }));
  }
  retArr = [...externalArr, ...userArr];
  return retArr;
};
