/* eslint-disable */
import React from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link } from 'react-router-dom';
import { withBaseIcon, Icon } from 'react-icons-kit';
import {
  statsBars,
  ticket,
  equalizer,
  search,
  pushpin,
  link,
  checkmark,
  loop2,
  info,
  table2,
  cloudDownload,
  printer,
  truck,
  coinDollar,
} from 'react-icons-kit/icomoon/';
// eslint-disable-next-line camelcase
import { screen_expand_2 } from 'react-icons-kit/ikons/screen_expand_2';
import { ic_view_list } from 'react-icons-kit/md/ic_view_list';
import { layers } from 'react-icons-kit/feather/';
import { archive, exclamationCircle } from 'react-icons-kit/fa';
import classNames from 'classnames';
import {
  ic_people as icPeople,
  ic_content_copy as icContentCopy,
  ic_edit as icEdit,
  ic_history as icHistory,
  ic_done as icDone,
  ic_close as icClose,
  ic_remove as icRemove,
  ic_add as icAdd,
  ic_close as icDelete,
  ic_expand_more as icExpand,
  ic_person_add as icPersonAdd,
  ic_keyboard_arrow_down as icKeyboardArrowDown,
  ic_autorenew as icAutorenew,
  ic_arrow_forward as icArrowForward,
  ic_chevron_right as icChevronRight,
  ic_warning as icWarning,
  ic_brightness_1 as icBrightness,
  ic_location_on as icLocation,
} from 'react-icons-kit/md/';
import { calendarO } from 'react-icons-kit/fa/calendarO';
import {
  THERAPY_DISPENSING_OPTIN,
  THERAPY_DISPENSING_OPTOUT,
  TASK_CARD_CONTACT_ICON,
} from 'constants/index';
import { u1F311 as circleIndicator } from 'react-icons-kit/noto_emoji_regular';
import trellislogo from '../../lib/logos/trellis_logo.png';
import perProtocolIcon from '../../lib/logos/PP.png';
import noteIcon from '../../lib/logos/note.png';
import blueNoteIcon from '../../lib/logos/note_blue.png';
import hamburgerIcon from '../../lib/logos/hamburger.png';
import communicationIcon from '../../lib/logos/talking.png';
import dispensingIconOptIn from '../../lib/logos/dispensing_opt_in.svg';
import dispensingIconOptOut from '../../lib/logos/dispensing_opt_out.svg';
import dispensingIconUndecided from '../../lib/logos/dispensing_undecided.svg';
import clinicalSupportIconUndecided from '../../lib/logos/clinical_support_undecided.svg';
import clinicalSupportIconOptIn from '../../lib/logos/clinical_support_opt_in.svg';
import clinicalSupportIconOptOut from '../../lib/logos/clinical_support_opt_out.svg';
import prescriptionBottleIcon from '../../lib/logos/prescription_bottle.svg';
import CompleteSVG from '../../lib/logos/Complete.svg';
import OutstandingSVG from '../../lib/logos/Outstanding.svg';
import PartialSVG from '../../lib/logos/Partial.svg';
import Clear from '../../lib/weather/ico-clear.svg';
import Clouds from '../../lib/weather/ico-clouds.svg';
import Drizzle from '../../lib/weather/ico-drizzle.svg';
import Mist from '../../lib/weather/ico-mist.svg';
import Rain from '../../lib/weather/ico-rain.svg';
import Snow from '../../lib/weather/ico-snow.svg';

import Thunderstorm from '../../lib/weather/ico-thunderstorm.svg';
import Pill from '../../lib/logos/icon-pills.svg';
import colors from '../../lib/themes/colors.module.scss';
import EHR from '../../lib/logos/EHR.svg';
import SMS from '../../lib/logos/SMS.svg';
import WK from '../../lib/logos/WK.svg';
import CAIGreyIcon from '../../lib/logos/CAIGreyIcon.svg';
import PRNGreyIcon from '../../lib/logos/PRNGreyIcon.svg';
import CMGreyIconI from '../../lib/logos/CMGreyIconI.svg';
import CMGreyIconII from '../../lib/logos/CMGreyIconII.svg';
import CMGreyIconIII from '../../lib/logos/CMGreyIconIII.svg';
import CMGreyIconIV from '../../lib/logos/CMGreyIconIV.svg';
import CMGreyIconV from '../../lib/logos/CMGreyIconV.svg';

import APGreyIcon from '../../lib/logos/APGreyIcon.svg';
import NoteMethod from '../../lib/logos/icon-note-method.svg';
import Ovalred from '../../lib/logos/Oval-red.svg';
import OvalGreen from '../../lib/logos/Oval-green.svg';
import OvalMore from '../../lib/adherence/ico-more.svg';
import OvalLess from '../../lib/adherence/ico-less.svg';
import Rectangle from '../../lib/logos/Rectangle';
import Star from '../../lib/logos/star-white.svg';
import StarSelected from '../../lib/logos/star-black.svg';

import { styles } from './icons-styles';
import { Error } from '@mui/icons-material';
import { useQuestionnaireStatus } from 'containers/accreditation/use-questionnaire-state';
import { DispensingStatusValue } from 'constants/enums';

const { teal, steelGrey } = colors;

const SideIconContainer = withBaseIcon({
  size: 26,
});

const checklist = {
  viewBox: '0 0 20 18',
  children: [
    {
      name: 'path',
      attribs: {
        fill: '#000000',
        d: 'M18 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V2C20 0.9 19.1 0 18 0ZM8 14H3V12H8V14ZM8 10H3V8H8V10ZM8 6H3V4H8V6ZM12.82 12L10 9.16L11.41 7.75L12.82 9.17L15.99 6L17.41 7.42L12.82 12Z',
      },
    },
  ],
};

const NavIcon = props => {
  const { icon, text, notify } = props;
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
      <Grid item>
        <SideIconContainer icon={icon} />
      </Grid>
      <Grid item>
        <Typography variant="button">{text}</Typography>
        {!!notify && (
          <Error
            sx={({ palette }) => ({
              paddingLeft: '6px',
              fontSize: '16px',
              display: 'inline-block',
              verticalAlign: 'middle',
              color: palette.primary.darkRed,
            })}
          />
        )}
      </Grid>
    </Grid>
  );
};

export const TrellisLogo = props => {
  const { onClick, to = '/', width, height } = props;
  return (
    <Link {...props} to={to} onClick={onClick} style={{ lineHeight: '0' }}>
      <img src={trellislogo} width={width || 48} height={height || 48} alt="trellis logo" />
    </Link>
  );
};
export const MainNavSearch = () => <NavIcon icon={search} />;
export const MainNavPatients = () => <NavIcon icon={icPeople} text="Patients" />;
export const MainNavSchedule = () => <NavIcon icon={calendarO} text="Schedule" />;

export const ScheduleIcon = () => (
  <Icon icon={calendarO} size={13} style={{ paddingLeft: '4px' }} />
);
export const MainNavReports = () => <NavIcon icon={statsBars} text="Reports" />;
export const MainNavShipping = () => <NavIcon icon={truck} text="Shipping" />;
export const MainNavAccreditation = () => {
  const { count, alert, isLoaded } = useQuestionnaireStatus();
  return isLoaded ? (
    <NavIcon
      icon={checklist}
      {...{ notify: alert, text: `Accreditation${count > 0 ? ` (${count})` : ''}` }}
    />
  ) : (
    <NavIcon icon={checklist} text="Accreditation" />
  );
};
export const MainNavCreatePatient = () => <NavIcon icon={icPersonAdd} text="Create Patient" />;
export const MainNavSupport = () => <NavIcon icon={ticket} text="Support" />;
export const MainNavSettings = () => <NavIcon icon={equalizer} text="Settings" />;
export const DropdownIcon = () => <NavIcon icon={icKeyboardArrowDown} />;

export const PatientOverviewIndicator = props => {
  const { content } = props;
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item style={{ color: teal }}>
        <Icon icon={circleIndicator} />
      </Grid>
      <Grid item>
        <Typography variant="body2">{content}</Typography>
      </Grid>
    </Grid>
  );
};
export const PatientOverviewCopy = props => <Icon icon={icContentCopy} {...props} />;

const wrapTypography = icon => (
  <Typography color="textSecondary" component="span">
    {icon}
  </Typography>
);

export const UnreadOverdueSmsIndicator = ({ className }) => (
  <img src={Ovalred} alt="overdue unread sms message" className={className} />
);
export const UnreadSmsIndicator = ({ className }) => (
  <img src={OvalGreen} alt="unread sms message" className={className} />
);
export const AdherenceMoreButton = ({ className }) => (
  <img src={OvalMore} alt="show adherence data" className={className} />
);
export const AdherenceLessButton = ({ className }) => (
  <img src={OvalLess} alt="hide adherence data" className={className} />
);
export const MoreMrnsButton = ({ className }) => (
  <img src={OvalMore} alt="show additional MRNs" className={className} />
);
export const LessMrnsButton = ({ className }) => (
  <img src={OvalLess} alt="hide additional MRNs" className={className} />
);

export const EditPencil = props => wrapTypography(<Icon icon={icEdit} size={20} {...props} />);
export const EditPencilMd = ({ id }) => wrapTypography(<Icon icon={icEdit} size={20} id={id} />);
export const EditAdd = props => wrapTypography(<Icon icon={icAdd} size={20} {...props} />);
export const EditRemove = props => wrapTypography(<Icon icon={icRemove} size={20} {...props} />);
export const EditDone = () => wrapTypography(<Icon icon={icDone} size={20} />);
export const EditClose = props => wrapTypography(<Icon icon={icClose} size={20} {...props} />);
export const EditDelete = () => wrapTypography(<Icon icon={icDelete} size={20} />);
export const EditHistory = () => wrapTypography(<Icon icon={icHistory} size={20} />);
export const Location = ({ size = 20, color = steelGrey }) =>
  wrapTypography(<Icon icon={icLocation} style={{ color }} size={size} />);
export const Warning = ({ size = 20, style }) =>
  wrapTypography(<Icon style={style} icon={icWarning} size={size} />);
export const LinkIcon = props => <Icon icon={link} size={20} {...props} />;
export const PerProtocolIcon = props => <img src={perProtocolIcon} {...props} alt="per protocol" />;
export const CloudDownloadIcon = props =>
  wrapTypography(<Icon icon={cloudDownload} size={20} {...props} />);
export const ScreenExpand = props =>
  // eslint-disable-next-line camelcase
  wrapTypography(<Icon icon={screen_expand_2} size={20} {...props} />);
export const Printer = props => wrapTypography(<Icon icon={printer} size={20} {...props} />);

export const Dot = props => wrapTypography(<Icon icon={icBrightness} {...props} />);

export const HamburgerIcon = () => (
  <img
    style={{
      cursor: 'pointer',
      left: '-13px',
      padding: '0',
      position: 'relative',
    }}
    src={hamburgerIcon}
    alt="expand drawer"
  />
);
export const NoteIcon = () => (
  <img
    src={noteIcon}
    alt="note"
    style={{
      width: 15,
      height: 15,
    }}
  />
);
export const MoreNotesIcon = () => wrapTypography(<Icon icon={icExpand} size={20} text="" />);
export const MoreRightIcon = () => wrapTypography(<Icon icon={icChevronRight} size={20} text="" />);
export const BlueNoteIcon = () => (
  <img
    src={blueNoteIcon}
    alt="note"
    style={{
      width: 15,
      height: 15,
    }}
  />
);
export const CommunicationIcon = () => (
  <img
    src={communicationIcon}
    alt="communication"
    style={{
      width: 25,
      height: 15,
    }}
  />
);

const dispensingStyling = {
  width: 35,
  height: 35,
  float: 'left',
};
export const DispensingIconUndecided = () => (
  <img src={dispensingIconUndecided} alt="dispensingUndecided" style={dispensingStyling} />
);
export const DispensingIconOptIn = () => (
  <img src={dispensingIconOptIn} alt="dispensingOptIn" style={dispensingStyling} />
);
export const DispensingIconOptOut = () => (
  <img src={dispensingIconOptOut} alt="dispensingOptOut" style={dispensingStyling} />
);
export const ClinicalSupportIconUndecided = () => (
  <img
    src={clinicalSupportIconUndecided}
    alt="clinicalSupportUndecided"
    style={dispensingStyling}
  />
);
export const ClinicalSupportIconOptOut = () => (
  <img src={clinicalSupportIconOptOut} alt="clinicalSupportOptOut" style={dispensingStyling} />
);
export const ClinicalSupportIconOptIn = () => (
  <img src={clinicalSupportIconOptIn} alt="clinicalSupportOptIn" style={dispensingStyling} />
);

export const getClinicalSupportIcon = clinicalSupportId => {
  switch (clinicalSupportId) {
    case THERAPY_DISPENSING_OPTIN:
      return <ClinicalSupportIconOptIn />;
    case THERAPY_DISPENSING_OPTOUT:
      return <ClinicalSupportIconOptOut />;
    default:
      return <ClinicalSupportIconUndecided />;
  }
};

export const getDispensingIcon = dispensingId => {
  switch (dispensingId) {
    case DispensingStatusValue.OptIn:
      return <DispensingIconOptIn />;
    case DispensingStatusValue.OptOut:
      return <DispensingIconOptOut />;
    default:
      return <DispensingIconUndecided />;
  }
};

export const TaskCardContactIcon = ({ idSuffix }) => (
  <img
    src={communicationIcon}
    alt={TASK_CARD_CONTACT_ICON}
    style={{ height: 12, width: 20 }}
    id={`${TASK_CARD_CONTACT_ICON}_${idSuffix}`}
  />
);

export const PrescriptionBottleIcon = ({ className }) => (
  <img src={prescriptionBottleIcon} alt="T" className={className} />
);
export const ExpandIcon = ({ className }) => <MenuOpenIcon className={className ?? ''} />;
export const CircleIndicatorIcon = ({ size, color, styles = {} }) => (
  <Icon size={size} style={{ ...styles, color }} icon={circleIndicator} />
);
export const SmallSearch = () => <Icon icon={search} size={20} />;
export const CompleteIcon = () => wrapTypography(<Icon icon={icDone} size={20} />);
export const TaskCompleteIcon = ({ color }) =>
  wrapTypography(<Icon icon={icDone} style={{ color }} size={40} />);
export const CheckmarkIcon = ({ color, size }) => (
  <Icon style={{ color }} icon={checkmark} size={size} />
);

// Rendered Icons
const taskIconSmall = ({ classes, btnText, taskName }) => (
  <Tooltip title={taskName} placement="top-start">
    <span className={classes.taskIconSmall}>
      <span className={classes.taskNameSmall}>{btnText}</span>
    </span>
  </Tooltip>
);

const taskIconMedium = ({ classes, btnText, taskName }) => (
  <Tooltip title={taskName} placement="top-start">
    <span className={classes.taskIconMedium}>
      <span className={classes.taskNameMedium}>{btnText}</span>
    </span>
  </Tooltip>
);

const taskIconSidebar = ({
  classes,
  btnText,
  taskCount,
  isSelected,
  taskName,
  icon: CustomIcon,
  isLoading,
}) => {
  const taskClass = isSelected
    ? classNames(classes.taskSidebar, classes.taskChecked)
    : classes.taskSidebar;
  const taskNumberLengthClass =
    `${taskCount}`.length < 3
      ? classes.taskNumberCount
      : classNames(classes.taskNumberCount, classes.taskNumberCountLong);
  const taskNumberClass = isSelected
    ? classNames(taskNumberLengthClass, classes.taskNumberChecked)
    : taskNumberLengthClass;
  return (
    <Tooltip title={taskName} placement="right">
      <span className={taskClass}>
        {isLoading ? (
          <span className={taskNumberClass}>
            <div style={{ marginTop: '-1px' }}>
              <LoopIcon size={10} />
            </div>
          </span>
        ) : null}
        {!isLoading && taskCount !== 0 && <span className={taskNumberClass}>{taskCount}</span>}
        {CustomIcon ? (
          <CustomIcon className={classes.customIconSidebar} />
        ) : (
          <span className={classes.taskName}>{btnText}</span>
        )}
      </span>
    </Tooltip>
  );
};

const pinnedNoteIcon = ({ classes, isPinned, upshiftForLabel, additionalStyle }) => {
  const pinnedclass = isPinned ? classes.pinnedNote : classes.unPinnedNote;
  return (
    <span>
      <Icon
        icon={pushpin}
        size={13}
        className={upshiftForLabel ? classes.upshiftForLabel : pinnedclass}
        style={additionalStyle}
      />
    </span>
  );
};

const archivedNoteIcon = ({ classes, inlineArchivedTop = false }) => (
  <span>
    <Icon
      icon={archive}
      className={inlineArchivedTop ? classes.inlineArchivedTop : classes.inlineArchived}
    />
  </span>
);

const archiveIcon = () => <Icon icon={archive} />;

const pencilIcon = () => <Icon icon={icEdit} />;

export const TaskIconSmall = withStyles(styles, { withTheme: true })(taskIconSmall);
export const TaskIconMedium = withStyles(styles, { withTheme: true })(taskIconMedium);
export const TaskIconSidebar = withStyles(styles, { withTheme: true })(taskIconSidebar);

export const PinnedNoteIcon = withStyles(styles, { withTheme: true })(pinnedNoteIcon);
export const ArchivedNoteIcon = withStyles(styles, { withTheme: true })(archivedNoteIcon);
export const ArchiveIcon = withStyles(styles, { withTheme: true })(archiveIcon);
export const PencilIcon = withStyles(styles, { withTheme: true })(pencilIcon);

export const CompleteSVGIcon = ({ className }) => (
  <img
    src={CompleteSVG}
    alt="Complete"
    className={className}
    style={{
      height: 35,
    }}
  />
);

export const OutstandingSVGIcon = ({ className }) => (
  <img
    src={OutstandingSVG}
    alt="Outstanding"
    className={className}
    style={{
      height: 35,
    }}
  />
);

export const PartialSVGIcon = ({ className }) => (
  <img
    src={PartialSVG}
    alt="Partial"
    className={className}
    style={{
      height: 35,
    }}
  />
);

export const RetryIcon = ({ color, size }) => (
  <Icon style={{ color }} icon={icAutorenew} size={size} />
);

export const ArrowForwardIcon = ({ color, size }) => (
  <Icon style={{ color }} icon={icArrowForward} size={size} />
);

export const InfoIcon = ({ color, size }) => <Icon style={{ color }} icon={info} size={size} />;
export const LoopIcon = ({ color, size }) => <Icon style={{ color }} icon={loop2} size={size} />;

export const TableIcon = ({ color, size }) => <Icon style={{ color }} icon={table2} size={size} />;

export const CardsIcon = ({ color, size }) => <Icon style={{ color }} icon={layers} size={size} />;

export const ExclamationCircleIcon = props =>
  wrapTypography(<Icon icon={exclamationCircle} {...props} />);

export const ViewListIcon = props => wrapTypography(<Icon icon={ic_view_list} {...props} />);

export const ClearIcon = () => <img src={Clear} alt="Clear" />;
export const CloudsIcon = () => <img src={Clouds} alt="Clouds" />;
export const DrizzleIcon = () => <img src={Drizzle} alt="Drizzle" />;
export const MistIcon = () => <img src={Mist} alt="Mist" />;
export const RainIcon = () => <img src={Rain} alt="Rain" />;
export const SnowIcon = () => <img src={Snow} alt="Snow" />;
export const ThunderstormIcon = () => <img src={Thunderstorm} alt="Thunderstorm" />;
export const PillIcon = props => {
  const { therapy } = props;
  // eslint-disable-next-line camelcase
  const dosisRegimen = therapy?.dosis_regimen;
  return <img src={Pill} alt="Pill" title={dosisRegimen || null} />;
};
export const EHRIcon = ({ className }) => <img src={EHR} alt="EHR" className={className} />;
export const SMSIcon = ({ className }) => <img src={SMS} alt="SMS" className={className} />;
export const WKIcon = ({ className }) => <img src={WK} alt="WK" className={className} />;
export const PRNIcon = ({ className }) => <img src={PRNGreyIcon} alt="PRN" className={className} />;
export const CAIcon = ({ className }) => <img src={CAIGreyIcon} alt="CA" className={className} />;
export const CMIcon1 = ({ className }) => (
  <img src={CMGreyIconI} alt="C-I " className={className} />
);
export const CMIcon2 = ({ className }) => (
  <img src={CMGreyIconII} alt="C-II" className={className} />
);
export const CMIcon3 = ({ className }) => (
  <img src={CMGreyIconIII} alt="C-III" className={className} />
);
export const CMIcon4 = ({ className }) => (
  <img src={CMGreyIconIV} alt="C-IV" className={className} />
);
export const CMIcon5 = ({ className }) => <img src={CMGreyIconV} alt="C-V" className={className} />;

export const getCMIcon = (controlledSubstanceCode, classVar) => {
  switch (controlledSubstanceCode) {
    case '1':
      return <CMIcon1 className={classVar} />;
    case '2':
      return <CMIcon2 className={classVar} />;
    case '3':
      return <CMIcon3 className={classVar} />;
    case '4':
      return <CMIcon4 className={classVar} />;
    case '5':
      return <CMIcon5 className={classVar} />;
    default:
      return <></>;
  }
};

export const APIcon = ({ className, style = {} }) => (
  <img src={APGreyIcon} alt="AP" style={style} className={className} />
);
export const NoteMethodIcon = ({ className, title }) => (
  <img src={NoteMethod} alt="NoteMethod" className={className} title={title} />
);
export const RectangleIcon = ({ className }) => <Rectangle className={className} />;

export const StarIcon = ({ className }) => (
  <img src={Star} alt="User custom list" className={className} />
);

export const StarIconSelected = ({ className }) => (
  <img src={StarSelected} alt="User custom list" className={className} />
);

export const MoneyIcon = ({ style }) => <Icon icon={coinDollar} className={style} />;
export const DuplicateProfilesDetails = ({ direction }) => {
  if (direction === 'open') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
        title="chevron up"
      >
        <path
          fillRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      title="chevron down"
    >
      <path
        fillRule="evenodd"
        // eslint-disable-next-line max-len
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};
