/* eslint-disable react/destructuring-assignment */
import AddressSearchBar from 'components/form/address/address_search_bar';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import cx from 'classnames';
import React from 'react';
import { ADD_ADDRESS_FORM } from 'constants/index';
import { addressTypes } from 'constants/lists';
import { buildQaId } from 'utils/build-qa-id';
import { change, Field, InjectedFormProps, reduxForm, untouch } from 'redux-form';
import { compose } from 'recompose';
import { ContactUtil } from 'utils/contact-util';
import { displayAddress } from 'services/utils/task-service';
import { getModalStyle } from 'services/utils/styles-service';
import { Button, Grid, Modal, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IState } from 'interfaces/redux/IState';
import { nameOfFactory } from 'utils/types-util';
import { PatientUtil } from 'utils/patient-util';
import { renderDropdown, renderTextField } from 'components/form/field/redux-field';
import { required } from 'components/form/validation/validation';
import { useContactOptions, valueIfPatientSelected } from 'hooks/useContactOptions';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignRankingAndKeys,
  formatContactName,
  formatPatientName,
} from 'services/utils/demographic-service';
import { addContactModalActions } from 'actions/action-add-contact-modal';
import { IAddressSuggestions } from 'components/react-hook-form-fields/types';
import AddIcon from '@mui/icons-material/Add';
import { styles } from './add-address-modal.styles';
import { IProps } from './interfaces/IProps';
import { IFormProps } from './interfaces/IFormProps';
import { editPatient } from '../../actions/action-patient';
import { editContact } from '../../actions/action-contacts';
import { addAddressModalActions } from '../../actions/action-add-address-modal';

interface IAddAddressModalProps extends IProps, InjectedFormProps<IFormProps> {}
type Props = IAddAddressModalProps;

const qaIdBuilder = buildQaId('add-address-dialog');
const nameOfFormFields = nameOfFactory<IFormProps>();

// eslint-disable-next-line react/function-component-definition, @typescript-eslint/naming-convention
const AddAddressModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes, reset, form } = props;

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [saveDisabled, setSaveDisabled] = React.useState<boolean>(false);

  const patient = useSelector((state: IState) => state.patient);
  const contacts = useSelector((state: IState) => state.contactList);
  const addAddressModal = useSelector((state: IState) => state.addAddressModal);
  const contactOptions = useContactOptions();
  const dispatch = useDispatch();
  const formatFieldValue = (value: string) => {
    if (addAddressModal.mode === 'therapies') {
      return {
        label: value,
        value,
      };
    }
    return value;
  };

  const handleCancel = () => {
    setErrorMessage('');
    setSaveDisabled(false);
    reset();
    if (addAddressModal.formId && addAddressModal.field) {
      dispatch(untouch(addAddressModal.formId, addAddressModal.field));
    }
    dispatch(addAddressModalActions.resetModal());
  };

  const submitNewPatientAddress = (formValues: Partial<IFormProps>) => {
    const rankedAddresses = assignRankingAndKeys(
      (patient.addresses || []).concat(formValues as []),
    );
    const payload = {
      ...PatientUtil.convertPatientFromStateForUpdate(patient),
      addresses: JSON.stringify(rankedAddresses),
    };
    (dispatch(editPatient(payload)) as unknown as Promise<object>)
      .then(() => {
        if (addAddressModal.formId && addAddressModal.field) {
          const name = formatPatientName(patient);
          const address = displayAddress(formValues);
          dispatch(
            change(
              addAddressModal.formId,
              addAddressModal.field,
              formatFieldValue(`${name} - ${address}`),
            ),
          );
        }
        handleCancel();
        dispatch(addAddressModalActions.resetModal());
      })
      .catch(() => {
        setSaveDisabled(false);
        setErrorMessage('Could not update patient');
      });
  };
  const submitNewContactAddress = (contactId: number, formValues: Partial<IFormProps>) => {
    const theContact = contacts[contactId];
    const updatedAddresses = [...(theContact.addresses ?? []), formValues];
    const rankedAddresses = assignRankingAndKeys(updatedAddresses);
    const payload = {
      ...ContactUtil.convertContactFromStateForUpdate(theContact),
      addresses: JSON.stringify(rankedAddresses),
    };

    (dispatch(editContact(payload)) as unknown as Promise<object>)
      .then(() => {
        if (addAddressModal.formId && addAddressModal.field) {
          const name = formatContactName(theContact);
          const address = displayAddress(formValues);
          dispatch(
            change(
              addAddressModal.formId,
              addAddressModal.field,
              formatFieldValue(`${name} - ${address}`),
            ),
          );
        }
        handleCancel();
      })
      .catch(() => {
        setSaveDisabled(false);
        setErrorMessage('Could not update contact');
      });
  };

  const handleSubmit = async (formValues: Partial<IFormProps>) => {
    setSaveDisabled(true);

    const { contact, ...restFormValues } = formValues;

    if (contact === valueIfPatientSelected) {
      submitNewPatientAddress(restFormValues);
    } else if (contact) {
      submitNewContactAddress(contact, restFormValues);
    }
  };

  return (
    <Modal open={addAddressModal.visible} data-qa-id={qaIdBuilder('modal')}>
      <form data-qa-id={qaIdBuilder('form')}>
        <div style={getModalStyle()} className={classes.addModal}>
          <Grid container>
            {errorMessage && (
              <Grid item xs={12} className={classes.row}>
                <Typography className={classes.error}>{errorMessage}</Typography>
              </Grid>
            )}
            <Grid item xs={12} className={classes.row}>
              <Typography variant="h6" className={classes.heading}>
                Add New Address
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Button
                onClick={() => {
                  dispatch(
                    addContactModalActions.setState({
                      visible: true,
                      formToUpdate: {
                        formName: form,
                        fieldName: nameOfFormFields('contact'),
                      },
                    }),
                  );
                }}
                variant="outlined"
                startIcon={<AddIcon />}
              >
                New Contact
              </Button>
            </Grid>

            <Grid item xs={12} className={classes.row}>
              <Grid container spacing={1}>
                <Grid item xs={7}>
                  <Field
                    name={nameOfFormFields('contact')}
                    label="Contact *"
                    component={renderDropdown}
                    fields={contactOptions}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name={nameOfFormFields('use')}
                    label="Type *"
                    component={renderDropdown}
                    fields={addressTypes}
                    validate={[required]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={cx(classes.row, classes.lastRow)}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Field
                    name={nameOfFormFields('line1')}
                    label="Address *"
                    validate={[required]}
                    component={AddressSearchBar}
                    setSuggestions={(address: IAddressSuggestions) => {
                      props.change(nameOfFormFields('city'), address.city);
                      props.change(nameOfFormFields('state'), address.state);
                      props.change(nameOfFormFields('zip'), address.zip);
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Field
                    name={nameOfFormFields('line2')}
                    label={ADD_ADDRESS_FORM.line2.label}
                    component={renderTextField}
                    placeholder={ADD_ADDRESS_FORM.line2.placeholder}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name={nameOfFormFields('city')}
                    label="City"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Field
                    name={nameOfFormFields('state')}
                    label="State"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name={nameOfFormFields('zip')}
                    label="Zip"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ConfirmationPanel
                handleSubmit={props.handleSubmit(handleSubmit)}
                handleCancel={handleCancel}
                disableSubmit={saveDisabled}
              />
            </Grid>
          </Grid>
        </div>
      </form>
    </Modal>
  );
};

export default compose<Props, IAddAddressModalProps>(
  withStyles(styles),
  reduxForm({ form: 'add-address-modal' }),
)(AddAddressModal);
